import { useMemo, useState } from "react";
import ComparatorListItem from "src/services/ui/common-components/ComparatorListItem";
import { useEnergy } from "../energy/energyProvider";
import { Cart, CartType } from "./cart";
import { useCart } from "./cartProvider";
import { Brand, DualWithOffers, Offer } from "../energy/energy";
import EnergyDetailsModal from "../energy/EnergyDetailsModal";
import { PricePer } from "../common";
import iconGaz from "../../../assets/img/icons/icon-gas-plain.svg";

const GazSection = ({
  elements,
  priceFormat,
  togglePriceFormat,
  disableRemove = false,
}: {
  elements: Cart<CartType.Gas>[];
  priceFormat?: PricePer;
  togglePriceFormat?: () => void;
  disableRemove?: boolean;
}): JSX.Element => {
  const { offers, brands } = useEnergy();
  const { removeFromCart } = useCart();

  // State
  const [selectedOffer, setSelectedOffer] = useState<Offer | null>(null);
  const [selectedDual, setSelectedDual] = useState<
    (DualWithOffers & { brand: Brand }) | null
  >(null);

  const offersWithBrands = useMemo(
    () =>
      new Map(
        [...offers.values()]
          .filter((offer) => brands.has(offer.brandId))
          .map((offer) => [
            offer.id,
            { ...offer, brand: brands.get(offer.brandId)! },
          ]),
      ),
    [offers, brands],
  );

  return (
    <div className={"section"}>
      <h2 className={"cart-section-title"}>
        <img alt={"gaz"} className={"icon"} src={iconGaz} />
        Mon contrat de gaz
      </h2>

      {elements.length > 1 && (
        <div className={"info info-warning-soft full-w m-bottom-xs"}>
          Veuillez ne conserver qu’une seule de ces offres pour passer à l’étape
          suivante
        </div>
      )}

      <div className={"offers-list"}>
        {elements.map((element) => {
          if (offersWithBrands.has(element.data.offerId)) {
            const offer = offersWithBrands.get(element.data.offerId)!;
            return (
              <ComparatorListItem
                key={element.id + "-" + element.data.offerId}
                brand={offer.brand}
                calendlyContext={"energy"}
                cart={element}
                offer={offer}
                onDelete={
                  disableRemove ? undefined : (cartId) => removeFromCart(cartId)
                }
                pricePer={priceFormat}
                togglePricePer={togglePriceFormat}
                viewMore={() => setSelectedOffer(offer)}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
      {selectedOffer && (
        <EnergyDetailsModal
          brand={brands.get(selectedOffer.brandId)!}
          cart={true}
          offer={selectedOffer}
          onClose={() => setSelectedOffer(null)}
        />
      )}

      {selectedDual && (
        <EnergyDetailsModal
          brand={selectedDual.brand}
          cart={true}
          dual={selectedDual}
          onClose={() => setSelectedDual(null)}
        />
      )}
    </div>
  );
};

export default GazSection;
