import { parseISO } from "date-fns";
import { User } from "../../auth/user";
import { Location } from "../../google-maps/place";
import loggerBuilder from "../../logger";
import { typedPropertyToValueBuilder } from "../../notion/notion";
import { EnergyType } from "../energy/ConsumptionCalculatorDialog";
import { Brand as EnergyBrand, Offer as EnergyOffer } from "../energy/energy";
import { Home, Situation } from "../HomeInput";
import {
  Brand as InsuranceBrand,
  ClientType,
  Feature,
  FeatureCode,
  FlatLevel,
  HabitationUsage,
  HousingType,
} from "../insurance/insurance";
import {
  Brand as InternetBrand,
  InternetTechnology,
  Offer as InternetOffer,
} from "../internet/internet";
import { Brand as MovingBrand } from "../moving/moving";
import { Brand as WaterBrand } from "../water/water";

const logger = loggerBuilder("compare/cart");

export enum CartType {
  Electricity = "energy_elec",
  Gas = "energy_gas",
  Dual = "energy_dual",
  Insurance = "insurance",
  Internet = "internet",
  Water = "water",
  Moving = "moving",
}

export interface NewCart<T extends CartType>
  extends Pick<Cart<T>, "type" | "data"> {}
export interface Cart<
  Type extends CartType,
  Data = Type extends CartType.Electricity | CartType.Gas
    ? EnergyData
    : Type extends CartType.Dual
    ? DualData // NOTE: Only used to addToCart, splitted by the backend
    : Type extends CartType.Insurance
    ? InsuranceData
    : Type extends CartType.Internet
    ? InternetData
    : Type extends CartType.Water
    ? WaterData
    : Type extends CartType.Moving
    ? MovingData
    : never
> {
  id: string;
  createdAt: Date;
  type: Type;
  data: Data & {
    offerId?: string;
    brandId?: string;
    submittedBrandId?: string;
  };
}
export interface CartWithOffer<
  Type extends CartType,
  OfferType = Type extends CartType.Electricity | CartType.Gas
    ? EnergyOffer & { brand: EnergyBrand }
    : Type extends CartType.Insurance
    ? { brand: InsuranceBrand }
    : Type extends CartType.Internet
    ? InternetOffer & { brand: InternetBrand }
    : Type extends CartType.Moving
    ? { brand: MovingBrand }
    : Type extends CartType.Water
    ? { brand: WaterBrand }
    : null
> extends Cart<Type> {
  offer: OfferType;
  price: number | null;
}

export interface EnergyData {
  offerId: string; // Dual ID or Offer ID
  electricityConsumption: number;
  gasConsumption: number;
  address: Location;
  home: Home | null;
  situation: Situation | null;
  area: number | null;
  residentCount: number | null;
  heatingSource: EnergyType | null;
  hotWaterSource: EnergyType | null;
  cookingSource: EnergyType | null;
}
export interface DualData {
  electricity: EnergyData;
  gas: EnergyData;
}
export interface InsuranceData {
  brandId: string;
  price: number | null;
  simulatedPrice: number | null;
  calculatedFeatures: [FeatureCode, Feature<any>][];
  address: Location;
  home: Home | null;
  situation: Situation | null;
  housingType: HousingType | null;
  flatLevel: FlatLevel | null;
  clientType: ClientType | null;
  housingCategory: HabitationUsage | null;
  roomNumber: number | null;
  size: number | null;
}
export interface InternetData {
  offerId: string;
  address: Location;
  home: Home | null;
  situation: Situation | null;
  bestAvailableTechnology: InternetTechnology | null;
}
export interface WaterData {
  brandId: string;
  address: Location;
  home: Home | null;
  situation: Situation | null;
}
export interface MovingData {
  previousAddress: Location | null;
  newAddress: Location | null;
  home: Home | null;
  situation: Situation | null;
  submittedBrandId: string | null;
  surface: number | null;
  movingDate: Date | null;
}

export function mapRawCartsToCarts(
  rawCarts: unknown[],
): Map<string, Cart<any>> {
  return logger.withErrorCatcher(
    () =>
      new Map(
        rawCarts.map((cart: any) => {
          const propertyToValue = typedPropertyToValueBuilder(cart.properties);
          logger.debug(`Mapping cart: ${cart.id}`);

          const type = propertyToValue("Type", "select");
          const data = JSON.parse(propertyToValue("Related Data", "title"));

          const refinedType =
            type === "energy"
              ? (data as EnergyData).electricityConsumption > 0 &&
                (data as EnergyData).gasConsumption > 0
                ? CartType.Dual
                : (data as EnergyData).electricityConsumption > 0
                ? CartType.Electricity
                : CartType.Gas
              : type;

          return [
            cart.id,
            {
              id: cart.id,
              createdAt: parseISO(cart.created_time),
              type: refinedType,
              data,
            } as Cart<any>,
          ];
        }),
      ),
    "Error while parsing carts",
  );
}

export interface CartMutation<T extends CartType> {
  mutationId: string;
  tempCartId?: string;
  cartToAdd?: NewCart<T>;
  cartToRemove?: Cart<T>["id"];
}

export function getElementIdFromCart(cart: NewCart<any>): string | null {
  return (
    ([CartType.Electricity, CartType.Gas, CartType.Internet].includes(cart.type)
      ? cart.data.offerId
      : [CartType.Insurance, CartType.Water].includes(cart.type)
      ? cart.data.brandId
      : cart.type === CartType.Moving
      ? cart.data.submittedBrandId
      : null) || null
  );
}

export interface Checkout {
  userData:
    | (Pick<User, "firstname" | "lastname" | "phoneNumber"> & {
        asProfessional: boolean;
        civility: string;
        birthDate: Date;
      })
    | null;
  home: {
    address: Location;
    bat: string | null;
    floor: number | null;
    stair: string | null;
    rooms: number | null;
    surface: number | null;
    type: HousingType;
    usage: HabitationUsage;
    clientType: ClientType;
    withDigicodeOrIntercom: boolean;
  } | null;
  dates: { title: string; date: Date; key: string }[] | null;
  contract: {
    iban: string;
    bic: string;
    bankingInstitution: string;
    bankingHolderIsUser: boolean;
  } | null;
  elements: CartWithOffer<any>[];
}

export function prepareCheckoutToSubmit(checkout: Checkout): any {
  return {
    ...checkout,
    userData: checkout.userData && {
      ...checkout.userData,
      birthDate: checkout.userData.birthDate.toISOString(),
    },
    dates:
      checkout.dates &&
      checkout.dates.map((date) => ({
        ...date,
        date: date.date.toISOString(),
      })),
  };
}
