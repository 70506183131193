import React from "react";
import { useToastsWithIntl } from "../../services/toast-notifications";
import { LOGIN_LINK } from "../../routes/public";
import RegisterForm from "../../services/auth/components/RegisterForm";
import { useAuth } from "../../services/auth/apiProvider";
import { useNavigate } from "react-router-dom";
import Card from "../../services/ui/block/Card";
import CardHead from "../../services/ui/block/CardHead";
import CardBody from "../../services/ui/block/CardBody";
import { GtmEvent, useGtm } from "src/services/gtm/gtmProvider";
import { pickKeys } from "src/services/data-structures/object";

const Register = (): JSX.Element => {
  const auth = useAuth();
  const gtm = useGtm();
  const { toastError, toastSuccess } = useToastsWithIntl(["auth"]);
  const navigate = useNavigate();

  return (
    <Card className={"auth-card theme-light"}>
      <CardHead title={"Création de compte utilisateur"} />

      <CardBody>
        <RegisterForm
          onSubmit={(registerInfos, setSubmitting) => {
            return auth.register(registerInfos).then(
              () => {
                gtm?.push(GtmEvent.SIGN_UP, {
                  event_name: "sign_up",
                  user_data: pickKeys(registerInfos, ["email"]),
                });
                toastSuccess("auth:register.TOAST_SUCCESS");
                navigate(LOGIN_LINK);
                setSubmitting(false);
              },
              (err) => {
                if (err?.response?.status === 409)
                  toastError("auth:register.TOAST_ERROR_ALREADY_EXISTS");
                else toastError("auth:register.TOAST_ERROR");
              },
            );
          }}
        />
      </CardBody>
    </Card>
  );
};

export default Register;
