import NeedHelpBlock from "../../../services/ui/common-components/NeedHelpBlock";
import Faq from "src/services/common-components/Faq";
import { FAQService } from "src/services/content/content";

import WaterComparator from "./WaterComparator";

const WaterPage = (): JSX.Element => {
  return (
    <div>
      <WaterComparator />
      <Faq service={FAQService.Water} />
      <NeedHelpBlock
        service={"water"}
        text={
          "Contactez notre équipe d'experts du service des eaux pour obtenir une réponse simple et rapide."
        }
      />
    </div>
  );
};

export default WaterPage;
