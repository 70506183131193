import FSelectParse from "./FSelectParse";
import { useYupField } from "./Form";
import { AnySchema, Schema } from "yup";
import { Namespace, TFuncKey, useTranslation } from "react-i18next";
import { getNumericEnumEntries } from "../data-structures/enum";

const FSelect = ({
  name,
  disabled,
  className,
  id,
  placeholder,
}: {
  name: string;
  disabled?: boolean;
  className?: string;
  id?: string;
  placeholder?: string;
}): JSX.Element => {
  const fieldSchema = useYupField(name) as AnySchema;

  const { enum: enumList, translate } = fieldSchema.meta() as NonNullable<
    Schema["metaInterface"]
  >;
  const { t } = useTranslation(translate ? ([translate[0]] as Namespace) : []);

  return (
    <FSelectParse className={className} disabled={disabled} id={id} name={name}>
      {fieldSchema.spec.nullable && <option>{placeholder}</option>}
      {Array.isArray(enumList)
        ? enumList
            .filter((value): value is string | number => value !== null)
            .map((value) => (
              <option key={value} value={value}>
                {translate ? t(`${translate[1]}.${value}` as TFuncKey) : value}
              </option>
            ))
        : getNumericEnumEntries(enumList).map(([id, name]) => (
            <option key={id} value={id}>
              {t(
                `${
                  (translate as NonNullable<typeof translate>)[1]
                }.${name}` as TFuncKey,
              )}
            </option>
          ))}
    </FSelectParse>
  );
};

export default FSelect;
