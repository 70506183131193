import { useState } from "react";
import { useMoving } from "../../../services/compare/moving/movingProvider";
import { Brand } from "../../../services/compare/moving/moving";
import MovingDetailsDialog from "../../../services/compare/moving/components/MovingDetailsDialog";
import MovingQueryDetailsForm from "../../../services/compare/moving/components/MovingQueryDetailsForm";
import iconArrowRightWhite from "../../../assets/img/icons/icon-arrow-circle-right-white.svg";
import Dialog from "../../../services/ui/block/Dialog";
import iconReload from "../../../assets/img/icons/icon-reload-light-blue.svg";
import confirmImage from "../../../assets/img/carousel/moving/movingConfirm.png";
import imgMovingCosts from "../../../assets/img/moving-cost.png";
import imgMovingFormulas from "../../../assets/img/moving-formulas.png";

import BrandLogo1 from "../../../assets/img/brand-logos/moving/bhb.png";
import BrandLogo2 from "../../../assets/img/brand-logos/moving/demenageurs-bretons.png";
import BrandLogo3 from "../../../assets/img/brand-logos/moving/schenker.png";
import BrandLogo4 from "../../../assets/img/brand-logos/moving/demeco.png";
import BrandLogo5 from "../../../assets/img/brand-logos/moving/ags.png";
import BrandLogo6 from "../../../assets/img/brand-logos/moving/gentlemen.png";
import BrandLogo7 from "../../../assets/img/brand-logos/moving/giraud.png";
import HowItWorksMoving from "../../../services/compare/moving/components/HowItWorksMoving";
import MovingTeam from "src/services/compare/moving/components/MovingTeam";
import MovecoolReinsuranceBlock from "src/services/ui/common-components/MovecoolReinsuranceBlock";

const MovingComparator = (): JSX.Element => {
  // Store
  const {
    // brands,
    movingDate,
    newAddress,
    previousAddress,
    surface,
  } = useMoving();
  // const { formatDate } = useDate();

  // State
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
  const [needToFillQueryDetails, setNeedToFillQueryDetails] = useState(
    !(movingDate && newAddress && previousAddress && !!surface),
  );
  const [showQueryDetailsDialog, setShowQueryDetailsDialog] = useState(false);

  // Filters
  // const filteredBrands = useMemo(() => [...brands.values()], [brands]);

  // Sort
  // const sortedBrands = useMemo(
  //   () =>
  //     [...filteredBrands].sort((a, b) => {
  //       if (a.postalCode && b.postalCode && previousAddress?.postCode) {
  //         const prevPostCode = Number(previousAddress?.postCode);
  //         const aDistance = Math.abs(Number(a.postalCode) - prevPostCode);
  //         const bDistance = Math.abs(Number(b.postalCode) - prevPostCode);
  //         return aDistance - bDistance;
  //       } else {
  //         return 0;
  //       }
  //     }),
  //   [filteredBrands, previousAddress?.postCode],
  // );

  return (
    <div>
      {needToFillQueryDetails ? (
        <div className={"container-wrap --wrap--s"}>
          <div className={"section"}>
            <MovingQueryDetailsForm
              afterSubmit={() => setNeedToFillQueryDetails(false)}
            />
          </div>
          <div className={"section"}>
            <div className={"card-block"}>
              <div className={"card-head"}>
                <h2 className={"card-title"}>
                  Combien coûte un déménagement en France ?
                </h2>
              </div>
              <div className={"card"}>
                <div className={"card-body"}>
                  <img
                    alt={"coût déménagement"}
                    className={"img-block"}
                    src={imgMovingCosts}
                  />
                  <div className={"section body-s center"}>
                    Les informations présentées dans le tableau ci-dessus sont
                    fournies à titre indicatif pour les formules « transport »
                    uniquement. Le meilleur moyen pour vous de savoir combien va
                    couter votre déménagement est de recevoir des devis
                    personnalisés en cliquant sur le bouton ci dessous.
                    <div className={"margin-top-l center"}>
                      {needToFillQueryDetails ? (
                        <button
                          className={"btn-1"}
                          onClick={() => setShowQueryDetailsDialog(true)}
                        >
                          Recevoir des devis personnalisés
                          <img
                            alt={"étape suivante"}
                            className={"icon icon-right"}
                            src={iconArrowRightWhite}
                          />
                        </button>
                      ) : (
                        <button
                          className={"link-3-s margin-auto"}
                          onClick={() => setShowQueryDetailsDialog(true)}
                        >
                          <img
                            alt={"étape suivante"}
                            className={"icon icon-left"}
                            src={iconReload}
                          />
                          Modifier mes informations
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"section"}>
            <div className={"card-block"}>
              <div className={"card-head"}>
                <h2 className={"card-title"}>
                  Quelles sont les formules disponibles ?
                </h2>
              </div>
              <div className={"card"}>
                <div className={"card-body"}>
                  <img
                    alt={"formules déménagement"}
                    className={"img-block"}
                    src={imgMovingFormulas}
                  />
                  <div className={"section body-s center"}>
                    Les informations présentées dans le tableau ci-dessus sont
                    fournies à titre indicatif et peuvent varier selon
                    l’entreprise choisie. Le meilleur moyen pour vous de savoir
                    combien va couter votre déménagement est de recevoir des
                    devis personnalisé en cliquant sur le bouton ci dessous.
                    <div className={"margin-top-l center"}>
                      {needToFillQueryDetails ? (
                        <button
                          className={"btn-1"}
                          onClick={() => setShowQueryDetailsDialog(true)}
                        >
                          Recevoir des devis personnalisés
                          <img
                            alt={"étape suivante"}
                            className={"icon icon-right"}
                            src={iconArrowRightWhite}
                          />
                        </button>
                      ) : (
                        <button
                          className={"link-3-s margin-auto"}
                          onClick={() => setShowQueryDetailsDialog(true)}
                        >
                          <img
                            alt={"étape suivante"}
                            className={"icon icon-left"}
                            src={iconReload}
                          />
                          Modifier mes informations
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={"section-xl container-wrap --wrap--m"}>
          <img alt={""} className={"banner-img"} src={confirmImage} />
          {/* {(sortedBrands.length > 0 && (
            <>
              <div className={"section"}>
                <div className={"info info-success full-w"}>
                  <h2>Vous allez être recontactés</h2>
                  <br />
                  <h3>
                    Une mise en concurrence sera effectuée
                    <br />
                    une fois votre dossier validé par notre expert déménagement.
                  </h3>
                  <p className={"body"}>
                    Voici quelques déménageurs identifiés comme potentiellement
                    pertinents pour votre projet.
                  </p>
                </div>
              </div>
              <div className={"section"}>
                <LoadMoreList
                  items={sortedBrands}
                  loadMoreLabel={"Afficher plus d'offres"}
                  paginatedBy={10}
                  render={(item) => (
                    <MovingListCard key={item.id} brand={item} />
                  )}
                />
              </div>
            </>
          )) || (
            <div className={"card"}>
              <div className={"card-body center"}>Aucune offre a proximité</div>
            </div>
          )} */}
        </div>
      )}

      <div className={"section-xxl"}>
        <h2 className={"title-m center"}>
          +1500 entreprises de déménagement comparées
        </h2>
        <p className={"center"}>Tous les déménageurs sont sur Movecool</p>
        <div className={"brand-logos"}>
          <img alt={"Alpiq"} src={BrandLogo1} />
          <img alt={"Engie"} src={BrandLogo2} />
          <img alt={"Ekwateur"} src={BrandLogo3} />
          <img alt={"Edf"} src={BrandLogo4} />
          <img alt={"Vattenfall"} src={BrandLogo5} />
          <img alt={"Total Energies"} src={BrandLogo6} />
          <img alt={"Ilek"} src={BrandLogo7} />
        </div>
      </div>

      <HowItWorksMoving />

      <MovingTeam />

      <MovecoolReinsuranceBlock />

      {/* <div className={"comparator-list"}>
        <div className={"grid"}>
          <div className={"comparator-sidebar"}>
            <div className={"section"}>
              <div className={"card-block"}>
                <CardHead title={"Vos informations"} />
                <div className={"card"}>
                  <CardBody>
                    
                    {needToFillQueryDetails ? (
                      <p className={"body-s center"}>
                        Renseignez les informations sur votre déménagement pour
                        recevoir jusqu'à 6 devis d'entreprise de déménagement
                        différentes.
                      </p>
                    ) : (
                      <div>
                        <div className={"filter-group"}>
                          <div className={"filter-title"}>
                            <img
                              alt={"adresse de départ"}
                              className={"icon"}
                              src={iconLocation}
                            />
                            Adresse de départ
                          </div>
                          <p>{previousAddress?.plainAddress}</p>
                        </div>
                        <div className={"filter-group"}>
                          <div className={"filter-title"}>
                            <img
                              alt={"adresse de départ"}
                              className={"icon"}
                              src={iconDestination}
                            />
                            Adresse d'arrivée
                          </div>
                          <p>{newAddress?.plainAddress}</p>
                        </div>
                        <div className={"filter-group"}>
                          <div className={"filter-title"}>
                            <img
                              alt={"adresse de départ"}
                              className={"icon"}
                              src={iconDate}
                            />
                            Date du déménagement
                          </div>
                          <p>{formatDate(movingDate!)}</p>
                        </div>
                        <div className={"filter-group"}>
                          <div className={"filter-title"}>
                            <img
                              alt={"adresse de départ"}
                              className={"icon"}
                              src={iconSurface}
                            />
                            Surface
                          </div>
                          <p>{surface} m²</p>
                        </div>
                      </div>
                    )}

                    <div className={"margin-top-l center"}>
                      {needToFillQueryDetails ? (
                        <button
                          className={"btn-1 btn-block"}
                          onClick={() => setShowQueryDetailsDialog(true)}
                        >
                          Recevoir des devis
                          <img
                            alt={"étape suivante"}
                            className={"icon icon-right"}
                            src={iconArrowRightWhite}
                          />
                        </button>
                      ) : (
                        <button
                          className={"link-3-s margin-auto"}
                          onClick={() => setShowQueryDetailsDialog(true)}
                        >
                          <img
                            alt={"étape suivante"}
                            className={"icon icon-left"}
                            src={iconReload}
                          />
                          Modifier mes informations
                        </button>
                      )}
                    </div>
                  </CardBody>
                </div>
              </div>
            </div>
            <div className={"section"}>
              <div className={"card-block"}>
                <CardHead title={"y avez-vous pensé ?"} />
                <div className={"card"}>
                  <CardBody>
                    <div className={"center"}>
                      <img
                        alt={"La Poste"}
                        className={"card-image"}
                        src={logoLaPoste}
                      />
                      <br />
                      <h3 className={"title-xs"}>Vous déménagez ?</h3>
                      <p>Transférez votre courrier !</p>
                    </div>
                    <div className={"section"}>
                      <a
                        className={"btn-outlined btn-block"}
                        href={"https://www.laposte.fr/demenagement-absence"}
                        rel={"noreferrer"}
                        target={"_blank"}
                      >
                        J'Y VAIS
                      </a>
                    </div>
                  </CardBody>
                </div>
              </div>
            </div>
          </div>
          <div className={"comparator-offers"}>
            <div className={"section-l"}></div>
            
          </div>
        </div>
      </div> */}

      {selectedBrand && (
        <MovingDetailsDialog
          brand={selectedBrand}
          onClose={() => setSelectedBrand(null)}
        />
      )}

      {showQueryDetailsDialog && (
        <Dialog
          className={"side-panel"}
          closeLabel={"Retour  x"}
          onClose={() => setShowQueryDetailsDialog(false)}
        >
          <MovingQueryDetailsForm
            afterSubmit={() => {
              setNeedToFillQueryDetails(false);
              setShowQueryDetailsDialog(false);
            }}
          />
        </Dialog>
      )}
    </div>
  );
};

export default MovingComparator;
