import { HTMLAttributes, useContext, useState } from "react";

import Dialog from "../../ui/block/Dialog";

import { Brand } from "./insurance";

import { useAuth } from "src/services/auth/apiProvider";

import PersonalDetailsDialog from "./PersonalDetailsDialog";
import { promiseThenOpenUrl } from "src/services/data-structures/promise";
import { getAcheelUrl } from "./api";
import { HousingContext } from "./housingProvider";

import ImgActionCall from "../../../assets/img/img-action-call.png";
import ImgActionRdv from "../../../assets/img/img-action-rdv.png";
import ImgActionOnline from "../../../assets/img/img-action-online.png";

const InsuranceActionsDialog = <
  P extends HTMLAttributes<HTMLDivElement> & {
    brand: Brand;
    onClose(): void;
  }
>({
  brand,
  onClose,
  ...props
}: P): JSX.Element => {
  const housingContext = useContext(HousingContext);
  const { generateCalendlyLink } = useAuth();
  const [
    showPersonalDetailsRequired,
    setShowPersonalDetailsRequired,
  ] = useState<string | null>(null);

  return (
    <>
      <Dialog
        className={"actions-dialog"}
        closeLabel={"Revenir aux offres"}
        onClose={onClose}
      >
        <h2 className={"title"}>Finaliser ma souscription</h2>
        <div className={"action-options"}>
          {(!!brand.url || brand.apiId === "acheel") && (
            <div className={"option --primary"}>
              <img
                alt={"souscrire en ligne"}
                className={"option-img"}
                src={ImgActionOnline}
              />
              <button
                className={"option-action"}
                onClick={() => {
                  if (brand.apiId === "acheel") {
                    setShowPersonalDetailsRequired(brand.apiId);
                  } else window.open(brand.url!);
                }}
              >
                Continuer en ligne
              </button>
              <div className={"option-label"}>3 minutes top chrono</div>
            </div>
          )}
          <div className={"option"}>
            <img alt={"appeler"} className={"option-img"} src={ImgActionCall} />
            <a className={"option-action"} href={"tel: +33980805110"}>
              09 80 80 51 10
            </a>
            <div className={"option-label"}>Réponse en moins de 90s</div>
          </div>
          <div className={"option"}>
            <img
              alt={"rendez-vous"}
              className={"option-img"}
              src={ImgActionRdv}
            />
            <a
              className={"option-action"}
              href={generateCalendlyLink("", "insurance")}
              rel={"noreferrer"}
              target={"_blank"}
            >
              Me faire rappeler
            </a>
            <div className={"option-label"}>Quand je veux</div>
          </div>
        </div>
      </Dialog>
      {showPersonalDetailsRequired && (
        <PersonalDetailsDialog
          onClose={() => setShowPersonalDetailsRequired(null)}
          onSubmit={(infos) => {
            if (showPersonalDetailsRequired === "acheel") {
              return promiseThenOpenUrl(() =>
                getAcheelUrl(housingContext!.acheelEstimate!.code, infos).then(
                  ({ data }) => data.url,
                ),
              ).then(() => setShowPersonalDetailsRequired(null));
            } else {
              return Promise.resolve();
            }
          }}
        />
      )}
    </>
  );
};

export default InsuranceActionsDialog;
