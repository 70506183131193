import { addMethod, date, mixed, string } from "yup";
import { getNumericEnumEntries } from "../data-structures/enum";
import { Namespace, TFuncKey } from "react-i18next";

addMethod(string, "password", function () {
  return this.required().meta({ password: true });
});

addMethod(date, "time", function () {
  return this.meta({ time: true });
});

addMethod(mixed, "disabled", function () {
  return this.test("disabled", () => true);
});

addMethod(mixed, "notEditable", function () {
  return this.nullable()
    .optional()
    .meta({ disabled: true })
    .transform(() => undefined);
});

addMethod(mixed, "notVisible", function () {
  return this.nullable()
    .optional()
    .meta({ notVisible: true })
    .transform(() => null);
});

addMethod(string, "multiline", function () {
  return this.meta({ multiline: true });
});

addMethod(
  mixed,
  "oneOfEnum",
  function (
    values: Record<string | number | symbol, unknown> | Array<unknown>,
    namespace?: Namespace,
    baseKey?: TFuncKey<Namespace>,
    nullValue?: boolean,
  ) {
    if (Array.isArray(values)) {
      return this.oneOf(values as any).meta({
        translate: namespace ? [namespace, baseKey] : undefined,
        select: true,
        enum: values,
      });
    } else {
      const numericEntries = getNumericEnumEntries(values);
      return this.clone({ nullable: nullValue ? true : this.spec.nullable })
        .oneOf(
          (numericEntries as (number | null)[][])
            .map(([id]) => id)
            .concat(nullValue ? [null] : []) as number[],
        )
        .meta({
          translate: [namespace, baseKey],
          enum: values,
          select: true,
        });
    }
  },
);
