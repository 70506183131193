import { AxiosPromise } from "axios";
import baseAPI from "../../auth/api";
import { Cart, CartMutation, CartType, NewCart } from "./cart";

export function getCart(userId: string): AxiosPromise<unknown[]> {
  return baseAPI.get(`/carts/${userId}`);
}

export function addToCart<T extends CartType>(
  userId: string,
  cart: NewCart<T>,
): AxiosPromise<unknown[]> {
  return baseAPI.post(`/carts/${userId}`, cart);
}

export function deleteFromCart(
  userId: string,
  cartId: Cart<never>["id"],
): AxiosPromise<unknown[]> {
  return baseAPI.delete(`/carts/${userId}/${cartId}`);
}

export function submitCart(
  userId: string,
  cartsIds: Cart<never>["id"][],
  cartEstimatedAmount: number,
  complementaryData: any,
  temporaryData: any,
): AxiosPromise<unknown[]> {
  return baseAPI.post(`/carts/${userId}/submit`, {
    cartsIds,
    cartEstimatedAmount,
    complementaryData,
    temporaryData,
  });
}

export function pushCartMutations(
  userId: string,
  cartMutations: CartMutation<any>[],
  override: boolean,
): AxiosPromise<unknown[]> {
  return baseAPI.post(
    `/carts/${userId}/batch?override=${override}`,
    cartMutations,
  );
}

export function saveHousingDataRequest(
  userId: string,
  checkout: any,
  housing: any,
): AxiosPromise<void> {
  return baseAPI.post(`/carts/${userId}/housing`, {
    checkout,
    housing,
  });
}

export function saveContractDataRequest(
  userId: string,
  checkout: any,
  housing: any,
  contract: any,
): AxiosPromise<void> {
  return baseAPI.post(`/carts/${userId}/contract`, {
    checkout,
    housing,
    contract,
  });
}
