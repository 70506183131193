// import { useState } from "react";
import ComparatorListItem from "src/services/ui/common-components/ComparatorListItem";
import { PricePer } from "../common";
// import { OfferWithBrand } from "../internet/internet";
// import InternetDetailsDialog from "../internet/InternetDetailsDialog";
import { useInternet } from "../internet/internetProvider";
import { Cart, CartType } from "./cart";
import { useCart } from "./cartProvider";
import iconInternet from "../../../assets/img/icons/icon-internet-plain.svg";

const InternetSection = ({
  elements,
  priceFormat,
  togglePriceFormat,
  disableRemove = false,
}: {
  elements: Cart<CartType.Internet>[];
  priceFormat?: PricePer;
  togglePriceFormat?: () => void;
  disableRemove?: boolean;
}): JSX.Element => {
  const { offersWithBrands } = useInternet();
  const { removeFromCart } = useCart();

  // const [selectedOffer, setSelectedOffer] = useState<OfferWithBrand | null>(
  //   null,
  // );

  return (
    <div className={"section"}>
      <h2 className={"cart-section-title"}>
        <img alt={"Internet"} className={"icon"} src={iconInternet} />
        Ma box internet
      </h2>

      {elements.length > 1 && (
        <div className={"info info-warning-soft full-w m-bottom-xs"}>
          Veuillez ne conserver qu’une seule de ces offres pour passer à l’étape
          suivante
        </div>
      )}

      <div className={"offers-list"}>
        {elements.map((element) => {
          if (offersWithBrands.has(element.data.offerId)) {
            const offer = offersWithBrands.get(element.data.offerId)!;

            return (
              <ComparatorListItem
                key={element.data.offerId}
                brand={offer.brand}
                calendlyContext={"internet"}
                cart={element}
                dataCompleteCondition={true}
                offer={offer}
                onDelete={
                  disableRemove ? undefined : (cartId) => removeFromCart(cartId)
                }
                pricePer={priceFormat}
                togglePricePer={togglePriceFormat}
                // viewMore={() => setSelectedOffer(offer)}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
      {/* {selectedOffer && (
        <InternetDetailsDialog
          cart={true}
          offer={selectedOffer}
          onClose={() => setSelectedOffer(null)}
        />
      )} */}
    </div>
  );
};

export default InternetSection;
