import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Highlight,
  isLocationMatching,
  PricePer,
  sortByRelevance,
} from "../common";
import ConsumptionCalculatorDialog, {
  ConsumptionForm,
} from "./ConsumptionCalculatorDialog";
import ConsumptionFormatButton from "./ConsumptionFormatButton";
import {
  ConsumptionCalculatorStep,
  useConsumption,
} from "./consumptionProvider";
import {
  Brand,
  Dual,
  DualWithOffers,
  EnergyType,
  Offer,
  OfferFeature,
} from "./energy";
import EnergyDetailsModal from "./EnergyDetailsModal";
import EnergyHighlightedCard from "./EnergyHighlightedCard";
import { useEnergy } from "./energyProvider";
import { multiSorts, orderByField } from "../../data-structures/array";
import { usePlace } from "../../google-maps/placeProvider";
import { toFixed } from "../../numbers/formatting";
import { withLoader } from "../../routing/useLoader";
import Card from "../../ui/block/Card";
import CardBody from "../../ui/block/CardBody";
import CardHead from "../../ui/block/CardHead";
import Button from "../../ui/reactive/Button";
import { cx } from "@emotion/css";
import iconEnergy from "../../../assets/img/icons/icon-energy-dark.svg";
import iconElectricity from "../../../assets/img/icons/icon-electricity-blue.svg";
import iconGas from "../../../assets/img/icons/icon-gas-blue.svg";
import iconOptions from "../../../assets/img/icons/icon-options-blue.svg";
import iconLocationBlue from "../../../assets/img/icons/icon-location-blue.svg";
import iconStarBlue from "../../../assets/img/icons/icon-star-blue.svg";
import WhatIsAnEldDialog from "./WhatIsAnEldDialog";
import LoadMoreList from "../../ui/reactive/LoadMoreList";
import iconFilter from "../../../assets/img/icons/icon-filters-blue.svg";
import ComparatorListItem from "../../ui/common-components/ComparatorListItem";
import { useCart } from "../../compare/cart/cartProvider";
import { CartType } from "../../compare/cart/cart";
import OrderByPhone from "src/services/common-components/OrderByPhone";
import iconReload from "../../../assets/img/icons/icon-reload-light-blue.svg";
import { useNavigate } from "react-router-dom";
import { CART_LINK } from "src/routes/cart";
import iconCart from "../../../assets/img/icons/icon-cart-green.svg";
import Dialog from "../../ui/block/Dialog";
import { GtmEvent, useGtm } from "../../gtm/gtmProvider";
import BrandLogo1 from "../../../assets/img/brand-logos/energy/alpiq.png";
import BrandLogo2 from "../../../assets/img/brand-logos/energy/engie.png";
import BrandLogo3 from "../../../assets/img/brand-logos/energy/ekwateur.png";
import BrandLogo4 from "../../../assets/img/brand-logos/energy/edf.png";
import BrandLogo5 from "../../../assets/img/brand-logos/energy/vattenfall.png";
import BrandLogo6 from "../../../assets/img/brand-logos/energy/total.png";
import BrandLogo7 from "../../../assets/img/brand-logos/energy/ilek.png";
import HowItWorksMain from "src/services/common-components/HowItWorksMain";
import MovecoolReinsuranceBlock from "src/services/ui/common-components/MovecoolReinsuranceBlock";
import EnergyTeam from "./EnergyTeam";
import EnergyActionsDialog from "./EnergyActionsDialog";
import WhyMovecool from "src/services/common-components/WhyMovecool";

enum Sort {
  Relevance,
  PriceAsc,
  PriceDesc,
}

const EnergyComparator = (): JSX.Element => {
  // Store
  const {
    electricityConsumption,
    gasConsumption,
    area,
    cookingSource,
    heatingSource,
    hotWaterSource,
    lastConsumptionCalculatorStep,
    residentCount,
    isSetConsumption,
  } = useConsumption();
  const { brands, duals, offers } = useEnergy();
  const { currentLocation } = usePlace();
  const { push } = useGtm();

  // State
  const [selectedOffer, setSelectedOffer] = useState<Offer | null>(null);
  const [selectedDual, setSelectedDual] = useState<
    (DualWithOffers & { brand: Brand }) | null
  >(null);
  const [energyType, setEnergyType] = useState(EnergyType.Electricity);
  const [showConsumptionDialog, setShowConsumptionDialog] = useState(false);
  const [calculatedConsumtionFormat, setCalculatedConsumtionFormat] = useState(
    PricePer.Year,
  );
  const [showEldInfo, setShowEldInfo] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const attachedEld = useMemo(
    () =>
      [...brands.values()].find(
        (brand) =>
          currentLocation &&
          isLocationMatching(
            currentLocation.postCode!,
            brand.postalCodes,
            false,
          ),
      ) || null,
    [brands, currentLocation],
  );

  // Filters
  const [withdrawnFilter, setWithdrawnFilter] = useState(false);
  const [greenFilter, setGreenFilter] = useState(false);
  const [fixedPriceFilter, setFixedPriceFilter] = useState(false);
  // NOTE: Only the offers with the selected type and the good location
  const goodTypeOffers: Offer[] = useMemo(() => {
    if (attachedEld !== null) {
      // If we are in an ELD territory
      const eld = [...brands.values()].find(
        (brand) =>
          !currentLocation ||
          isLocationMatching(
            currentLocation.postCode!,
            brand.postalCodes,
            false,
          ),
      )!;
      return [...offers.values()].filter(
        (offer) => offer.type === energyType && offer.brandId === eld.id,
      );
    } else {
      // Else return all the non ELD offers
      return [...offers.values()].filter(
        (offer) =>
          offer.type === energyType &&
          brands.get(offer.brandId)?.postalCodes.length === 0,
      );
    }
  }, [attachedEld, brands, offers, currentLocation, energyType]);
  // NOTE: The offers that will be displayed in the list
  const filteredOffers = useMemo(
    () =>
      goodTypeOffers.filter(
        (offer) =>
          (!withdrawnFilter || !offer.withdrawn) &&
          (!greenFilter || offer.features.includes(OfferFeature.GreenEnergy)) &&
          (!fixedPriceFilter || offer.fixed_price),
      ),
    [goodTypeOffers, greenFilter, fixedPriceFilter, withdrawnFilter],
  );
  // NOTE: The duals that will be displayed in the list
  const filteredDuals = useMemo(() => {
    let filteredDuals: Dual[] = [];

    if (attachedEld !== null) {
      // If we are in an ELD territory
      const eld = [...brands.values()].find(
        (brand) =>
          !currentLocation ||
          isLocationMatching(
            currentLocation.postCode!,
            brand.postalCodes,
            false,
          ),
      )!;
      filteredDuals = [...duals.values()].filter((dual) => {
        const brandId = offers.get(dual.electricity)?.brandId;
        return brandId === eld.id;
      });
    } else {
      // Else return all the non ELD duals
      filteredDuals = [...duals.values()].filter((dual) => {
        const brandId = offers.get(dual.electricity)?.brandId;
        if (!brandId) return false;
        const postalCodes = brands.get(brandId)?.postalCodes;
        return postalCodes && postalCodes.length === 0;
      });
    }

    return filteredDuals
      .filter((dual) => {
        const electricityOffer = offers.get(dual.electricity)!;
        const gasOffer = offers.get(dual.gas)!;

        return (
          (!greenFilter ||
            (electricityOffer.features.includes(OfferFeature.GreenEnergy) &&
              gasOffer.features.includes(OfferFeature.GreenEnergy))) &&
          (!fixedPriceFilter ||
            (electricityOffer.fixed_price && gasOffer.fixed_price))
        );
      })
      .map((dual) => {
        const electricityOffer = offers.get(dual.electricity)!;
        const gasOffer = offers.get(dual.gas)!;

        // NOTE: The sort on the dual is the sum of the offer prices if no consumption is given
        // NOTE: If a consumption is given, the sort is the consumption * price for each offer
        let totalPrice =
          electricityConsumption || gasConsumption
            ? 0
            : electricityOffer.price + gasOffer.price;
        if (electricityConsumption)
          totalPrice += electricityConsumption * electricityOffer.price;
        if (gasConsumption) totalPrice += gasConsumption * gasOffer.price;

        return {
          ...dual,
          brand: brands.get(electricityOffer.brandId)!,
          electricityOffer,
          gasOffer,
          totalPrice,
        };
      });
  }, [
    brands,
    currentLocation,
    duals,
    electricityConsumption,
    fixedPriceFilter,
    gasConsumption,
    greenFilter,
    attachedEld,
    offers,
  ]);

  // Sort
  const [sort, setSort] = useState(Sort.Relevance);
  const sortedOffers = useMemo(
    () =>
      [...filteredOffers].sort(
        sort === Sort.Relevance
          ? multiSorts(sortByRelevance, orderByField("price", false))
          : sort === Sort.PriceAsc
          ? orderByField("price", false)
          : orderByField("price", true),
      ),
    [filteredOffers, sort],
  );
  const sortedDuals = useMemo(
    () =>
      [...filteredDuals].sort(
        sort === Sort.Relevance
          ? multiSorts(sortByRelevance, orderByField("totalPrice", true))
          : sort === Sort.PriceAsc
          ? orderByField("totalPrice", false)
          : orderByField("totalPrice", true),
      ),
    [filteredDuals, sort],
  );

  // Memos
  const electricityOffers = useMemo(
    () =>
      [...offers.values()].filter(
        (offer) => offer.type === EnergyType.Electricity,
      ),
    [offers],
  );

  const offerHighlights = useMemo(() => {
    return energyType !== EnergyType.Dual
      ? goodTypeOffers.reduce(
          (highlights, offer) =>
            offer.highlights.length > 0
              ? highlights.concat(
                  offer.highlights.map((h) => ({
                    label: h,
                    element: { offer, brand: brands.get(offer.brandId) },
                  })),
                )
              : highlights,
          [] as Highlight[],
        )
      : [];
  }, [brands, energyType, goodTypeOffers]);

  const dualHighlights = useMemo(() => {
    return energyType === EnergyType.Dual
      ? [...duals.values()].reduce((highlights, dual) => {
          if (dual.highlights.length > 0) {
            const electricityOffer = offers.get(dual.electricity);
            const gasOffer = offers.get(dual.gas);
            const brand = electricityOffer
              ? brands.get(electricityOffer.brandId)
              : undefined;

            return highlights.concat(
              dual.highlights.map((h) => ({
                label: h,
                element: {
                  dual: { ...dual, electricityOffer, gasOffer, brand },
                },
              })),
            );
          } else {
            return highlights;
          }
        }, [] as Highlight[])
      : [];
  }, [brands, duals, energyType, offers]);

  // Effects
  useEffect(() => {
    // NOTE: If we dont have the electricity consumption and gas consumption,
    // NOTE: we open the consumption calculator dialog after 3 minutes.
    if (electricityConsumption === null && gasConsumption === null) {
      const timeout = setTimeout(() => {
        setShowConsumptionDialog(true);
      }, 1000 * 60 * 3);
      return () => clearTimeout(timeout);
    }

    if (electricityConsumption !== null && gasConsumption !== null) {
      setEnergyType(EnergyType.Dual);
    } else if (electricityConsumption !== null) {
      setEnergyType(EnergyType.Electricity);
    } else if (gasConsumption !== null) {
      setEnergyType(EnergyType.Gas);
    }
  }, [electricityConsumption, gasConsumption]);

  const { addToCart } = useCart();
  const navigate = useNavigate();

  const scrollToHighlight = useCallback(() => {
    const sectionHighlightTitle = document.getElementById("comparator");
    sectionHighlightTitle!.scrollIntoView({ behavior: "smooth" });
  }, []);

  const [chosenOffer, setChosenOffer] = useState<Offer | Dual | null>(null);

  return (
    <div className={"margin-top-l"} id={"comparator"}>
      {!isSetConsumption && (
        <div className={"container-wrap --wrap--s"}>
          <ConsumptionForm
            onSubmit={() => {
              setShowConsumptionDialog(false);
              push(GtmEvent.FORM, {
                event_name: "submit_monthly_estimation",
                item_category: "Énergie",
              });
              return Promise.resolve().then(() => {
                scrollToHighlight();
              });
            }}
          />
        </div>
      )}

      {isSetConsumption && (offerHighlights.length || dualHighlights.length) && (
        <>
          <h2 className={"title-l center"}>
            Notre sélection des meilleures offres du mois
          </h2>
          <div className={"offers-highlights"}>
            {offerHighlights.map((highlight) => (
              <EnergyHighlightedCard
                key={highlight.label}
                brand={highlight.element.brand as Brand}
                label={highlight.label}
                offer={highlight.element.offer as Offer}
                onChoose={() =>
                  setChosenOffer(highlight.element.offer as Offer)
                }
                onEstimate={() => {
                  setShowConsumptionDialog(true);
                  push(GtmEvent.GENERIC_EVENT, {
                    event_name: "click_monthly_estimation",
                    item_category: "Énergie",
                    item_variant: energyType,
                    item_id: (highlight.element.offer as Offer).id,
                    item_name: (highlight.element.offer as Offer).name,
                    item_brand: (highlight.element.brand as Brand).name,
                    item_price: (highlight.element.offer as Offer).price / 100,
                  });
                }}
                viewMore={() =>
                  setSelectedOffer(highlight.element.offer as Offer)
                }
              />
            ))}

            {dualHighlights.map((highlight) => (
              <EnergyHighlightedCard
                key={highlight.label}
                brand={
                  (highlight.element.dual as { brand: Brand }).brand as Brand
                }
                dual={highlight.element.dual as DualWithOffers}
                label={highlight.label}
                onChoose={() =>
                  setChosenOffer(highlight.element.dual as DualWithOffers)
                }
                onEstimate={() => {
                  setShowConsumptionDialog(true);
                  push(GtmEvent.GENERIC_EVENT, {
                    event_name: "click_monthly_estimation",
                    item_category: "Énergie",
                    item_variant: energyType,
                    item_id: (highlight.element.dual as DualWithOffers).id,
                    item_name: (highlight.element.dual as DualWithOffers).name,
                    item_brand: (highlight.element.dual as { brand: Brand })
                      .brand.name,
                  });
                }}
                viewMore={() =>
                  setSelectedDual(
                    highlight.element.dual as DualWithOffers & { brand: Brand },
                  )
                }
              />
            ))}
          </div>
          {energyType === EnergyType.Electricity && !!currentLocation && (
            <div className={"section-xl"}>
              {electricityOffers.length > 0 && attachedEld === null && (
                <div className={"info info-success"}>
                  <h3>Cette adresse est bien desservie par Enedis</h3>
                  Actuellement {electricityOffers.length} offres d'électricité
                  sont disponibles
                </div>
              )}

              {attachedEld !== null && (
                <div className={"info info-success"}>
                  <h3>Cette adresse est associée à une ELD</h3>
                  <Button
                    className={"link-3"}
                    onClick={() => setShowEldInfo(true)}
                  >
                    Qu'est-ce que c'est ?
                  </Button>
                </div>
              )}
            </div>
          )}
          <div className={"section-l container-wrap --wrap--m"}>
            <WhyMovecool />
          </div>
        </>
      )}

      {isSetConsumption && (
        <div className={"section"}>
          <h2 className={"title-l center"}>
            L'ensemble des offres disponibles
          </h2>
        </div>
      )}

      <div className={"comparator-list margin-top"}>
        <div className={"grid"}>
          <div className={"comparator-sidebar"}>
            {isSetConsumption && (
              <>
                <div className={"card-block"}>
                  <CardHead className={"center"} title={"Votre consommation"} />
                  <Card>
                    <CardBody>
                      <div className={"grid"}>
                        {electricityConsumption !== null && (
                          <div className={"display-group"}>
                            <h3 className={"group-title"}>
                              <img
                                alt={""}
                                className={"icon"}
                                src={iconElectricity}
                              />
                              Électricité
                            </h3>
                            <div>
                              <small>
                                {toFixed(
                                  electricityConsumption *
                                    (calculatedConsumtionFormat ===
                                    PricePer.Month
                                      ? 1
                                      : 12),
                                  2,
                                )}{" "}
                                kWh{" "}
                                <ConsumptionFormatButton
                                  onClick={() =>
                                    setCalculatedConsumtionFormat((prev) =>
                                      prev === PricePer.Month
                                        ? PricePer.Year
                                        : PricePer.Month,
                                    )
                                  }
                                  overrideFormat={calculatedConsumtionFormat}
                                />
                              </small>
                            </div>
                          </div>
                        )}

                        {gasConsumption !== null && (
                          <div className={"display-group"}>
                            <h3 className={"group-title"}>
                              <img alt={""} className={"icon"} src={iconGas} />
                              Gaz
                            </h3>
                            <div>
                              <small>
                                {toFixed(
                                  gasConsumption *
                                    (calculatedConsumtionFormat ===
                                    PricePer.Month
                                      ? 1
                                      : 12),
                                  2,
                                )}{" "}
                                kWh{" "}
                                <ConsumptionFormatButton
                                  onClick={() =>
                                    setCalculatedConsumtionFormat((prev) =>
                                      prev === PricePer.Month
                                        ? PricePer.Year
                                        : PricePer.Month,
                                    )
                                  }
                                  overrideFormat={calculatedConsumtionFormat}
                                />
                              </small>
                            </div>
                          </div>
                        )}
                        {currentLocation !== null && (
                          <div className={"display-group"}>
                            <h3 className={"group-title"}>
                              <img
                                alt={""}
                                className={"icon"}
                                src={iconLocationBlue}
                              />
                              Mon adresse
                            </h3>
                            <div>
                              <small>{currentLocation.plainAddress}</small>
                            </div>
                          </div>
                        )}
                        {offers && (
                          <div className={"display-group"}>
                            <h3 className={"group-title"}>
                              <img
                                alt={""}
                                className={"icon"}
                                src={iconStarBlue}
                              />
                              Offres disponibles
                            </h3>
                            <div>
                              <small>{offers.size} offres disponibles</small>
                            </div>
                            <div>
                              <small>
                                {offerHighlights.length > 0
                                  ? offerHighlights.length
                                  : dualHighlights.length}{" "}
                                opportunités intéressantes
                              </small>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className={"margin-top-l center"}>
                        <button
                          className={"link-3-s margin-auto"}
                          onClick={() => setShowConsumptionDialog(true)}
                        >
                          <img
                            alt={"icône modifier"}
                            className={"icon icon-left"}
                            src={iconReload}
                          />
                          Modifier mes informations
                        </button>
                      </div>
                    </CardBody>
                  </Card>
                </div>

                {showFilter && (
                  <Dialog
                    className={"side-panel"}
                    closeLabel={"Retour  x"}
                    onClose={() => setShowFilter((prev) => !prev)}
                  >
                    <div className={"card-block"}>
                      <CardHead title={"Filtrer les résultats"} />
                      <Card>
                        <CardBody>
                          <div className={"filter-group"}>
                            <h3 className={"filter-title"}>
                              <img
                                alt={""}
                                className={"icon"}
                                src={iconEnergy}
                              />
                              Énergie
                            </h3>
                            {electricityConsumption !== null && (
                              <Button
                                className={cx(
                                  energyType === EnergyType.Electricity &&
                                    "active",
                                )}
                                onClick={() => {
                                  setEnergyType(EnergyType.Electricity);
                                  push(GtmEvent.GENERIC_EVENT, {
                                    event_name: "filter",
                                    item_category: "Énergie",
                                    item_variant: "Électricité",
                                    option_filter: "Électricité",
                                  });
                                }}
                              >
                                Électricité
                              </Button>
                            )}

                            {gasConsumption !== null && (
                              <Button
                                className={cx(
                                  energyType === EnergyType.Gas && "active",
                                )}
                                onClick={() => {
                                  setEnergyType(EnergyType.Gas);
                                  push(GtmEvent.GENERIC_EVENT, {
                                    event_name: "filter",
                                    item_category: "Énergie",
                                    item_variant: "Gaz",
                                    option_filter: "Gaz",
                                  });
                                }}
                              >
                                Gaz
                              </Button>
                            )}
                            {electricityConsumption !== null &&
                              gasConsumption !== null && (
                                <Button
                                  className={cx(
                                    energyType === EnergyType.Dual && "active",
                                  )}
                                  onClick={() => {
                                    setEnergyType(EnergyType.Dual);
                                    push(GtmEvent.GENERIC_EVENT, {
                                      event_name: "filter",
                                      item_category: "Énergie",
                                      item_variant: "Électricité & Gaz",
                                      option_filter: "Électricité & Gaz",
                                    });
                                  }}
                                >
                                  Électricité & Gaz
                                </Button>
                              )}
                          </div>

                          <div className={"filter-group"}>
                            <h3 className={"filter-title"}>
                              <img
                                alt={""}
                                className={"icon"}
                                src={iconOptions}
                              />
                              Options
                            </h3>
                            <Button
                              className={cx(greenFilter && "active")}
                              onClick={() => {
                                setGreenFilter((prev) => !prev);
                                push(GtmEvent.GENERIC_EVENT, {
                                  event_name: "filter",
                                  item_category: "Énergie",
                                  item_variant:
                                    energyType === EnergyType.Dual
                                      ? "Électricité & Gaz"
                                      : energyType,
                                  option_filter: "Énergie verte",
                                });
                              }}
                            >
                              Énergie verte
                            </Button>
                            <Button
                              className={cx(fixedPriceFilter && "active")}
                              onClick={() => {
                                setFixedPriceFilter((prev) => !prev);
                                push(GtmEvent.GENERIC_EVENT, {
                                  event_name: "filter",
                                  item_category: "Énergie",
                                  item_variant:
                                    energyType === EnergyType.Dual
                                      ? "Électricité & Gaz"
                                      : energyType,
                                  option_filter: "Prix fixe",
                                });
                              }}
                            >
                              Prix fixe
                            </Button>
                            <Button
                              className={cx(!fixedPriceFilter && "active")}
                              onClick={() => {
                                setFixedPriceFilter((prev) => !prev);
                                push(GtmEvent.GENERIC_EVENT, {
                                  event_name: "filter",
                                  item_category: "Énergie",
                                  item_variant:
                                    energyType === EnergyType.Dual
                                      ? "Électricité & Gaz"
                                      : energyType,
                                  option_filter: "Prix indexé",
                                });
                              }}
                            >
                              Prix indexé
                            </Button>
                          </div>
                          <div className={"filter-group"}>
                            <h3 className={"filter-title"}>Disponibilité</h3>
                            <Button
                              className={cx(withdrawnFilter && "active")}
                              onClick={() => {
                                setWithdrawnFilter((prev) => !prev);
                                push(GtmEvent.GENERIC_EVENT, {
                                  event_name: "filter",
                                  item_category: "Énergie",
                                  item_variant:
                                    energyType === EnergyType.Dual
                                      ? "Électricité & Gaz"
                                      : energyType,
                                  option_filter:
                                    "Masquer les offres retirées du marché",
                                });
                              }}
                            >
                              Masquer les offres retirées du marché
                            </Button>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                    <Button
                      className={"btn-block margin-top"}
                      onClick={() => setShowFilter((prev) => !prev)}
                    >
                      Valider
                    </Button>
                  </Dialog>
                )}

                <div className={"section filter"}>
                  <div className={"card-block"}>
                    <CardHead title={"Filtrer les résultats"} />
                    <Card>
                      <CardBody>
                        <div className={"filter-group"}>
                          <h3 className={"filter-title"}>
                            <img alt={""} className={"icon"} src={iconEnergy} />
                            Énergie
                          </h3>
                          {electricityConsumption !== null && (
                            <Button
                              className={cx(
                                energyType === EnergyType.Electricity &&
                                  "active",
                              )}
                              onClick={() => {
                                setEnergyType(EnergyType.Electricity);
                                push(GtmEvent.GENERIC_EVENT, {
                                  event_name: "filter",
                                  item_category: "Énergie",
                                  item_variant: "Électricité",
                                  option_filter: "Électricité",
                                });
                              }}
                            >
                              Électricité
                            </Button>
                          )}

                          {gasConsumption !== null && (
                            <Button
                              className={cx(
                                energyType === EnergyType.Gas && "active",
                              )}
                              onClick={() => {
                                setEnergyType(EnergyType.Gas);
                                push(GtmEvent.GENERIC_EVENT, {
                                  event_name: "filter",
                                  item_category: "Énergie",
                                  item_variant: "Gaz",
                                  option_filter: "Gaz",
                                });
                              }}
                            >
                              Gaz
                            </Button>
                          )}

                          {electricityConsumption !== null &&
                            gasConsumption !== null && (
                              <Button
                                className={cx(
                                  energyType === EnergyType.Dual && "active",
                                )}
                                onClick={() => {
                                  setEnergyType(EnergyType.Dual);
                                  push(GtmEvent.GENERIC_EVENT, {
                                    event_name: "filter",
                                    item_category: "Énergie",
                                    item_variant: "Électricité & Gaz",
                                    option_filter: "Électricité & Gaz",
                                  });
                                }}
                              >
                                Électricité & Gaz
                              </Button>
                            )}
                        </div>

                        <div className={"filter-group"}>
                          <h3 className={"filter-title"}>
                            <img
                              alt={""}
                              className={"icon"}
                              src={iconOptions}
                            />
                            Options
                          </h3>
                          <Button
                            className={cx(greenFilter && "active")}
                            onClick={() => {
                              setGreenFilter((prev) => !prev);
                              push(GtmEvent.GENERIC_EVENT, {
                                event_name: "filter",
                                item_category: "Énergie",
                                item_variant:
                                  energyType === EnergyType.Dual
                                    ? "Électricité & Gaz"
                                    : energyType,
                                option_filter: "Énergie verte",
                              });
                            }}
                          >
                            Énergie verte
                          </Button>
                          <Button
                            className={cx(fixedPriceFilter && "active")}
                            onClick={() => {
                              setFixedPriceFilter((prev) => !prev);
                              push(GtmEvent.GENERIC_EVENT, {
                                event_name: "filter",
                                item_category: "Énergie",
                                item_variant:
                                  energyType === EnergyType.Dual
                                    ? "Électricité & Gaz"
                                    : energyType,
                                option_filter: "Prix fixe",
                              });
                            }}
                          >
                            Prix fixe
                          </Button>
                          <Button
                            className={cx(!fixedPriceFilter && "active")}
                            onClick={() => {
                              setFixedPriceFilter((prev) => !prev);
                              push(GtmEvent.GENERIC_EVENT, {
                                event_name: "filter",
                                item_category: "Énergie",
                                item_variant:
                                  energyType === EnergyType.Dual
                                    ? "Électricité & Gaz"
                                    : energyType,
                                option_filter: "Prix indexé",
                              });
                            }}
                          >
                            Prix indexé
                          </Button>
                        </div>
                        <div className={"filter-group"}>
                          <h3 className={"filter-title"}>Disponibilité</h3>
                          <Button
                            className={cx(withdrawnFilter && "active")}
                            onClick={() => {
                              setWithdrawnFilter((prev) => !prev);
                              push(GtmEvent.GENERIC_EVENT, {
                                event_name: "filter",
                                item_category: "Énergie",
                                item_variant:
                                  energyType === EnergyType.Dual
                                    ? "Électricité & Gaz"
                                    : energyType,
                                option_filter:
                                  "Masquer les offres retirées du marché",
                              });
                            }}
                          >
                            Masquer les offres retirées du marché
                          </Button>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
                <div className={"section"}>
                  <OrderByPhone />
                </div>
              </>
            )}
          </div>
          <div className={"comparator-offers"}>
            {electricityConsumption !== null || gasConsumption !== null ? (
              <>
                <div className={"list-head"}>
                  <button
                    className={"btn-filter margin-right"}
                    onClick={() => {
                      setShowFilter((prev) => !prev);
                    }}
                  >
                    Filtres
                    <img
                      alt={"estimer"}
                      className={"icon icon-right"}
                      src={iconFilter}
                    />
                  </button>
                  <select
                    className={"select-sp"}
                    onChange={(ev) => setSort(parseInt(ev.target.value))}
                    value={sort}
                  >
                    <option value={Sort.Relevance}>Classer / trier</option>
                    <option value={Sort.PriceAsc}>Du - cher au + cher</option>
                    <option value={Sort.PriceDesc}>Du + cher au - cher</option>
                  </select>
                </div>
                <LoadMoreList<typeof sortedOffers[0] | typeof sortedDuals[0]>
                  items={
                    energyType !== EnergyType.Dual ? sortedOffers : sortedDuals
                  }
                  loadMoreLabel={"Afficher plus d'offres"}
                  paginatedBy={10}
                  render={(item) => {
                    if (energyType !== EnergyType.Dual) {
                      const offer = item as typeof sortedOffers[0];
                      return (
                        <ComparatorListItem
                          key={offer.id}
                          brand={brands.get(offer.brandId)!}
                          calendlyContext={"energy"}
                          cartIcon={iconCart}
                          dataCompleteCondition={
                            (offer?.type === EnergyType.Electricity &&
                              !!electricityConsumption) ||
                            (offer?.type === EnergyType.Gas && !!gasConsumption)
                          }
                          offer={offer}
                          onChoose={() => {
                            setChosenOffer(offer);
                          }}
                          // onAddToCart={(ev) => {
                          //   ev.stopPropagation();
                          //   return addToCart({
                          //     type:
                          //       offer.type === EnergyType.Electricity
                          //         ? CartType.Electricity
                          //         : CartType.Gas,
                          //     data: {
                          //       ...(lastConsumptionCalculatorStep ===
                          //       ConsumptionCalculatorStep.Calculated
                          //         ? {
                          //             area,
                          //             cookingSource,
                          //             heatingSource,
                          //             hotWaterSource,
                          //             residentCount,
                          //           }
                          //         : {
                          //             area: null,
                          //             cookingSource: null,
                          //             heatingSource: null,
                          //             hotWaterSource: null,
                          //             residentCount: null,
                          //           }),
                          //       offerId: offer.id!,
                          //       address: currentLocation!,
                          //       electricityConsumption:
                          //         electricityConsumption || 0,
                          //       gasConsumption: gasConsumption || 0,
                          //       home: null,
                          //       situation: null,
                          //     },
                          //   });
                          // }}
                          // onEstimate={() => {
                          //   setShowConsumptionDialog(true);
                          //   push(GtmEvent.GENERIC_EVENT, {
                          //     event_name: "click_monthly_estimation",
                          //     item_category: "Énergie",
                          //     item_variant: energyType,
                          //     item_id: offer.id,
                          //     item_name: offer.name,
                          //     item_brand: brands.get(offer.brandId)?.name,
                          //     item_price: offer.price / 100,
                          //   });
                          // }}
                          viewMore={() => setSelectedOffer(offer)}
                        />
                      );
                    } else {
                      const dual = item as typeof sortedDuals[0];
                      return (
                        <ComparatorListItem
                          key={dual.id}
                          brand={dual.brand}
                          calendlyContext={"energy"}
                          cartIcon={iconCart}
                          dataCompleteCondition={
                            !!(electricityConsumption && gasConsumption)
                          }
                          offer={dual}
                          onChoose={() => setChosenOffer(dual)}
                          // onAddToCart={(ev) => {
                          // ev.stopPropagation();
                          // const data = {
                          //   ...(lastConsumptionCalculatorStep ===
                          //     ConsumptionCalculatorStep.Calculated
                          //     ? {
                          //       area,
                          //       cookingSource,
                          //       heatingSource,
                          //       hotWaterSource,
                          //       residentCount,
                          //     }
                          //     : {
                          //       area: null,
                          //       cookingSource: null,
                          //       heatingSource: null,
                          //       hotWaterSource: null,
                          //       residentCount: null,
                          //     }),
                          //   offerId: dual.id!,
                          //   address: currentLocation!,
                          //   electricityConsumption:
                          //     electricityConsumption || 0,
                          //   gasConsumption: gasConsumption || 0,
                          //   home: null,
                          //   situation: null,
                          // };
                          // return addToCart({
                          //   type: CartType.Dual,
                          //   data: {
                          //     electricity: {
                          //       ...data,
                          //       offerId: dual.electricity,
                          //     },
                          //     gas: {
                          //       ...data,
                          //       offerId: dual.gas,
                          //     },
                          //   },
                          // });
                          // }}
                          // onEstimate={() => {
                          //   setShowConsumptionDialog(true);
                          //   push(GtmEvent.GENERIC_EVENT, {
                          //     event_name: "click_monthly_estimation",
                          //     item_category: "Énergie",
                          //     item_variant: energyType,
                          //     item_id: dual.id,
                          //     item_name: dual.name,
                          //     item_brand: dual.brand.name,
                          //   });
                          // }}
                          viewMore={() => setSelectedDual(dual)}
                        />
                      );
                    }
                  }}
                />
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      {!isSetConsumption && (
        <>
          <div className={"section-xxl"}>
            <h2 className={"title-m center"}>
              +40 fournisseurs d'électricité comparés
            </h2>
            <p className={"center"}>Tous les fournisseurs sont sur Movecool</p>
            <div className={"brand-logos"}>
              <img alt={"Alpiq"} src={BrandLogo1} />
              <img alt={"Engie"} src={BrandLogo2} />
              <img alt={"Ekwateur"} src={BrandLogo3} />
              <img alt={"Edf"} src={BrandLogo4} />
              <img alt={"Vattenfall"} src={BrandLogo5} />
              <img alt={"Total Energies"} src={BrandLogo6} />
              <img alt={"Ilek"} src={BrandLogo7} />
            </div>
          </div>

          <HowItWorksMain />
        </>
      )}

      <EnergyTeam />

      {!isSetConsumption && <MovecoolReinsuranceBlock />}

      {selectedOffer && (
        <EnergyDetailsModal
          brand={brands.get(selectedOffer.brandId)!}
          offer={selectedOffer}
          onClose={() => setSelectedOffer(null)}
          onEstimate={() => {
            setShowConsumptionDialog(true);
            push(GtmEvent.GENERIC_EVENT, {
              event_name: "click_monthly_estimation",
              item_category: "Énergie",
              item_variant: energyType,
              item_id: selectedOffer.id,
              item_name: selectedOffer.name,
              item_brand: brands.get(selectedOffer.brandId)?.name,
              price: selectedOffer.price / 100,
            });
          }}
        />
      )}

      {selectedDual && (
        <EnergyDetailsModal
          brand={selectedDual.brand}
          dual={selectedDual}
          onClose={() => setSelectedDual(null)}
          onEstimate={() => {
            setShowConsumptionDialog(true);
            push(GtmEvent.GENERIC_EVENT, {
              event_name: "click_monthly_estimation",
              item_category: "Énergie",
              item_variant: energyType,
              item_id: selectedDual.id,
              item_name: selectedDual.name,
              item_brand: selectedDual.brand.name,
            });
          }}
        />
      )}

      {showConsumptionDialog && (
        <ConsumptionCalculatorDialog
          onClose={() => setShowConsumptionDialog(false)}
          onSubmit={() => {
            setShowConsumptionDialog(false);
            push(GtmEvent.FORM, {
              event_name: "submit_monthly_estimation",
              item_category: "Énergie",
            });
            return Promise.resolve();
          }}
        />
      )}

      {showEldInfo && attachedEld !== null && (
        <WhatIsAnEldDialog
          brand={attachedEld}
          onClose={() => setShowEldInfo(false)}
        />
      )}

      {chosenOffer && (
        <EnergyActionsDialog
          onClose={() => {
            setChosenOffer(null);
          }}
          onFinalizeOnline={(ev) => {
            ev.stopPropagation();
            if (energyType !== EnergyType.Dual) {
              const offer = chosenOffer as typeof sortedOffers[0];
              return addToCart({
                type:
                  offer.type === EnergyType.Electricity
                    ? CartType.Electricity
                    : CartType.Gas,
                data: {
                  ...(lastConsumptionCalculatorStep ===
                  ConsumptionCalculatorStep.Calculated
                    ? {
                        area,
                        cookingSource,
                        heatingSource,
                        hotWaterSource,
                        residentCount,
                      }
                    : {
                        area: null,
                        cookingSource: null,
                        heatingSource: null,
                        hotWaterSource: null,
                        residentCount: null,
                      }),
                  offerId: offer.id!,
                  address: currentLocation!,
                  electricityConsumption: electricityConsumption || 0,
                  gasConsumption: gasConsumption || 0,
                  home: null,
                  situation: null,
                },
              }).then(() => {
                navigate(CART_LINK);
              });
            } else {
              ev.stopPropagation();
              const dual = chosenOffer as typeof sortedDuals[0];
              const data = {
                ...(lastConsumptionCalculatorStep ===
                ConsumptionCalculatorStep.Calculated
                  ? {
                      area,
                      cookingSource,
                      heatingSource,
                      hotWaterSource,
                      residentCount,
                    }
                  : {
                      area: null,
                      cookingSource: null,
                      heatingSource: null,
                      hotWaterSource: null,
                      residentCount: null,
                    }),
                offerId: dual.id!,
                address: currentLocation!,
                electricityConsumption: electricityConsumption || 0,
                gasConsumption: gasConsumption || 0,
                home: null,
                situation: null,
              };
              return addToCart({
                type: CartType.Dual,
                data: {
                  electricity: {
                    ...data,
                    offerId: dual.electricity,
                  },
                  gas: {
                    ...data,
                    offerId: dual.gas,
                  },
                },
              }).then(() => {
                navigate(CART_LINK);
              });
            }
          }}
        />
      )}
    </div>
  );
};

const useLoad = () => {
  const { loadAll } = useEnergy();

  return loadAll;
};

export default withLoader(EnergyComparator, useLoad);
