import ProfilePic1 from "../../../assets/img/teams/internet/leo.png";
import ProfilePic2 from "../../../assets/img/teams/internet/jeanne.png";
import ProfilePic3 from "../../../assets/img/teams/internet/elliot.png";
import ProfilePic4 from "../../../assets/img/teams/internet/victore.png";
import ProfilePic5 from "../../../assets/img/teams/internet/thierry.png";
import { useAuth } from "src/services/auth/apiProvider";

const InternetTeam = () => {
  const { generateCalendlyLink } = useAuth();
  return (
    <div className={"team"}>
      <h2 className={"title"}>
        Une équipe disponible 6&nbsp;jours&nbsp;sur&nbsp;7
      </h2>
      <div className={"ctas"}>
        <a className={"btn-outlined"} href={"tel:+33980805110"}>
          09 80 80 51 10
        </a>
        <a
          className={"btn-1"}
          href={generateCalendlyLink("", "internet")}
          rel={"noreferrer"}
          target={"_blank"}
        >
          Être rappelé
        </a>
      </div>
      <div className={"teammates"}>
        <div className={"teammate"}>
          <img alt={"Léo"} src={ProfilePic1} />
          <p className={"name"}>Léo</p>
          <p className={"position"}>Expert Box Internet</p>
        </div>
        <div className={"teammate"}>
          <img alt={"Jeanne"} src={ProfilePic2} />
          <p className={"name"}>Jeanne</p>
          <p className={"position"}>Experte Box Internet</p>
        </div>
        <div className={"teammate"}>
          <img alt={"Elliot"} src={ProfilePic3} />
          <p className={"name"}>Elliot</p>
          <p className={"position"}>Expert Box Internet</p>
        </div>
        <div className={"teammate"}>
          <img alt={"Victore"} src={ProfilePic4} />
          <p className={"name"}>Victore</p>
          <p className={"position"}>Expert Box Internet</p>
        </div>
        <div className={"teammate"}>
          <img alt={"Thierry"} src={ProfilePic5} />
          <p className={"name"}>Thierry</p>
          <p className={"position"}>Team Leader Telecom</p>
        </div>
      </div>
    </div>
  );
};

export default InternetTeam;
