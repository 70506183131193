import {
  ComponentType,
  createContext,
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useRef,
} from "react";

export interface ScrollToAPI {
  getTargetRef<T extends HTMLElement>(): RefObject<T>;
  scrollToTarget(block?: ScrollLogicalPosition): void;
}

const ScrollToContext = createContext<ScrollToAPI | null>(null);

export const ProvideScrollTo = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const target = useRef<HTMLElement>(null);
  const scrollToTarget = useCallback(
    (block: ScrollLogicalPosition = "start") => {
      if (target.current) {
        target.current.scrollIntoView({ behavior: "smooth", block });
      }
    },
    [target],
  );
  const getTargetRef = useCallback(
    <T extends HTMLElement>(): RefObject<T> => target as RefObject<T>,
    [target],
  );

  return (
    <ScrollToContext.Provider
      value={{
        getTargetRef,
        scrollToTarget,
      }}
    >
      {children}
    </ScrollToContext.Provider>
  );
};

export function withProvideScrollTo<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvideScrollTo(props: P) {
    return (
      <ProvideScrollTo>
        <WrappedComponent {...props} />
      </ProvideScrollTo>
    );
  }

  WithProvideScrollTo.displayName = `withProvideScrollTo(${displayName})`;

  return WithProvideScrollTo;
}

export function useScrollTo(): ScrollToAPI {
  return useContext(ScrollToContext) as ScrollToAPI;
}

export const Anchor = ({
  offsetY = -10,
}: {
  offsetY?: number;
}): JSX.Element => {
  const { getTargetRef } = useScrollTo();
  return (
    <div
      ref={getTargetRef()}
      style={{
        position: "relative",
        top: `${offsetY}px`,
      }}
    />
  );
};
