import React, { ReactElement } from "react";
import { PRIVATE } from "../../../routes/private";
import { useAuth } from "../apiProvider";
import { Navigate, useLocation } from "react-router-dom";
import generatePath from "../../routing/generatePath";
import useQueryParams from "../../routing/useQueryParams";
import { PUBLIC_LINK } from "../../../routes/public";

function withRedirectToPrivate<P, C extends React.ComponentType<P>>(
  WrappedComponent: C,
): C {
  return ((props: P): JSX.Element => {
    const auth = useAuth();
    const [{ redirectTo }] = useQueryParams();

    if (auth.user !== null && !redirectTo) return <Navigate to={PRIVATE} />;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <WrappedComponent {...(props as P)} />;
  }) as C;
}

export default withRedirectToPrivate;

export function RequiresAuth({
  redirectTo,
  children,
}: {
  redirectTo?: string;
  children: ReactElement;
}): JSX.Element {
  const { user } = useAuth();
  const location = useLocation();

  if (user) return children;

  if (redirectTo) {
    const link = generatePath(
      redirectTo,
      {},
      { authRedirectTo: location.pathname },
    );

    return <Navigate to={link} />;
  } else {
    // TODO: Replace this with a better solution to stop the navigation from happening
    const state = location.state as { history: string[] };
    const link = generatePath(
      !!state?.history ? state.history[state.history.length - 1] : PUBLIC_LINK,
      {},
      { authRedirectTo: location.pathname },
    );

    return <Navigate to={link} />;
  }
}
