import { cx } from "@emotion/css";
import { useField } from "formik";
import ReactTooltip from "react-tooltip";
import { AnySchema, boolean, number, object, InferType } from "yup";
import Form, { useYupField } from "../../forms/Form";
import NumberField from "../../forms/NumberField";
import SubmitButton from "../../forms/SubmitButton";
import ValidationsErrors from "../../forms/ValidationsErrors";
import CardHead from "../../ui/block/CardHead";
import { useHousing, coverageTooltips } from "./housingProvider";
import { GtmEvent, useGtm } from "src/services/gtm/gtmProvider";

const RefinementsSchema = object({
  totalGoodCoverage: number().label("Valeur des biens couverts").required(),
  deductible: number().label("Montant de la franchise").required(),
  valueableCoverage: number().label("Objets de valeur").required(),
  theftCoverage: boolean().label("Vol & Vandalisme"),
  lockReplacementCoverage: boolean().label("Dépannage Serrurerie"),
  schoolCoverage: boolean().label("Assurance scolaire"),
  asNewCoverage: boolean().label("Remboursement valeur à neuf"),
  assistanceCoverage: boolean().label("Assistance"),
  brokenGlassCoverage: boolean().label("Bris de glace"),
});
type ComparatorRefinementSchemaType = InferType<typeof RefinementsSchema>;

const BooleanField = ({
  name,
  onClick,
}: {
  name: string;
  onClick?: () => void;
}): JSX.Element => {
  const fieldSchema = useYupField(name) as AnySchema;
  const [field, , helpers] = useField(name);

  return (
    <div>
      <button
        className={cx(field.value && "active")}
        onClick={() => {
          helpers.setValue(!field.value);
          onClick && onClick();
        }}
        type={"button"}
      >
        {fieldSchema.spec.label}
      </button>
    </div>
  );
};

const ComparatorRefinementCard = ({
  onSubmit,
}: {
  onSubmit: (values: ComparatorRefinementSchemaType) => Promise<void>;
}) => {
  const {
    asNewCoverage,
    assistanceCoverage,
    brokenGlassCoverage,
    deductible,
    lockReplacementCoverage,
    schoolCoverage,
    theftCoverage,
    valueableCoverage,
    totalGoodCoverage,
    setRefinements,
  } = useHousing();

  const { push } = useGtm();

  const franchiseDataTip =
    "Exemple: Si votre franchise est de 150 € et que vous demandez un remboursement pour un sinistre d’une valeur 500 €. Alors, vous recevrez la différence entre les deux, c'est-à-dire 500 € - 150 = 350 €. Vous recevrez 350 €.<br/>Si vous augmentez le montant de la franchise, cela diminue votre mensualité, mais recevrez moins d’argent en cas de d’indemnisation. Si vous baissez le montant de la franchise, cela augmente votre mensualité, mais recevrez plus d’argent en cas de d’indemnisation.";
  const coverageAmountDataTip =
    "Le capital mobilier, c'est la somme déclarée des valeurs de vos biens, donc le montant maximal qui sera remboursé en cas de sinistre pour tous vos biens mobilier : <ul><li>Les meubles : tables, chaises, armoires ;</li><li>La décoration : tapis, rideaux, lustres ;</li><li>Les appareils électroniques : téléviseurs, ordinateurs ;</li><li>L’électroménager : lave-vaisselle, plaque de cuisson, four ;</li></ul><br>Quand vous déclarez votre capital mobilier à votre assurance, vous lui indiquez le niveau de risque à assurer. Donc, vous estimez la somme qui serait nécessaire pour remplacer toutes vos affaires à l'identique si tous vos biens étaient détruits par un sinistre.";

  return (
    <div className={"card-block"}>
      <CardHead title={"Affiner les résultats"} />
      <div className={"card"}>
        <div className={"card-body"}>
          <Form
            initialValues={{
              totalGoodCoverage: totalGoodCoverage || 8000,
              deductible: deductible || 150,
              valueableCoverage: valueableCoverage || 0,
              theftCoverage,
              brokenGlassCoverage,
              assistanceCoverage,
              lockReplacementCoverage,
              schoolCoverage,
              asNewCoverage,
            }}
            onSubmit={(values) => {
              setRefinements(values);
              return onSubmit(values);
            }}
            schema={RefinementsSchema}
          >
            <div className={"form-block filter-group"}>
              <h3 className={"filter-title with-tooltip"}>
                Valeur des biens couverts (€)
                <span
                  className={"tooltip-trigger"}
                  data-tip={coverageAmountDataTip}
                >
                  ?
                </span>
              </h3>
              <NumberField name={"totalGoodCoverage"} />
            </div>
            <div className={"form-block filter-group"}>
              <h3 className={"filter-title with-tooltip"}>
                Montant max. de la franchise (€)
                <span className={"tooltip-trigger"} data-tip={franchiseDataTip}>
                  ?
                </span>
              </h3>
              <NumberField name={"deductible"} />
            </div>
            <div className={"form-block filter-group"}>
              <h3 className={"filter-title with-tooltip"}>
                Objets de valeur (€)
                <span
                  className={"tooltip-trigger"}
                  data-tip={
                    "Tout objet dont la valeur est supérieure à 1800€ est un objet précieux et doit faire l'objet d'une déclaration spécifique."
                  }
                >
                  ?
                </span>
              </h3>
              <NumberField name={"valueableCoverage"} />
            </div>
            <div className={"filter-group"}>
              <h3 className={"filter-title"}>Garanties recommandées</h3>
              <div className={"with-tooltip"}>
                <BooleanField
                  name={"theftCoverage"}
                  onClick={() => {
                    push(GtmEvent.GENERIC_EVENT, {
                      event_name: "filter",
                      item_category: "Assurance",
                      option_filter: "Vol et Vandalisme",
                    });
                  }}
                />
                <span
                  className={"tooltip-trigger"}
                  data-tip={coverageTooltips.theftCoverage}
                >
                  ?
                </span>
              </div>
              <div className={"with-tooltip"}>
                <BooleanField
                  name={"brokenGlassCoverage"}
                  onClick={() => {
                    push(GtmEvent.GENERIC_EVENT, {
                      event_name: "filter",
                      item_category: "Assurance",
                      option_filter: "Brise de glace",
                    });
                  }}
                />
                <span
                  className={"tooltip-trigger"}
                  data-tip={coverageTooltips.brokenGlass}
                >
                  ?
                </span>
              </div>
            </div>
            {/* <div className={"filter-group"}>
              <h3 className={"filter-title"}>Garanties supplémentaires</h3>
              <div className={"with-tooltip"}>
                <BooleanField
                  name={"assistanceCoverage"}
                  onClick={() => {
                    push(GtmEvent.GENERIC_EVENT, {
                      event_name: "filter",
                      item_category: "Assurance",
                      option_filter: "Assistance",
                    });
                  }}
                />
                <span
                  className={"tooltip-trigger"}
                  data-tip={coverageTooltips.assistance}
                >
                  ?
                </span>
              </div>
              <div className={"with-tooltip"}>
                <BooleanField
                  name={"lockReplacementCoverage"}
                  onClick={() => {
                    push(GtmEvent.GENERIC_EVENT, {
                      event_name: "filter",
                      item_category: "Assurance",
                      option_filter: "Dépannage serrurerie",
                    });
                  }}
                />
                <span
                  className={"tooltip-trigger"}
                  data-tip={coverageTooltips.lockSmith}
                >
                  ?
                </span>
              </div>
              <div className={"with-tooltip"}>
                <BooleanField
                  name={"schoolCoverage"}
                  onClick={() => {
                    push(GtmEvent.GENERIC_EVENT, {
                      event_name: "filter",
                      item_category: "Assurance",
                      option_filter: "Assurance scolaire",
                    });
                  }}
                />
                <span
                  className={"tooltip-trigger"}
                  data-tip={coverageTooltips.scolar}
                >
                  ?
                </span>
              </div>
              <div className={"with-tooltip"}>
                <BooleanField
                  name={"asNewCoverage"}
                  onClick={() => {
                    push(GtmEvent.GENERIC_EVENT, {
                      event_name: "filter",
                      item_category: "Assurance",
                      option_filter: "Ramboursement valeur à neuf",
                    });
                  }}
                />
                <span
                  className={"tooltip-trigger"}
                  data-tip={coverageTooltips.replacementCost}
                >
                  ?
                </span>
              </div>
            </div> */}

            <ValidationsErrors />

            <div className={"form-footer"}>
              <SubmitButton className={"btn-block"}>Valider</SubmitButton>
            </div>
          </Form>
        </div>
      </div>
      <ReactTooltip class={"tooltip"} html={true} />
    </div>
  );
};

export default ComparatorRefinementCard;
