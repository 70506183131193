import { useContext, useMemo, useState } from "react";
import { FormikContextType, FormikContext } from "formik";
import Button, { ButtonProps } from "../ui/reactive/Button";

interface SubmitButtonProps extends ButtonProps {}

const SubmitButton = (props: SubmitButtonProps): JSX.Element => {
  const formik: FormikContextType<any> | undefined = useContext(FormikContext);

  const [isHookedSubmitting, setIsHookedSubmitting] = useState(false);

  // Memos
  const isSubmitting = useMemo(
    () => (formik?.isSubmitting && !props.onClick) || isHookedSubmitting,
    [formik?.isSubmitting, isHookedSubmitting, props.onClick],
  );

  return (
    <Button
      type={"submit"}
      {...props}
      disabled={isSubmitting}
      onClick={(ev) => {
        if (props.onClick) {
          ev.stopPropagation();
          const onSubmit = props.onClick(ev as any) as Promise<any> | void;

          if (onSubmit instanceof Promise) {
            setIsHookedSubmitting(true);
            onSubmit.finally(() => setIsHookedSubmitting(false));
          }
        }
      }}
    >
      {isSubmitting ? "Chargement..." : props.children}
    </Button>
  );
};

export default SubmitButton;
