import { AxiosPromise } from "axios";
import { Location } from "../../google-maps/place";
import { publicAPI } from "../publicApi";

interface UserMoveData {
  //provisoire
  firstname: string | null;
  lastname: string | null;
  phoneNumber: string | null;
  mail: string | null;

  previousAddress: Location | null;
  newAddress: Location | null;
  movingDate: Date | null;
  surface: number | null;
}

export function getBrands(): AxiosPromise<unknown[]> {
  return publicAPI.get("/moving/brands");
}

export function getFilteredBrands(
  from: Location,
  to: Location,
): AxiosPromise<unknown[]> {
  return publicAPI.get(
    `/moving/brands?from=${from.postCode}&to=${to.postCode}`,
  );
}

export function createLead(data: UserMoveData): AxiosPromise<any> {
  return publicAPI.post("/moving/report", data);
}
