import Card from "../../ui/block/Card";
import CardBody from "../../ui/block/CardBody";
import {
  InternetDebitCodeToMax,
  InternetOperator,
  InternetTechnology,
} from "./internet";
import { useInternet } from "./internetProvider";
import logoSfr from "../../../assets/img/logo-sfr.png";
import logoOrange from "../../../assets/img/logo-orange.png";
import logoFree from "../../../assets/img/logo-free.png";
import logoBouygues from "../../../assets/img/logo-bouygues.png";
import { withLoader } from "../../routing/useLoader";

const ElegibilityCard = (): JSX.Element | null => {
  const { bestElegibilityByOp } = useInternet();

  return bestElegibilityByOp.length > 0 ? (
    <Card className={"card-available-technologies"}>
      <CardBody>
        {/* TODO: TABLE BHD8 num fibre */}
        {bestElegibilityByOp.map(([operator, elegibility]) => (
          <div
            key={elegibility.tech_code + operator}
            className={"provider-item"}
          >
            <div className={"provider-logo"}>
              {operator === InternetOperator.Bouygues ? (
                <img alt={"Logo Bouygues"} src={logoBouygues} />
              ) : operator === InternetOperator.Free ? (
                <img alt={"Logo Free"} src={logoFree} />
              ) : operator === InternetOperator.Sfr ? (
                <img alt={"Logo SFR"} src={logoSfr} />
              ) : operator === InternetOperator.Orange ? (
                <img alt={"Logo Orange"} src={logoOrange} />
              ) : null}
            </div>

            {/* <p className={"technology"}>
              <img alt={"Logo fibre"} className={"icon"} src={iconNetwork} />
              {elegibility.tech_code === InternetTechnology.Fiber ? (
                <span className={"fiber"}>Fibre</span>
              ) : (
                "ADSL"
              )}
            </p> */}

            {!!InternetDebitCodeToMax[elegibility.down_code] && (
              <p className={"bandwidth"}>
                {elegibility.tech_code === InternetTechnology.Fiber ? (
                  <span className={"fiber"}>Fibre</span>
                ) : (
                  "ADSL"
                )}{" "}
                à {InternetDebitCodeToMax[elegibility.down_code]}
              </p>
            )}

            <div>
              <a
                className={"btn btn-1"}
                href={
                  operator === InternetOperator.Bouygues
                    ? "https://tracking.publicidees.com/clic.php?promoid=242854&progid=5334&partid=60815"
                    : operator === InternetOperator.Free
                    ? "https://www.puremium1.com/aff_c?offer_id=2&aff_id=1495&url_id=6"
                    : operator === InternetOperator.Sfr
                    ? "https://www.awin1.com/cread.php?s=2066230&v=7315&q=309015&r=708513"
                    : operator === InternetOperator.Orange
                    ? "https://track.effiliation.com/servlet/effi.click?id_compteur=22855491"
                    : "/#"
                }
                rel={"noreferrer"}
                target={"_blank"}
              >
                Voir le site
              </a>
            </div>
          </div>
        ))}
      </CardBody>
    </Card>
  ) : null;
};

const useLoad = () => {
  const { getElegibility } = useInternet();
  return getElegibility;
};

export default withLoader(ElegibilityCard, useLoad);
