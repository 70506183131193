import Card from "../../ui/block/Card";
import CardBody from "../../ui/block/CardBody";
import CardHead from "../../ui/block/CardHead";
import Dialog from "../../ui/block/Dialog";
import Button from "../../ui/reactive/Button";
import { Brand } from "./energy";

const WhatIsAnEldDialog = ({
  brand,
  onClose,
}: {
  brand: Brand;
  onClose(): void;
}): JSX.Element => {
  return (
    <Dialog onClose={onClose}>
      <Card className={"card-popup-info"}>
        <CardHead title={"L'information Movecool"} />
        <CardBody className={"center"}>
          <div className={"card-img"}>
            <img alt={"Logo " + brand.name} src={brand.logo} />
            <h3>{brand.name}</h3>
            {brand.url && (
              <a href={brand.url} rel={"noreferrer"} target={"_blank"}>
                Site internet
              </a>
            )}
          </div>
          <h3>
            Votre code postal correspond au territoire de desserte d’une ELD
          </h3>
          <p>
            Votre commune est approvisionnée en électricité par une Entreprise
            Locale de Distribution, vous ne pouvez pas changer de fournisseur
            pour le moment.
          </p>
          <p>
            Les entreprises locales de distribution (ELD) sont des fournisseurs
            historiques locaux restés en situation de monopole sur leur
            territoire de desserte.
          </p>
        </CardBody>
        <div className={"card-footer"}>
          <Button onClick={onClose}>&larr; Retour</Button>
        </div>
      </Card>
    </Dialog>
  );
};

export default WhatIsAnEldDialog;
