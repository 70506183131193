import { object, string } from "yup";
import { useAuth } from "../../auth/apiProvider";
import { User } from "../../auth/user";
import AutoField from "../../forms/AutoField";
import Form from "../../forms/Form";
import SubmitButton from "../../forms/SubmitButton";
import ValidationsErrors from "../../forms/ValidationsErrors";
import CardBody from "../../ui/block/CardBody";
import Dialog from "../../ui/block/Dialog";

type UserInfos = Pick<User, "firstname" | "email" | "lastname" | "phoneNumber">;

const PersonalDetailsDialog = ({
  onClose,
  onSubmit,
}: {
  onClose(): void;
  onSubmit(data: UserInfos): Promise<void>;
}): JSX.Element => {
  const { user } = useAuth();
  return (
    <Dialog className={"side-panel"} closeLabel={"Retour  x"} onClose={onClose}>
      <div className={"card-block"}>
        <div className={"card-head"}>
          <h2 className={"card-title --left"}>Retrouvez votre devis</h2>
        </div>
        <div className={"card"}>
          <CardBody>
            <p>
              Continuez votre devis sur Acheel.com en renseignant les
              informations ci-dessous.
            </p>

            <Form
              initialValues={{
                firstname: user?.firstname || null,
                lastname: user?.lastname || null,
                email: user?.email || null,
                phoneNumber: user?.phoneNumber || null,
              }}
              onSubmit={(v) => onSubmit(v as UserInfos)}
              schema={object({
                firstname: string().label("Prénom").nullable().required(),
                lastname: string().label("Nom").nullable().required(),
                email: string().label("E-mail").email().nullable().required(),
                phoneNumber: string()
                  .label("Numéro de téléphone")
                  .nullable()
                  .required(),
              })}
            >
              <AutoField name={"firstname"} />
              <AutoField name={"lastname"} />
              <AutoField name={"email"} />
              <AutoField name={"phoneNumber"} />

              <div className={"form-footer"}>
                <ValidationsErrors />

                <div className={"buttons-row right"}>
                  <SubmitButton>Accèder à mon devis</SubmitButton>
                </div>
              </div>

              {/* <SubmitButtonHack /> */}
            </Form>
          </CardBody>
        </div>
      </div>
    </Dialog>
  );
};

export default PersonalDetailsDialog;
