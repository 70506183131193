import { Navigate, Route, useMatch } from "react-router-dom";
import {
  COMPARE_ENERGY,
  COMPARE_ENERGY_LINK,
  COMPARE_INSURANCE,
  COMPARE_INSURANCE_LINK,
  COMPARE_INTERNET,
  COMPARE_INTERNET_LINK,
  COMPARE_MOVING,
  COMPARE_MOVING_LINK,
  COMPARE_WATER,
  COMPARE_WATER_LINK,
} from "../../../routes/compare";
import AuthDialog from "../../../services/auth/components/AuthDialog";
import { useCart } from "../../../services/compare/cart/cartProvider";
import { withProvideContent } from "../../../services/content/contentProvider";
import Routes from "../../../services/routing/Routes";
import { useKeepQueryParams } from "../../../services/routing/useQueryParams";
import EnergyPage from "./EnergyPage";
import InternetPage from "./InternetPage";
import MovingPage from "./MovingPage";
import WaterPage from "./WaterPage";
import imgHeaderEnergy from "../../../assets/img/img-header-energy.png";
import imgHeaderInsurance from "../../../assets/img/img-header-insurance.png";
import imgHeaderInternet from "../../../assets/img/img-header-internet.png";
import imgHeaderWater from "../../../assets/img/img-header-water.png";
import imgHeaderMoving from "../../../assets/img/img-header-moving.png";
import InsurancePage from "./InsurancePage";
import { useCallback, useEffect, useState } from "react";
import ConsumptionCalculatorDialog from "../../../services/compare/energy/ConsumptionCalculatorDialog";
import { usePlace } from "../../../services/google-maps/placeProvider";
import InsuranceCalculatorDialog from "../../../services/compare/insurance/InsuranceCalculatorDialog";
import { useHousing } from "../../../services/compare/insurance/housingProvider";
import {
  ClientType,
  HousingType,
} from "../../../services/compare/insurance/insurance";
import InternetCaluculatorDialog from "../../../services/compare/internet/InternetCalculatorDialog";
import iconCtaBtnArrow from "../../../assets/img/icons/icon-arrow-circle-right-white.svg";
import { useConsumption } from "../../../services/compare/energy/consumptionProvider";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import ImgGoogleNotation from "../../../assets/img/google-notation.png";

const Compare = (): JSX.Element => {
  const { pendingToAdd, addToCart, setPendingToAdd } = useCart();
  const keepQueryParams = useKeepQueryParams();

  const matchEnergy = useMatch(COMPARE_ENERGY_LINK + "*");
  const matchInsurance = useMatch(COMPARE_INSURANCE_LINK + "*");
  const matchInternet = useMatch(COMPARE_INTERNET_LINK + "*");
  const matchWater = useMatch(COMPARE_WATER_LINK + "*");
  const matchMoving = useMatch(COMPARE_MOVING_LINK + "*");

  const [showCalculatorDialog, setShowCalculatorDialog] = useState(false);
  const [dataCompleteCondition, setDataCompleteCondition] = useState(false);

  const { currentLocation, setCurrentLocation } = usePlace();
  const {
    loadQuotes,
    clientType,
    flatLevel,
    housingCategory,
    housingType,
    roomNumber,
    size,
  } = useHousing();
  const { isSetConsumption } = useConsumption();

  useEffect(() => {
    setDataCompleteCondition(
      clientType !== null &&
        flatLevel !== null &&
        housingCategory !== null &&
        housingType !== null &&
        roomNumber !== null &&
        size !== null,
    );
  }, [
    clientType,
    flatLevel,
    housingCategory,
    housingType,
    roomNumber,
    size,
    dataCompleteCondition,
  ]);

  const scrollToHighlight = useCallback(() => {
    const sectionHighlightTitle = document.getElementById("comparator");
    sectionHighlightTitle!.scrollIntoView({ behavior: "smooth" });
  }, []);

  const currentMonth = format(new Date(), "LLLL yyyy", { locale: fr });

  return (
    <div
      className={
        matchEnergy
          ? "comp-energy"
          : matchInsurance
          ? "comp-insurance"
          : matchInternet
          ? "comp-internet"
          : matchWater
          ? "comp-water"
          : matchMoving
          ? "comp-mooving"
          : ""
      }
    >
      <div className={"jumbo-head"}>
        <div className={"container-wrap"}>
          <div className={"grid"}>
            <div className={"col-m-2-3"}>
              <h1 className={"jumbo-title"}>
                {matchEnergy ? (
                  <>
                    Comparez les prix des{" "}
                    <span className={"txt-branded"}>
                      fournisseurs d'énergie
                    </span>{" "}
                    en {currentMonth} et économisez&nbsp;!
                  </>
                ) : matchInsurance ? (
                  <>
                    Comparez les prix des{" "}
                    <span className={"txt-branded"}>Assurances Habitation</span>{" "}
                    en {currentMonth} et économisez&nbsp;!
                  </>
                ) : matchInternet ? (
                  <>
                    Comparez les prix des{" "}
                    <span>
                      <span className={"txt-branded"}>Box Internet</span> en{" "}
                      {currentMonth}
                    </span>
                    et économisez&nbsp;!
                  </>
                ) : matchWater ? (
                  <>
                    Trouvez le
                    <br />
                    <span className={"txt-branded"}>service des eaux</span>
                    <br />
                    disponible chez vous.
                  </>
                ) : matchMoving ? (
                  <>
                    Comparez les prix des{" "}
                    <span className={"txt-branded"}>Déménageurs</span> en{" "}
                    {currentMonth} et économisez&nbsp;!
                  </>
                ) : (
                  ""
                )}
              </h1>
              <p className={"jumbo-desc"}>
                {matchEnergy ? (
                  "Comparez les fournisseurs et trouvez la meilleure offre d’énergie en 2 minutes chrono."
                ) : matchInsurance ? (
                  <>
                    Comparez les assurances et trouvez la meilleure offre
                    <br />
                    en <strong>2 minutes</strong> chrono.
                  </>
                ) : matchInternet ? (
                  <>
                    Comparez les opérateurs téléphoniques et trouvez la
                    meilleure box internet
                    <br />
                    en <strong>2 minutes</strong> chrono.
                  </>
                ) : matchWater ? (
                  "Choisissez celle que vous préférez en fonction de vos critères. Puis Movecool s'occupe des démarches à votre place, nous n'avez rien à faire."
                ) : matchMoving ? (
                  <>
                    Renseignez les informations sur votre déménagement en{" "}
                    <strong>2 minutes</strong> chrono
                    <br />
                    pour recevoir jusqu'à 6 devis d'entreprise de déménagement
                    différentes.
                  </>
                ) : (
                  ""
                )}
              </p>
              {(matchEnergy || matchInsurance || matchInternet) && (
                <div className={"header-ctas"}>
                  {((matchInternet && !currentLocation) ||
                    (matchInsurance && !dataCompleteCondition) ||
                    (matchEnergy && !isSetConsumption)) && (
                    <div>
                      <button
                        className={"btn-1"}
                        onClick={() => setShowCalculatorDialog(true)}
                      >
                        Comparer en 2 min
                        <img
                          alt={"estimer"}
                          className={"icon icon-right"}
                          src={iconCtaBtnArrow}
                        />
                      </button>
                    </div>
                  )}
                  <div className={"google-notation"}>
                    <img
                      alt={"noté 4,9/5 sur Google"}
                      src={ImgGoogleNotation}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className={"col-m-1-3"}>
              <img
                alt={""}
                className={"jumbo-img"}
                src={
                  matchEnergy
                    ? imgHeaderEnergy
                    : matchInsurance
                    ? imgHeaderInsurance
                    : matchInternet
                    ? imgHeaderInternet
                    : matchWater
                    ? imgHeaderWater
                    : matchMoving
                    ? imgHeaderMoving
                    : undefined
                }
              />
            </div>
          </div>
          <div className={"jumbo-reinsurance"}>
            <span>
              Service <strong>100% gratuit</strong>
            </span>
            <span>
              {matchInsurance ? (
                <>
                  <strong>Tous les assureurs</strong> référencés
                </>
              ) : matchInternet ? (
                <>
                  <strong>Tous les opérateurs</strong> référencés
                </>
              ) : (
                <>
                  <strong>Toutes les offres</strong> référencées
                </>
              )}
            </span>
            <span>
              Service client <strong>en France</strong>
            </span>
          </div>
        </div>
      </div>

      <div className={"container-wrap"}>
        {/* <div className={"comp-form-and-nav"}>
          <div className={"card card-comp-nav"}>
            <div className={"card-body"}>
              <Nav>
                <NavLink
                  className={"energy-nav-item"}
                  onClick={() =>
                    push(GtmEvent.GENERIC_EVENT, {
                      event_name: "product_type",
                      item_category: "Énergie",
                    })
                  }
                  to={COMPARE_ENERGY_LINK}
                >
                  <span className={"nav-icon"} />
                  Énergie
                </NavLink>

                <NavLink
                  className={"insurance-nav-item"}
                  onClick={() =>
                    push(GtmEvent.GENERIC_EVENT, {
                      event_name: "product_type",
                      item_category: "Assurance",
                    })
                  }
                  to={COMPARE_INSURANCE_LINK}
                >
                  <span className={"nav-icon"} />
                  Assurance habitation
                </NavLink>

                <NavLink
                  className={"internet-nav-item"}
                  onClick={() =>
                    push(GtmEvent.GENERIC_EVENT, {
                      event_name: "product_type",
                      item_category: "Box Internet",
                    })
                  }
                  to={COMPARE_INTERNET_LINK}
                >
                  <span className={"nav-icon"} />
                  Box Internet
                </NavLink>

                <NavLink
                  className={"mooving-nav-item"}
                  onClick={() =>
                    push(GtmEvent.GENERIC_EVENT, {
                      event_name: "product_type",
                      item_category: "Déménageurs",
                    })
                  }
                  to={COMPARE_MOVING_LINK}
                >
                  <span className={"nav-icon"} />
                  Déménageurs
                </NavLink>

                <NavLink
                  className={"water-nav-item"}
                  onClick={() =>
                    push(GtmEvent.GENERIC_EVENT, {
                      event_name: "product_type",
                      item_category: "Eau",
                    })
                  }
                  to={COMPARE_WATER_LINK}
                >
                  <span className={"nav-icon"} />
                  Eau
                </NavLink>
              </Nav>
            </div>
          </div>
        </div> */}

        <Routes>
          <Route
            element={<Navigate to={keepQueryParams(COMPARE_INSURANCE_LINK)} />}
            index
          />
          <Route element={<EnergyPage />} path={COMPARE_ENERGY} />
          <Route element={<InsurancePage />} path={COMPARE_INSURANCE} />
          <Route element={<InternetPage />} path={COMPARE_INTERNET} />
          <Route element={<MovingPage />} path={COMPARE_MOVING} />
          <Route element={<WaterPage />} path={COMPARE_WATER} />
        </Routes>

        {pendingToAdd !== null && (
          <AuthDialog
            onAuthSuccess={(userId) => addToCart(pendingToAdd!, userId)}
            onClose={() => setPendingToAdd(null)}
          />
        )}
        {showCalculatorDialog &&
          (matchEnergy ? (
            <ConsumptionCalculatorDialog
              onClose={() => setShowCalculatorDialog(false)}
              onSubmit={() => {
                setShowCalculatorDialog(false);
                return Promise.resolve().then(() => scrollToHighlight());
              }}
            />
          ) : matchInsurance ? (
            <InsuranceCalculatorDialog
              onClose={() => setShowCalculatorDialog(false)}
              onSubmit={(values) => {
                const location = currentLocation || values.currentLocation;
                const quotes = loadQuotes({
                  floor: values.flatLevel!,
                  habitationUsage: values.housingCategory!,
                  isHouse: values.housingType === HousingType.House,
                  isOwner: values.clientType === ClientType.Owner,
                  roomsCount: values.roomNumber!,
                  surface: values.size!,
                  address: location!.cleanedAddress!,
                  city: location!.city!,
                  postCode: location!.postCode!,
                });
                return quotes.then(() => {
                  setShowCalculatorDialog(false);
                  scrollToHighlight();
                });
              }}
            />
          ) : (
            <InternetCaluculatorDialog
              onClose={() => setShowCalculatorDialog(false)}
              onSubmit={(values) => {
                setCurrentLocation(values.address!);
                setShowCalculatorDialog(false);
                return Promise.resolve().then(() => scrollToHighlight());
              }}
            />
          ))}
      </div>
    </div>
  );
};

export default withProvideContent(Compare);
