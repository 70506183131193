import { toFixed } from "../../numbers/formatting";

const DAILY_WATER_CONSUMPTION = 50; // In liters/person/day
export function getMonthlyWaterConsumptionInKWh(
  numberOfPeople: number,
): number {
  // NOTE: 1.162 is the official conversion factor
  const dailyWaterConsumptionInWh =
    1.162 * (55 - 15) * numberOfPeople * DAILY_WATER_CONSUMPTION;
  const monthlyWaterConsumptionInWh = (dailyWaterConsumptionInWh * 365) / 12;
  const monthlyWaterConsumptionInKWh = monthlyWaterConsumptionInWh / 1000;

  return toFixed(monthlyWaterConsumptionInKWh, 2);
}

const YEARLY_HEATING_CONSUMPTION = 110; // In kWh/m2/year
export function getMonthlyHeatingConsumptionInKWh(numberOfM2: number): number {
  return toFixed((numberOfM2 * YEARLY_HEATING_CONSUMPTION) / 12, 2);
}

const YEARLY_COOKING_CONSUMPTION = 200; // In kWh/person/year
export function getMonthlyCookingConsumptionInKWh(
  numberOfPeople: number,
): number {
  return toFixed((numberOfPeople * YEARLY_COOKING_CONSUMPTION) / 12, 2);
}

const AVERAGE_MONTHLY_DOMESTIC_ELECTRICAL_CONSUMPTION = 2; // In kWh/m2/month
export function getAverageMonthlyElectricalConsumption(area: number): number {
  return toFixed(area * AVERAGE_MONTHLY_DOMESTIC_ELECTRICAL_CONSUMPTION, 2);
}
