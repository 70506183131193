import InsuranceComparator from "../../../services/compare/insurance/InsuranceComparator";

const InsurancePage = (): JSX.Element => {
  return (
    <div>
      <InsuranceComparator />
    </div>
  );
};

export default InsurancePage;
