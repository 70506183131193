import React from "react";
import withRedirectToPrivate from "../../services/auth/hocs/withRedirectToPrivate";
import {
  LOGIN_LINK,
  LOST_PASSWORD_LINK,
  RESET_PASSWORD,
} from "../../routes/public";
// eslint-disable-next-line no-restricted-imports
import Link from "../../services/routing/components/Link";
import { object, ref, string } from "yup";
import { useToastsWithIntl } from "../../services/toast-notifications";
import { useAuth } from "../../services/auth/apiProvider";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "../../services/routing/useParams";
import Card from "src/services/ui/block/Card";
import CardBody from "../../services/ui/block/CardBody";
import CardHead from "../../services/ui/block/CardHead";
import FormFooter from "../../services/forms/FormFooter";
import Form from "../../services/forms/Form";
import SubmitButton from "../../services/forms/SubmitButton";
import ValidationsErrors from "../../services/forms/ValidationsErrors";
import PasswordField from "../../services/forms/PasswordField";

const ResetPasswordSchema = object().shape({
  password: string()
    .label("mot de passe")
    .required()
    .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, {
      message:
        "Le mot de passe doit contenir 8 caractères min. dont au moins une lettre, un chiffre et un caractère spécial.",
    }),
  passwordConfirmation: string()
    .label("confirmation du mot de passe")
    .required()
    .equals([ref("password")], "Les mots de passe ne correspondent pas."),
});

const ResetPassword = (): JSX.Element => {
  const { resetPassword } = useAuth();
  const { toastSuccess, toastError } = useToastsWithIntl(["auth"]);
  const navigate = useNavigate();

  const { guid } = useParams<typeof RESET_PASSWORD>();
  return (
    <Card className={"auth-card theme-light --max-600"}>
      <CardHead title={"Réinitialisation de votre mot de passe"} />

      <CardBody>
        <Form
          initialValues={{ password: "", passwordConfirmation: "" }}
          onSubmit={(values) => {
            return resetPassword(guid, values.password).then(
              () => {
                toastSuccess("auth:reset-password.SUCCESS");
                return navigate(LOGIN_LINK);
              },
              (err: AxiosError) => {
                if (err.response?.status === 406) {
                  toastError("auth:reset-password.ERROR_LINK_ALREADY_USE");
                  return navigate(LOST_PASSWORD_LINK);
                } else {
                  toastError("auth:reset-password.ERROR");
                }
              },
            );
          }}
          schema={ResetPasswordSchema}
        >
          <div className={"form-block"}>
            <label className={"input-label"}>Nouveau mot de passe *</label>
            <PasswordField name={"password"} placeholder={"**********"} />

            <p className={"input-tip"}>
              8 caractères min. dont au moins une lettre, un chiffre et un
              caractère spécial.
            </p>
          </div>

          <div className={"form-block"}>
            <label className={"input-label"}>
              Confirmation du nouveau mot de passe *
            </label>
            <PasswordField
              name={"passwordConfirmation"}
              placeholder={"Password confirmation"}
            />
          </div>

          <ValidationsErrors />

          <FormFooter>
            <div className={"section-s"}>
              <SubmitButton block>Réinitialiser</SubmitButton>
            </div>
            <div>
              <Link className={"btn-link btn-block"} to={LOGIN_LINK}>
                Retour à la connexion
              </Link>
            </div>
          </FormFooter>
        </Form>
      </CardBody>
    </Card>
  );
};

export default withRedirectToPrivate(ResetPassword);
