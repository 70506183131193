import {
  ComponentType,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { PricePer } from "../common";
import { EnergyType } from "./ConsumptionCalculatorDialog";

export enum ConsumptionCalculatorStep {
  KnownConsumption,
  EstimatedWithPdl,
  Calculated,
}

export interface ConsumptionAPI {
  // Stored in query params
  electricityConsumption: number | null;
  gasConsumption: number | null;

  // Stored in flash memory
  pdlNumber: string | null;
  lastConsumptionCalculatorStep: ConsumptionCalculatorStep;
  area: number | null;
  residentCount: number | null;
  hotWaterSource: EnergyType | null;
  heatingSource: EnergyType | null;
  cookingSource: EnergyType | null;
  priceFormat: PricePer;

  setPriceFormat(priceFormat: PricePer | ((prev: PricePer) => PricePer)): void;
  setConsumption(electricity: number | null, gas: number | null): void;
  setPdlNumber(pdlNumber: string | null): void;
  setLastConsumptionCalculatorStep(
    lastConsumptionCalculatorStep: ConsumptionCalculatorStep,
  ): void;
  setArea(area: number | null): void;
  setResidentCount(residentCount: number | null): void;
  setHotWaterSource(hotWaterSource: EnergyType | null): void;
  setHeatingSource(heatingSource: EnergyType | null): void;
  setCookingSource(cookingSource: EnergyType | null): void;
  isSetConsumption: boolean;
}

export const ConsumptionContext = createContext<ConsumptionAPI | null>(null);

export const ProvideConsumption = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [electricityConsumption, setElectricityConsumption] = useState<
    number | null
  >(null);
  const [gasConsumption, setGasConsumption] = useState<number | null>(null);

  const [priceFormat, setPriceFormat] = useState<PricePer>(PricePer.Month);

  // TODO: sequencial call setqueryparams
  const setConsumption: ConsumptionAPI["setConsumption"] = useCallback(
    (elec, gas) => {
      setElectricityConsumption(elec !== null && elec > 0 ? elec : null);
      setGasConsumption(gas !== null && gas > 0 ? gas : null);
    },
    [],
  );

  const [pdlNumber, setPdlNumber] = useState<ConsumptionAPI["pdlNumber"]>(null);
  const [
    lastConsumptionCalculatorStep,
    setLastConsumptionCalculatorStep,
  ] = useState<ConsumptionAPI["lastConsumptionCalculatorStep"]>(
    ConsumptionCalculatorStep.Calculated,
  );
  const [area, setArea] = useState<ConsumptionAPI["area"]>(null);
  const [residentCount, setResidentCount] = useState<
    ConsumptionAPI["residentCount"]
  >(null);
  const [hotWaterSource, setHotWaterSource] = useState<
    ConsumptionAPI["hotWaterSource"]
  >(null);
  const [heatingSource, setHeatingSource] = useState<
    ConsumptionAPI["heatingSource"]
  >(null);
  const [cookingSource, setCookingSource] = useState<
    ConsumptionAPI["cookingSource"]
  >(null);

  const isSetConsumption = useMemo(() => {
    return !!gasConsumption || !!electricityConsumption;
  }, [electricityConsumption, gasConsumption]);

  return (
    <ConsumptionContext.Provider
      value={{
        electricityConsumption,
        gasConsumption,
        pdlNumber,
        lastConsumptionCalculatorStep,
        area,
        residentCount,
        hotWaterSource,
        heatingSource,
        cookingSource,
        priceFormat,
        isSetConsumption,
        setConsumption,
        setPdlNumber,
        setLastConsumptionCalculatorStep,
        setArea,
        setResidentCount,
        setHotWaterSource,
        setHeatingSource,
        setCookingSource,
        setPriceFormat,
      }}
    >
      {children}
    </ConsumptionContext.Provider>
  );
};

export function withProvideConsumption<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvideConsumption(props: P) {
    return (
      <ProvideConsumption>
        <WrappedComponent {...props} />
      </ProvideConsumption>
    );
  }

  WithProvideConsumption.displayName = `withProvideConsumption(${displayName})`;

  return WithProvideConsumption;
}

export function useConsumption() {
  return useContext(ConsumptionContext)!;
}
