import {
  ComponentType,
  createContext,
  ReactNode,
  useContext,
  useState,
} from "react";

export interface LoadingAPI {
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

export const LoadingContext = createContext<LoadingAPI | null>(null);

export const ProvideLoading = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [loading, setLoading] = useState<LoadingAPI["loading"]>(false);

  return (
    <LoadingContext.Provider
      value={{
        loading,
        setLoading,
      }}
    >
      {children}

      {loading && <LoadingDisplay />}
    </LoadingContext.Provider>
  );
};

export function withProvideLoading<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvideLoading(props: P) {
    return (
      <ProvideLoading>
        <WrappedComponent {...props} />
      </ProvideLoading>
    );
  }

  WithProvideLoading.displayName = `withProvideLoading(${displayName})`;

  return WithProvideLoading;
}

export function useLoading(): LoadingAPI {
  return useContext(LoadingContext) as LoadingAPI;
}

const LoadingDisplay = (): JSX.Element => {
  return (
    <div className={"loading-display"}>
      <div>
        <div className={"spinner"}></div>
        <div className={"messages"}>
          <p>Analyse de vos informations…</p>
          <p>Actualisation des offres disponibles sur le marché…</p>
          <p>Calcul des mensualités…</p>
          <p>
            Identification des meilleures offres en fonction de vos critères…
          </p>
          <p>Affichage des informations demandées…</p>
        </div>
      </div>
    </div>
  );
};

export default LoadingDisplay;
