import {
  ComponentType,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../auth/apiProvider";
import { Cart, CartType } from "../compare/cart/cart";
import { useEnergy } from "../compare/energy/energyProvider";
import { useInsurance } from "../compare/insurance/insuranceProvider";
import { useInternet } from "../compare/internet/internetProvider";
import { useMoving } from "../compare/moving/movingProvider";
import { useWater } from "../compare/water/waterProvider";
import { loggerBuilder } from "../logger";
import { spliceReturn } from "../data-structures/array";

const logger = loggerBuilder("gtmProvider");

export interface GtmCartItem {
  item_id: string;
  item_name: string;
  index: number | null;
  item_brand: string | null;
  item_category: string;
  item_variant?: string;
  price?: number | null;
  currency: string;
}

export enum GtmEvent {
  VIRTUAL_PAGE_VIEW = "virtual_page_view",
  LOGIN = "login",
  SIGN_UP = "sign_up",
  GENERIC_EVENT = "generic_event",
  ECOMMERCE = "ecommerce",
  CHECKOUT = "checkout",
  FORM = "form",
}

interface GtmAPI {
  init(): void;
  setCookieRefused(state: boolean): void;
  push(event: GtmEvent, data: Record<string | number | symbol, unknown>): void;

  mapCartToItem(
    carts: Omit<Cart<any>, "id" | "createdAt">,
    idx: number | null,
  ): GtmCartItem | null;
}

export const GtmContext = createContext<GtmAPI | null>(null);

export const ProvideGtm = ({
  gtmId,
  autoInit,
  children,
}: {
  gtmId: string;
  autoInit: boolean;
  children: ReactNode;
}): JSX.Element => {
  const { user, setAfterLoginHooks } = useAuth();
  const location = useLocation();
  const { brands: energyBrands, offers: energyOffers } = useEnergy();
  const { brands: insuranceBrands } = useInsurance();
  const { brands: internetBrands, offers: internetOffers } = useInternet();
  const { brands: waterBrands } = useWater();
  const { brands: movingBrands } = useMoving();

  // By default we consider that cookies are refused
  const [cookieRefused, setCookieRefused] = useState(true);

  const push: GtmAPI["push"] = useCallback(
    (event, data) => {
      logger.info(`Event "${event}" pushed to GTM`, data);

      const dataLayer = (window as any).dataLayer;
      if (!dataLayer) {
        if (!cookieRefused)
          logger.error(
            "Cannot push GTM event, dataLayer has not been installed",
          );
        return;
      }

      dataLayer.push({
        event: event,
        ...data,
      });
    },
    [cookieRefused],
  );

  const init = useCallback(() => {
    const _window = window as any;

    if (!_window.dataLayer) {
      _window.dataLayer = [];
      push(GtmEvent.VIRTUAL_PAGE_VIEW, {
        user_id: user?.id,
      });

      const script = document.createElement("script");
      script.setAttribute("id", "google-gtm");
      script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gtmId}');`;
      document.body.appendChild(script);

      logger.info("GTM installed");
    } else {
      logger.info("GTM has already been installed");
    }
  }, [gtmId, push, user?.id]);

  useEffect(() => {
    if (autoInit) {
      init();
    }
  }, [autoInit, init]);

  useEffect(() => {
    logger.debug(
      "Moved to page",
      `${window.location.protocol}//${window.location.host}${location.pathname}${location.search}${location.hash}`,
    );
    push(GtmEvent.VIRTUAL_PAGE_VIEW, {
      user_id: user?.id,
    });
  }, [location, push, user?.id]);

  // Mappers
  const mapCartToItem: GtmAPI["mapCartToItem"] = useCallback(
    (e, idx = null) => {
      if (
        e.type === CartType.Electricity &&
        energyOffers.has(e.data.offerId!)
      ) {
        const t_e = e as Cart<CartType.Electricity>;
        const offer = energyOffers.get(t_e.data.offerId);
        return {
          item_id: t_e.id,
          item_name: offer!.name,
          index: idx,
          item_brand: offer?.brandId
            ? energyBrands.get(offer.brandId)?.name || null
            : null,
          item_category: "Énergie",
          item_variant: "Électricité",
          price: offer?.price
            ? (t_e.data.electricityConsumption * offer.price) / 100 +
              offer.subscription
            : null,
          currency: "EUR",
        };
      } else if (e.type === CartType.Gas && energyOffers.has(e.data.offerId!)) {
        const t_e = e as Cart<CartType.Gas>;
        const offer = energyOffers.get(t_e.data.offerId);
        return {
          item_id: t_e.id,
          item_name: offer!.name,
          index: idx,
          item_brand: offer?.brandId
            ? energyBrands.get(offer.brandId)?.name || null
            : null,
          item_category: "Énergie",
          item_variant: "Gaz",
          price: offer?.price
            ? (t_e.data.gasConsumption * offer.price) / 100 + offer.subscription
            : null,
          currency: "EUR",
        };
      } else if (
        e.type === CartType.Insurance &&
        insuranceBrands.has(e.data.brandId!)
      ) {
        const t_e = e as Cart<CartType.Insurance>;
        const brand = insuranceBrands.get(t_e.data.brandId);
        return {
          item_id: t_e.id,
          item_name: brand!.name,
          index: idx,
          item_brand: brand?.name || null,
          item_category: "Assurance",
          price: t_e.data.price ? t_e.data.price / 12 : null,
          currency: "EUR",
        };
      } else if (
        e.type === CartType.Internet &&
        internetOffers.has(e.data.offerId!)
      ) {
        const t_e = e as Cart<CartType.Internet>;
        const offer = internetOffers.get(t_e.data.offerId);
        return {
          item_id: t_e.id,
          item_name: offer!.name,
          index: idx,
          item_brand: offer?.brandId
            ? internetBrands.get(offer.brandId)?.name || null
            : null,
          item_category: "Internet",
          price: offer?.price ? offer.price : null,
          currency: "EUR",
        };
      } else if (
        e.type === CartType.Water &&
        waterBrands.has(e.data.brandId!)
      ) {
        const t_e = e as Cart<CartType.Water>;
        const brand = waterBrands.get(t_e.data.brandId!);
        return {
          item_id: t_e.id,
          item_name: brand!.name,
          index: idx,
          item_brand: brand!.name,
          item_category: "Eau",
          currency: "EUR",
        };
      } else if (
        e.type === CartType.Moving &&
        movingBrands.has(e.data.brandId!)
      ) {
        const t_e = e as Cart<CartType.Moving>;
        const brand = movingBrands.get(t_e.data.brandId!);
        return {
          item_id: t_e.id,
          item_name: brand!.name,
          index: idx,
          item_brand: brand!.name,
          item_category: "Déménagement",
          currency: "EUR",
        };
      } else {
        return null;
      }
    },
    [
      energyBrands,
      energyOffers,
      insuranceBrands,
      internetBrands,
      internetOffers,
      movingBrands,
      waterBrands,
    ],
  );

  // Hooks
  useEffect(() => {
    setAfterLoginHooks((prev) => [
      ...prev,
      [
        "gtm-login-hook",
        () => {
          push(GtmEvent.LOGIN, {
            event_name: "login",
          });
          return Promise.resolve();
        },
      ],
    ]);
    return () => {
      setAfterLoginHooks((prev) =>
        spliceReturn(prev, ([hookId]) => hookId === "gtm-login-hook"),
      );
    };
  }, [push, setAfterLoginHooks]);

  return (
    <GtmContext.Provider
      value={{
        push,
        init,
        setCookieRefused,
        mapCartToItem,
      }}
    >
      {children}
    </GtmContext.Provider>
  );
};

export function withProvideGtm<P extends Record<string, unknown>>(
  gtmId: string,
  WrappedComponent: ComponentType<P>,
  autoInit = true,
): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvideGtm(props: P) {
    return (
      <ProvideGtm autoInit={autoInit} gtmId={gtmId}>
        <WrappedComponent {...props} />
      </ProvideGtm>
    );
  }

  WithProvideGtm.displayName = `withProvideGtm(${displayName})`;

  return WithProvideGtm;
}

export function useGtm(): GtmAPI {
  return useContext(GtmContext) as GtmAPI;
}
