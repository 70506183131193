import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { object, string, boolean, ObjectSchema } from "yup";
import { UserToRegisterForm } from "../user";
import ValidationsErrors from "../../forms/ValidationsErrors";
import SubmitButton from "../../forms/SubmitButton";
import Form from "../../forms/Form";
import AutoField from "../../forms/AutoField";
import { LOGIN_LINK } from "src/routes/public";
import { Link } from "react-router-dom";
import { useField } from "formik";

interface Props {
  onSubmit: (
    values: UserToRegisterForm,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => Promise<void>;
  onLoginBack?: (() => void) | null;
  FormFooterElement?: JSX.Element | null;
  defaultValues?: Partial<UserToRegisterForm>;
}

const BooleanField = ({
  name,
  children,
}: {
  name: string;
  children: JSX.Element;
}): JSX.Element => {
  const [field, , helpers] = useField(name);

  return (
    <div className={"form-block"}>
      <label>
        <input
          checked={field.value}
          onChange={() => helpers.setValue(!field.value)}
          type={"checkbox"}
        />
        {children}
      </label>
    </div>
  );
};

const RegisterForm = ({
  onSubmit,
  defaultValues,
  onLoginBack,
  FormFooterElement = null,
}: Props): JSX.Element => {
  /* Hooks */
  const { t } = useTranslation(["auth"]);

  /* Models */
  const RegisterSchema: ObjectSchema<UserToRegisterForm> = useMemo(
    () =>
      object()
        .shape({
          email: string().label(t("auth:email")).required().email(),
          password: string().label(t("auth:password")).required().password(),
          optinMentions: boolean()
            .label("Acceptation conditions d'utilisation")
            .default(false)
            .oneOf([true], () => ({ key: "acceptation_conditions" }))
            .required(() => ({ key: "acceptation_conditions" })),
          optinContact: boolean()
            .label("Acceptation d'être rappele")
            .default(false)
            .oneOf([true], () => ({ key: "acceptation_callback" }))
            .required(() => ({ key: "acceptation_callback" })),
        })
        .defined(),
    [t],
  );

  return (
    <Form
      initialValues={{
        email: "",
        password: "",
        optinMentions: null,
        optinContact: null,
        ...defaultValues,
      }}
      onSubmit={(values, { setSubmitting }) => {
        return onSubmit(RegisterSchema.validateSync(values), setSubmitting);
      }}
      schema={RegisterSchema}
    >
      <div>
        <AutoField name={"email"} />
        <AutoField name={"password"} />
        <div className={"margin-top"}>
          <BooleanField name={"optinMentions"}>
            <label className={"checkbox-label"}>
              J'accepte les{" "}
              <a
                href={
                  "https://movecool.notion.site/Mentions-L-gales-Conditions-G-n-rales-d-utilisation-7de1bc001712449fb83908486d769537"
                }
                rel={"noreferrer"}
                target={"_blank"}
              >
                Condtions générales d'utilisation
              </a>{" "}
              et la{" "}
              <a
                href={
                  "https://movecool.notion.site/Politique-de-confidentialit-gestion-des-cookies-48624257b4754a498d8727d8efd135d5"
                }
                rel={"noreferrer"}
                target={"_blank"}
              >
                politique de protection des données
              </a>{" "}
              *
            </label>
          </BooleanField>
          <BooleanField name={"optinContact"}>
            <label className={"checkbox-label"}>
              J'accepte d'être recontacté par Movecool et ses marques
              partenaires pour m'accompagner dans mes démarches *
            </label>
          </BooleanField>
        </div>

        <ValidationsErrors />

        {FormFooterElement ? (
          FormFooterElement
        ) : (
          <div className={"section"}>
            <SubmitButton className={"btn-1 btn-block"}>
              Créer mon compte
            </SubmitButton>

            {onLoginBack === null ? null : onLoginBack ? (
              <button className={"btn-link btn-block"} onClick={onLoginBack}>
                Retour à la connexion
              </button>
            ) : (
              <Link className={"btn-link btn-block"} to={LOGIN_LINK}>
                Retour à la connexion
              </Link>
            )}
          </div>
        )}
      </div>
    </Form>
  );
};

export default RegisterForm;
