import EnergyComparator from "src/services/compare/energy/EnergyComparator";

const EnergyPage = (): JSX.Element => {
  return (
    <div>
      <EnergyComparator />
    </div>
  );
};

export default EnergyPage;
