import { HTMLAttributes } from "react";

import Dialog from "../../ui/block/Dialog";

import { InternetOfferType, OfferWithBrand } from "./internet";
import InternetPriceDisplay from "./PriceDisplay";
import iconFiber from "../../../assets/img/icons/icon-wifi-blue.svg";

import iconArrow from "../../../assets/img/icons/icon-arrow-circle-right-white.svg";
import FeaturesList from "./FeaturesList";

const InternetDetailsDialog = <
  P extends HTMLAttributes<HTMLDivElement> & {
    cart?: boolean;
    offer: OfferWithBrand;
    onClose(): void;
    onChooseOffer(): void;
  }
>({
  cart,
  offer,
  onClose,
  onChooseOffer,
  ...props
}: P): JSX.Element => {
  return (
    <Dialog
      className={"offer-detail-popup"}
      closeLabel={"Revenir aux offres"}
      onClose={onClose}
    >
      <div className={"grid"} {...props}>
        <div className={"col-m-1-2"}>
          <div className={"card-body"}>
            <div className={"brand-and-offer-head"}>
              <img
                alt={`Logo ${offer.brand.name}`}
                className={"brand-logo"}
                src={offer.brand.logo}
              />
              <div>
                <h2 className={"brand-name"}>{offer.brand.name}</h2>
                {offer && <h3 className={"offer-name"}>{offer.name}</h3>}
                <div className={"icon-text"}>
                  <img alt={"fibre"} className={"icon"} src={iconFiber} />
                  {offer.type === InternetOfferType.Fiber ? (
                    <span>Fibre</span>
                  ) : (
                    <span>ADSL</span>
                  )}
                </div>
              </div>
            </div>
            <div className={"section grid"}>
              <div className={"col-m-1-3"}>
                <img
                  alt={offer.name}
                  className={"box-image"}
                  src={offer.boxPicture}
                />
                <p className={"box-name"}>{offer.boxName}</p>
              </div>
              <div className={"col-m-2-3 features-column"}>
                <FeaturesList offer={offer} />
              </div>
            </div>
          </div>
        </div>
        <div className={"col-m-1-2 col-offer-detail"}>
          <div className={"card-body"}>
            <div>
              <h4 className={"section-title"}>Le petit plus de cette offre</h4>
              <p className={"body-s"}>{offer.description}</p>
              <div className={"section"}>
                <InternetPriceDisplay offer={offer} />
              </div>
            </div>

            <div className={"offer-cta comp-internet"}>
              <div className={"buttons-row stretch"}>
                <button
                  className={"btn-branded btn-block"}
                  onClick={() => {
                    onClose();
                    onChooseOffer();
                  }}
                >
                  <img
                    alt={"arrow right"}
                    className={"icon icon-left"}
                    src={iconArrow}
                  />
                  Choisir
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default InternetDetailsDialog;
