import Card from "../../ui/block/Card";
import CardBody from "../../ui/block/CardBody";
import CardHead from "../../ui/block/CardHead";
import Dialog from "../../ui/block/Dialog";
import Button from "../../ui/reactive/Button";
import tutorImg from "../../../assets/img/tutor.png";
import { GtmEvent, useGtm } from "../../gtm/gtmProvider";

const HowToReadAnEnergyInvoiceDialog = ({
  onClose,
}: {
  onClose(): void;
}): JSX.Element => {
  const { push } = useGtm();

  return (
    <Dialog onClose={onClose}>
      <Card className={"card-popup-info"}>
        <CardHead title={"L'information Movecool"} />
        <CardBody>
          <div className={"card-img"}>
            <img alt={"professeur"} src={tutorImg} />
          </div>

          <h3>Une facture d’électricité est composée de 3 éléments :</h3>
          <ul>
            <li>
              <strong>Une partie variable,</strong> votre consommation
              électrique multipliée par le prix du kilowattheure d’électricité
            </li>
            <li>
              <strong>Une partie fixe,</strong> un abonnement donnant accès aux
              services du fournisseur
            </li>
            <li>
              <strong>Les taxes de l'état,</strong> la TICFE, la CTA, les TCFE
              et la TVA
            </li>
          </ul>
        </CardBody>
        <div className={"card-footer"}>
          <p>
            Besoin d’aide pour comprendre votre facture ?<br />
            <a
              className={"link"}
              href={"tel:0745893259"}
              onClick={() => {
                push(GtmEvent.GENERIC_EVENT, {
                  event_name: "call",
                });
              }}
            >
              Contactez- nous !
            </a>
          </p>
          <Button onClick={onClose}>&larr; Fermer</Button>
        </div>
      </Card>
    </Dialog>
  );
};

export default HowToReadAnEnergyInvoiceDialog;
