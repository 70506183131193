import { cx } from "@emotion/css";
import { Autocomplete } from "@react-google-maps/api";
import { useField } from "formik";
import {
  createRef,
  HTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from "react";
import { number, object, string } from "yup";
import Form from "../forms/Form";
import SubmitButton from "../forms/SubmitButton";
import ValidationsErrors from "../forms/ValidationsErrors";
import { Location, mapPlaceResultToLocation } from "../google-maps/place";
import { usePlace } from "../google-maps/placeProvider";

export enum Home {
  Current,
  Future,
}

const HomeSelect = ({
  name,
  ...props
}: HTMLAttributes<HTMLDivElement> & { name: string }): JSX.Element => {
  const [field, , helper] = useField<Home | string>(name);
  // NOTE: This is because the formik field is stored as a string

  return (
    <div {...props}>
      <div className={"input-label"}>Type d'adresse</div>
      <select
        className={"select"}
        id={"addressType"}
        name={"addressType"}
        onChange={(event) => helper.setValue(event.target.value)}
        value={field.value}
      >
        <option value={Home.Current}>Logement actuel</option>
        <option value={Home.Future}>Futur logement</option>
      </select>
    </div>
  );
};

export enum Situation {
  Moving,
  Saving,
}

const SituationSelect = ({
  name,
  ...props
}: HTMLAttributes<HTMLDivElement> & { name: string }): JSX.Element => {
  const [field, , helper] = useField<Situation | string>(name);
  // NOTE: This is because the formik field is stored as a string

  return (
    <div {...props} style={{ display: "none" }}>
      <div className={"input-label"}>Situation</div>
      <select
        className={"select"}
        id={"addressType"}
        name={"addressType"}
        onChange={(event) => helper.setValue(event.target.value)}
        value={field.value}
      >
        <option value={Situation.Saving}>Faire des économies</option>
        <option value={Situation.Moving}>Déménager</option>
      </select>
    </div>
  );
};

export const GoogleMapsSearchbar = ({
  name,
  label,
  className,
}: {
  name: string;
  label?: string;
  className?: string;
}): JSX.Element => {
  const [field, , helper] = useField<Location | null>(name);
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(field.value?.plainAddress || "");

    return () => {
      setValue("");
    };
  }, [field.value?.plainAddress]);

  const [
    autocomplete,
    setAutocomplete,
  ] = useState<google.maps.places.Autocomplete | null>(null);

  const setValueOnClick = useCallback(() => {
    if (autocomplete) {
      helper.setValue(mapPlaceResultToLocation(autocomplete.getPlace()));
    }
  }, [autocomplete, helper]);

  const autocompleteRef = createRef<HTMLDivElement>();
  const onEnter = useCallback((event: any) => {
    if (event.key === "Enter") {
      // NOTE: We need to catch the Enter keypress to prevent the parent form from submitting
      event.preventDefault();
    }
  }, []);
  useEffect(() => {
    const current = autocompleteRef.current;
    current?.addEventListener("keydown", onEnter);

    return () => {
      current?.removeEventListener("keydown", onEnter);
    };
  }, [autocompleteRef, onEnter]);

  return (
    <div
      ref={autocompleteRef}
      className={cx(className, "stretch", "map-search")}
    >
      <Autocomplete
        onLoad={setAutocomplete}
        onPlaceChanged={setValueOnClick}
        restrictions={{ country: "fr" }}
      >
        <div>
          <div className={"input-label"}>{label || "Adresse postale"}</div>
          <input
            className={"form-control input"}
            onChange={(e) => setValue(e.target.value)}
            placeholder={"Saisissez l'adresse du logement"}
            type={"text"}
            value={value}
            required
          />
        </div>
      </Autocomplete>
    </div>
  );
};

const HomeInput = ({
  home: initialHome,
  situation: initialSituation,
  onSubmit,
}: {
  home?: Home;
  situation?: Situation;
  onSubmit({
    home,
    address,
    situation,
  }: {
    home: Home;
    address: Location;
    situation: Situation;
  }): Promise<unknown>;
}): JSX.Element => {
  const { currentLocation, setCurrentLocation } = usePlace();

  return (
    <Form
      enableReinitialize={true}
      initialValues={{
        situation:
          initialSituation === undefined ? Situation.Saving : initialSituation,
        home: initialHome === undefined ? Home.Current : initialHome,
        address: currentLocation,
      }}
      onSubmit={(values) => {
        setCurrentLocation(values.address);
        return onSubmit(values as any);
      }}
      schema={object({
        situation: string().required(),
        home: number().required(),
        address: object({
          plainAddress: string(),
          cleanedAddress: string(),
          city: string(),
          postCode: string(),
        })
          .label("adresse")
          .nullable()
          .required({ key: "address" }),
      })}
    >
      <div className={"card"}>
        <div className={"card-body inputs-row main-address-inputs-row"}>
          <SituationSelect name={"situation"} />
          <GoogleMapsSearchbar name={"address"} />
          <HomeSelect name={"home"} />
          <SubmitButton className={"btn-compare"}>Comparer</SubmitButton>
        </div>
      </div>

      <ValidationsErrors />
    </Form>
  );
};

export default HomeInput;
