import React from "react";
import { cx } from "@emotion/css";

const SiteLayout = ({
  className,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>): JSX.Element => {
  return <div {...props} className={cx(className, "site-layout")} />;
};

export default SiteLayout;
