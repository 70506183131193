import { DetailedHTMLProps, HTMLAttributes } from "react";
import { cx } from "@emotion/css";

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const FormFooter = ({ className, ...formFooterProps }: Props): JSX.Element => {
  return <div {...formFooterProps} className={cx("form-footer", className)} />;
};

export default FormFooter;
