import { compile, PathFunction } from "path-to-regexp";
import { stringify } from "query-string";

const cache: Record<string, PathFunction<Record<string, unknown>>> = {};
const cacheLimit = 10000;
let cacheCount = 0;

function compilePath(path: string): PathFunction<Record<string, unknown>> {
  if (cache[path]) return cache[path];

  const generator = compile(path);

  if (cacheCount < cacheLimit) {
    cache[path] = generator;
    cacheCount++;
  }

  return generator;
}

function generatePath(
  uncleanedPath = "/",
  params = {},
  queryParams: Record<string, unknown> | undefined = undefined,
): string {
  const path = uncleanedPath.replace(/\/\?.*/, "/");
  const searchParams = queryParams
    ? stringify(
        Object.fromEntries(
          Object.entries(queryParams).map(([key, value]) => [
            key,
            typeof value === "boolean" ? (value ? null : undefined) : value,
          ]),
        ),
      ).replaceAll("%20", "+")
    : ""; // NOTE: Compatibility between react-router-dom and query-string

  if (path === "/") {
    return searchParams.length > 0 ? `/?${searchParams}` : "/";
  } else {
    const compiledPath = compilePath(path)(params);
    if (queryParams === undefined || Object.keys(queryParams).length < 0)
      return compiledPath;

    // NOTE: Need a trailing slash with query params for react-router-dom
    // Else all the Link components will make the application crash
    return compiledPath.replace(/\/?$/, `/?${searchParams}`);
  }
}

export default generatePath;
