import { DetailedHTMLProps, InputHTMLAttributes } from "react";

interface InputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {}

const Input = ({ ...inputProps }: InputProps): JSX.Element => {
  return <input {...inputProps} className={"input"} />;
};

export default Input;
