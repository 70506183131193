import { cx } from "@emotion/css";
import { useField, useFormikContext } from "formik";
import { number, object, InferType } from "yup";
import Form from "../../forms/Form";
import NumberField from "../../forms/NumberField";
import ValidationsErrors from "../../forms/ValidationsErrors";
import Button from "../../ui/reactive/Button";
import { useHousing } from "./housingProvider";
import {
  ClientType,
  FlatLevel,
  HabitationUsage,
  HousingType,
} from "./insurance";
import { usePlace } from "../../google-maps/placeProvider";
import { GoogleMapsSearchbar } from "../HomeInput";
import { Location, locationSchema } from "../../google-maps/place";
import { Checkout } from "../cart/cart";
import { GtmEvent, useGtm } from "../../gtm/gtmProvider";
import SubmitButton from "src/services/forms/SubmitButton";
import CardBody from "src/services/ui/block/CardBody";
import Dialog from "src/services/ui/block/Dialog";
import Card from "src/services/ui/block/Card";

export const HousingTypeSelect = ({
  name,
  checkout,
}: {
  name: string;
  checkout?: Checkout;
}): JSX.Element => {
  const [field, , helper] = useField<HousingType>(name);

  return (
    <div className={"section-s"}>
      <label className={"input-label"}>Type de logement</label>
      <div className={"buttons-switch"}>
        <button
          className={cx([
            "select-btn",
            field.value === HousingType.Flat && "active",
          ])}
          onClick={() => helper.setValue(HousingType.Flat)}
          type={"button"}
        >
          Appartement
        </button>
        <button
          className={cx([
            "select-btn",
            field.value === HousingType.House && "active",
          ])}
          onClick={() => helper.setValue(HousingType.House)}
          type={"button"}
        >
          Maison
        </button>
      </div>
    </div>
  );
};

export const FlatLevelSelect = <
  FormikType extends InsuranceCalculatorSchemaType
>({
  name,
  hidden,
}: {
  name: string;
  hidden?(values: FormikType): boolean;
}): JSX.Element => {
  const [field, , helper] = useField<FlatLevel>(name);
  const { values } = useFormikContext<FormikType>();

  return hidden === undefined || hidden(values) ? (
    <div className={"section-s"}>
      <label className={"input-label"}>Étage</label>
      <div className={"buttons-switch"}>
        <button
          className={cx([
            "select-btn",
            field.value === FlatLevel.Ground && "active",
          ])}
          onClick={() => helper.setValue(FlatLevel.Ground)}
          type={"button"}
        >
          RDC
        </button>
        <button
          className={cx([
            "select-btn",
            field.value === FlatLevel.Middle && "active",
          ])}
          onClick={() => helper.setValue(FlatLevel.Middle)}
          type={"button"}
        >
          Intermédiaire
        </button>
        <button
          className={cx([
            "select-btn",
            field.value === FlatLevel.Top && "active",
          ])}
          onClick={() => helper.setValue(FlatLevel.Top)}
          type={"button"}
        >
          Dernier étage
        </button>
      </div>
    </div>
  ) : (
    <></>
  );
};

export const ClientTypeSelect = ({
  name,
  checkout,
}: {
  name: string;
  checkout?: Checkout;
}): JSX.Element => {
  const [field, , helper] = useField<ClientType>(name);

  return (
    <div className={"section-s"}>
      <label className={"input-label"}>Occupation</label>
      <div className={"buttons-switch"}>
        <Button
          className={cx([
            "select-btn",
            field.value === ClientType.Owner && "active",
          ])}
          onClick={() => helper.setValue(ClientType.Owner)}
          type={"button"}
        >
          Propriétaire
        </Button>
        <Button
          className={cx([
            "select-btn",
            field.value === ClientType.Renter && "active",
          ])}
          onClick={() => helper.setValue(ClientType.Renter)}
          type={"button"}
        >
          Locataire
        </Button>
      </div>
    </div>
  );
};

export const HousingCategorySelect = <
  FormikType extends InsuranceCalculatorSchemaType
>({
  name,
  checkout,
}: {
  name: string;
  checkout?: Checkout;
}): JSX.Element => {
  const [field, , helper] = useField<HabitationUsage>(name);
  const { values } = useFormikContext<FormikType>();

  return (
    <div className={"section-s"}>
      <label className={"input-label"}>Type de résidence</label>
      <div className={"buttons-switch"}>
        <Button
          className={cx([
            "select-btn",
            field.value === HabitationUsage.Main && "active",
          ])}
          onClick={() => helper.setValue(HabitationUsage.Main)}
          type={"button"}
        >
          Résidence principale
        </Button>
        <Button
          className={cx([
            "select-btn",
            field.value === HabitationUsage.Secondary && "active",
          ])}
          onClick={() => helper.setValue(HabitationUsage.Secondary)}
          type={"button"}
        >
          Résidence secondaire
        </Button>
        {(values.clientType === ClientType.Owner ||
          (!values.clientType &&
            checkout &&
            checkout.home?.clientType === ClientType.Owner)) && (
          <Button
            className={cx([
              "select-btn",
              field.value === HabitationUsage.NonOccupantOwner && "active",
            ])}
            onClick={() => helper.setValue(HabitationUsage.NonOccupantOwner)}
            type={"button"}
          >
            Propriétaire non occupant
          </Button>
        )}
      </div>
    </div>
  );
};

export const InsuranceCalculatorSchema = object({
  housingType: number()
    .label("Type de logement")
    .oneOf([HousingType.Flat, HousingType.House])
    .required(),
  flatLevel: number()
    .label("Niveau de logement")
    .oneOf([FlatLevel.Ground, FlatLevel.Middle, FlatLevel.Top])
    .required(),
  roomNumber: number().label("Nombre de pièces").nullable().required(),
  size: number().label("Surface (m2)").nullable().required(),
  clientType: number()
    .label("Occupation")
    .oneOf([ClientType.Owner, ClientType.Renter])
    .required(),
  housingCategory: number().label("Catégorie de logement").required(),
  currentLocation: locationSchema()
    .label("Adresse postale")
    .nullable()
    .required(() => ({ key: "address" })),
});

type InsuranceCalculatorSchemaType = InferType<
  typeof InsuranceCalculatorSchema
>;

export const InsuranceForm = ({
  onClose,
  onSubmit,
}: {
  onClose?(): void;
  onSubmit(values: InsuranceCalculatorSchemaType): Promise<void>;
}) => {
  const {
    housingType,
    flatLevel,
    clientType,
    housingCategory,
    roomNumber,
    size,
    setHousing,
  } = useHousing();
  const { currentLocation, setCurrentLocation } = usePlace();
  const { push } = useGtm();

  return (
    <div className={"card-block"}>
      <div className={"card-head"}>
        <div className={"card-title --center"}>
          Complétez le formulaire pour afficher les meilleures offres du moment
        </div>
      </div>
      <Card>
        <div className={"card-body"}>
          <div className={"card-body"}>
            <Form
              initialValues={
                {
                  housingType:
                    housingType !== null ? housingType : HousingType.Flat,
                  flatLevel: flatLevel !== null ? flatLevel : FlatLevel.Middle,
                  clientType:
                    clientType !== null ? clientType : ClientType.Renter,
                  housingCategory:
                    housingCategory !== null
                      ? housingCategory
                      : HabitationUsage.Main,
                  roomNumber,
                  size,
                  currentLocation,
                } as InsuranceCalculatorSchemaType
              }
              onSubmit={(values) => {
                push(GtmEvent.FORM, {
                  event_name: "submit_housing_info",
                  rooms: values.roomNumber,
                  surface: values.size,
                  housing:
                    values.housingType === HousingType.Flat
                      ? "Appartement"
                      : "Maison",
                  floor:
                    values.flatLevel === FlatLevel.Top
                      ? "Dernier étage"
                      : values.flatLevel === FlatLevel.Ground
                      ? "RDC"
                      : "Etage intermédiaire",
                  owner:
                    values.clientType === ClientType.Owner
                      ? "Propriétaire"
                      : "Locataire",
                  residence: values.housingCategory,
                  user_address: values.currentLocation.plainAddress,
                });
                const location = values.currentLocation as Location | null;
                setCurrentLocation(location);
                setHousing(values);
                return onSubmit(values);
              }}
              schema={InsuranceCalculatorSchema}
            >
              <HousingTypeSelect name={"housingType"} />

              <FlatLevelSelect
                hidden={(values) => values.housingType === HousingType.Flat}
                name={"flatLevel"}
              />

              <ClientTypeSelect name={"clientType"} />

              <HousingCategorySelect name={"housingCategory"} />

              <div className={"section-s"}>
                <GoogleMapsSearchbar name={"currentLocation"} />
              </div>

              <div className={"section-s"}>
                <div className={"grid"}>
                  <div className={"col-m-1-2"}>
                    <label className={"input-label"}>Nb. de pièces</label>
                    <NumberField name={"roomNumber"} />
                  </div>
                  <div className={"col-m-1-2"}>
                    <label className={"input-label"}>Surface (m2)</label>
                    <NumberField name={"size"} />
                  </div>
                </div>
              </div>

              <ValidationsErrors />

              <div className={"form-footer"}>
                <div className={"buttons-row right"}>
                  {onClose && (
                    <button
                      className={"btn-outlined"}
                      onClick={onClose}
                      type={"button"}
                    >
                      Retour
                    </button>
                  )}
                  <SubmitButton>Comparer</SubmitButton>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Card>
    </div>
  );
};

const InsuranceCalculatorDialog = ({
  onClose,
  onSubmit,
}: {
  onClose(): void;
  onSubmit(values: InsuranceCalculatorSchemaType): Promise<void>;
  skipUserSteps?: boolean;
}): JSX.Element => {
  return (
    <Dialog className={"side-panel"} closeLabel={"Retour  x"} onClose={onClose}>
      <CardBody>
        <InsuranceForm onClose={onClose} onSubmit={onSubmit} />
      </CardBody>
    </Dialog>
  );
};

export default InsuranceCalculatorDialog;
