import { HTMLAttributes } from "react";

import Dialog from "../../ui/block/Dialog";

import { useAuth } from "src/services/auth/apiProvider";

import ImgActionCall from "../../../assets/img/img-action-call.png";
import ImgActionRdv from "../../../assets/img/img-action-rdv.png";
import ImgActionOnline from "../../../assets/img/img-action-online.png";
import { Offer } from "./internet";

const InternetActionsDialog = <
  P extends HTMLAttributes<HTMLDivElement> & {
    offer: Offer;
    onClose(): void;
  }
>({
  offer,
  onClose,
  ...props
}: P): JSX.Element => {
  const { generateCalendlyLink } = useAuth();

  return (
    <>
      <Dialog
        className={"actions-dialog"}
        closeLabel={"Revenir aux offres"}
        onClose={onClose}
      >
        <h2 className={"title"}>Finaliser ma souscription</h2>
        <div className={"action-options"}>
          {!!offer.url && (
            <div className={"option --primary"}>
              <img
                alt={"souscrire en ligne"}
                className={"option-img"}
                src={ImgActionOnline}
              />
              <button
                className={"option-action"}
                onClick={() => {
                  window.open(offer.url!);
                }}
              >
                Continuer en ligne
              </button>
              <div className={"option-label"}>3 minutes top chrono</div>
            </div>
          )}
          <div className={"option"}>
            <img alt={"appeler"} className={"option-img"} src={ImgActionCall} />
            <a className={"option-action"} href={"tel: +33980805110"}>
              09 80 80 51 10
            </a>
            <div className={"option-label"}>Réponse en moins de 90s</div>
          </div>
          <div className={"option"}>
            <img
              alt={"rendez-vous"}
              className={"option-img"}
              src={ImgActionRdv}
            />
            <a
              className={"option-action"}
              href={generateCalendlyLink("", "internet")}
              rel={"noreferrer"}
              target={"_blank"}
            >
              Me faire rappeler
            </a>
            <div className={"option-label"}>Quand je veux</div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default InternetActionsDialog;
