import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { COMPARE_ENERGY_LINK } from "src/routes/compare";
import { useKeepQueryParams } from "src/services/routing/useQueryParams";
import { CART_PERSONAL_INFOS_LINK } from "../../../routes/cart";
import { CartType } from "../../../services/compare/cart/cart";
import { useCart } from "../../../services/compare/cart/cartProvider";
import ElectricitySection from "../../../services/compare/cart/ElectricitySection";
import GazSection from "../../../services/compare/cart/GazSection";
import InsuranceSection from "../../../services/compare/cart/InsuranceSection";
import InternetSection from "../../../services/compare/cart/InternetSection";
import MovingSection from "../../../services/compare/cart/MovingSection";
import WaterSection from "../../../services/compare/cart/WaterSection";
import { PricePer } from "../../../services/compare/common";
import { useConsumption } from "../../../services/compare/energy/consumptionProvider";
import NavLink from "../../../services/routing/components/NavLink";
import {
  ClientType,
  HousingType,
} from "../../../services/compare/insurance/insurance";
// import { useInternet } from "../../../services/compare/internet/internetProvider";
// import {
//   InternetOfferType,
//   OfferWithBrand,
// } from "../../../services/compare/internet/internet";
import {
  Brand as BrandEnergy,
  DualWithOffers,
  Offer,
} from "../../../services/compare/energy/energy";
import { useEnergy } from "../../../services/compare/energy/energyProvider";
import Dialog from "../../../services/ui/block/Dialog";
import Card from "../../../services/ui/block/Card";
import CardHead from "../../../services/ui/block/CardHead";
import CardBody from "../../../services/ui/block/CardBody";
import { object, string } from "yup";
import { GoogleMapsSearchbar } from "../../../services/compare/HomeInput";
import SubmitButton from "../../../services/forms/SubmitButton";
import { useQueryParam } from "../../../services/routing/useQueryParam";
import { usePlace } from "../../../services/google-maps/placeProvider";
import Form from "../../../services/forms/Form";
// import InternetDetailsDialog from "../../../services/compare/internet/InternetDetailsDialog";
import EnergyDetailsModal from "../../../services/compare/energy/EnergyDetailsModal";
import ConsumptionCalculatorDialog from "../../../services/compare/energy/ConsumptionCalculatorDialog";
// import InsuranceDetailsDialog from "../../../services/compare/insurance/InsuranceDetailsDialog";
import InsuranceCalculatorDialog from "../../../services/compare/insurance/InsuranceCalculatorDialog";
import { useHousing } from "../../../services/compare/insurance/housingProvider";
import OrderByPhone from "src/services/common-components/OrderByPhone";
import CartReinsuranceBlock from "src/services/ui/common-components/CartReinsuranceBlock";
import { GtmEvent, useGtm } from "../../../services/gtm/gtmProvider";

const ViewCart = () => {
  const {
    elements,
    elementsPerCartType,
    // electricityOffer,
    // insuranceOffer,
    // gasOffer,
    // internetOffer,
    // nextMonthAmount,
    totalAmount,
    gtmCartItems,
    gtmUserAddress,
  } = useCart();

  const { currentLocation, setCurrentLocation } = usePlace();

  const keepQueryParams = useKeepQueryParams();

  // const { brands: brandsEnergy, offers: energyOffers } = useEnergy();
  const { brands: brandsEnergy } = useEnergy();
  const { setPriceFormat } = useConsumption();
  const [pricePer, setPricePer] = useState(PricePer.Month);
  const [showConsumptionDialog, setShowConsumptionDialog] = useState(false);
  const [selectedEnergyOffer, setSelectedEnergyOffer] = useState<Offer | null>(
    null,
  );
  const [selectedDual, setSelectedDual] = useState<
    (DualWithOffers & { brand: BrandEnergy }) | null
  >(null);

  const {
    clientType,
    flatLevel,
    housingCategory,
    housingType,
    roomNumber,
    size,
    loadQuotes,
  } = useHousing();
  // const { brands: brandsInsurance } = useInsurance();
  const [showInsuranceCalculator, setShowInsuranceCalculator] = useState(false);
  const [dataCompleteCondition, setDataCompleteCondition] = useState(false);

  // const [, setSelectedInsuranceBrand] = useState<BrandInsurance | null>(null);

  // const {
  //   offersWithBrands: internetOffers,
  //   bestElegibilityByOp,
  // } = useInternet();
  const [showInternetCalculator, setShowInternetCalculator] = useState(false);
  // const bestTechByOp = useMemo(() => new Map(bestElegibilityByOp), [
  //   bestElegibilityByOp,
  // ]);

  // const [
  //   selectedInternetOffer,
  //   setSelectedInternetOffer,
  // ] = useState<OfferWithBrand | null>(null);

  const [, setCurrentLocationQueryParam] = useQueryParam("address", "string");

  useEffect(() => {
    setPriceFormat(PricePer.Month);
  }, [setPriceFormat]);

  const togglePricePer = useCallback(() => {
    setPricePer((prev) => {
      const newFormat =
        prev === PricePer.Month ? PricePer.Year : PricePer.Month;

      setPriceFormat(newFormat);

      return newFormat;
    });
  }, [setPriceFormat]);

  // Filters
  // const filteredInsuranceBrands = useMemo(
  //   () =>
  //     [...brandsInsurance.values()].filter(
  //       (brand) =>
  //         !currentLocation ||
  //         (isLocationMatching(currentLocation.postCode!, brand.postalCodes) &&
  //           brand.isCustomizable),
  //     ),
  //   [brandsInsurance, currentLocation],
  // );

  useEffect(() => {
    setDataCompleteCondition(
      clientType !== null &&
        flatLevel !== null &&
        housingCategory !== null &&
        housingType !== null &&
        roomNumber !== null &&
        size !== null,
    );
  }, [
    clientType,
    flatLevel,
    housingCategory,
    housingType,
    roomNumber,
    size,
    dataCompleteCondition,
  ]);

  // const insuranceHighlights = useMemo(
  //   () =>
  //     [...filteredInsuranceBrands.values()].reduce(
  //       (highlights, brand) =>
  //         brand.highlights.length > 0
  //           ? highlights.concat(
  //             brand.highlights.map((h) => ({
  //               label: h,

  //               element: { brand },
  //             })),
  //           )
  //           : highlights,

  //       [] as Highlight[],
  //     ),

  //   [filteredInsuranceBrands],
  // );

  // Memos

  // const internetHighlights = useMemo(
  //   () =>
  //     [...internetOffers.values()].reduce(
  //       (highlights, offer) =>
  //         offer.highlights.length > 0 &&
  //           (bestTechByOp.has(offer.brand.elegibilityCode) ||
  //             offer.type === InternetOfferType.Fiber)
  //           ? highlights.concat(
  //             offer.highlights.map((h) => ({
  //               label: h,

  //               element: { offer },
  //             })),
  //           )
  //           : highlights,

  //       [] as Highlight[],
  //     ),
  //   [bestTechByOp, internetOffers],
  // );

  // const energyHighlights = useMemo(() => {
  //   return [...energyOffers.values()].reduce(
  //     (highlights, offer) =>
  //       offer.highlights.length > 0
  //         ? highlights.concat(
  //           offer.highlights.map((h) => ({
  //             label: h,
  //             element: { offer, brand: brandsEnergy.get(offer.brandId) },
  //           })),
  //         )
  //         : highlights,
  //     [] as Highlight[],
  //   );
  // }, [brandsEnergy, energyOffers]);

  const { push } = useGtm();
  useEffect(() => {
    push(GtmEvent.ECOMMERCE, {
      event_name: "view_cart",
      user_address: gtmUserAddress,
      ecommerce: {
        value: totalAmount,
        currency: "EUR",
        items: gtmCartItems,
      },
    });
  }, [gtmCartItems, push, totalAmount, gtmUserAddress]);

  return (
    <div className={"container-wrap section"}>
      {![...elements].length && (
        <div className={"section"}>
          <div className={"card"}>
            <div className={"card-body center"}>
              <h3 className={"section-title"}>Votre panier est vide</h3>
              <div className={"section"}>
                <Link
                  className={"btn-1"}
                  to={keepQueryParams(COMPARE_ENERGY_LINK)}
                >
                  Revenir au comparateur
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      {!![...elements].length && (
        <div className={"grid"}>
          <div className={"col-m-2-3 cart-main"}>
            {elementsPerCartType.has(CartType.Electricity) && (
              <ElectricitySection
                elements={elementsPerCartType.get(CartType.Electricity) as any}
                priceFormat={pricePer}
                togglePriceFormat={togglePricePer}
              />
            )}

            {elementsPerCartType.has(CartType.Gas) && (
              <GazSection
                elements={elementsPerCartType.get(CartType.Gas) as any}
                priceFormat={pricePer}
                togglePriceFormat={togglePricePer}
              />
            )}

            {elementsPerCartType.has(CartType.Insurance) && (
              <InsuranceSection
                elements={elementsPerCartType.get(CartType.Insurance) as any}
                priceFormat={pricePer}
                togglePriceFormat={togglePricePer}
              />
            )}

            {elementsPerCartType.has(CartType.Internet) && (
              <InternetSection
                elements={elementsPerCartType.get(CartType.Internet) as any}
                priceFormat={pricePer}
                togglePriceFormat={togglePricePer}
              />
            )}

            {elementsPerCartType.has(CartType.Moving) && (
              <MovingSection
                elements={elementsPerCartType.get(CartType.Moving) as any}
                priceFormat={pricePer}
                togglePriceFormat={togglePricePer}
              />
            )}

            {elementsPerCartType.has(CartType.Water) && (
              <WaterSection
                elements={elementsPerCartType.get(CartType.Water) as any}
                priceFormat={pricePer}
                togglePriceFormat={togglePricePer}
              />
            )}

            {/* <div className={"visible-s"}>
              <OrderByPhone />
              <div className={"section"}>
                <CartReinsuranceBlock />
              </div>
            </div> */}

            {/* <div className={"section"}>
              <img
                alt={"économies Movecool"}
                className={"banner-img"}
                src={bannerImg}
              />
            </div> */}

            {
              !elementsPerCartType.has(CartType.Electricity) &&
                // <div className={"section comp-energy --no-bg"}>
                //   <h2 className={"cart-section-title"}>
                //     <img
                //       alt={"électricité"}
                //       className={"icon"}
                //       src={iconElectricity}
                //     />
                //     Ajouter un contrat d'éléctricité
                //   </h2>

                {
                  /* <div className={"offers-highlights --cross-selling"}>
              {energyHighlights.map(
                (highlight) =>
                  (highlight.element.offer as Offer).type ===
                    "Électricité" && (
                    <EnergyHighlightedCard
                      key={(highlight.element.offer as Offer).id}
                      brand={highlight.element.brand as BrandEnergy}
                      label={highlight.label}
                      offer={highlight.element.offer as Offer}
                      onEstimate={() => setShowConsumptionDialog(true)}
                      viewMore={() =>
                        setSelectedEnergyOffer(
                          highlight.element.offer as Offer,
                        )
                      }
                    />
                  ),
              )}
            </div> */
                }
              // </div>
            }

            {/* {!elementsPerCartType.has(CartType.Insurance) && (
              <div className={"section comp-insurance  --no-bg"}>
                <h2 className={"cart-section-title"}>
                  <img
                    alt={"assurance"}
                    className={"icon"}
                    src={iconInsurance}
                  />
                  Ajouter une assurance habitation
                </h2>
                <div className={"offers-highlights --cross-selling"}>
                  {insuranceHighlights.map((highlight) => (
                    <InsuranceHighlightedCard
                      key={highlight.label}
                      brand={highlight.element.brand as BrandInsurance}
                      dataCompleteCondition={dataCompleteCondition}
                      label={highlight.label}
                      onClick={() =>
                        setSelectedInsuranceBrand(
                          highlight.element.brand as BrandInsurance,
                        )
                      }
                      onEstimate={() => setShowInsuranceCalculator(true)}
                      pricePer={pricePer}
                      togglePricePer={togglePricePer}
                      viewMore={() =>
                        setSelectedInsuranceBrand(
                          highlight.element.brand as BrandInsurance,
                        )
                      }
                    />
                  ))}
                </div>
              </div>
            )} */}

            {/* {!elementsPerCartType.has(CartType.Internet) && (
              <div className={"section comp-internet  --no-bg"}>
                <h2 className={"cart-section-title"}>
                  <img
                    alt={"électricité"}
                    className={"icon"}
                    src={iconInternet}
                  />
                  Ajouter une box internet
                </h2>
                <div className={"offers-highlights --cross-selling"}>
                  {internetHighlights.map((highlight) => (
                    <InternetHighlightedCard
                      key={highlight.label}
                      dataCompleteCondition={currentLocation !== null}
                      label={highlight.label}
                      offer={highlight.element.offer as OfferWithBrand}
                      onClick={() =>
                        setSelectedInternetOffer(
                          highlight.element.offer as OfferWithBrand,
                        )
                      }
                      onEstimate={() => setShowInternetCalculator(true)}
                      viewMore={() =>
                        setSelectedInternetOffer(
                          highlight.element.offer as OfferWithBrand,
                        )
                      }
                    />
                  ))}
                </div>
              </div>
            )} */}
            {elements.size > 0 && (
              <div className={"checkout-nav"}>
                <Link className={"back-link"} to={COMPARE_ENERGY_LINK}>
                  &lt; Retour au comparateur
                </Link>
                <fieldset disabled={!totalAmount}>
                  <NavLink
                    className={"btn-1"}
                    to={keepQueryParams(CART_PERSONAL_INFOS_LINK)}
                  >
                    Continuer
                  </NavLink>
                </fieldset>
              </div>
            )}

            <div className={"checkout-disclaimer"}>
              En passant commande vous acceptez nos{" "}
              <a
                href={
                  "https://movecool.notion.site/Mentions-L-gales-Conditions-G-n-rales-d-utilisation-7de1bc001712449fb83908486d769537"
                }
                rel={"noreferrer"}
                target={"_blank"}
              >
                conditions générales d'utilisation et de vente,
              </a>{" "}
              notre{" "}
              <a
                href={
                  "https://movecool.notion.site/Politique-de-confidentialit-gestion-des-cookies-48624257b4754a498d8727d8efd135d5"
                }
                rel={"noreferrer"}
                target={"_blank"}
              >
                politique de protection des données
              </a>{" "}
              ainsi que les conditions tarifaires des offres auxquelles vous
              souscrivez.
            </div>
          </div>
          <div className={"col-m-1-3 cart-aside hidden-s"}>
            {/* {elements.size > 0 && (
              <div className={"card"}>
                <div className={"card-head --sep cart-next-step"}>
                  <div className={"card-title"}>
                    Récapitualtif de mon panier
                  </div>
                  <fieldset disabled={!totalAmount}>
                    <NavLink
                      className={"btn-1 btn-block margin-top"}
                      to={keepQueryParams(CART_PERSONAL_INFOS_LINK)}
                    >
                      Passer à l'étape suivante
                    </NavLink>
                  </fieldset>
                </div>

                <div className={"card-body --sections-sep"}>
                  {(elementsPerCartType.get(CartType.Electricity)?.length ||
                    0) < 1 ? null : (elementsPerCartType.get(
                      CartType.Electricity,
                    )?.length || 0) > 1 ? (
                    <div className={"section-xs"}>
                      <div className={"cart-section-title"}>
                        <img
                          alt={"électricité"}
                          className={"icon"}
                          src={iconElectricity}
                        />
                        Mon contrat d'électricité
                      </div>
                      <div className={"section-content"}>
                        <div
                          className={
                            "info info-warning-soft full-w m-bottom-xs"
                          }
                        >
                          Veuillez ne conserver qu’une seule offre pour passer à
                          l’étape suivante
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={"section-xs"}>
                      <div className={"cart-section-title"}>
                        <img
                          alt={"électricité"}
                          className={"icon"}
                          src={iconElectricity}
                        />
                        Mon contrat d'électricité
                      </div>
                      <div className={"cart-recap"}>
                        <div className={"recap-row --head"}>
                          <div>A payer chaque mois</div>
                          <div>{formatPrice(electricityOffer!.price!)}</div>
                        </div>

                        <div>{electricityOffer!.offer.name}</div>
                        <div className={"recap-row --head"}>
                          <div>A payer sur la prochaine facture uniquement</div>
                          <div>13.94&nbsp;€</div>
                        </div>
                        <div>Frais de mise en service ENEDIS</div>
                      </div>
                    </div>
                  )}

                  {(elementsPerCartType.get(CartType.Gas)?.length || 0) <
                  1 ? null : (elementsPerCartType.get(CartType.Gas)?.length ||
                      0) > 1 ? (
                    <div className={"section-xs"}>
                      <div className={"cart-section-title"}>
                        <img alt={"gaz"} className={"icon"} src={iconGaz} />
                        Mon contrat de gaz
                      </div>
                      <div className={"section-content"}>
                        <div
                          className={
                            "info info-warning-soft full-w m-bottom-xs"
                          }
                        >
                          Veuillez ne conserver qu’une seule offre pour passer à
                          l’étape suivante
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={"section-xs"}>
                      <div className={"cart-section-title"}>
                        <img alt={"gaz"} className={"icon"} src={iconGaz} />
                        Mon contrat de gaz
                      </div>
                      <div className={"cart-recap"}>
                        <div className={"recap-row --head"}>
                          <div>A payer chaque mois</div>
                          <div>{formatPrice(gasOffer!.price!)}</div>
                        </div>
                        <div>{gasOffer!.offer.name}</div>
                        <div className={"recap-row --head"}>
                          <div>A payer sur la prochaine facture uniquement</div>
                          <div>19.88&nbsp;€</div>
                        </div>
                        <div>Frais de mise en service GRDF</div>
                      </div>
                    </div>
                  )}

                  {(elementsPerCartType.get(CartType.Internet)?.length || 0) <
                  1 ? null : (elementsPerCartType.get(CartType.Internet)
                      ?.length || 0) > 1 ? (
                    <div className={"section-xs"}>
                      <div className={"cart-section-title"}>
                        <img
                          alt={"internet"}
                          className={"icon"}
                          src={iconInternet}
                        />
                        Ma box internet
                      </div>
                      <div className={"section-content"}>
                        <div
                          className={
                            "info info-warning-soft full-w m-bottom-xs"
                          }
                        >
                          Veuillez ne conserver qu’une seule offre pour passer à
                          l’étape suivante
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={"section-xs"}>
                      <div className={"cart-section-title"}>
                        <img
                          alt={"internet"}
                          className={"icon"}
                          src={iconInternet}
                        />
                        Ma box internet
                      </div>
                      <div className={"cart-recap"}>
                        <div className={"recap-row --head"}>
                          <div>A payer chaque mois</div>
                          <div>{formatPrice(internetOffer!.offer.price)}</div>
                        </div>
                        <div>{internetOffer!.offer.name}</div>
                        <div className={"recap-row --head"}>
                          <div>A payer sur la prochaine facture uniquement</div>
                          <div>{internetOffer!.offer.activationFee}&nbsp;€</div>
                        </div>
                        <div>Frais d'ouverture de service</div>
                      </div>
                    </div>
                  )}

                  {(elementsPerCartType.get(CartType.Insurance)?.length || 0) <
                  1 ? null : (elementsPerCartType.get(CartType.Insurance)
                      ?.length || 0) > 1 ? (
                    <div className={"section-xs"}>
                      <div className={"cart-section-title"}>
                        <img
                          alt={"assurance"}
                          className={"icon"}
                          src={iconInsurance}
                        />
                        Mon assurance habitation
                      </div>
                      <div className={"section-content"}>
                        <div
                          className={
                            "info info-warning-soft full-w m-bottom-xs"
                          }
                        >
                          Veuillez ne conserver qu’une seule offre pour passer à
                          l’étape suivante
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={"section-xs"}>
                      <div className={"cart-section-title"}>
                        <img
                          alt={"assurance"}
                          className={"icon"}
                          src={iconInsurance}
                        />
                        Mon assurance habitation
                      </div>
                      <div className={"cart-recap"}>
                        <div className={"recap-row --head"}>
                          <div>A payer chaque mois</div>
                          <div>{formatPrice(insuranceOffer!.price || 0)}</div>
                        </div>
                        <div>{insuranceOffer!.offer.brand.name}</div>
                        <div className={"recap-row --head"}>
                          <div>A payer sur la prochaine facture uniquement</div>
                          <div>0&nbsp;€</div>
                        </div>
                        <div>Frais de dossier</div>
                      </div>
                    </div>
                  )}
                </div>

                <div className={"card-footer --sep"}>
                  <div className={"cart-recap"}>
                    <div className={"recap-row --head --big"}>
                      <div>Total à payer chaque mois</div>
                      <div>
                        {!!totalAmount ? `${formatPrice(totalAmount)}` : "-"}
                      </div>
                    </div>
                    <div className={"recap-row"}>
                      <div>
                        A payer en plus sur ma premiere facture uniquement
                      </div>
                      <div>
                        {!!nextMonthAmount
                          ? `${formatPrice(nextMonthAmount)}`
                          : "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )} */}
            <div className={"section-s hidden-s"}>
              <OrderByPhone />
              <div className={"section"}>
                <CartReinsuranceBlock />
              </div>
            </div>
          </div>

          {selectedEnergyOffer && (
            <EnergyDetailsModal
              brand={brandsEnergy.get(selectedEnergyOffer.brandId)!}
              offer={selectedEnergyOffer}
              onClose={() => setSelectedEnergyOffer(null)}
              onEstimate={() => setShowConsumptionDialog(true)}
            />
          )}

          {selectedDual && (
            <EnergyDetailsModal
              brand={selectedDual.brand}
              dual={selectedDual}
              onClose={() => setSelectedDual(null)}
              onEstimate={() => setShowConsumptionDialog(true)}
            />
          )}

          {showConsumptionDialog && (
            <ConsumptionCalculatorDialog
              onClose={() => setShowConsumptionDialog(false)}
              onSubmit={() => {
                setShowConsumptionDialog(false);

                return Promise.resolve();
              }}
              skipUserSteps={true}
            />
          )}

          {/* {selectedInsuranceBrand && (
            <InsuranceDetailsDialog
              brand={selectedInsuranceBrand}
              onClose={() => setSelectedInsuranceBrand(null)}
            />
          )} */}

          {showInsuranceCalculator && (
            <InsuranceCalculatorDialog
              onClose={() => setShowInsuranceCalculator(false)}
              onSubmit={(values) => {
                const location = currentLocation || values.currentLocation;
                const quotes = loadQuotes({
                  floor: values.flatLevel!,
                  habitationUsage: values.housingCategory!,
                  isHouse: values.housingType === HousingType.House,
                  isOwner: values.clientType === ClientType.Owner,
                  roomsCount: values.roomNumber!,
                  surface: values.size!,
                  address: location!.cleanedAddress!,
                  city: location!.city!,
                  postCode: location!.postCode!,
                });
                setShowInsuranceCalculator(false);
                return quotes;
              }}
              skipUserSteps={true}
            />
          )}

          {/* {selectedInternetOffer && (
            <InternetDetailsDialog
              offer={selectedInternetOffer}
              onClose={() => setSelectedInternetOffer(null)}
            />
          )} */}

          {showInternetCalculator && (
            <Dialog onClose={() => setShowInternetCalculator(false)}>
              <Card>
                <CardHead title={"Test d'éligibilité"} />
                <CardBody>
                  <Form
                    initialValues={{
                      address: currentLocation,
                    }}
                    onSubmit={(values) => {
                      setCurrentLocation(values.address!);
                      setCurrentLocationQueryParam(
                        values.address?.plainAddress || null,
                      );
                      setShowInternetCalculator(false);
                      return Promise.resolve();
                    }}
                    schema={object({
                      address: object({
                        plainAddress: string(),
                        cleanedAddress: string(),
                        city: string(),
                        postCode: string(),
                      })
                        .label("adresse")
                        .nullable()
                        .required({ key: "address" }),
                    })}
                  >
                    <GoogleMapsSearchbar
                      className={"form-block"}
                      name={"address"}
                    />

                    <SubmitButton className={"form-block"}>
                      Confirmer
                    </SubmitButton>
                  </Form>
                </CardBody>
              </Card>
            </Dialog>
          )}
        </div>
      )}
    </div>
  );
};

export default ViewCart;
