import blockImage from "../../assets/img/face.png";
import { useAuth } from "../auth/apiProvider";
import { GtmEvent, useGtm } from "../gtm/gtmProvider";

const OrderByPhone = ({ title }: { title?: string }): JSX.Element => {
  const { generateCalendlyLink } = useAuth();
  const { push } = useGtm();

  return (
    <div className={"card-block"}>
      <div className={"card-head"}>
        <h2 className={"card-title"}>Une question ?</h2>
      </div>
      <div className={"card-cta"}>
        <img alt={"man's face"} className={"block-image"} src={blockImage} />
        <p className={"body-txt"}>
          Contactez-nous maintenant au téléphone au{" "}
          <a
            className={"link"}
            href={"tel:0980805110"}
            onClick={() => {
              push(GtmEvent.GENERIC_EVENT, {
                event_name: "call",
              });
            }}
          >
            09 80 80 51 10
          </a>{" "}
          ou bien faites vous rappeler plus tard.
        </p>
        <a
          className={"btn-1 btn-block"}
          href={generateCalendlyLink("", "header")}
          onClick={() => {
            push(GtmEvent.ECOMMERCE, {
              event_name: "click_callback", // REVIEW: Wording of the tracking ??
            });
          }}
          rel={"noreferrer"}
          target={"_blank"}
        >
          Me faire rappeler
        </a>
      </div>
    </div>
  );
};

export default OrderByPhone;
