import { useDate } from "../../date/DateContext";
import { Offer } from "./internet";

const InternetPriceDisplay = ({ offer }: { offer: Offer }): JSX.Element => {
  const { formatDate } = useDate();

  return (
    <div className={"section center"}>
      <h3 className={"title-xxs"}>Détail du prix</h3>
      <div className={"block-price"}>
        <p className={"price-component"}>
          <span className={"left"}>
            <strong>À payer chaque mois</strong>
            {offer.commitmentDuration && offer.commitmentEndPrice && (
              <>
                <br />
                <span className={"body-xs"}>
                  Pendant {offer.commitmentDuration} mois puis{" "}
                  {offer.commitmentEndPrice}€ / mois
                </span>
                <br />
                <span className={"body-xs"}>
                  Engagement de{" "}
                  {offer.commitmentDuration === 6
                    ? 12
                    : offer.commitmentDuration}{" "}
                  mois
                </span>
              </>
            )}
          </span>
          <strong>{offer.price}€</strong>
        </p>
        {offer.activationFee && (
          <p className={"price-component"}>
            <span className={"left"}>
              <strong>Frais de mise en service</strong>
              <br />
              <span className={"body-xs"}>
                À payer sur la première facture uniquement
              </span>
            </span>
            <strong>{offer.activationFee}&nbsp;€</strong>
          </p>
        )}
      </div>
      {offer.updated_at && (
        <div>
          <p className={"price-update"}>
            <i>Tarifs mis à jour le {formatDate(offer.updated_at, "PPP")}</i>
          </p>
        </div>
      )}
    </div>
  );
};

export default InternetPriceDisplay;
