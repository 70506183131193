import MovingComparator from "./MovingComparator";

const MovingPage = (): JSX.Element => {
  return (
    <div>
      <MovingComparator />
    </div>
  );
};

export default MovingPage;
