import React from "react";

import Routes from "src/services/routing/Routes";
import { Navigate, Route } from "react-router-dom";

import AppLayout from "../services/ui/layouts/AppLayout";
import LayoutContent from "../services/ui/layouts/LayoutContent";
import ContentView from "../services/ui/layouts/ContentView";
import CommonHeader from "../services/auth/components/CommonHeader";
import PageContent from "src/services/ui/block/PageContent";
import { Content } from "../services/design-system/Head";
import { COMPARE_INSURANCE, COMPARE_INSURANCE_LINK } from "src/routes/compare";
import InsuranceComparator from "src/services/compare/insurance/InsuranceComparator";

const Private = (): JSX.Element => {
  return (
    <AppLayout>
      <CommonHeader />

      <LayoutContent>
        <ContentView>
          <Content>
            <PageContent>
              <Routes>
                <Route
                  element={<Navigate to={COMPARE_INSURANCE_LINK} />}
                  index={true}
                />
                <Route
                  element={<InsuranceComparator />}
                  path={`${COMPARE_INSURANCE}/*`}
                />
              </Routes>
            </PageContent>
          </Content>
        </ContentView>
      </LayoutContent>
    </AppLayout>
  );
};

export default Private;
