import { useCallback, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { CART_LINK } from "../../../routes/cart";
import {
  COMPARE_ENERGY_LINK,
  COMPARE_INSURANCE_LINK,
  COMPARE_INTERNET_LINK,
  COMPARE_LINK,
} from "../../../routes/compare";
import { CartType } from "../../../services/compare/cart/cart";
import { useCart } from "../../../services/compare/cart/cartProvider";
import ElectricitySection from "../../../services/compare/cart/ElectricitySection";
import GazSection from "../../../services/compare/cart/GazSection";
import InsuranceSection from "../../../services/compare/cart/InsuranceSection";
import InternetSection from "../../../services/compare/cart/InternetSection";
import { PricePer } from "../../../services/compare/common";
import { useConsumption } from "../../../services/compare/energy/consumptionProvider";
import Link from "../../../services/routing/components/Link";
import { useKeepQueryParams } from "../../../services/routing/useQueryParams";
import bannerImg from "../../../assets/img/carousel/cart/checkout-confirmed.png";
import NeedHelpBlock from "src/services/ui/common-components/NeedHelpBlock";
import OrderByPhone from "src/services/common-components/OrderByPhone";
import imgCtaEnergy from "../../../assets/img/img-cta-energy.png";
import imgCtaInsurance from "../../../assets/img/img-cta-insurance.png";
import imgCtaBox from "../../../assets/img/img-cta-box.png";
import NextSteps from "src/services/ui/common-components/NextSteps";
import { GtmEvent, useGtm } from "src/services/gtm/gtmProvider";
import { useAuth } from "src/services/auth/apiProvider";
import { randomUUID } from "src/services/data-structures/crypto";

const CartSubmitted = () => {
  const { user } = useAuth();
  const { checkedOutElements, checkout, totalAmount, gtmCartItems } = useCart();
  const { push, mapCartToItem } = useGtm();
  const keepQueryParams = useKeepQueryParams();

  const { setPriceFormat } = useConsumption();
  const [pricePer, setPricePer] = useState(PricePer.Month);
  useEffect(() => {
    setPriceFormat(PricePer.Month);
  }, [setPriceFormat]);
  const togglePricePer = useCallback(() => {
    setPricePer((prev) => {
      const newFormat =
        prev === PricePer.Month ? PricePer.Year : PricePer.Month;
      setPriceFormat(newFormat);
      return newFormat;
    });
  }, [setPriceFormat]);

  const auth = useAuth();

  useEffect(() => {
    auth.logout();
  }, [auth]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if ((checkout.contract?.iban || "").length > 0) {
      const items = [...(checkedOutElements?.values() || [])]
        .flat()
        .map(mapCartToItem);
      const total = items.reduce((acc, el) => (el?.price || 0) + acc, 0);
      push(GtmEvent.ECOMMERCE, {
        event_name: "purchase",
        ecommerce: {
          currency: "EUR",
          transaction_id: randomUUID(),
          value: total,
          tax: total * 0.2,
          items,
        },
        dates: checkout.dates,
        user_data: {
          email: user?.email,
          phone: checkout.userData?.phoneNumber,
          user_firstname: checkout.userData?.firstname,
          user_lastname: checkout.userData?.lastname,
        },
      });
    }
  }, [
    checkedOutElements,
    checkout.dates,
    checkout.contract?.iban,
    checkout.elements,
    checkout.userData?.firstname,
    checkout.userData?.lastname,
    checkout.userData?.phoneNumber,
    gtmCartItems,
    mapCartToItem,
    push,
    totalAmount,
    user?.email,
  ]);

  return checkedOutElements ? (
    <div className={"container-wrap"}>
      <div className={"checkout-top-nav"}>
        <Link className={"back-link"} to={keepQueryParams(COMPARE_LINK)}>
          &lt; Retour au comparateur
        </Link>
      </div>

      <div className={"section-l"}>
        <img
          alt={"commande confirmée"}
          className={"banner-img"}
          src={bannerImg}
        />
      </div>

      <h2 className={"margin-top-l page-section-title hidden-s"}>
        Récapitulatif de votre commande
      </h2>

      <div className={"grid"}>
        <div className={"col-m-2-3"}>
          {checkedOutElements.has(CartType.Electricity) && (
            <ElectricitySection
              elements={checkedOutElements.get(CartType.Electricity) as any}
              priceFormat={pricePer}
              togglePriceFormat={togglePricePer}
              disableRemove
            />
          )}

          {checkedOutElements.has(CartType.Gas) && (
            <GazSection
              elements={checkedOutElements.get(CartType.Gas) as any}
              priceFormat={pricePer}
              togglePriceFormat={togglePricePer}
              disableRemove
            />
          )}

          {checkedOutElements.has(CartType.Insurance) && (
            <InsuranceSection
              elements={checkedOutElements.get(CartType.Insurance) as any}
              priceFormat={pricePer}
              togglePriceFormat={togglePricePer}
              disableRemove
            />
          )}

          {checkedOutElements.has(CartType.Internet) && (
            <InternetSection
              elements={checkedOutElements.get(CartType.Internet) as any}
              priceFormat={pricePer}
              togglePriceFormat={togglePricePer}
              disableRemove
            />
          )}

          {/* {checkedOutElements.has(CartType.Moving) && (
        <MovingSection
          elements={checkedOutElements.get(CartType.Moving) as any}
          priceFormat={pricePer}
          togglePriceFormat={togglePricePer}
          disableRemove
        />
      )}

      {checkedOutElements.has(CartType.Water) && (
        <WaterSection
          elements={checkedOutElements.get(CartType.Water) as any}
          priceFormat={pricePer}
          togglePriceFormat={togglePricePer}
          disableRemove
        />
      )} */}
        </div>
        <div className={"col-m-1-3"}>
          <div className={"section-s"}>
            <OrderByPhone />
          </div>
        </div>
      </div>

      <div className={"section-xl"}>
        <h2 className={"title-l center"}>
          Que se passe-t-il une fois ma commande confirmée ?
        </h2>
        <NextSteps />
      </div>

      <div className={"cart"}>
        <NeedHelpBlock
          service={"cart"}
          text={
            "Si vous emménagez dans les 48h, contactez-nous maintenant pour que votre dossier soit traité en priorité."
          }
          title={"Vous êtes pressé(e) ?"}
        />
      </div>

      <div className={"section-l"}>
        <h2 className={"page-section-title"}>D'autres envies ?</h2>

        <div className={"grid --gap"}>
          <div className={"col-s-1-3"}>
            <div className={"card-block"}>
              <div className={"card-head"}>
                <h2 className={"card-title"}>Énergie</h2>
              </div>
              <div className={"card-cta"}>
                <img alt={"énergie"} src={imgCtaEnergy} />
                <a
                  className={"btn-1 btn-block"}
                  href={COMPARE_ENERGY_LINK}
                  rel={"noreferrer"}
                >
                  Choisir une offre
                </a>
              </div>
            </div>
          </div>

          <div className={"col-s-1-3"}>
            <div className={"card-block"}>
              <div className={"card-head"}>
                <h2 className={"card-title"}>Box Internet</h2>
              </div>
              <div className={"card-cta"}>
                <img alt={"internet"} src={imgCtaBox} />
                <a
                  className={"btn-1 btn-block"}
                  href={COMPARE_INTERNET_LINK}
                  rel={"noreferrer"}
                >
                  Choisir une offre
                </a>
              </div>
            </div>
          </div>

          <div className={"col-s-1-3"}>
            <div className={"card-block"}>
              <div className={"card-head"}>
                <h2 className={"card-title"}>Assurance</h2>
              </div>
              <div className={"card-cta"}>
                <img alt={"assurance"} src={imgCtaInsurance} />
                <a
                  className={"btn-1 btn-block"}
                  href={COMPARE_INSURANCE_LINK}
                  rel={"noreferrer"}
                >
                  Choisir une offre
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={"section-l"} />
    </div>
  ) : (
    <Navigate to={CART_LINK} />
  );
};

export default CartSubmitted;
