import { AxiosPromise } from "axios";
import { Location } from "../../google-maps/place";
import { publicAPI } from "../publicApi";

export function getOffers(): AxiosPromise<unknown[]> {
  return publicAPI.get("/internet/offers");
}

export function getBrands(): AxiosPromise<unknown[]> {
  return publicAPI.get("/internet/brands");
}

export function getEligibility(location: Location): AxiosPromise<unknown[]> {
  return publicAPI.post("/internet/eligibility", {
    lat: location.lat,
    lng: location.lng,
    address: location.cleanedAddress,
  });
}
