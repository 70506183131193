import React from "react";
import { cx } from "@emotion/css";

const Nav = ({
  className,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>): JSX.Element => {
  return <nav {...props} className={cx(className, "nav")} />;
};

export default Nav;
