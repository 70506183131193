export function isLocationMatching(
  postalCode: string | null | undefined,
  postalCodeList: string[],
  matchIfEmpty: boolean = true,
): boolean {
  // NOTE: If no postal code in the list => then it's available everywhere
  // Else we check if the department is in the list of postal codes
  // Finally we check the postal code
  return (
    !!postalCode &&
    ((postalCodeList.length === 0 && matchIfEmpty) ||
      postalCodeList.includes(postalCode.slice(0, 2)) ||
      postalCodeList.includes(postalCode))
  );
}

export enum PricePer {
  Month,
  Year,
}

export interface Highlight {
  element: Record<string, unknown>;
  label: string;
}

export function sortByRelevance<T extends { relevance: number | null }>(
  a: T,
  b: T,
): number {
  return (a.relevance || Infinity) - (b.relevance || Infinity);
}
