import BestPricesIcon from "../../assets/img/icons/trophy-emoji.png";
import NoPaperWorkIcon from "../../assets/img/icons/lightning-emoji.png";
import FreeIcon from "../../assets/img/icons/gift-emoji.png";
import NoCommitmentIcon from "../../assets/img/icons/guy-emoji.png";

const WhyMovecool = (): JSX.Element => {
  return (
    <div className={"why-movecool"}>
      <div className={"card-body"}>
        <h2 className={"why-title"}>Pourquoi passer par Movecool&nbsp;?</h2>
        <div className={"why-row"}>
          <div className={"why-item"}>
            <div className={"item-img"}>
              <img alt={"les meilleurs prix"} src={BestPricesIcon} />
            </div>

            <div className={"item-title"}>Les meilleurs prix</div>
            <div className={"item-txt"}>Et des promos négociées pour vous</div>
          </div>
          <div className={"why-item"}>
            <div className={"item-img"}>
              <img alt={"les meilleurs prix"} src={NoPaperWorkIcon} />
            </div>

            <div className={"item-title"}>Aucune démarche</div>
            <div className={"item-txt"}>On s’occupe de tout</div>
          </div>
          <div className={"why-item"}>
            <div className={"item-img"}>
              <img alt={"les meilleurs prix"} src={FreeIcon} />
            </div>

            <div className={"item-title"}>C’est gratuit</div>
            <div className={"item-txt"}>Des experts vous conseillent</div>
          </div>
          <div className={"why-item"}>
            <div className={"item-img"}>
              <img alt={"les meilleurs prix"} src={NoCommitmentIcon} />
            </div>

            <div className={"item-title"}>Sans engagement</div>
            <div className={"item-txt"}>Et puis quoi encore ?</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyMovecool;
