import { DetailedHTMLProps, HTMLAttributes, useState } from "react";
import { cx } from "@emotion/css";

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const Collapsible = ({
  className,
  title,
  onClick,
  ...itemProps
}: Props): JSX.Element => {
  const [open, setOPen] = useState(false);

  const toggle = (ev: any) => {
    setOPen(!open);
    if (!open && onClick) {
      onClick(ev);
    }
  };
  return (
    <div className={cx(className, open && "expanded")}>
      <div className={"item-title"} onClick={toggle}>
        {title}
      </div>
      <div {...itemProps} />
    </div>
  );
};

export default Collapsible;
