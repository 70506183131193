import HowItWorksInternet from "src/services/compare/internet/HowItWorksInternet";
import InternetComparator from "../../../services/compare/internet/InternetComparator";
import MovecoolReinsuranceBlock from "src/services/ui/common-components/MovecoolReinsuranceBlock";
import InternetTeam from "src/services/compare/internet/InternetTeam";

const InternetPage = (): JSX.Element => {
  return (
    <div>
      <InternetComparator />
      <HowItWorksInternet />
      <InternetTeam />
      <div className={"section"}>
        <MovecoolReinsuranceBlock />
      </div>
    </div>
  );
};

export default InternetPage;
