import { object, string } from "yup";
import Form from "../../forms/Form";
import { usePlace } from "../../google-maps/placeProvider";
import { GoogleMapsSearchbar } from "../HomeInput";
import { Location } from "../../google-maps/place";
import SubmitButton from "src/services/forms/SubmitButton";
import CardBody from "src/services/ui/block/CardBody";
import Dialog from "src/services/ui/block/Dialog";
import Card from "src/services/ui/block/Card";

const InternetCaluculatorDialog = ({
  onClose,
  onSubmit,
}: {
  onClose(): void;
  onSubmit(values: { address: Location | null }): Promise<void>;
}) => {
  const { currentLocation } = usePlace();

  return (
    <Dialog className={"side-panel"} closeLabel={"Retour  x"} onClose={onClose}>
      <CardBody>
        <div className={"card-block"}>
          <div className={"card-head"}>
            <div className={"card-title --left"}>Mon Logement</div>
          </div>
          <Card>
            <div className={"card-body"}>
              <div className={"card-body"}>
                <Form
                  initialValues={{
                    address: currentLocation,
                  }}
                  onSubmit={onSubmit}
                  schema={object({
                    address: object({
                      plainAddress: string(),
                      cleanedAddress: string(),
                      city: string(),
                      postCode: string(),
                    })
                      .label("adresse")
                      .nullable()
                      .required({ key: "address" }),
                  })}
                >
                  <GoogleMapsSearchbar
                    className={"form-block"}
                    name={"address"}
                  />
                  <div className={"form-footer right"}>
                    <div className={"buttons-row stretch right"}>
                      <button
                        className={"btn-outlined"}
                        onClick={onClose}
                        type={"button"}
                      >
                        Retour
                      </button>
                      <SubmitButton>Suivant</SubmitButton>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </Card>
        </div>
      </CardBody>
    </Dialog>
  );
};

export default InternetCaluculatorDialog;
