import { boolean, number, object, string, date } from "yup";
import { useAuth } from "../../../services/auth/apiProvider";
import Form from "../../../services/forms/Form";
import RegisterForm from "../../../services/auth/components/RegisterForm";
import { useCart } from "../../../services/compare/cart/cartProvider";
import { Cart, CartType } from "../../../services/compare/cart/cart";
import AutoField from "../../../services/forms/AutoField";
import SubmitButton from "../../../services/forms/SubmitButton";
import ValidationsErrors from "../../../services/forms/ValidationsErrors";
import {
  ClientType,
  FlatLevel,
  HabitationUsage,
  HousingType,
} from "../../../services/compare/insurance/insurance";
import { GoogleMapsSearchbar } from "../../../services/compare/HomeInput";
import { useEffect, useMemo, useState } from "react";
import {
  Anchor,
  useScrollTo,
  withProvideScrollTo,
} from "../../../services/ui/reactive/scrollToProvider";
import { setTimeoutPromise } from "../../../services/data-structures/promise";
import DateField from "../../../services/forms/DateField";
import Link from "../../../services/routing/components/Link";
import { CART_CONTRACT_LINK, CART_LINK } from "../../../routes/cart";
import PersonalInfosForm from "../../../services/auth/components/PersonalInfosForm";
import { locationSchema } from "../../../services/google-maps/place";
import { useToastsWithIntl } from "../../../services/toast-notifications";
import CartReinsuranceBlock from "src/services/ui/common-components/CartReinsuranceBlock";
import useNavigate from "../../../services/routing/useNavigate";
import blockImage from "../../../assets/img/face.png";
import { GtmEvent, useGtm } from "../../../services/gtm/gtmProvider";
import { formatISO, isDate } from "date-fns";
import { pickKeys } from "src/services/data-structures/object";
import ImgTutor from "../../../assets/img/tutor.png";
import WhyMovecool from "src/services/common-components/WhyMovecool";

enum Step {
  Infos,
  Home,
  Dates,
}

const CartPersonalInfos = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { user, register, setShowAuthDialog, updateUser, login } = useAuth();
  const {
    checkout,
    setCheckout,
    elementsPerCartType,
    elements,
    setOverrideCart,
    totalAmount,
    gtmCartItems,
    electricityOffer,
    gasOffer,
    saveHousingData,
    saveContractData,
  } = useCart();
  const { scrollToTarget } = useScrollTo();
  const { toastError } = useToastsWithIntl(["auth"]);
  const navigate = useNavigate();
  const { push } = useGtm();

  const [step, setStep] = useState(Step.Infos);

  const insuranceCart = useMemo(() => {
    return (
      ((elementsPerCartType.get(CartType.Insurance) ||
        [])[0] as Cart<CartType.Insurance>) || null
    );
  }, [elementsPerCartType]);

  const initialDatesValues = useMemo(() => {
    return {
      internetRdvDate: checkout.dates?.filter(
        (date) => date.key === "internetRdvDate",
      )[0]?.date,
      electricityActivationDate: checkout.dates?.filter(
        (date) => date.key === "electricityActivationDate",
      )[0]?.date,
      gasActivationDate: checkout.dates?.filter(
        (date) => date.key === "gasActivationDate",
      )[0]?.date,
      insuranceCoverageDate: checkout.dates?.filter(
        (date) => date.key === "insuranceCoverageDate",
      )[0]?.date,
    };
  }, [checkout]);

  const datesSchema = useMemo(() => {
    return object({
      ...(elementsPerCartType.has(CartType.Internet)
        ? {
            internetRdvDate: date()
              .label("Créneau d'arrivée du technicien")
              .required(),
          }
        : {}),
      ...(elementsPerCartType.has(CartType.Electricity)
        ? {
            electricityActivationDate: date()
              .label("Date de mise en service de votre compteur d'électricité")
              .required(),
          }
        : {}),
      ...(elementsPerCartType.has(CartType.Gas)
        ? {
            gasActivationDate: date()
              .label("Date de mise en service de votre compteur de gaz")
              .required(),
          }
        : {}),
      ...(elementsPerCartType.has(CartType.Insurance)
        ? {
            insuranceCoverageDate: date()
              .label("Date de début de couverture")
              .required(),
          }
        : {}),
    });
  }, [elementsPerCartType]);

  // When on this component override the cart on batchUpdate
  useEffect(() => {
    setOverrideCart(true);

    // On unmount set it back to default
    return () => {
      setOverrideCart(false);
    };
  }, [setOverrideCart]);

  const { generateCalendlyLink } = useAuth();

  return (
    <div className={"grid container-wrap"}>
      <div className={"checkout-top-nav"}>
        <Link className={"back-link"} to={CART_LINK}>
          &lt; Retour à l'étape précédente
        </Link>
      </div>

      <div className={"col-m-2-3"}>
        {!user && (
          <div className={"section"}>
            <h2 className={"cart-section-title"}>&nbsp;</h2>

            <div className={"grid"}>
              <div className={"col-m-1-2"}>
                <div className={"card"}>
                  <div className={"card-head"}>
                    <h2 className={"card-title"}>Créer un compte</h2>
                    <p className={"margin-top"}>
                      Finalisez votre commande en ligne, en quelques clics.
                    </p>
                  </div>
                  <div className={"card-body"}>
                    <RegisterForm
                      onLoginBack={null}
                      onSubmit={(registerInfos) => {
                        setStep(Step.Infos);
                        return register(registerInfos).then(
                          () => {
                            push(GtmEvent.SIGN_UP, {
                              event_name: "sign_up",
                              user_data: pickKeys(registerInfos, ["email"]),
                            });
                            return login(registerInfos).then(() => {
                              scrollToTarget();
                            });
                          },
                          (err) => {
                            if (err?.response?.status === 409)
                              toastError(
                                "auth:register.TOAST_ERROR_ALREADY_EXISTS",
                              );
                            else toastError("auth:register.TOAST_ERROR");
                          },
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={"col-m-1-2"}>
                <div className={"card"}>
                  <div className={"card-head"}>
                    <h2 className={"card-title"}>Déjà client ?</h2>
                    <p className={"margin-top"}>
                      Vous possédez déjà un compte Movecool
                    </p>
                  </div>
                  <div className={"card-body"}>
                    <button
                      className={"btn-1 btn-block"}
                      onClick={() => {
                        setShowAuthDialog(true);
                      }}
                    >
                      Me connecter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <fieldset className={"checkout-section"} disabled={!user}>
          {step === Step.Infos && <Anchor />}
          <h2 className={"section-title"}>Mes informations de contact</h2>
          <div className={"section"}>
            <div className={"card"}>
              <div className={"card-body"}>
                <div className={"section-s"}>
                  <div className={"info info-light"}>
                    <div className={"lrow"}>
                      <img alt={"Information"} src={ImgTutor} />
                      <p>
                        C’est presque terminé ! Nous avons juste besoin de
                        quelques informations pour mettre en place votre
                        contrat.
                      </p>
                    </div>
                  </div>
                </div>

                <PersonalInfosForm
                  extraSchema={{
                    asProfessional: boolean()
                      .label("Il s'agit d'une commande à titre professionnel")
                      .defined(),
                    birthDate: date()
                      .label("Date de naissance")
                      .nullable()
                      .required(),
                  }}
                  formFooter={
                    <div className={"form-footer-with-acceptance"}>
                      <div className={"checkout-disclaimer"}>
                        En cliquant sur suivant vous acceptez les <br />
                        <a
                          href={
                            "https://movecool.notion.site/Mentions-L-gales-Conditions-G-n-rales-d-utilisation-7de1bc001712449fb83908486d769537"
                          }
                          rel={"noreferrer"}
                          target={"_blank"}
                        >
                          Conditions Générales
                        </a>{" "}
                        du site Movecool.
                      </div>
                      <SubmitButton>Suivant</SubmitButton>
                    </div>
                  }
                  initialValues={{
                    asProfessional: false,
                    birthDate: checkout.userData?.birthDate || user?.birthDate,
                    lastname: checkout.userData?.lastname,
                    firstname: checkout.userData?.firstname,
                    phoneNumber: checkout.userData?.phoneNumber,
                  }}
                  onSubmit={(values) => {
                    setStep(Step.Home);
                    return updateUser({
                      ...user!,
                      ...values,
                      checkout: {
                        electricityOffer,
                        gasOffer,
                      },
                    }).then(() => {
                      push(GtmEvent.CHECKOUT, {
                        event_name: "checkout_step1",
                        ecommerce: {
                          value: totalAmount,
                          currency: "EUR",
                          items: gtmCartItems,
                        },
                        user_data: {
                          email: user?.email,
                          phone: values.phoneNumber,
                          user_firstname: values.firstname,
                          user_lastname: values.lastname,
                        },
                      });
                      setCheckout({
                        ...checkout,
                        userData: values,
                      });
                      scrollToTarget();
                    });
                  }}
                >
                  {({ civility, firstname, lastname, phoneNumber }) => (
                    <>
                      {/* <div className={"form-block"}>
                        <div className={"filter-group"}>
                          <label className={"input-label"}>
                            Informations sur le titulaire
                          </label>
                          <ToggleField name={"asProfessional"} />
                        </div>
                      </div> */}
                      <div>
                        <label className={"input-label"}>Civilité *</label>
                        {civility}
                      </div>
                      <div className={"grid"}>
                        <div className={"col-m-1-2"}>{firstname}</div>
                        <div className={"col-m-1-2"}>{lastname}</div>
                        <div className={"col-m-1-2"}>{phoneNumber}</div>
                        <div className={"col-m-1-2"}>
                          <AutoField name={"birthDate"} />
                        </div>
                      </div>
                    </>
                  )}
                </PersonalInfosForm>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset className={"checkout-section"} disabled={!checkout.userData}>
          {step === Step.Home && <Anchor />}
          <h2 className={"section-title"}>Mon logement</h2>
          <div className={"section"}>
            <div className={"card"}>
              <div className={"card-body"}>
                <Form
                  enableReinitialize={true}
                  initialValues={{
                    ...checkout.home,
                    address:
                      checkout.home !== null
                        ? checkout.home?.address || null
                        : insuranceCart?.data.address !== null
                        ? insuranceCart?.data.address || null
                        : ([...elements.values()][0]?.data as any | undefined)
                            ?.address || null,
                    floor:
                      checkout.home !== null
                        ? checkout.home?.floor
                        : insuranceCart?.data.flatLevel === FlatLevel.Ground
                        ? 0
                        : null,
                    type:
                      checkout.home !== null
                        ? checkout.home?.type
                        : insuranceCart?.data.housingType !== null
                        ? insuranceCart?.data.housingType
                        : null,
                    clientType:
                      checkout.home !== null
                        ? checkout.home?.clientType
                        : insuranceCart?.data.clientType !== null
                        ? insuranceCart?.data.clientType
                        : null,
                    surface:
                      checkout.home !== null
                        ? checkout.home?.surface
                        : insuranceCart?.data.size !== null
                        ? insuranceCart?.data.size
                        : null,
                    rooms:
                      checkout.home !== null
                        ? checkout.home?.rooms
                        : insuranceCart?.data.roomNumber !== null
                        ? insuranceCart?.data.roomNumber
                        : null,
                    usage:
                      checkout.home !== null
                        ? checkout.home?.usage
                        : insuranceCart?.data.housingCategory !== null
                        ? insuranceCart?.data.housingCategory
                        : null,
                    withDigicodeOrIntercom: false,
                  }}
                  onSubmit={(values) => {
                    saveHousingData(
                      {
                        electricityOffer,
                        gasOffer,
                      },
                      values,
                    );
                    push(GtmEvent.CHECKOUT, {
                      event_name: "checkout_step2",
                      ecommerce: {
                        value: totalAmount,
                        currency: "EUR",
                        items: gtmCartItems,
                        user_data: {
                          email: user?.email,
                          phone: checkout.userData?.phoneNumber,
                          user_firstname: checkout.userData?.firstname,
                          user_lastname: checkout.userData?.lastname,
                        },
                        home_data: {
                          property_zip: values.address.postCode,
                          address: values.address.plainAddress,
                          rooms: values.rooms,
                          surface: values.surface,
                          item_category2:
                            values.type === HousingType.Flat
                              ? "Appartement"
                              : "Maison",
                          item_category3:
                            (values.clientType as ClientType | null) ===
                            ClientType.Owner
                              ? "Propriétaire"
                              : "Locataire",
                          item_category4:
                            values.usage === HabitationUsage.Main
                              ? "Principale"
                              : values.usage ===
                                HabitationUsage.NonOccupantOwner
                              ? "Non occupant"
                              : "Secondaire",
                        },
                      },
                    });
                    setStep(Step.Dates);
                    setCheckout({
                      ...checkout,
                      home: values as any,
                    });
                    return setTimeoutPromise(250).then(() => {
                      scrollToTarget();
                    });
                  }}
                  schema={object({
                    bat: string()
                      .label(
                        "Complément d'adresse (étage, immeuble, appartement...)",
                      )
                      .nullable(),
                    stair: string().label("Escalier").nullable(),
                    type: number()
                      .label("Type de bien")
                      .oneOf([HousingType.Flat, HousingType.House]),
                    floor: number().label("Etage").nullable(),
                    rooms: number()
                      .label("Nombre de pièces")
                      .nullable()
                      .required(),
                    surface: number()
                      .label("Surface (m2)")
                      .nullable()
                      .required(),
                    clientType: number()
                      .label("Qualité")
                      .oneOf([ClientType.Owner, ClientType.Renter]),
                    usage: number()
                      .label("Type de résidence")
                      .oneOf([
                        HabitationUsage.Main,
                        HabitationUsage.NonOccupantOwner,
                        HabitationUsage.Secondary,
                      ]),
                    address: locationSchema()
                      .label("Adresse du contrat")
                      .nullable()
                      .required(),
                    withDigicodeOrIntercom: boolean()
                      .label(
                        "Votre logement comporte un digicode, un interphone ou autre modalité d'accès à préciser.",
                      )
                      .required(),
                  })}
                >
                  <div className={"section-s"}>
                    <GoogleMapsSearchbar
                      label={"Adresse du contrat"}
                      name={"address"}
                    />
                  </div>
                  <div className={"section-s grid"}>
                    <div>
                      <AutoField name={"bat"} />
                    </div>
                    {/* <div className={"col-s-1-3"}>
                      <AutoField name={"stair"} />
                    </div> */}
                    {/* <div className={"col-s-1-3"}>
                      <AutoField name={"floor"} />
                    </div> */}
                  </div>

                  <div className={"section-s"}>
                    <div className={"grid"}>
                      <div className={"col-s-1-2"}>
                        <AutoField name={"rooms"} />
                      </div>
                      <div className={"col-s-1-2"}>
                        <AutoField name={"surface"} />
                      </div>
                    </div>
                  </div>

                  {/* <HousingTypeSelect checkout={checkout} name={"type"} />

                  <ClientTypeSelect checkout={checkout} name={"clientType"} />

                  <HousingCategorySelect checkout={checkout} name={"usage"} /> */}

                  {/* <ToggleField name={"withDigicodeOrIntercom"} /> */}

                  <div className={"section-s"}>
                    <div className={"info info-light"}>
                      <div className={"lrow"}>
                        <img alt={"Information"} src={ImgTutor} />
                        <p>
                          On s’occupe de mettre le compteur à votre nom. Vous
                          n’aurez plus qu’à vous installer.
                        </p>
                      </div>
                    </div>
                  </div>

                  <ValidationsErrors />

                  <div className={"margin-top right"}>
                    <SubmitButton>Valider</SubmitButton>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset className={"checkout-section"} disabled={!checkout.home}>
          {step === Step.Dates && <Anchor />}
          <h2 className={"section-title"}>Date de début du contrat</h2>
          <div>
            Choisissez la date à laquelle vous souhaitez faire démarrer votre
            contrat.
          </div>
          <div className={"section"}>
            <div className={"card"}>
              <div className={"card-body"}>
                <Form
                  initialValues={initialDatesValues}
                  onSubmit={(values) => {
                    saveContractData(
                      {
                        electricityOffer,
                        gasOffer,
                      },
                      checkout.home,
                      values,
                    );
                    push(GtmEvent.CHECKOUT, {
                      event_name: "checkout_step3",
                      ecommerce: {
                        value: totalAmount,
                        currency: "EUR",
                        items: gtmCartItems,
                        user_data: {
                          email: user?.email,
                          phone: checkout.userData?.phoneNumber,
                          user_firstname: checkout.userData?.firstname,
                          user_lastname: checkout.userData?.lastname,
                        },
                        home_data: {
                          property_zip: checkout.home?.address.postCode,
                          address: checkout.home?.address.plainAddress,
                          rooms: checkout.home?.rooms,
                          surface: checkout.home?.surface,
                          item_category2:
                            checkout.home?.type === HousingType.Flat
                              ? "Appartement"
                              : "Maison",
                          item_category3:
                            (checkout.home?.clientType as ClientType | null) ===
                            ClientType.Owner
                              ? "Propriétaire"
                              : "Locataire",
                          item_category4:
                            checkout.home?.usage === HabitationUsage.Main
                              ? "Principale"
                              : checkout.home?.usage ===
                                HabitationUsage.NonOccupantOwner
                              ? "Non occupant"
                              : "Secondaire",
                        },
                        dates: Object.fromEntries(
                          [...Object.entries(values)].map(([key, date]) => [
                            key,
                            isDate(date) ? formatISO(date) : date,
                          ]),
                        ),
                      },
                    });
                    setCheckout({
                      ...checkout,
                      dates: [...Object.entries(values)]
                        .filter(([, date]) => !!date)
                        .map(([key, date]) => ({
                          key: key,
                          date: (date as unknown) as Date,
                          title:
                            key === "internetRdvDate"
                              ? "Créneau d'arrivée du technicien"
                              : key === "electricityActivationDate"
                              ? "Date de mise en service de votre compteur"
                              : key === "insuranceCoverageDate"
                              ? "Date de début de couverture"
                              : key === "gasActivationDate"
                              ? "Date de mise en service de votre compteur"
                              : "",
                        })),
                    });
                    navigate(CART_CONTRACT_LINK);
                    return setTimeoutPromise(250);
                  }}
                  schema={datesSchema}
                >
                  {elementsPerCartType.has(CartType.Internet) && (
                    <div className={"section-s"}>
                      <label className={"input-label"}>
                        Créneau d'arrivée du technicien *<div>Box internet</div>{" "}
                      </label>
                      <DateField name={"internetRdvDate"} />
                    </div>
                  )}
                  {elementsPerCartType.has(CartType.Electricity) && (
                    <div className={"section-s"}>
                      <label className={"input-label"}>
                        Date de mise en service de votre compteur *
                        <div>Électricité</div>
                      </label>
                      <DateField name={"electricityActivationDate"} />
                    </div>
                  )}
                  {elementsPerCartType.has(CartType.Gas) && (
                    <div className={"section-s"}>
                      <label className={"input-label"}>
                        Date de mise en service de votre compteur *
                        <div>Gaz</div>
                      </label>
                      <DateField name={"gasActivationDate"} />
                    </div>
                  )}
                  {elementsPerCartType.has(CartType.Insurance) && (
                    <div className={"section-s"}>
                      <label className={"input-label"}>
                        Date de début de couverture *
                        <div>Assurance habitation</div>{" "}
                      </label>
                      <DateField name={"insuranceCoverageDate"} />
                    </div>
                  )}
                  <ValidationsErrors />
                  <div className={"margin-top right"}>
                    <SubmitButton>Valider</SubmitButton>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </fieldset>
        <div className={"section"}>
          <WhyMovecool />
        </div>
      </div>
      <div className={"col-m-1-3"}>
        <div className={"section"}>
          <div className={"card-block"}>
            <div className={"card-head"}>
              <h2 className={"card-title"}>Contacter un conseiller</h2>
            </div>
            <div className={"card-cta"}>
              <img
                alt={"man's face"}
                className={"block-image"}
                src={blockImage}
              />
              <p className={"body-txt"}>
                Finalisez votre commande par téléphone au{" "}
                <a
                  className={"link"}
                  href={"tel:0980805110"}
                  onClick={() => {
                    push(GtmEvent.GENERIC_EVENT, {
                      event_name: "call",
                    });
                  }}
                >
                  09 80 80 51 10
                </a>{" "}
                ou bien faites vous rappeler plus tard.
              </p>
              <a
                className={"btn-1 btn-block"}
                href={generateCalendlyLink("", "header")}
                onClick={() => {
                  push(GtmEvent.ECOMMERCE, {
                    event_name: "click_callback", // REVIEW: Wording of the tracking ??
                  });
                }}
                rel={"noreferrer"}
                target={"_blank"}
              >
                Me faire rappeler
              </a>
            </div>
          </div>
        </div>

        <div className={"section"}>
          <CartReinsuranceBlock />
        </div>
      </div>

      <div className={"checkout-nav"}>
        <Link className={"back-link"} to={CART_LINK}>
          &lt; Retour à l'étape précédente
        </Link>
        <fieldset disabled={checkout && checkout.dates?.length === 0}>
          <Link className={"btn-1"} to={CART_CONTRACT_LINK}>
            Continuer
          </Link>
        </fieldset>
      </div>

      <div className={"checkout-disclaimer"}>
        En passant commande vous acceptez nos{" "}
        <a
          href={
            "https://movecool.notion.site/Mentions-L-gales-Conditions-G-n-rales-d-utilisation-7de1bc001712449fb83908486d769537"
          }
          rel={"noreferrer"}
          target={"_blank"}
        >
          conditions générales d'utilisation et de vente,
        </a>{" "}
        notre{" "}
        <a
          href={
            "https://movecool.notion.site/Politique-de-confidentialit-gestion-des-cookies-48624257b4754a498d8727d8efd135d5"
          }
          rel={"noreferrer"}
          target={"_blank"}
        >
          politique de protection des données
        </a>{" "}
        ainsi que les conditions tarifaires des offres auxquelles vous
        souscrivez.
      </div>
    </div>
  );
};

export default withProvideScrollTo(CartPersonalInfos);
