import {
  ComponentType,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import ReactTooltip from "react-tooltip";
import { getBrands as apiGetBrands } from "./api";
import { useHousing } from "./housingProvider";
import {
  Brand,
  Feature,
  FeatureCode,
  mapRawBrandsToBrands,
  quoteToFeatures,
} from "./insurance";

export interface InsuranceAPI {
  brands: Map<string, Brand>;

  getBrands(): Promise<void>;
  loadAll(): Promise<void>;
}

export const InsuranceContext = createContext<InsuranceAPI | null>(null);

export const ProvideInsurance = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const {
    lovysEstimate,
    lukoEstimate,
    appeninEstimate,
    acheelEstimate,
    leocareEstimate,
  } = useHousing();

  const [brands, setBrands] = useState<InsuranceAPI["brands"]>(new Map());

  const getBrands = useCallback(
    () =>
      apiGetBrands().then(({ data }) => setBrands(mapRawBrandsToBrands(data))),
    [],
  );
  const loadAll = useCallback(async () => {
    await Promise.all([getBrands()]);
  }, [getBrands]);

  const brandsWithEstimates = useMemo(
    () =>
      new Map(
        [...brands.entries()]
          // .filter(([, brand]) => {
          //   return !(
          //     (brand.apiId === "luko" && lukoEstimate === undefined) ||
          //     (brand.apiId === "lovys" && lovysEstimate === undefined) ||
          //     (brand.apiId === "acheel" && acheelEstimate === undefined) ||
          //     (brand.apiId === "leocare" && leocareEstimate === undefined) ||
          //     (brand.apiId === "appenin" && appeninEstimate === undefined)
          //   );
          // })
          .map(([id, brand]) => [
            id,
            {
              ...brand,
              price:
                (brand.apiId === "luko"
                  ? lukoEstimate?.price
                  : brand.apiId === "lovys"
                  ? lovysEstimate?.price
                  : brand.apiId === "appenin"
                  ? appeninEstimate?.price
                  : brand.apiId === "acheel"
                  ? acheelEstimate?.price
                  : brand.apiId === "leocare"
                  ? leocareEstimate?.price
                  : null) || null,
              calculatedFeatures: new Map([
                ...(
                  (brand.apiId === "luko"
                    ? quoteToFeatures(lukoEstimate)
                    : brand.apiId === "lovys"
                    ? quoteToFeatures(lovysEstimate)
                    : brand.apiId === "appenin"
                    ? quoteToFeatures(appeninEstimate)
                    : brand.apiId === "acheel"
                    ? quoteToFeatures(acheelEstimate)
                    : brand.apiId === "leocare"
                    ? quoteToFeatures(leocareEstimate)
                    : new Map()) || new Map()
                ).entries(),
                ...brand.features.map((f): [FeatureCode, Feature<any>] => [
                  f.code,
                  f,
                ]),
              ]),
              url:
                (brand.apiId === "luko"
                  ? lukoEstimate?.url
                  : brand.apiId === "lovys"
                  ? lovysEstimate?.url
                  : brand.apiId === "appenin"
                  ? appeninEstimate?.url
                  : null) || null,
            },
          ]),
      ),
    [
      acheelEstimate,
      appeninEstimate,
      brands,
      leocareEstimate,
      lovysEstimate,
      lukoEstimate,
    ],
  );

  useEffect(() => {
    // Rebuild tooltips after brands are fully loaded
    ReactTooltip.rebuild();
  }, [brandsWithEstimates]);

  return (
    <InsuranceContext.Provider
      value={{
        brands: brandsWithEstimates,
        getBrands,
        loadAll,
      }}
    >
      {children}
    </InsuranceContext.Provider>
  );
};

export function withProvideInsurance<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvideInsurance(props: P) {
    return (
      <ProvideInsurance>
        <WrappedComponent {...props} />
      </ProvideInsurance>
    );
  }

  WithProvideInsurance.displayName = `withProvideInsurance(${displayName})`;

  return WithProvideInsurance;
}

export function useInsurance(): InsuranceAPI {
  return useContext(InsuranceContext) as InsuranceAPI;
}
