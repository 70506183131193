import { useField } from "formik";
import { object, Schema, string, InferType, ObjectSchema } from "yup";
import AutoField from "../../forms/AutoField";
import Form from "../../forms/Form";
import ValidationsErrors from "../../forms/ValidationsErrors";
import { Never } from "../../types/utility";
import { useAuth } from "../apiProvider";
import { User } from "../user";
import IconCheck from "../../../assets/img/icons/icon-check-round-blue.png";

export interface UserInfosBase
  extends Pick<User, "civility" | "firstname" | "lastname" | "phoneNumber"> {}

const PersonalInfosForm = <
  ExtendedValues extends Partial<UserInfosBase>,
  // ExtraSchema forbid the override of the keys of BaseForm and allow adding new schemas
  ExtraSchema extends {
    [k in keyof Omit<ExtendedValues, keyof UserInfosBase>]: Schema<
      ExtendedValues[k]
    >;
  } &
    Never<UserInfosBase>,
  // OnSubmitValues is typed with the fusion of the base schema and the ExtraSchema
  OnSubmitValues extends InferType<
    ObjectSchema<
      {
        [k in keyof Omit<ExtraSchema, keyof UserInfosBase>]: InferType<
          ExtraSchema[k]
        >;
      } &
        UserInfosBase
    >
  >
>({
  initialValues,
  extraSchema,
  formFooter,
  children,
  onSubmit,
}: {
  initialValues?: ExtendedValues;
  extraSchema?: ExtraSchema;
  formFooter: JSX.Element;
  onSubmit(values: OnSubmitValues): Promise<unknown>;
  children?: (elements: {
    civility: JSX.Element;
    lastname: JSX.Element;
    firstname: JSX.Element;
    phoneNumber: JSX.Element;
  }) => JSX.Element | null;
}): JSX.Element => {
  const { user } = useAuth();

  const civility = <CivilityField name={"civility"} />;
  const firstname = <AutoField name={"firstname"} />;
  const lastname = <AutoField name={"lastname"} />;
  const phoneNumber = <AutoField name={"phoneNumber"} />;

  return (
    <Form
      enableReinitialize={true}
      initialValues={{
        ...initialValues,
        civility: initialValues?.civility || user?.civility || null,
        lastname: initialValues?.lastname || user?.lastname || null,
        firstname: initialValues?.firstname || user?.firstname || null,
        phoneNumber: initialValues?.phoneNumber || user?.phoneNumber || null,
      }}
      onSubmit={(values) => {
        return onSubmit(values as any);
      }}
      schema={object({
        ...extraSchema,
        civility: string().label("Civilité").oneOf(["M.", "Mme."]).required(),
        lastname: string().label("Nom").required(),
        firstname: string().label("Prénom").required(),
        phoneNumber: string()
          .label("Téléphone de contact")
          .required()
          .test("", (val) => /^[+]?((33[0-9])|[0-9]{2})[0-9]{8}$/im.test(val)),
      })}
    >
      {children ? (
        children({ civility, firstname, lastname, phoneNumber })
      ) : (
        <>
          <div className={"form-block"}>{civility}</div>
          <div className={"form-block"}>{firstname}</div>
          <div className={"form-block"}>{lastname}</div>
          <div className={"form-block"}>{phoneNumber}</div>
        </>
      )}

      <div className={"margin-top"}>
        <div className={"info info-light"}>
          <div className={"lrow"}>
            <img alt={"Information"} src={IconCheck} />
            <div>
              <h3 className={"info-title"}>
                NOUS PROTÉGEONS VOS DONNÉES PERSONNELLES
              </h3>
              <p>
                Vos informations de contact sont nécessaires pour établir votre
                contrat. Nous ne communiquerons jamais vos informations à un
                tiers sans votre accord.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={"form-footer"}>
        <ValidationsErrors />

        <div className={"right"}>{formFooter}</div>
      </div>
    </Form>
  );
};

const CivilityField = ({ name }: { name: string }) => {
  const [field, , helpers] = useField(name);

  return (
    <div className={"form-block"}>
      <div className={"switch-select"}>
        <button
          className={field.value === "M." ? "active" : ""}
          onClick={() => helpers.setValue("M.")}
          type={"button"}
        >
          M.
        </button>
        <button
          className={field.value === "Mme." ? "active" : ""}
          onClick={() => helpers.setValue("Mme.")}
          type={"button"}
        >
          Mme.
        </button>
      </div>
      <div className={"section-xs"} />
    </div>
  );
};

export default PersonalInfosForm;
