import { Brand, FeatureCode } from "./insurance";
// import iconAPIOffer from "../../../assets/img/icons/icon-insurance-api.svg";
// import iconBDDOffer from "../../../assets/img/icons/icon-insurance-bdd.svg";
import iconTheft from "../../../assets/img/icons/icon-theft.svg";
import iconLegalMin from "../../../assets/img/icons/icon-legal-min.svg";
import iconBrokenGlass from "../../../assets/img/icons/icon-broken-glass.svg";
import iconAssistance from "../../../assets/img/icons/icon-assistance.svg";
import iconLockSmith from "../../../assets/img/icons/icon-locksmith.svg";
import iconScolar from "../../../assets/img/icons/icon-scolar.svg";
import iconReplacementCost from "../../../assets/img/icons/icon-replacement-cost.svg";
import { cx } from "@emotion/css";
import { coverageTooltips } from "./housingProvider";

const FeaturesList = ({
  brand,
  className,
}: {
  brand: Brand;
  className?: string;
}): JSX.Element => {
  return (
    <div className={cx("icons-list", className)}>
      {/* {brand.apiId ? (
        <img
          alt={"icône offre partenaire"}
          className={"icon"}
          data-tip={
            "Offre dont le prix et les garanties sont personnalisables."
          }
          src={iconAPIOffer}
        />
      ) : (
        <img
          alt={"icône offre estimée"}
          className={"icon"}
          data-tip={
            "Cette offre est une indication tarifaire (non personnalisable) dont le prix est calculé sur la base d'un appartement de 2 pièces et 100m2."
          }
          src={iconBDDOffer}
        />
      )} */}
      <img
        alt={"icône minimum légal"}
        className={"icon"}
        data-tip={coverageTooltips.legalMin}
        src={iconLegalMin}
      />
      {brand.calculatedFeatures.has(FeatureCode.TheftCoverage) && (
        <img
          alt={"icône vol & vandalisme"}
          className={"icon"}
          data-tip={coverageTooltips.theftCoverage}
          src={iconTheft}
        />
      )}

      {brand.calculatedFeatures.has(FeatureCode.BrokenGlassCoverage) && (
        <img
          alt={"icône bris de glace"}
          className={"icon"}
          data-tip={coverageTooltips.brokenGlass}
          src={iconBrokenGlass}
        />
      )}

      {brand.calculatedFeatures.has(FeatureCode.AssistanceCoverage) && (
        <img
          alt={"icône assistance"}
          className={"icon"}
          data-tip={coverageTooltips.assistance}
          src={iconAssistance}
        />
      )}

      {brand.calculatedFeatures.has(FeatureCode.SchoolCoverage) && (
        <img
          alt={"icône assurance scolaire"}
          className={"icon"}
          data-tip={coverageTooltips.scolar}
          src={iconScolar}
        />
      )}

      {brand.calculatedFeatures.has(FeatureCode.LockCoverage) && (
        <img
          alt={"icône serrurerie"}
          className={"icon"}
          data-tip={coverageTooltips.lockSmith}
          src={iconLockSmith}
        />
      )}

      {brand.calculatedFeatures.has(FeatureCode.AsNewCoverage) && (
        <img
          alt={"icône remboursement valeur à neuf"}
          className={"icon"}
          data-tip={coverageTooltips.replacementCost}
          src={iconReplacementCost}
        />
      )}
    </div>
  );
};

export default FeaturesList;
