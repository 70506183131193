import ProfilePic1 from "../../../../assets/img/teams/moving/walid.png";
import ProfilePic2 from "../../../../assets/img/teams/moving/samuel.png";
import ProfilePic3 from "../../../../assets/img/teams/moving/elodie.png";
import ProfilePic4 from "../../../../assets/img/teams/moving/antoine.png";
import { useAuth } from "src/services/auth/apiProvider";

const MovingTeam = () => {
  const { generateCalendlyLink } = useAuth();
  return (
    <div className={"team"}>
      <h2 className={"title"}>
        Une équipe disponible 6&nbsp;jours&nbsp;sur&nbsp;7
      </h2>
      <div className={"ctas"}>
        <a className={"btn-outlined"} href={"tel:+33980805110"}>
          09 80 80 51 10
        </a>
        <a
          className={"btn-1"}
          href={generateCalendlyLink("", "moving")}
          rel={"noreferrer"}
          target={"_blank"}
        >
          Être rappelé
        </a>
      </div>
      <div className={"teammates"}>
        <div className={"teammate"}>
          <img alt={"Walid"} src={ProfilePic1} />
          <p className={"name"}>Walid</p>
          <p className={"position"}>Expert déménagement</p>
        </div>
        <div className={"teammate"}>
          <img alt={"Samuel"} src={ProfilePic2} />
          <p className={"name"}>Samuel</p>
          <p className={"position"}>Expert déménagement</p>
        </div>
        <div className={"teammate"}>
          <img alt={"Élodie"} src={ProfilePic3} />
          <p className={"name"}>Élodie</p>
          <p className={"position"}>Experte déménagement</p>
        </div>
        <div className={"teammate"}>
          <img alt={"Antoine"} src={ProfilePic4} />
          <p className={"name"}>Antoine</p>
          <p className={"position"}>Expert déménagement</p>
        </div>
      </div>
    </div>
  );
};

export default MovingTeam;
