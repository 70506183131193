import { MutableRefObject, useEffect, useMemo, useState } from "react";

const useOnScreen = <E extends Element | null, T extends MutableRefObject<E>>(
  ref: T,
  offsetX = "0px",
): boolean => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting),
        { rootMargin: `${offsetX} 0px 500%` },
      ),
    [offsetX],
  );

  useEffect(() => {
    if (ref.current !== null) {
      observer.observe(ref.current!);
      // Remove the observer as soon as the component is unmounted
      return () => {
        observer.disconnect();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return isIntersecting;
};

export default useOnScreen;
