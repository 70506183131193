import { HTMLAttributes } from "react";
import Card from "../../ui/block/Card";
import CardBody from "../../ui/block/CardBody";
import CardHead from "../../ui/block/CardHead";
import { useConsumption } from "./consumptionProvider";
import { Brand, DualWithOffers, Offer, EnergyType } from "./energy";
import PriceDisplay from "./PriceDisplay";
import iconDocumentsBlue from "../../../assets/img/icons/icon-documents-blue.svg";
import iconLink from "../../../assets/img/icons/icon-link-blue.svg";
import iconCtaBtnArrow from "../../../assets/img/icons/icon-arrow-circle-right-white.svg";
import IconList from "./IconList";
import SubmitButton from "../../forms/SubmitButton";

type ExtractOffer<P> = P extends { offer: infer O } ? O : undefined;

const EnergyHighlightedCard = <
  P extends HTMLAttributes<HTMLDivElement> & {
    label: string;
    brand: Brand;
    viewMore?: () => void;
    onEstimate?: () => void;
    onChoose: () => void;
  } & (ExtractOffer<P> extends undefined
      ? {
          dual: DualWithOffers;
          offer?: never;
        }
      : {
          offer: Offer;
          dual?: never;
        })
>({
  label,
  offer,
  dual,
  brand,
  viewMore,
  onEstimate,
  onChoose,
  ...props
}: P): JSX.Element => {
  const { electricityConsumption, gasConsumption } = useConsumption();

  return (
    <div className={"card-block"}>
      <CardHead title={label} />
      <div className={"offer-promo"}>
        {((offer?.promotions.length || 0) > 0 ||
          (dual?.promotions.length || 0) > 0) && (
          <span>{offer?.promotions[0] || dual?.promotions[0]}</span>
        )}
      </div>
      <Card {...props}>
        <CardBody>
          <div className={"offer-header"}>
            <div className={"brand-logo"}>
              <img alt={"logo marque"} src={brand.logo} />
            </div>
          </div>
          <div className={"offer-name"}>{offer?.name || dual?.name}</div>
          {/* TODO : finish refactoring for dual offers */}
          {offer && <PriceDisplay offer={offer} />}
          {dual && <PriceDisplay offer={dual} />}
          <IconList offer={offer || dual} />
        </CardBody>
        <div className={"card-footer"}>
          {(offer?.type === EnergyType.Electricity &&
            !!electricityConsumption) ||
          (offer?.type === EnergyType.Gas && !!gasConsumption) ||
          (dual && electricityConsumption && gasConsumption) ? (
            <>
              <SubmitButton
                className={"btn-branded"}
                // onClick={(ev) => {
                //   navigate(CART_LINK);
                //   ev.stopPropagation();
                //   const data = {
                //     ...(lastConsumptionCalculatorStep ===
                //       ConsumptionCalculatorStep.Calculated
                //       ? {
                //         area,
                //         cookingSource,
                //         heatingSource,
                //         hotWaterSource,
                //         residentCount,
                //       }
                //       : {
                //         area: null,
                //         cookingSource: null,
                //         heatingSource: null,
                //         hotWaterSource: null,
                //         residentCount: null,
                //       }),
                //     offerId: (offer?.id || dual?.id)!,
                //     address: currentLocation!,
                //     electricityConsumption: electricityConsumption || 0,
                //     gasConsumption: gasConsumption || 0,
                //     home: null,
                //     situation: null,
                //   };

                //   return addToCart(
                //     dual
                //       ? {
                //         type: CartType.Dual,
                //         data: {
                //           electricity: {
                //             ...data,
                //             offerId: dual.electricity,
                //           },
                //           gas: {
                //             ...data,
                //             offerId: dual.gas,
                //           },
                //         },
                //       }
                //       : {
                //         type:
                //           offer?.type === EnergyType.Electricity
                //             ? CartType.Electricity
                //             : CartType.Gas,
                //         data,
                //       },
                //   );
                // }}
                onClick={onChoose}
                type={"button"}
              >
                <img
                  alt={""}
                  className={"icon icon-left"}
                  src={iconCtaBtnArrow}
                />
                Choisir
              </SubmitButton>
              {/* <SubmitButton
                className={"btn-branded-outlined"}
                onClick={(ev) => {
                  ev.stopPropagation();
                  const data = {
                    ...(lastConsumptionCalculatorStep ===
                    ConsumptionCalculatorStep.Calculated
                      ? {
                          area,
                          cookingSource,
                          heatingSource,
                          hotWaterSource,
                          residentCount,
                        }
                      : {
                          area: null,
                          cookingSource: null,
                          heatingSource: null,
                          hotWaterSource: null,
                          residentCount: null,
                        }),
                    offerId: (offer?.id || dual?.id)!,
                    address: currentLocation!,
                    electricityConsumption: electricityConsumption || 0,
                    gasConsumption: gasConsumption || 0,
                    home: null,
                    situation: null,
                  };
                  return addToCart(
                    dual
                      ? {
                          type: CartType.Dual,
                          data: {
                            electricity: {
                              ...data,
                              offerId: dual.electricity,
                            },
                            gas: {
                              ...data,
                              offerId: dual.gas,
                            },
                          },
                        }
                      : {
                          type:
                            offer?.type === EnergyType.Electricity
                              ? CartType.Electricity
                              : CartType.Gas,
                          data,
                        },
                  );
                }}
                type={"button"}
              >
                <img alt={""} className={"icon icon-left"} src={iconCart} />
                Ajouter au panier
              </SubmitButton> */}
            </>
          ) : (
            <button className={"btn-cta-estimate"} onClick={onEstimate}>
              Estimer ma mensualité
              <img
                alt={"estimer"}
                className={"icon icon-right"}
                src={iconCtaBtnArrow}
              />
            </button>
          )}
          <button className={"btn-link"} onClick={viewMore}>
            <img
              alt={""}
              className={"icon icon-left"}
              src={iconDocumentsBlue}
            />
            En savoir plus
          </button>
          {offer?.url && (
            <a
              className={"btn-link"}
              href={offer.url}
              rel={"noreferrer"}
              target={"_blank"}
            >
              <img
                alt={"icône lien"}
                className={"icon icon-left"}
                src={iconLink}
              />
              Site Web
            </a>
          )}
        </div>
      </Card>
    </div>
  );
};

export default EnergyHighlightedCard;
