import { HTMLAttributes } from "react";
import Dialog from "../../../ui/block/Dialog";
import { Brand } from "../moving";

const MovingDetailsDialog = <
  P extends HTMLAttributes<HTMLDivElement> & {
    brand: Brand;
    onClose(): void;
  }
>({
  brand,
  onClose,
  ...props
}: P): JSX.Element => {
  return (
    <Dialog className={"dialog-card-l"} onClose={onClose}>
      <div className={"row"} {...props}>
        <div className={"col-fit"}>
          <img
            alt={`Logo ${brand.name}`}
            src={brand.logo}
            style={{ width: "6em" }}
          />
        </div>

        <div className={"col-auto grid"}>
          <div className={"col-1-1"}>
            <h2>{brand.name}</h2>
            <p>{brand.description}</p>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default MovingDetailsDialog;
