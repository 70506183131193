import { useCallback, useEffect, useMemo, useState } from "react";
import { isLocationMatching, PricePer, sortByRelevance } from "../common";
import { useHousing } from "./housingProvider";
import { Brand, ClientType, HousingType } from "./insurance";
import InsuranceCalculatorDialog, {
  InsuranceForm,
} from "./InsuranceCalculatorDialog";
import InsuranceDetailsDialog from "./InsuranceDetailsDialog";
import { useInsurance } from "./insuranceProvider";
import { usePlace } from "../../google-maps/placeProvider";
import { withLoader } from "../../routing/useLoader";
import CardHead from "../../ui/block/CardHead";
import LoadMoreList from "../../ui/reactive/LoadMoreList";
import ComparatorListItem from "../../ui/common-components/ComparatorListItem";
import { useCart } from "../../compare/cart/cartProvider";
import { CartType } from "../../compare/cart/cart";
import iconTel from "../../../assets/img/icons/icon-tel-purple.svg";
import ComparatorRefinementCard from "./ComparatorRefinementCard";
// import iconCtaBtnArrow from "../../../assets/img/icons/icon-arrow-circle-right-white.svg";
import iconReload from "../../../assets/img/icons/icon-reload-light-blue.svg";
// import { cx } from "@emotion/css";
import iconOptions from "../../../assets/img/icons/icon-options-blue.svg";
import iconLocation from "../../../assets/img/icons/icon-location-blue.svg";
// import iconRdv from "../../../assets/img/icons/icon-appointment-blue.svg";
import { multiSorts } from "../../data-structures/array";
import { useToastsWithIntl } from "../../toast-notifications";
import OrderByPhone from "src/services/common-components/OrderByPhone";
import iconCart from "../../../assets/img/icons/icon-cart-purple.svg";
import iconFilter from "../../../assets/img/icons/icon-filters-blue.svg";
import Dialog from "../../ui/block/Dialog";
// import { useAuth } from "../../auth/apiProvider";
import { GtmEvent, useGtm } from "../../gtm/gtmProvider";
import WhyMovecool from "src/services/common-components/WhyMovecool";
import MovecoolReinsuranceBlock from "src/services/ui/common-components/MovecoolReinsuranceBlock";
import BrandLogo1 from "../../../assets/img/brand-logos/insurance/macif.png";
import BrandLogo2 from "../../../assets/img/brand-logos/insurance/groupama.png";
import BrandLogo3 from "../../../assets/img/brand-logos/insurance/luko.png";
import BrandLogo4 from "../../../assets/img/brand-logos/insurance/direct-assurance.png";
import BrandLogo5 from "../../../assets/img/brand-logos/insurance/mma.png";
import BrandLogo6 from "../../../assets/img/brand-logos/insurance/axa.png";
import BrandLogo7 from "../../../assets/img/brand-logos/insurance/allianz.png";
import InsuranceTeam from "./InsuranceTeam";
import InsuranceActionsDialog from "./InsuranceActionsDialog";
import HowItWorksMain from "src/services/common-components/HowItWorksMain";

enum Sort {
  Relevance,
  PriceAsc,
  PriceDesc,
}

const compareBrandsPrice = (desc: boolean) => (
  b1: Brand,
  b2: Brand,
): number => {
  const a = desc ? b2 : b1;
  const b = desc ? b1 : b2;
  return (a.price || a.simulatedPrice) - (b.price || b.simulatedPrice);
};

const InsuranceComparator = (): JSX.Element => {
  // Store
  const { toastError } = useToastsWithIntl(["compare"]);
  const { brands } = useInsurance();
  const {
    clientType,
    flatLevel,
    housingCategory,
    housingType,
    roomNumber,
    size,
    canRefineQuotes,
    quotesAlreadyLoaded,
    loadQuotes,
  } = useHousing();
  const { currentLocation } = usePlace();
  // const { generateCalendlyLink } = useAuth();
  const { push } = useGtm();

  // State
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
  const [chosenBrand, setChosenBrand] = useState<Brand | null>(null);
  const [sort, setSort] = useState(Sort.PriceAsc);
  const [showInsuranceCaclulator, setShowInsuranceCalculator] = useState(false);
  const [pricePer, setPricePer] = useState(PricePer.Month);
  const togglePricePer = useCallback(() => {
    setPricePer((prev) =>
      prev === PricePer.Month ? PricePer.Year : PricePer.Month,
    );
  }, []);
  const [showFilter, setShowFilter] = useState(false);

  // Filters
  const [customizableFilter, setCustomizableFilter] = useState(false);
  const filteredBrands = useMemo(
    () =>
      [...brands.values()].filter(
        (brand) =>
          !currentLocation ||
          (isLocationMatching(currentLocation.postCode!, brand.postalCodes) &&
            (!customizableFilter || (brand.isCustomizable && brand.price))),
      ),
    [brands, currentLocation, customizableFilter],
  );

  const brandsWithoutFinalPrice = useMemo(
    () => [...brands.values()].filter((brand) => brand.price === null),
    [brands],
  );

  // Sort
  const sortedBrands = useMemo(
    () =>
      [...filteredBrands].sort(
        sort === Sort.Relevance
          ? multiSorts(sortByRelevance, compareBrandsPrice(false))
          : sort === Sort.PriceAsc
          ? compareBrandsPrice(false)
          : compareBrandsPrice(true),
      ),
    [filteredBrands, sort],
  );

  const scrollToHighlight = useCallback(() => {
    const sectionHighlightTitle = document.getElementById("comparator");
    sectionHighlightTitle!.scrollIntoView({ behavior: "smooth" });
  }, []);

  // Memos
  // const brandHighlights = useMemo(
  //   () =>
  //     [...brands.values()].reduce(
  //       (highlights, brand) =>
  //         brand.highlights.length > 0
  //           ? highlights.concat(
  //             brand.highlights.map((h) => ({
  //               label: h,
  //               element: { brand },
  //             })),
  //           )
  //           : highlights,
  //       [] as Highlight[],
  //     ),
  //   [brands],
  // );

  const [dataCompleteCondition, setDataCompleteCondition] = useState(false);

  // Effects
  useEffect(() => {
    setDataCompleteCondition(
      clientType !== null &&
        flatLevel !== null &&
        housingCategory !== null &&
        housingType !== null &&
        roomNumber !== null &&
        size !== null,
    );
    // NOTE: If we dont have the housing data,
    // NOTE: we open the insurance calculator dialog after 3 minutes.
    if (!dataCompleteCondition) {
      const timeout = setTimeout(() => {
        // TODO: Add an intermediary dialog
        setShowInsuranceCalculator(true);
      }, 1000 * 60 * 3);

      return () => clearTimeout(timeout);
    } else setCustomizableFilter((prev) => !prev);
  }, [
    clientType,
    flatLevel,
    housingCategory,
    housingType,
    roomNumber,
    size,
    dataCompleteCondition,
  ]);

  const { addToCart } = useCart();

  return (
    <div className={"margin-top-l"} id={"comparator"}>
      {/* {canRefineQuotes && brandHighlights.length && (
        <>
          <h2 className={"page-section-title"}>
            Les meilleures offres du moment
          </h2>
          <div className={"offers-highlights"}>
            {brandHighlights.map((highlight) => (
              <InsuranceHighlightedCard
                key={highlight.label}
                brand={highlight.element.brand as Brand}
                dataCompleteCondition={dataCompleteCondition}
                label={highlight.label}
                onClick={() =>
                  setSelectedBrand(highlight.element.brand as Brand)
                }
                onEstimate={() => setShowInsuranceCalculator(true)}
                pricePer={pricePer}
                togglePricePer={togglePricePer}
                viewMore={() =>
                  setSelectedBrand(highlight.element.brand as Brand)
                }
              />
            ))}
          </div>
        </>
      )} */}

      {!dataCompleteCondition || !quotesAlreadyLoaded ? ( // before initial form completion
        <div className={"container-wrap --wrap--s"}>
          <InsuranceForm
            onSubmit={(values) => {
              const location = currentLocation || values.currentLocation;
              const quotes = loadQuotes({
                floor: values.flatLevel!,
                habitationUsage: values.housingCategory!,
                isHouse: values.housingType === HousingType.House,
                isOwner: values.clientType === ClientType.Owner,
                roomsCount: values.roomNumber!,
                surface: values.size!,
                address: location!.cleanedAddress!,
                city: location!.city!,
                postCode: location!.postCode!,
              });
              push(GtmEvent.FORM, {
                event_name: "submit_monthly_estimation",
                item_category: "Assurance",
              });

              return quotes.then(() => {
                scrollToHighlight();
              });
            }}
          />
        </div>
      ) : (
        // after initial form completion => display comparator lists
        <>
          <div className={"comparator-list"}>
            <div className={"grid"}>
              <div className={"comparator-sidebar"}>
                {dataCompleteCondition && (
                  <>
                    {canRefineQuotes && (
                      <>
                        <div className={"section filter"}>
                          <ComparatorRefinementCard
                            onSubmit={(values) => {
                              return loadQuotes({
                                ...values,
                                address: currentLocation!.plainAddress,
                                city: currentLocation!.city,
                                postCode: currentLocation!.postCode,
                              }).then(
                                () => {
                                  setShowInsuranceCalculator(false);
                                },
                                () => {
                                  toastError(
                                    "compare:getting-quote-for-housing.ERROR",
                                  );
                                },
                              );
                            }}
                          />
                        </div>
                        {showFilter && (
                          <Dialog
                            className={"side-panel"}
                            closeLabel={"Retour  x"}
                            onClose={() => setShowFilter((prev) => !prev)}
                          >
                            <ComparatorRefinementCard
                              onSubmit={(values) => {
                                setShowFilter((prev) => !prev);
                                return loadQuotes({
                                  ...values,
                                  address: currentLocation!.plainAddress,
                                  city: currentLocation!.city,
                                  postCode: currentLocation!.postCode,
                                }).then(
                                  () => {
                                    setShowInsuranceCalculator(false);
                                  },
                                  () => {
                                    toastError(
                                      "compare:getting-quote-for-housing.ERROR",
                                    );
                                  },
                                );
                              }}
                            />
                          </Dialog>
                        )}
                      </>
                    )}
                    <div className={"section"}>
                      <div className={"card-block"}>
                        <CardHead title={"Vos informations"} />

                        <div className={"card"}>
                          <div className={"card-body"}>
                            {
                              roomNumber && (
                                <>
                                  <div className={"filter-group"}>
                                    <h3 className={"filter-title"}>
                                      <img
                                        alt={"icône adresse"}
                                        className={"icon"}
                                        src={iconLocation}
                                      />
                                      Votre adresse
                                    </h3>
                                    <p>{currentLocation?.plainAddress}</p>
                                  </div>
                                  <div className={"filter-group"}>
                                    <h3 className={"filter-title"}>
                                      <img
                                        alt={"icône paramètres"}
                                        className={"icon"}
                                        src={iconOptions}
                                      />
                                      Votre logement
                                      {clientType !== null && (
                                        <p>
                                          Vous êtes{" "}
                                          <strong>
                                            {clientType === ClientType.Owner
                                              ? "propriétaire"
                                              : "locataire"}
                                          </strong>{" "}
                                        </p>
                                      )}
                                      {housingType !== null && (
                                        <p>
                                          {housingType === HousingType.Flat ? (
                                            <span>
                                              d'un <strong>appartement</strong>
                                            </span>
                                          ) : (
                                            <span>
                                              d'une <strong>maison</strong>
                                            </span>
                                          )}
                                        </p>
                                      )}
                                      <p>
                                        de <strong>{size}m2</strong>
                                      </p>
                                      <p>
                                        avec{" "}
                                        <strong>{roomNumber} pièces</strong>
                                      </p>
                                    </h3>
                                  </div>
                                  <div className={"section center"}>
                                    <button
                                      className={"link-3-s margin-auto"}
                                      onClick={() =>
                                        setShowInsuranceCalculator(true)
                                      }
                                    >
                                      <img
                                        alt={"icône modifier"}
                                        className={"icon icon-left"}
                                        src={iconReload}
                                      />
                                      <span>Modifier mes informations</span>
                                    </button>
                                  </div>
                                </>
                              )
                              // ) : (
                              //   <>
                              //     <p className={"center"}>
                              //       Renseignez vos{" "}
                              //       <strong>informations manquantes</strong>
                              //     </p>
                              //     <p className={"center"}>
                              //       pour obtenir une{" "}
                              //       <strong>estimation de prix</strong> et nous
                              //       permettre de vous conseiller au mieux
                              //     </p>
                              //     <div className={"section-s center"}>
                              //       <button
                              //         className={"btn-cta-estimate btn-block"}
                              //         onClick={() => {
                              //           setShowInsuranceCalculator(true);
                              //           push(GtmEvent.GENERIC_EVENT, {
                              //             event_name: "click_monthly_estimation",
                              //             item_category: "Assurance",
                              //           });
                              //         }}
                              //       >
                              //         Estimer ma mensualité
                              //         <img
                              //           alt={"estimer"}
                              //           className={"icon icon-right"}
                              //           src={iconCtaBtnArrow}
                              //         />
                              //       </button>
                              //     </div>
                              //   </>
                              // )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className={"section"}>
                  <OrderByPhone />
                </div>
              </div>

              <div className={"comparator-offers"}>
                {dataCompleteCondition ? (
                  <>
                    {sortedBrands.length > 0 && (
                      <>
                        <h2 className={"page-section-title"}>
                          Notre sélection des meilleures offres du mois
                        </h2>
                        <div className={"list-head"}>
                          <button
                            className={"btn-filter margin-right"}
                            onClick={() => {
                              setShowFilter((prev) => !prev);
                            }}
                          >
                            Filtres
                            <img
                              alt={"estimer"}
                              className={"icon icon-right"}
                              src={iconFilter}
                            />
                          </button>
                          <select
                            className={"select-sp"}
                            onChange={(ev) =>
                              setSort(parseInt(ev.target.value))
                            }
                            value={sort}
                          >
                            <option value={Sort.Relevance}>
                              Classer / trier
                            </option>
                            <option value={Sort.PriceAsc}>
                              Du - cher au + cher
                            </option>
                            <option value={Sort.PriceDesc}>
                              Du + cher au - cher
                            </option>
                          </select>
                        </div>
                        <LoadMoreList
                          items={sortedBrands}
                          loadMoreLabel={"Afficher plus d'offres"}
                          paginatedBy={10}
                          render={(item) => (
                            <ComparatorListItem
                              key={item.id}
                              brand={item}
                              calendlyContext={"insurance"}
                              cartIcon={iconCart}
                              dataCompleteCondition={dataCompleteCondition}
                              iconCalling={iconTel}
                              // onAddToCart={(ev) => {
                              //   ev.stopPropagation();
                              //   return addToCart({
                              //     type: CartType.Insurance,
                              //     data: {
                              //       brandId: item.id,
                              //       address: currentLocation!,
                              //       price: item.price,
                              //       simulatedPrice: item.simulatedPrice,
                              //       calculatedFeatures: [
                              //         ...item.calculatedFeatures.entries(),
                              //       ],
                              //       home: null,
                              //       situation: null,
                              //       clientType,
                              //       flatLevel,
                              //       housingCategory,
                              //       housingType,
                              //       roomNumber,
                              //       size,
                              //     },
                              //   });
                              // }}
                              onChooseOffer={() => {
                                setChosenBrand(item);
                              }}
                              onEstimate={() => {
                                setShowInsuranceCalculator(true);
                                push(GtmEvent.GENERIC_EVENT, {
                                  event_name: "click_monthly_estimation",
                                  item_category: "Assurance",
                                  item_id: item.id,
                                  item_name: item.name,
                                  item_price: item.price || item.simulatedPrice,
                                });
                              }}
                              pricePer={pricePer}
                              togglePricePer={togglePricePer}
                              viewMore={() => setSelectedBrand(item)}
                            />
                          )}
                        />
                      </>
                    )}

                    {dataCompleteCondition && (
                      <>
                        <div className={"section"}>
                          <WhyMovecool />
                        </div>
                        <h2 className={"page-section-title"}>
                          Devis disponibles par téléphone
                        </h2>
                        {/* <div>
                          <div className={"buttons-row --center"}>
                            <a
                              className={"btn-branded-outlined"}
                              href={"tel:+33980805110"}
                            >
                              <img
                                alt={"icône téléphone"}
                                className={"icon icon-left"}
                                src={iconTel}
                              />
                              09 80 80 51 10
                            </a>
                            <a
                              className={"btn-outlined"}
                              href={generateCalendlyLink("", "insurance")}
                              rel={"noreferrer"}
                              target={"_blank"}
                            >
                              <img
                                alt={"icône téléphone"}
                                className={"icon icon-left"}
                                src={iconRdv}
                              />
                              Être appelé plus tard
                            </a>
                          </div>
                        </div> */}
                        <div className={"margin-top"}>
                          <LoadMoreList
                            items={brandsWithoutFinalPrice}
                            loadMoreLabel={"Afficher plus d'offres"}
                            paginatedBy={10}
                            render={(item) => (
                              <ComparatorListItem
                                key={item.id}
                                brand={item}
                                calendlyContext={"insurance"}
                                cartIcon={iconCart}
                                dataCompleteCondition={dataCompleteCondition}
                                iconCalling={iconTel}
                                onAddToCart={(ev) => {
                                  ev.stopPropagation();
                                  return addToCart({
                                    type: CartType.Insurance,
                                    data: {
                                      brandId: item.id,
                                      address: currentLocation!,
                                      price: item.price,
                                      simulatedPrice: item.simulatedPrice,
                                      calculatedFeatures: [
                                        ...item.calculatedFeatures.entries(),
                                      ],
                                      home: null,
                                      situation: null,
                                      clientType,
                                      flatLevel,
                                      housingCategory,
                                      housingType,
                                      roomNumber,
                                      size,
                                    },
                                  });
                                }}
                                onChooseOffer={() => {
                                  setChosenBrand(item);
                                }}
                                onEstimate={() =>
                                  setShowInsuranceCalculator(true)
                                }
                                pricePer={pricePer}
                                togglePricePer={togglePricePer}
                                viewMore={() => setSelectedBrand(item)}
                              />
                            )}
                          />
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {!dataCompleteCondition && (
        <>
          <div className={"section-xxl"}>
            <h2 className={"title-m center"}>
              +25 compagnies d'assurance comparées
            </h2>
            <p className={"center"}>Tous les assureurs sont sur Movecool</p>
            <div className={"brand-logos"}>
              <img alt={"Macif"} src={BrandLogo1} />
              <img alt={"Groupama"} src={BrandLogo2} />
              <img alt={"Luko"} src={BrandLogo3} />
              <img alt={"Direct Assurance"} src={BrandLogo4} />
              <img alt={"MMA"} src={BrandLogo5} />
              <img alt={"AXA"} src={BrandLogo6} />
              <img alt={"Allianz"} src={BrandLogo7} />
            </div>
          </div>

          <HowItWorksMain />
        </>
      )}

      <InsuranceTeam />

      {!dataCompleteCondition && <MovecoolReinsuranceBlock />}

      {selectedBrand && (
        <InsuranceDetailsDialog
          brand={selectedBrand}
          onChoose={() => setChosenBrand(selectedBrand)}
          onClose={() => setSelectedBrand(null)}
        />
      )}

      {chosenBrand && (
        <InsuranceActionsDialog
          brand={chosenBrand}
          onClose={() => setChosenBrand(null)}
        />
      )}

      {showInsuranceCaclulator && (
        <InsuranceCalculatorDialog
          onClose={() => setShowInsuranceCalculator(false)}
          onSubmit={(values) => {
            const location = currentLocation || values.currentLocation;
            const quotes = loadQuotes({
              floor: values.flatLevel!,
              habitationUsage: values.housingCategory!,
              isHouse: values.housingType === HousingType.House,
              isOwner: values.clientType === ClientType.Owner,
              roomsCount: values.roomNumber!,
              surface: values.size!,
              address: location!.cleanedAddress!,
              city: location!.city!,
              postCode: location!.postCode!,
            });
            push(GtmEvent.FORM, {
              event_name: "submit_monthly_estimation",
              item_category: "Assurance",
            });
            setShowInsuranceCalculator(false);
            return quotes;
          }}
        />
      )}
    </div>
  );
};

const useLoad = () => {
  const { loadAll } = useInsurance();

  return loadAll;
};

export default withLoader(InsuranceComparator, useLoad);
