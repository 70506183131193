import { useAuth } from "src/services/auth/apiProvider";
import iconTel from "../../../assets/img/icons/icon-tel-blue.svg";
import iconRdv from "../../../assets/img/icons/icon-appointment-blue.svg";
import { GtmEvent, useGtm } from "../../gtm/gtmProvider";

const NeedHelpBlock = ({
  title,
  text,
  service,
}: {
  title?: string;
  text: string;
  service: string;
}): JSX.Element => {
  const { generateCalendlyLink } = useAuth();
  const { push } = useGtm();

  return (
    <div className={"need-help-card"}>
      <div className={"card-body"}>
        <h3 className={"content-title"}>{title || "Besoin d'aide ?"}</h3>
        <p>{text}</p>
        <div className={"buttons"}>
          <a
            href={"tel:+33980805110"}
            onClick={() => {
              push(GtmEvent.GENERIC_EVENT, {
                event_name: "call",
              });
            }}
          >
            <img
              alt={"icône téléphone"}
              className={"icon icon-left"}
              src={iconTel}
            />
            09 80 80 51 10
          </a>
          <a
            href={generateCalendlyLink("", service)}
            onClick={() => {
              push(GtmEvent.ECOMMERCE, {
                event_name: "click_callback",
              });
            }}
            rel={"noreferrer"}
            target={"_blank"}
          >
            <img
              alt={"icône téléphone"}
              className={"icon icon-left"}
              src={iconRdv}
            />
            Être appelé plus tard
          </a>
        </div>
      </div>
    </div>
  );
};

export default NeedHelpBlock;
