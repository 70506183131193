import { toFixed } from "src/services/numbers/formatting";
import loggerBuilder from "../../logger";
import { typedPropertyToValueBuilder } from "../../notion/notion";

const logger = loggerBuilder("compare/internet");

export enum InternetOfferType {
  Adsl = "adsl",
  Fiber = "fibre",
  G4 = "4g",
}

export const INTERNET_OFFER_OPTION_NO_COMMITMENT = "Sans engagement";

export type OfferWithBrand = Offer & { brand: Brand };

export interface Offer {
  id: string;
  name: string;
  description: JSX.Element;
  price: number;
  bandwidth: number;
  type: InternetOfferType;
  options: string[];
  brandId: Brand["id"];
  url: string | null;
  movecoolRecommandation: boolean;
  bestConnection: boolean;
  commitmentDuration: number | null;
  commitmentEndPrice: number | null;
  relevance: number;
  highlights: string[];
  promotions: string[];
  updated_at: Date | null;
  tvChannelsAmount: number;
  boxPicture: string;
  boxName: string;
  phone: string | null;
  activationFee: number | null;
}
export function mapRawOffersToOffers(offers: unknown[]): Map<string, Offer> {
  return logger.withErrorCatcher(
    () =>
      new Map(
        offers.map((offer: any) => {
          logger.debug(`Mapping offer: ${offer.id}`);
          const propertyToValue = typedPropertyToValueBuilder(offer.properties);

          const highlights = propertyToValue("3 Best Offer", "select");
          const promotions = propertyToValue("Promotion", "select");

          const newOffer = {
            id: offer.id,
            name: propertyToValue("Nom", "title"),
            description: propertyToValue("Les plus de l'offre", "rich_text")
              .element,
            price: propertyToValue("Prix / mois", "number")!,
            bandwidth: propertyToValue("Débit (Mbit/s)", "number")!,
            type: propertyToValue(
              "Type",
              "select",
            )?.toLowerCase() as InternetOfferType,
            options: propertyToValue(
              "Options Incluse",
              "multi_select",
            ) as string[],
            brandId: propertyToValue("Marque", "relation")[0],
            url: propertyToValue("Url", "url"),
            movecoolRecommandation: propertyToValue(
              "Recommandée MoveCool",
              "checkbox",
            ),
            bestConnection: propertyToValue(
              "La meilleure connexion",
              "checkbox",
            ),
            commitmentDuration: propertyToValue(
              "Durée d'engagement (mois)",
              "number",
            ),
            commitmentEndPrice: propertyToValue("Prix final", "number"),
            relevance: propertyToValue("Pertinence", "number")!,
            highlights: highlights ? [highlights] : [],
            promotions: promotions ? [promotions] : [],
            updated_at: propertyToValue("Date de MAJ", "date"),
            tvChannelsAmount:
              propertyToValue("Nombre de chaînes TV", "number") || 0,
            boxPicture: propertyToValue("Image Box", "files")[0],
            boxName: propertyToValue("Nom de la Box", "rich_text").plain_text,
            phone: propertyToValue("Téléphone", "rich_text").plain_text,
            activationFee: propertyToValue("Frais d'activation", "number"),
          };
          if (newOffer.commitmentDuration === null)
            newOffer.options.push(INTERNET_OFFER_OPTION_NO_COMMITMENT);

          return [offer.id, newOffer];
        }),
      ),
    "Error while parsing offers",
  );
}

export interface Brand {
  id: string;
  name: string;
  description: JSX.Element;
  logo: string;
  url: string | null;
  postalCodes: string[];
  offersIds: string[];
  elegibilityCode: InternetOperator;
}
export function mapRawBrandsToBrands(rawBrands: unknown[]): Map<string, Brand> {
  return logger.withErrorCatcher(
    () =>
      new Map(
        rawBrands.map((brand: any) => {
          logger.debug(`Mapping brand: ${brand.id}`);
          const propertyToValue = typedPropertyToValueBuilder(brand.properties);

          return [
            brand.id,
            {
              id: brand.id,
              name: propertyToValue("Nom", "title"),
              description: propertyToValue("Description", "rich_text").element,
              logo: propertyToValue("Logo", "files")[0],
              url: propertyToValue("Url", "url"),
              postalCodes: propertyToValue("Code postaux", "multi_select"),
              offersIds: propertyToValue("Offres", "relation"),
              elegibilityCode: propertyToValue("elegibility_code", "rich_text")
                .plain_text as InternetOperator,
            },
          ];
        }),
      ),
    "Error while parsing brands",
  );
}

export enum InternetTechnology {
  Fiber = "FO",
  Satellite = "SAT",
  Adsl = "CU",
  Coaxial = "COAX",
}

export enum InternetOperator {
  Orange = "FRTE",
  Sfr = "SFR0",
  Bouygues = "BOUY",
  Free = "FREE",
}

export const InternetDebitCodeToMax: Record<string, string> = {
  HD05: "4 Mbit/s",
  HD3: "10 Mbit/s",
  BHD8: "25 Mbit/s",
  THD30: "100 Mbit/s",
  THD100: "1 Gbit/s",
  THD1G: "plus de 1 Gbit/s",
};

export interface Elegibility {
  op_code: InternetOperator;
  op_nom: string;
  tech_code: InternetTechnology;
  tech_nom: string;
  up_code: string;
  up_nom: string;
  down_code: string;
  down_nom: string;
}

export function mapRawElegibitiesToElegibilities(
  rawElegibilities: unknown[],
): Elegibility[] {
  return logger.withErrorCatcher(
    () =>
      rawElegibilities.map((elegibility: any) => ({
        ...elegibility,
      })) as Elegibility[],
    "Error while parsing elegibilities",
  );
}

export function formatBandwidthDisplay(rawBandwidth: number): string {
  return rawBandwidth < 1000
    ? toFixed(rawBandwidth, 0) + " Mb/s"
    : toFixed(rawBandwidth / 1000, 2) + " Gb/s";
}
