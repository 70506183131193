import { useFormikContext } from "formik";
import { useDeepEffect } from "../data-structures/any";

const InitialValuesListener = ({
  initialValues,
}: {
  initialValues: Record<string, unknown>;
}): null => {
  const formik = useFormikContext();

  useDeepEffect(
    () => {
      for (const key of Object.keys(initialValues)) {
        // BUG: This is not working, but it raise an error that do nothing
        formik.setFieldValue(key, initialValues[key], false);
      }
    },
    [initialValues],
    false,
  );

  return null;
};

export default InitialValuesListener;
