import iconLocation from "../../../../assets/img/icons/icon-location-blue.svg";
import iconDestination from "../../../../assets/img/icons/icon-destination-blue.svg";
import iconSurface from "../../../../assets/img/icons/icon-area-blue.svg";
import iconDate from "../../../../assets/img/icons/icon-calendar-blue.svg";
import iconArrowRightWhite from "../../../../assets/img/icons/icon-arrow-circle-right-white.svg";
import CardBody from "../../../ui/block/CardBody";
import Form from "../../../forms/Form";
import { MovingAPI, useMoving } from "../movingProvider";
import { date, number, object, string } from "yup";
import { GoogleMapsSearchbar } from "../../HomeInput";
import NumberField from "../../../forms/NumberField";
import DateField from "../../../forms/DateField";
import ValidationsErrors from "../../../forms/ValidationsErrors";
import SubmitButton from "../../../forms/SubmitButton";
import { useState } from "react";
import AutoField from "../../../forms/AutoField";
import { useToastsWithIntl } from "../../../toast-notifications";
import { locationSchema } from "../../../google-maps/place";
import { useAuth } from "../../../auth/apiProvider";

const MovingForm = ({
  movingData,
  handleClickNext,
}: {
  movingData: MovingAPI;
  handleClickNext(): void;
}): JSX.Element => {
  return (
    <Form
      initialValues={{
        currentLocation: movingData.previousAddress,
        futurLocation: movingData.newAddress,
        surface: movingData.surface,
        movingDate: movingData.movingDate,
      }}
      onSubmit={(values) => {
        movingData.setPreviousAddress(values.currentLocation);
        movingData.setNewAddress(values.futurLocation);
        movingData.setMovingDate(values.movingDate);
        movingData.setSurface(values.surface);
        return movingData
          .loadBrands(values.currentLocation!, values.futurLocation!)
          .then(
            () => {
              handleClickNext();
            },
            () => {
              // TODO: Add an error feedback
            },
          );
      }}
      schema={object({
        currentLocation: locationSchema()
          .label("adresse de départ")
          .required(() => ({ key: "addressFrom" })),
        futurLocation: locationSchema()
          .label("adresse d'arrivée")
          .required(() => ({ key: "addressTo" })),
        surface: number().label("surface").required(),
        movingDate: date().label("date du déménagement").required(),
      })}
      listenInitialValuesChanges
    >
      <div className={"display-group"}>
        <h3 className={"group-title"}>
          <img alt={""} className={"icon"} src={iconLocation} />
          Adresse de départ
          <div className={"section-xs"}>
            <GoogleMapsSearchbar
              className={"no-label"}
              name={"currentLocation"}
            />
          </div>
        </h3>
      </div>
      <div className={"display-group"}>
        <h3 className={"group-title"}>
          <img alt={""} className={"icon"} src={iconDestination} />
          Adresse d'arrivée
          <div className={"section-xs"}>
            <GoogleMapsSearchbar
              className={"no-label"}
              name={"futurLocation"}
            />
          </div>
        </h3>
      </div>
      <div className={"display-group"}>
        <h3 className={"group-title"}>
          <img alt={""} className={"icon"} src={iconSurface} />
          Surface (m²)
          <div className={"section-xs"}>
            <NumberField name={"surface"} />
          </div>
        </h3>
      </div>
      <div className={"display-group"}>
        <h3 className={"group-title"}>
          <img alt={""} className={"icon"} src={iconDate} />
          Date du déménagement
          <div className={"section-xs"}>
            <DateField name={"movingDate"} />
          </div>
        </h3>
      </div>
      <div className={"section center"}>
        <ValidationsErrors />
        <div className={"center"}>
          <SubmitButton className={"btn-1"}>
            Recevoir des devis personnalisés
            <img
              alt={"icône suiv"}
              className={"icon icon-right"}
              src={iconArrowRightWhite}
            />
          </SubmitButton>
        </div>
      </div>
    </Form>
  );
};

const UserDataForm = ({
  movingData,
  afterSubmit,
  toastError,
}: {
  movingData: MovingAPI;
  afterSubmit(): void;
  toastError: any;
}): JSX.Element => {
  const { user } = useAuth();

  return (
    <Form
      initialValues={{
        currentLocation: movingData.previousAddress,
        futurLocation: movingData.newAddress,
        firstname: movingData.firstname || user?.firstname,
        lastname: movingData.lastname || user?.lastname,
        email: movingData.mail || user?.email,
        phoneNumber: movingData.phoneNumber || user?.phoneNumber,
      }}
      onSubmit={(values) => {
        movingData.setFirstName(values.firstname);
        movingData.setLastName(values.lastname);
        movingData.setMail(values.email);
        movingData.setPhoneNumber(values.phoneNumber);

        return movingData
          .loadBrands(values.currentLocation!, values.futurLocation!)
          .then(
            () => {
              return movingData.createLead(values).then(
                () => afterSubmit(),
                () => toastError("compare:submit-cart.ERROR"),
              );
            },
            () => {
              // TODO: Add an error feedback
            },
          );
      }}
      schema={object({
        firstname: string().label("Prénom").nullable().required(),
        lastname: string().label("Nom").nullable().required(),
        email: string().label("E-mail").email().nullable().required(),
        phoneNumber: string()
          .label("Numéro de téléphone")
          .nullable()
          .required(),
      })}
      listenInitialValuesChanges
    >
      <div className={"section-xs"}>
        <AutoField name={"lastname"} />
      </div>
      <div className={"section-xs"}>
        <AutoField name={"firstname"} />
      </div>
      <div className={"section-xs"}>
        <AutoField name={"phoneNumber"} />
      </div>

      <div className={"section-xs"}>
        <AutoField name={"email"} />
      </div>
      <div className={"section center"}>
        <ValidationsErrors />
        <div className={"buttons-row right"}>
          <SubmitButton className={"btn-1"}>
            Valider
            <img
              alt={"icône panier"}
              className={"icon icon-right"}
              src={iconArrowRightWhite}
            />
          </SubmitButton>
        </div>
      </div>
    </Form>
  );
};

const MovingQueryDetailsForm = ({
  afterSubmit,
}: {
  afterSubmit(): void;
}): JSX.Element => {
  // Store
  const movingData = useMoving();

  const [isNextStep, setIsNextStep] = useState<boolean>(false);
  const handleClickNext = () => {
    setIsNextStep((isNextStep) => !isNextStep);
  };

  const { toastError } = useToastsWithIntl(["compare"]);

  return (
    <div className={"card-body"}>
      <div className={"card-block"}>
        <div className={"card-head"}>
          <h2 className={"card-title --center"}>
            Complétez le formulaire pour recevoir des devis
          </h2>
        </div>
        <div className={"card"}>
          <CardBody>
            {!isNextStep
              ? MovingForm({ movingData, handleClickNext })
              : UserDataForm({ movingData, afterSubmit, toastError })}
          </CardBody>
        </div>
      </div>
    </div>
  );
};

export default MovingQueryDetailsForm;
