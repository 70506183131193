import {
  ComponentType,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Location } from "../../google-maps/place";
import { usePlace } from "../../google-maps/placeProvider";
import { useQueryParam } from "../../routing/useQueryParam";
import { Home } from "../HomeInput";
import {
  getBrands as apiGetBrands,
  createLead as apiCreateLead,
  getFilteredBrands,
} from "./api";
import { Brand, mapRawBrandsToBrands } from "./moving";
import { AxiosPromise } from "axios";
import { User } from "../../auth/user";

export interface MovingAPI {
  brands: Map<string, Brand>;
  previousAddress: Location | null;
  newAddress: Location | null;
  movingDate: Date | null;
  surface: number | null;

  //provisoire
  firstname: string | null;
  lastname: string | null;
  phoneNumber: string | null;
  mail: string | null;

  setPreviousAddress(address: Location | null): void;
  setNewAddress(address: Location | null): void;
  setMovingDate(date: Date | null): void;
  setSurface(surface: number | null): void;

  //provisoire
  setFirstName(firstname: string | null): void;
  setLastName(lastname: string | null): void;
  setPhoneNumber(phoneNumber: string | null): void;
  setMail(mail: string | null): void;

  getBrands(): Promise<void>;
  createLead(
    user: Pick<User, "email" | "firstname" | "lastname" | "phoneNumber">,
  ): AxiosPromise<any>;
  loadAll(): Promise<void>;
  loadBrands(from: Location, to: Location): Promise<void>;
}

export const MovingContext = createContext<MovingAPI | null>(null);

export const ProvideMoving = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const { currentLocation } = usePlace();
  const [home] = useQueryParam("home", "number");

  const [brands, setBrands] = useState<MovingAPI["brands"]>(new Map());
  const [previousAddress, setPreviousAddress] = useState<
    MovingAPI["previousAddress"] | null
  >(null);
  const [newAddress, setNewAddress] = useState<MovingAPI["newAddress"] | null>(
    null,
  );
  const [movingDate, setMovingDate] = useState<MovingAPI["movingDate"] | null>(
    null,
  );
  const [surface, setSurface] = useState<MovingAPI["surface"] | null>(null);

  const [firstname, setFirstName] = useState<MovingAPI["firstname"] | null>(
    null,
  );

  const [lastname, setLastName] = useState<MovingAPI["lastname"] | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<
    MovingAPI["phoneNumber"] | null
  >(null);
  const [mail, setMail] = useState<MovingAPI["mail"] | null>(null);

  useEffect(() => {
    if (home === Home.Current) {
      setPreviousAddress(currentLocation);
      setNewAddress(null);
    } else if (home === Home.Future) {
      setPreviousAddress(null);
      setNewAddress(currentLocation);
    }
  }, [currentLocation, home]);

  const getBrands = useCallback(
    () =>
      apiGetBrands().then(({ data }) => setBrands(mapRawBrandsToBrands(data))),
    [],
  );
  const loadAll = useCallback(async () => {
    await Promise.all([getBrands()]);
  }, [getBrands]);

  const loadBrands: MovingAPI["loadBrands"] = useCallback((from, to) => {
    return getFilteredBrands(from, to).then(({ data }) =>
      setBrands(mapRawBrandsToBrands(data)),
    );
  }, []);

  const createLead: MovingAPI["createLead"] = useCallback(
    (values) => {
      return apiCreateLead({
        ...values,
        mail: values.email,

        previousAddress,
        newAddress,
        movingDate,
        surface,
      });
    },
    [movingDate, newAddress, previousAddress, surface],
  );

  return (
    <MovingContext.Provider
      value={{
        previousAddress,
        newAddress,
        movingDate,
        surface,
        //provsoire
        firstname,
        lastname,
        phoneNumber,
        mail,

        brands,
        setPreviousAddress,
        setNewAddress,
        setMovingDate,
        setSurface,

        //provisoire
        setFirstName,
        setLastName,
        setPhoneNumber,
        setMail,

        getBrands,
        loadAll,
        loadBrands,
        createLead,
      }}
    >
      {children}
    </MovingContext.Provider>
  );
};

export function withProvideMoving<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvideMoving(props: P) {
    return (
      <ProvideMoving>
        <WrappedComponent {...props} />
      </ProvideMoving>
    );
  }

  WithProvideMoving.displayName = `withProvideMoving(${displayName})`;

  return WithProvideMoving;
}

export function useMoving(): MovingAPI {
  return useContext(MovingContext) as MovingAPI;
}
