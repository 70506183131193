import iconTel from "../../../assets/img/icons/icon-headphone.svg";
import iconBackArrow from "../../../assets/img/icons/icon-back-arrow.svg";
import iconSecured from "../../../assets/img/icons/icon-lock.svg";
import iconExpress from "../../../assets/img/icons/icon-delivery.svg";

const CartReinsuranceBlock = (): JSX.Element => {
  return (
    <div className={"cart-reinsurance-card"}>
      <div className={"card-body"}>
        <div>
          <img alt={"service client"} className={"icon"} src={iconTel} />
          Service client à votre écoute
        </div>
        <div>
          <img alt={"service client"} className={"icon"} src={iconBackArrow} />
          14 jours pour changer d'avis
        </div>
        <div>
          <img alt={"service client"} className={"icon"} src={iconSecured} />
          Paiement sécurisé
        </div>
        <div>
          <img alt={"service client"} className={"icon"} src={iconExpress} />
          Activation express
        </div>
      </div>
    </div>
  );
};

export default CartReinsuranceBlock;
