import { typedPropertyToValueBuilder } from "../../notion/notion";
import loggerBuilder from "../../logger";
import { mapMultiply } from "../../data-structures/number";

const logger = loggerBuilder("compare/insurance");

export enum FeatureCode {
  TotalCoverageAmount = "Valeur des bien couverts",
  DeductibleAmount = "Montant de la franchise",
  ValuableCoverage = "Objets de valeur",
  TheftCoverage = "Garantie Vol & Vandalisme",
  BrokenGlassCoverage = "Garantie bris de glace",
  AssistanceCoverage = "Assistance",
  LockCoverage = "Dépannage serrurerie",
  SchoolCoverage = "Assurance scolaire",
  AsNewCoverage = "Remboursement valeur à neuf",
  BestOffer = "Coup de coeur",
  MoveCoolPartner = "Partenaires MoveCool",
}
export interface Feature<
  Code extends FeatureCode,
  Val = Code extends
    | FeatureCode.TotalCoverageAmount
    | FeatureCode.DeductibleAmount
    | FeatureCode.ValuableCoverage
    ? number
    : Code extends FeatureCode.TheftCoverage
    ? boolean
    : null
> {
  code: Code;
  value: Val;
}
function mapStringToFeature(feature: string): Feature<any> {
  return {
    code: feature as FeatureCode,
    value: null,
  };
}

export interface Brand {
  id: string;
  relevance: number;
  name: string;
  description: JSX.Element;
  logo: string;
  url: string | null;
  postalCodes: string[];
  isCustomizable: boolean;
  simulatedPrice: number;
  // NOTE: price is not a property of the notion database,
  // NOTE: but it's the price calculated using the insurances APIs
  price: number | null;
  mostPopular: boolean;
  movecoolRecommandation: boolean;
  apiId: "lovys" | "luko" | "appenin" | "acheel" | "leocare";
  highlights: string[];
  promotions: string[];
  features: Feature<any>[];
  // NOTE: calculatedFeatures is not a property of the notion database,
  // NOTE: but it's calculated using the insurances APIs
  // NOTE: and concatenated with the features property
  calculatedFeatures: Map<FeatureCode, Feature<any>>;
  totalCoverageAmount: number | null;
  deductibleAmount: number | null;
}

function mapFeaturesListToMap(
  features: Feature<any>[],
): Map<FeatureCode, Feature<any>> {
  return new Map(features.map((feature) => [feature.code, feature]));
}

export function quoteToFeatures(
  quote?: BaseQuote | null,
): Map<FeatureCode, Feature<any>> {
  const quoteFeatures: Feature<any>[] = [];
  if (quote) {
    // Boolean features
    if (quote.asNewCoverage)
      quoteFeatures.push({ code: FeatureCode.AsNewCoverage, value: null });
    if (quote.assistanceCoverage)
      quoteFeatures.push({ code: FeatureCode.AssistanceCoverage, value: null });
    if (quote.brokenGlassCoverage)
      quoteFeatures.push({
        code: FeatureCode.BrokenGlassCoverage,
        value: null,
      });
    if (quote.lockCoverage)
      quoteFeatures.push({ code: FeatureCode.LockCoverage, value: null });
    if (quote.schoolCoverage)
      quoteFeatures.push({ code: FeatureCode.SchoolCoverage, value: null });
    if (quote.theftCoverage)
      quoteFeatures.push({ code: FeatureCode.TheftCoverage, value: null });

    // Number features
    if (quote.deductibleAmount)
      quoteFeatures.push({
        code: FeatureCode.DeductibleAmount,
        value: quote.deductibleAmount,
      });
    if (quote.totalCoverageAmount)
      quoteFeatures.push({
        code: FeatureCode.TotalCoverageAmount,
        value: quote.totalCoverageAmount,
      });
    if (quote.valuableCoverage)
      quoteFeatures.push({
        code: FeatureCode.ValuableCoverage,
        value: quote.valuableCoverage,
      });
  }

  return mapFeaturesListToMap(quoteFeatures);
}

export function mapRawBrandsToBrands(rawBrands: unknown[]): Map<string, Brand> {
  return logger.withErrorCatcher(
    () =>
      new Map(
        rawBrands.map((brand: any) => {
          logger.debug(`Mapping brand: ${brand.id}`);
          const propertyToValue = typedPropertyToValueBuilder(brand.properties);

          const highlights = propertyToValue("3 Best Offer", "select");
          const promotions = propertyToValue("Promotion", "select");

          return [
            brand.id,
            {
              id: brand.id,
              relevance: propertyToValue("Pertinence", "number")!,
              name: propertyToValue("Nom", "title"),
              description: propertyToValue("Description", "rich_text").element,
              logo: propertyToValue("Logo", "files")[0]!,
              url: propertyToValue("Url", "url"),
              postalCodes: propertyToValue("Codes postaux", "multi_select"),
              isCustomizable: propertyToValue(
                "Offre personnalisable",
                "checkbox",
              ),
              simulatedPrice: mapMultiply(
                propertyToValue("Prix (estimé)", "number")!,
                12,
              ),
              mostPopular: propertyToValue("La plus populaire", "checkbox"),

              movecoolRecommandation: propertyToValue(
                "Recommandée MoveCool",
                "checkbox",
              ),
              apiId:
                (propertyToValue("api_id", "select") as Brand["apiId"]) || null,
              price: null,
              highlights: highlights ? [highlights] : [],
              promotions: promotions ? [promotions] : [],
              features: propertyToValue("Features", "multi_select").map(
                mapStringToFeature,
              ),
              calculatedFeatures: new Map(),
              totalCoverageAmount: propertyToValue(
                "Capital mobilier",
                "number",
              ),
              deductibleAmount: propertyToValue("Franchise", "number"),
            },
          ];
        }),
      ),
    "Error while parsing brands",
  );
}

export enum HabitationUsage {
  Main = 1,
  Secondary = 2,
  NonOccupantOwner = 3,
}

export enum ClientType {
  Owner,
  Renter,
}

export enum FlatLevel {
  Ground = 1,
  Middle = 2,
  Top = 3,
}

export enum HousingType {
  House,
  Flat,
}

export interface BaseQuote {
  totalCoverageAmount?: number;
  deductibleAmount?: number;
  valuableCoverage?: number;
  theftCoverage: boolean;
  brokenGlassCoverage: boolean;
  assistanceCoverage: boolean;
  lockCoverage: boolean;
  schoolCoverage: boolean;
  asNewCoverage: boolean;
  bestOffer: boolean;
  moveCoolPartner: boolean;
}

export interface LukoQuote extends BaseQuote {
  code: string;
  name: string;
  price: number;
  url: string;
}

export interface LovysQuote extends BaseQuote {
  code: string;
  name: string;
  price: number;
  url: string;
}

export interface AppeninQuote extends BaseQuote {
  code: string;
  price: number;
  url: string;
}

export interface AcheelQuote extends BaseQuote {
  code: string;
  name: string;
  price: number;
}

export interface LeocareQuote extends BaseQuote {
  code: string;
  deposit: number;
  price: number;
}
