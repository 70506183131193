import anylogger from "anylogger";
import { debugWrapperBuider } from "../data-structures/function";
import { useEffect } from "react";
import { setLevel, LogLevelDesc } from "loglevel";

const LOG_LEVEL = (localStorage.getItem("loglevel") ||
  process.env.REACT_APP_LOG_LEVEL ||
  "error") as LogLevelDesc;
if (!localStorage.getItem("loglevel")) {
  setLevel(LOG_LEVEL, true);
}

const logger = (
  name: string,
  config: Record<string, unknown> | undefined = { log: "debug" },
) => {
  const logger = anylogger(name, config);
  logger.debug = ((message?: any, ...args: any[]) => {
    if (logger.enabledFor("debug")) {
      // eslint-disable-next-line no-console
      console.debug(message, ...args);
    }
  }) as typeof logger.debug;
  logger.debug(`Logger ${name} is working!`);
  return {
    ...logger,
    withErrorCatcher<CallFn extends (...args: any) => any>(
      fn: CallFn,
      withError: string | ((err: unknown) => string),
    ): ReturnType<CallFn> {
      try {
        return fn();
      } catch (e: unknown) {
        if (typeof withError === "function") logger.error(withError(e));
        else logger.error(withError, e);

        throw e;
      }
    },
    withDebugFunction: debugWrapperBuider(logger.debug),
    WithLogValue(value: any) {
      useEffect(() => {
        logger.log(value);
      }, [value]);
    },
  };
};

export function thisLine(): string {
  const e = new Error();
  const regex = /(.*):(\d+):(\d+)$/;

  try {
    const match = regex.exec(e.stack!.split("\n")[2])!;
    const context = {
      filepath: match[1],
      line: match[2],
      column: match[3],
    };
    return `${context.filepath}:${context.line}:${context.column} `;
  } catch {
    return "";
  }
}

export const loggerBuilder = logger;

export default logger;
