import { useField } from "formik";
import { HTMLAttributes, useCallback, useEffect, useState } from "react";
import { object, number, string } from "yup";
import Form from "../../forms/Form";
import NumberField from "../../forms/NumberField";
import TextField from "../../forms/TextField";
import ValidationsErrors from "../../forms/ValidationsErrors";
import { toFixed } from "../../numbers/formatting";
import Button from "../../ui/reactive/Button";
import Input from "../../ui/reactive/Input";
import {
  getMonthlyHeatingConsumptionInKWh,
  getMonthlyWaterConsumptionInKWh,
  getMonthlyCookingConsumptionInKWh,
  getAverageMonthlyElectricalConsumption,
} from "./consumption";
import {
  ConsumptionCalculatorStep,
  useConsumption,
} from "./consumptionProvider";
import { cx } from "@emotion/css";
import { usePlace } from "../../google-maps/placeProvider";
import { GoogleMapsSearchbar } from "../HomeInput";
import SubmitButton from "src/services/forms/SubmitButton";
import CardBody from "src/services/ui/block/CardBody";
import Dialog from "src/services/ui/block/Dialog";
import Card from "src/services/ui/block/Card";

enum ConsumptionUnit {
  kWhPerYear = "kWhPerYear",
  kWhPerMonth = "kWhPerMonth",
}
const ConsumptionInput = ({
  valueName,
  unitName,
  ...props
}: {
  valueName: string;
  unitName: string;
} & HTMLAttributes<HTMLDivElement>): JSX.Element => {
  const [valueField, , valueHelper] = useField(valueName);
  const [unitField, , unitHelper] = useField(unitName);

  return (
    <div className={"inputs-row"}>
      <Input
        {...props}
        {...valueField}
        onChange={(event) =>
          valueHelper.setValue(
            event.currentTarget.value === ""
              ? null
              : parseFloat(event.currentTarget.value),
          )
        }
        step={"0.01"}
        type={"number"}
        value={
          valueField.value === null || valueField.value === undefined
            ? ""
            : valueField.value
        }
      />
      <select
        className={"select fit"}
        onChange={(event) => unitHelper.setValue(event.target.value)}
        value={unitField.value}
      >
        <option value={ConsumptionUnit.kWhPerMonth}>kWh / mois</option>
        <option value={ConsumptionUnit.kWhPerYear}>kWh / an</option>
      </select>
    </div>
  );
};

export enum EnergyType {
  Electricity = "electricity",
  Gas = "gas",
  Both = "both",
  Other = "other",
}
const EnergyTypeField = ({ name }: { name: string }): JSX.Element => {
  const [field, , helper] = useField<EnergyType>(name);

  return (
    <div className={"buttons-switch"}>
      <button
        className={cx([
          "select-btn btn-block",
          field.value === EnergyType.Electricity && "active",
        ])}
        onClick={() => helper.setValue(EnergyType.Electricity)}
        type={"button"}
      >
        Électricité
      </button>
      <Button
        className={cx([
          "select-btn btn-block",
          field.value === EnergyType.Gas && "active",
        ])}
        onClick={() => helper.setValue(EnergyType.Gas)}
        type={"button"}
      >
        Gaz
      </Button>
      <Button
        className={cx([
          "select-btn btn-block",
          field.value === EnergyType.Both && "active",
        ])}
        onClick={() => helper.setValue(EnergyType.Both)}
        type={"button"}
      >
        Électricité & Gaz
      </Button>
      <Button
        className={cx([
          "select-btn btn-block",
          field.value === EnergyType.Other && "active",
        ])}
        onClick={() => helper.setValue(EnergyType.Other)}
        type={"button"}
      >
        Autre
      </Button>
    </div>
  );
};

const ConsumptionKnownForm = ({
  initialElectricityConsumption,
  initialGasConsumption,
  onSubmit,
  submitButton,
  cancelButton,
}: {
  initialElectricityConsumption: number | null;
  initialGasConsumption: number | null;
  onSubmit(consElecPerYear: number, consGasPerYear: number): Promise<void>;
  submitButton: JSX.Element;
  cancelButton?: JSX.Element | null;
}): JSX.Element => {
  const { setLastConsumptionCalculatorStep } = useConsumption();
  useEffect(() => {
    setLastConsumptionCalculatorStep(
      ConsumptionCalculatorStep.KnownConsumption,
    );
  }, [setLastConsumptionCalculatorStep]);

  const { currentLocation, setCurrentLocation } = usePlace();

  return (
    <Form
      initialValues={{
        currentLocation,
        electricityConsumption: initialElectricityConsumption,
        electricityConsumptionUnit: ConsumptionUnit.kWhPerMonth,
        gasConsumption: initialGasConsumption,
        gasConsumptionUnit: ConsumptionUnit.kWhPerMonth,
      }}
      onSubmit={({
        electricityConsumption,
        electricityConsumptionUnit,
        gasConsumption,
        gasConsumptionUnit,
        currentLocation,
      }) => {
        setCurrentLocation(currentLocation);

        return onSubmit(
          electricityConsumptionUnit === ConsumptionUnit.kWhPerYear
            ? toFixed(electricityConsumption! / 12, 2)
            : electricityConsumption!,
          gasConsumptionUnit === ConsumptionUnit.kWhPerYear
            ? toFixed(gasConsumption! / 12, 2)
            : gasConsumption!,
        );
      }}
      schema={object({
        currentLocation: object()
          .label("Adresse postal")
          .required(() => ({ key: "address" })),
        electricityConsumption: number()
          .label("Consommation d'électricité")
          .nullable()
          .transform((value) => (value === null ? 0 : value)),
        gasConsumption: number()
          .label("Consommation de gaz")
          .nullable()
          .transform((value) => (value === null ? 0 : value)),
      })}
    >
      <div className={"section-s"}>
        <GoogleMapsSearchbar name={"currentLocation"} />
      </div>
      <div className={"section-s"}>
        <div className={"section-xs"}>
          <ConsumptionInput
            placeholder={"Votre consommation d'électricité"}
            unitName={"electricityConsumptionUnit"}
            valueName={"electricityConsumption"}
          />
        </div>

        <div className={"section-xs"}>
          <ConsumptionInput
            placeholder={"Votre consommation de gaz"}
            unitName={"gasConsumptionUnit"}
            valueName={"gasConsumption"}
          />
        </div>
      </div>

      <ValidationsErrors />

      <div className={"section-l center"}>
        {/* <button className={"btn-1"} type={"submit"}>
          Afficher les prix
          <img alt={""} className={"icon icon-right"} src={iconArrowRight} />
        </button> */}
        <div className={"buttons-row right"}>
          {cancelButton}
          {submitButton}
        </div>
      </div>
    </Form>
  );
};

const PdlForm = ({
  onSubmit,
  submitButton,
  cancelButton,
}: {
  onSubmit(consElecPerYear: number, consGasPerYear: number): Promise<void>;
  submitButton: JSX.Element;
  cancelButton: JSX.Element | null;
}): JSX.Element => {
  const { setLastConsumptionCalculatorStep } = useConsumption();
  useEffect(() => {
    setLastConsumptionCalculatorStep(
      ConsumptionCalculatorStep.EstimatedWithPdl,
    );
  }, [setLastConsumptionCalculatorStep]);

  return (
    <Form
      initialValues={{
        pdlNumber: null,
      }}
      onSubmit={(values) => {
        // TODO: Implement PDL calculation
        // eslint-disable-next-line no-console
        console.error(
          `Consumption from Pdl is not implemented yet:\n${JSON.stringify(
            values,
          )}`,
        );
        throw new Error(
          `Consumption from Pdl is not implemented yet:\n${JSON.stringify(
            values,
          )}`,
        );
        return onSubmit(0, 0);
      }}
      schema={object({
        pdlNumber: string().label("Numéro de PDL ou PCE").nullable().required(),
      })}
    >
      <label>
        Numéro de compteur
        <TextField name={"pdlNumber"} placeholder={"Numéro de PDL ou PCE"} />
      </label>

      <ValidationsErrors />

      <div className={"section-l center"}>
        {/* <button className={"btn-1"} type={"submit"}>
          Afficher les prix
          <img alt={""} className={"icon icon-right"} src={iconArrowRight} />
        </button> */}
        {cancelButton}
        {submitButton}
      </div>
    </Form>
  );
};

const ConsumptionEstimationForm = ({
  onSubmit,
  submitButton,
  cancelButton,
}: {
  onSubmit(consElecPerYear: number, consGasPerYear: number): Promise<void>;
  submitButton: JSX.Element;
  cancelButton: JSX.Element | null;
}): JSX.Element => {
  const {
    setLastConsumptionCalculatorStep,
    area,
    residentCount,
    heatingSource,
    hotWaterSource,
    cookingSource,
    setArea,
    setResidentCount,
    setHeatingSource,
    setHotWaterSource,
    setCookingSource,
  } = useConsumption();
  useEffect(() => {
    setLastConsumptionCalculatorStep(ConsumptionCalculatorStep.Calculated);
  }, [setLastConsumptionCalculatorStep]);

  const { currentLocation, setCurrentLocation } = usePlace();
  return (
    <Form
      initialValues={{
        currentLocation,
        area,
        residentCount,
        heating: heatingSource || EnergyType.Electricity,
        hotWater: hotWaterSource || EnergyType.Electricity,
        cooking: cookingSource || EnergyType.Electricity,
      }}
      onSubmit={(values) => {
        const waterConsumption = getMonthlyWaterConsumptionInKWh(
          values.residentCount!,
        );
        const heatingConsumption = getMonthlyHeatingConsumptionInKWh(
          values.area!,
        );
        const cookingConsumption = getMonthlyCookingConsumptionInKWh(
          values.residentCount!,
        );
        setCurrentLocation(values.currentLocation);
        setArea(values.area);
        setResidentCount(values.residentCount);
        setHeatingSource(values.heating);
        setHotWaterSource(values.hotWater);
        setCookingSource(values.cooking);

        let consElec = getAverageMonthlyElectricalConsumption(values.area!);
        let consGas = 0;

        if (values.heating === EnergyType.Electricity)
          consElec += heatingConsumption;
        else if (values.heating === EnergyType.Gas)
          consGas += heatingConsumption;
        else if (values.heating === EnergyType.Both) {
          consElec += heatingConsumption / 2;
          consGas += heatingConsumption / 2;
        }

        if (values.hotWater === EnergyType.Electricity)
          consElec += waterConsumption;
        else if (values.hotWater === EnergyType.Gas)
          consGas += waterConsumption;
        else if (values.heating === EnergyType.Both) {
          consElec += waterConsumption / 2;
          consGas += waterConsumption / 2;
        }

        if (values.cooking === EnergyType.Electricity)
          consElec += cookingConsumption;
        else if (values.cooking === EnergyType.Gas)
          consGas += cookingConsumption;
        else if (values.heating === EnergyType.Both) {
          consElec += cookingConsumption / 2;
          consGas += cookingConsumption / 2;
        }
        return onSubmit(consElec, consGas);
      }}
      schema={object({
        currentLocation: object()
          .label("Adresse Postale")
          .required(() => ({ key: "address" })),
        area: number().label("Surface").nullable().required(),
        residentCount: number()
          .label("Nombre d'occupants")
          .nullable()
          .required(),
      })}
    >
      <div className={"section-s"}>
        <label className={"input-label"}>
          Énergie utilisée pour le chauffage
        </label>

        <EnergyTypeField name={"heating"} />
      </div>

      <div className={"section-s"}>
        <label className={"input-label"}>
          Énergie utilisée pour l'eau chaude
        </label>

        <EnergyTypeField name={"hotWater"} />
      </div>

      <div className={"section-s"}>
        <label className={"input-label"}>
          Énergie utilisée pour la cuisson
        </label>

        <EnergyTypeField name={"cooking"} />
      </div>

      <div className={"section-s"}>
        <GoogleMapsSearchbar name={"currentLocation"} />
      </div>

      <div className={"section-s"}>
        <div className={"grid"}>
          <div className={"col-m-1-2"}>
            <label className={"input-label"}>Surface du logement en m2</label>

            <NumberField name={"area"} placeholder={"eg. 50"} />
          </div>

          <div className={"col-m-1-2"}>
            <label className={"input-label"}>Nombre d'occupants </label>

            <NumberField name={"residentCount"} placeholder={"eg. 4"} />
          </div>
        </div>
      </div>

      <ValidationsErrors />

      <div className={"section-l center"}>
        <div className={"buttons-row right"}>
          {cancelButton}
          {submitButton}
        </div>
      </div>
    </Form>
  );
};

export const ConsumptionForm = ({
  onClose,
  onSubmit,
}: {
  onClose?(): void;
  onSubmit(): Promise<void>;
}) => {
  const {
    electricityConsumption,
    gasConsumption,
    setConsumption,
    lastConsumptionCalculatorStep,
  } = useConsumption();

  const [isConsumptionKnown, setIsConsumptionKnown] = useState(
    lastConsumptionCalculatorStep ===
      ConsumptionCalculatorStep.KnownConsumption,
  );

  const isPdlKnown = false;
  const onSubmitHook = useCallback(
    (consElec: number, consGas: number) => {
      setConsumption(toFixed(consElec, 2), toFixed(consGas, 2));
      return onSubmit();
    },
    [onSubmit, setConsumption],
  );

  return (
    <div className={"card-block"}>
      <div className={"card-head"}>
        <div className={"card-title --center"}>
          Complétez le formulaire pour afficher les meilleures offres du moment
        </div>
      </div>
      <Card>
        <div className={"card-body"}>
          <div className={"card-body"}>
            {/* <Form
          initialValues={{ currentLocation }}
          onSubmit={(values) => {
            setCurrentLocation(values.currentLocation);
            setCurrentLocationQueryParams(
              values.currentLocation
                ?.plainAddress as Location["plainAddress"],
            );
            return Promise.resolve();
          }}
        >
          <AutoSubmit maxDebounce={250} />
          <div className={"section-s"}>
            <GoogleMapsSearchbar name={"currentLocation"} />
          </div>
        </Form> */}

            <div className={"section-s"}>
              <div className={"buttons-switch"}>
                <button
                  className={cx([
                    "select-btn btn-block",
                    !isConsumptionKnown && "active",
                  ])}
                  onClick={() => setIsConsumptionKnown(false)}
                  type={"button"}
                >
                  J'estime ma consommation
                </button>
                <button
                  className={cx([
                    "select-btn btn-block",
                    isConsumptionKnown && "active",
                  ])}
                  onClick={() => setIsConsumptionKnown(true)}
                  type={"button"}
                >
                  Je connais ma consommation
                </button>
              </div>
            </div>

            {/* On masque pour l'instant l'option de récupération conso PDL via API Enedis */}
            {/* {!isConsumptionKnown && (
        <div>
          <Button
            onClick={() => setIsPdlKnown(true)}
            type={"button"}
            variant={isPdlKnown ? 1 : 2}
          >
            Je connais
          </Button>
          <Button
            onClick={() => setIsPdlKnown(false)}
            type={"button"}
            variant={isPdlKnown ? 2 : 1}
          >
            Je ne connais pas
          </Button>
          mon numéro de compteur
        </div>
      )} */}

            {isConsumptionKnown ? (
              <ConsumptionKnownForm
                cancelButton={
                  onClose ? (
                    <button
                      className={"btn-outlined"}
                      onClick={onClose}
                      type={"button"}
                    >
                      Retour
                    </button>
                  ) : null
                }
                initialElectricityConsumption={electricityConsumption}
                initialGasConsumption={gasConsumption}
                onSubmit={onSubmitHook}
                submitButton={<SubmitButton>Comparer</SubmitButton>}
              />
            ) : isPdlKnown ? (
              <PdlForm
                cancelButton={
                  onClose ? (
                    <button
                      className={"btn-outlined"}
                      onClick={onClose}
                      type={"button"}
                    >
                      Retour
                    </button>
                  ) : null
                }
                onSubmit={onSubmitHook}
                submitButton={<SubmitButton>Comparer</SubmitButton>}
              />
            ) : (
              <ConsumptionEstimationForm
                cancelButton={
                  onClose ? (
                    <button
                      className={"btn-outlined"}
                      onClick={onClose}
                      type={"button"}
                    >
                      Retour
                    </button>
                  ) : null
                }
                onSubmit={onSubmitHook}
                submitButton={<SubmitButton>Comparer</SubmitButton>}
              />
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

const ConsumptionCalculatorDialog = ({
  onClose,
  onSubmit,
  skipUserSteps,
}: {
  onClose(): void;
  onSubmit(): Promise<void>;
  skipUserSteps?: boolean;
}) => {
  // TODO: temporary until Enedis API implementation
  // const [isPdlKnown, setIsPdlKnown] = useState(
  //   lastConsumptionCalculatorStep ===
  //   ConsumptionCalculatorStep.EstimatedWithPdl ||
  //   lastConsumptionCalculatorStep !== ConsumptionCalculatorStep.Calculated,
  // );

  return (
    <Dialog className={"side-panel"} closeLabel={"Retour  x"} onClose={onClose}>
      <CardBody>
        <ConsumptionForm onClose={onClose} onSubmit={onSubmit} />
      </CardBody>
    </Dialog>
  );
};

export default ConsumptionCalculatorDialog;
