import { PUBLIC } from "./public";

export const COMPARE = "/compare" as const;
export const COMPARE_LINK = `${PUBLIC}${COMPARE}` as const;

export const COMPARE_ENERGY = `/energy` as const;
export const COMPARE_ENERGY_LINK = `${COMPARE_LINK}${COMPARE_ENERGY}/` as const;

export const COMPARE_INSURANCE = `/insurance` as const;
export const COMPARE_INSURANCE_LINK = `${COMPARE_LINK}${COMPARE_INSURANCE}/` as const;

export const COMPARE_INTERNET = `/internet` as const;
export const COMPARE_INTERNET_LINK = `${COMPARE_LINK}${COMPARE_INTERNET}/` as const;

export const COMPARE_MOVING = `/moving` as const;
export const COMPARE_MOVING_LINK = `${COMPARE_LINK}${COMPARE_MOVING}/` as const;

export const COMPARE_WATER = `/water` as const;
export const COMPARE_WATER_LINK = `${COMPARE_LINK}${COMPARE_WATER}/` as const;
