import React from "react";
import { cx } from "@emotion/css";

const AppLayout = ({
  className,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>): JSX.Element => {
  return <div {...props} className={cx(className, "app-layout")} />;
};

export default AppLayout;
