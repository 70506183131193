import { useCallback, useMemo, useState } from "react";
import { usePlace } from "../../../services/google-maps/placeProvider";
import { Brand } from "../../../services/compare/water/water";
import { useWater } from "../../../services/compare/water/waterProvider";
import WaterCard from "../../../services/compare/water/WaterCard";
import { groupByField } from "../../../services/data-structures/array";
import OrderByPhone from "src/services/common-components/OrderByPhone";
import CardHead from "src/services/ui/block/CardHead";
import CardBody from "src/services/ui/block/CardBody";
import Form from "../../../services/forms/Form";
import { GoogleMapsSearchbar } from "../../../services/compare/HomeInput";
import SubmitButton from "../../../services/forms/SubmitButton";
import { withLoader } from "../../../services/routing/useLoader";

const WaterComparator = (): JSX.Element => {
  // Store
  const { brands } = useWater();
  const { currentLocation, setCurrentLocation } = usePlace();

  const { getBrands } = useWater();

  const [isLoading, setIsLoading] = useState(false);

  // Memo
  const brandsArray = useMemo(() => [...brands.values()], [brands]);
  const localBrand: Brand | null = useMemo(() => {
    if (!currentLocation?.postCode) return null;

    const localBrand = brandsArray.find((b) =>
      b.postalCodes.includes(currentLocation.postCode),
    );
    if (localBrand) return localBrand;

    return null;
  }, [brandsArray, currentLocation?.postCode]);

  const regionalBrands: Brand[] = useMemo(() => {
    if (!currentLocation?.postCode) return [];
    let regionalBrands = [...brands.values()].filter((brand) =>
      brand.postalCodes.some((postcode) =>
        postcode.length > 4
          ? postcode.slice(0, 2) === currentLocation?.postCode.slice(0, 2)
          : "0" + postcode.slice(0, 1) ===
            currentLocation?.postCode.slice(0, 2),
      ),
    );
    const x = groupByField(regionalBrands, (brand) => brand.name.trim());

    regionalBrands = [...Object.values(x)].flatMap((brands) => brands[0]);

    return regionalBrands;
  }, [brands, currentLocation?.postCode]);

  return (
    <>
      <div className={"comparator-list"}>
        <div className={"grid"}>
          <div className={"comparator-sidebar"}>
            <div className={"card-block"}>
              <CardHead title={"Mon adresse"} />
              <div className={"card"}>
                <CardBody>
                  <Form
                    initialValues={{
                      address: currentLocation,
                    }}
                    onSubmit={(values) => {
                      setIsLoading(true);
                      return getBrands().then(
                        () => {
                          setCurrentLocation(values.address);
                          setIsLoading(false);
                        },
                        () => {
                          // TODO: Add an error feedback
                        },
                      );
                    }}
                  >
                    <GoogleMapsSearchbar name={"address"} />

                    <div className={"section"}>
                      <SubmitButton className={"btn btn-block"}>
                        Trouver mon fournisseur
                      </SubmitButton>
                    </div>
                  </Form>
                </CardBody>
              </div>
            </div>

            <div className={"section"}>
              <OrderByPhone title={"Besoin d'aide ?"} />
            </div>
          </div>
          <div className={"comparator-offers"}>
            <div className={"list-head"} />
            {currentLocation && (
              <div className={"card-block m-bottom"}>
                <div className={"card-head"}>
                  <h2 className={"card-title"}>Votre distributeur d'eau</h2>
                </div>
                <div className={"card"}>
                  <div className={"card-body"}>
                    {localBrand ? (
                      <>
                        <WaterCard brand={localBrand} />
                      </>
                    ) : regionalBrands.length > 0 ? (
                      <>
                        <div className={"section"}>
                          <h2 className={"section-title center"}>
                            Voici la liste des fournisseurs de votre
                            département.
                          </h2>
                        </div>
                        {/* 12 bis Rue Saint-Laud, 49100 Angers, France */}
                        <div>
                          {regionalBrands.map((brand) => (
                            <WaterCard
                              key={brand.id}
                              brand={brand}
                              className={"reduced"}
                            />
                          ))}
                        </div>
                      </>
                    ) : (
                      <p>Aucun fournisseur d'eau trouvé pour cette addresse</p>
                    )}
                    <div className={"section"}>
                      <p className={"body-s center"}>
                        Il est recommandé de solliciter un nouvel abonnement
                        d’eau avec 15 jours d’avance.
                        <br />
                        Vous pouvez aussi appeler Movecool au 09 80 51 51
                        10 (numéro gratuit). Un conseiller vous accompagne
                        pendant votre déménagement pour tous les transferts de
                        contrats : électricité, eau, internet et assurance.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className={"card-block"}>
              <div className={"card-head"}>
                <h1 className={"card-title"}>
                  COMMENT METTRE EN ROUTE MON COMPTEUR D’EAU
                </h1>
              </div>
              <div className={"card"}>
                <div className={"card-body"}>
                  <p>
                    Pour souscrire un abonnement d’eau, il faut trouver le
                    distributeur de sa commune, puis mettre en place un contrat
                    par téléphone ou en ligne.
                  </p>

                  <p>
                    <strong>
                      Cette démarche est essentielle pour éviter toute coupure
                      après le départ de l’ancien occupant
                    </strong>
                    <br />
                    Pour cela, quelques informations sont à fournir au
                    distributeur, dont :
                  </p>
                  <div className={"section"}>
                    <p>
                      💧 L’adresse exacte du logement, accompagnée des
                      compléments d’adresse
                    </p>
                    <p>💧 Le relevé des index de consommation</p>
                    <p>💧 Facultatif : le numéro du compteur d’eau</p>
                    <p>💧 Facultatif : le nom de l’ancien occupant</p>
                  </div>

                  <p>
                    Si l’arrivée d’eau est coupée, un technicien dépêché par le
                    distributeur doit intervenir pour le remettre en service.
                    C’est pourquoi il est recommandé d’anticiper cette démarche
                    15 jours à l’avance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && (
        <div className={"loading-display --water"}>
          <div>
            <div className={"spinner"}></div>
            <div className={"messages"}>
              <p>Actualisation de la liste de fournisseurs</p>
              <p>Mise à jour des informations</p>
              <p>Récupération des zones exploitées par les fournisseurs</p>
              <p>Identification du fournisseur pour votre localité</p>
              <p>Affichage du résultat dans quelques instants</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const useLoad = () => {
  const { brands, loadAll } = useWater();
  const { currentLocation } = usePlace();

  return useCallback(() => {
    if (currentLocation && [...brands.values()].length === 0) {
      return loadAll();
    } else {
      return Promise.resolve();
    }
  }, [brands, currentLocation, loadAll]);
};

export default withLoader(WaterComparator, useLoad);
