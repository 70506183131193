import {
  ComponentType,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { getBrands as apiGetBrands, getOffers as apiGetOffers } from "./api";
import {
  Brand,
  Dual,
  mapRawBrandsToBrands,
  mapRawDualsToDuals,
  mapRawOffersToOffers,
  Offer,
} from "./energy";

export interface EnergyAPI {
  offers: Map<string, Offer>;
  duals: Map<string, Dual>;
  brands: Map<string, Brand>;
  eldsLocations: Brand["postalCodes"];

  getOffers(): Promise<void>;
  getBrands(): Promise<void>;
  loadAll(): Promise<void>;
}

export const EnergyContext = createContext<EnergyAPI | null>(null);

export const ProvideEnergy = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [offers, setOffers] = useState<EnergyAPI["offers"]>(new Map());
  const [duals, setDuals] = useState<EnergyAPI["duals"]>(new Map());
  const [brands, setBrands] = useState<EnergyAPI["brands"]>(new Map());

  const eldsLocations = useMemo(
    () => [...brands.values()].flatMap(({ postalCodes }) => postalCodes),
    [brands],
  );

  const getOffers = useCallback(
    () =>
      apiGetOffers().then(({ data }) => {
        setOffers(mapRawOffersToOffers(data.offers));
        setDuals(mapRawDualsToDuals(data.duals));
      }),
    [],
  );
  const getBrands = useCallback(
    () =>
      apiGetBrands().then(({ data }) => setBrands(mapRawBrandsToBrands(data))),
    [],
  );
  const loadAll = useCallback(async () => {
    await Promise.all([getOffers(), getBrands()]);
  }, [getOffers, getBrands]);

  return (
    <EnergyContext.Provider
      value={{
        offers,
        brands,
        duals,
        eldsLocations,
        getOffers,
        getBrands,
        loadAll,
      }}
    >
      {children}
    </EnergyContext.Provider>
  );
};

export function withProvideEnergy<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvideEnergy(props: P) {
    return (
      <ProvideEnergy>
        <WrappedComponent {...props} />
      </ProvideEnergy>
    );
  }

  WithProvideEnergy.displayName = `withProvideEnergy(${displayName})`;

  return WithProvideEnergy;
}

export function useEnergy(): EnergyAPI {
  return useContext(EnergyContext) as EnergyAPI;
}
