import { cx } from "@emotion/css";
import { useField } from "formik";
import { DetailedHTMLProps, HTMLAttributes, useCallback } from "react";
import { useYupField } from "./Form";

interface Props
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  name: string;
  label?: string;
}

const ToggleField = ({
  name,
  label,
  className,
  ...props
}: Props): JSX.Element | null => {
  let fieldLabel = label;

  const [field, , helpers] = useField<boolean>(name);
  const yField = useYupField(name);

  if (yField?.label && !label) fieldLabel = yField.spec.label;

  const toggle = useCallback(() => helpers.setValue(!field.value), [
    field.value,
    helpers,
  ]);

  return yField?.meta()?.notVisible ? null : (
    <button
      {...props}
      className={cx("btn-toggle", field.value && "active", className)}
      onClick={toggle}
      type={"button"}
    >
      {fieldLabel}
    </button>
  );
};

export default ToggleField;
