import React, { useCallback } from "react";
import Login from "./public/Login";
import {
  LOGIN,
  LOST_PASSWORD,
  REGISTER,
  RESET_PASSWORD,
} from "../routes/public";
import Register from "./public/Register";
import LostPassword from "./public/LostPassword";
import ResetPassword from "./public/ResetPassword";
import Routes from "src/services/routing/Routes";
import { Route, Outlet, useNavigate, Navigate } from "react-router-dom";
import SiteLayout from "../services/ui/layouts/SiteLayout";
import LayoutContent from "../services/ui/layouts/LayoutContent";
import Compare from "./public/compare/Compare";
import { COMPARE, COMPARE_INSURANCE_LINK } from "../routes/compare";
import { withLoader } from "../services/routing/useLoader";
import { usePlace } from "../services/google-maps/placeProvider";
import useQueryParams from "../services/routing/useQueryParams";
import CommonHeader from "../services/auth/components/CommonHeader";
import { useAuth } from "../services/auth/apiProvider";
import AuthDialog, {
  AuthDialogState,
} from "../services/auth/components/AuthDialog";
import { useQueryParam } from "../services/routing/useQueryParam";
import Cart from "./public/Cart";
import { CART } from "../routes/cart";

const Public = (): JSX.Element => {
  const [authRedirectTo, setAuthRedirectTo] = useQueryParam(
    "authRedirectTo",
    "string",
  );
  const navigate = useNavigate();
  const { showAuthDialog, setShowAuthDialog } = useAuth();

  return (
    <SiteLayout>
      <CommonHeader />

      <div className={"layout-content"}>
        <Routes>
          {/* XXX: temporarily remove landing page on index => go to insurance comparator */}
          {/* <Route element={<LandingPage />} index /> */}
          <Route element={<Navigate to={COMPARE_INSURANCE_LINK} />} index />
          <Route element={<Compare />} path={`${COMPARE}/*`} />
          <Route element={<Cart />} path={`${CART}/*`} />
          <Route
            element={
              <LayoutContent className={"auth-layout-content"}>
                <Outlet />
              </LayoutContent>
            }
          >
            <Route element={<Login />} path={LOGIN} />
            <Route element={<Register />} path={REGISTER} />
            <Route element={<LostPassword />} path={LOST_PASSWORD} />
            <Route element={<ResetPassword />} path={RESET_PASSWORD} />
          </Route>
        </Routes>
      </div>

      <div className={"footer"}>
        <nav>
          <a
            href={
              "https://movecool.notion.site/Mentions-L-gales-Conditions-G-n-rales-d-utilisation-7de1bc001712449fb83908486d769537"
            }
            rel={"noreferrer"}
            target={"_blank"}
          >
            Conditions générales d'utilisation
          </a>
          <a
            href={
              "https://movecool.notion.site/Politique-de-confidentialit-gestion-des-cookies-48624257b4754a498d8727d8efd135d5"
            }
            rel={"noreferrer"}
            target={"_blank"}
          >
            Politique de protection des données
          </a>
          <a
            href={
              "https://movecool.notion.site/Mentions-L-gales-Comparateur-c856fe03c3fe4ea0a0251e44b3e71e44"
            }
            rel={"noreferrer"}
            target={"_blank"}
          >
            Mentions comparateur
          </a>
        </nav>
      </div>

      {showAuthDialog && (
        <AuthDialog
          defaultState={
            typeof showAuthDialog === "boolean"
              ? AuthDialogState.Login
              : showAuthDialog[0]
          }
          defaultUser={
            typeof showAuthDialog === "boolean" ? null : showAuthDialog[1]
          }
          onAuthSuccess={() => {
            if (authRedirectTo) {
              navigate(authRedirectTo, { replace: true });
            }
          }}
          onClose={() => {
            setAuthRedirectTo(null);
            setShowAuthDialog(false);
          }}
        />
      )}
    </SiteLayout>
  );
};

const useLoad = () => {
  const [queryParams] = useQueryParams();
  const { loadLocationFromAddress } = usePlace();

  return useCallback(
    () =>
      typeof queryParams.address === "string"
        ? loadLocationFromAddress(queryParams.address)
        : Promise.resolve(),
    // NOTE: We don't want to reload when we update the queryParams.address so we can safely remove it here
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadLocationFromAddress],
  );
};

export default withLoader(Public, useLoad);
