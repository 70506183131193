import { FunctionComponent, useEffect, useState } from "react";

interface Props {
  debounce?: boolean;
}

const Loading: FunctionComponent<Props> = ({
  debounce = true,
}: Props): JSX.Element | null => {
  const [showLoading, setShowLoading] = useState(!debounce);
  useEffect(() => {
    if (debounce) {
      const handler = setTimeout(() => setShowLoading(true), 200);

      return () => {
        clearTimeout(handler);
      };
    }
  });

  if (!showLoading) return null;
  return (
    <div className={"loading-message"}>
      <div className={"spinner"} />
      <div>Actualisation des offres en cours</div>
    </div>
  );
};

export default Loading;
