import iconStep1 from "../../../assets/img/icons/icon-step-1.png";
import iconStep2 from "../../../assets/img/icons/icon-step-2.png";
import iconStep3 from "../../../assets/img/icons/icon-step-3.png";

const NextSteps = (): JSX.Element => {
  return (
    <div className={"grid checkout-next-steps"}>
      <div className={"col-s-1-3"}>
        <div className={"card"}>
          <div className={"card-head"}>
            <img alt={"étape 1"} className={"icon"} src={iconStep1} />
            <h3 className={"card-title"}>Vérification</h3>
          </div>
          <div className={"card-body"}>
            Vérification de votre dossier par un conseiller Movecool sous 24h.
            S'il manque des informations, on vous rappelle.
          </div>
        </div>
      </div>
      <div className={"col-s-1-3"}>
        <div className={"card"}>
          <div className={"card-head"}>
            <img alt={"étape 1"} className={"icon"} src={iconStep2} />
            <h3 className={"card-title"}>Réception</h3>
          </div>
          <div className={"card-body"}>
            Vous recevez le contrat de la marque directement dans votre boite
            mail.
          </div>
        </div>
      </div>
      <div className={"col-s-1-3"}>
        <div className={"card"}>
          <div className={"card-head"}>
            <img alt={"étape 1"} className={"icon"} src={iconStep3} />
            <h3 className={"card-title"}>Activation</h3>
          </div>
          <div className={"card-body"}>
            Votre contrat est activé à la date souhaitée. Vous profitez de vos
            économies. Et voilà !
          </div>
        </div>
      </div>
    </div>
  );
};

export default NextSteps;
