import ImgStep1 from "../../../assets/img/step1.png";
import ImgStep2 from "../../../assets/img/step2.png";
import ImgStep3 from "../../../assets/img/step3.png";

const HowItWorksInternet = (): JSX.Element => {
  return (
    <div className={"how-it-works"}>
      <h2 className={"title"}>Comment ça marche ?</h2>
      <div className={"steps"}>
        <div className={"step"}>
          <img alt={"1"} src={ImgStep1} />
          <p>
            Saisissez <strong>votre adresse</strong>
          </p>
        </div>
        <div className={"step"}>
          <img alt={"2"} src={ImgStep2} />
          <p>
            Movecool identifie les{" "}
            <strong>3 meilleures offres du moment</strong>
          </p>
        </div>
        <div className={"step"}>
          <img alt={"3"} src={ImgStep3} />
          <p>
            Vous faites votre choix <strong>en ligne ou par téléphone</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowItWorksInternet;
