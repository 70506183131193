import { cx } from "@emotion/css";
import { HTMLAttributes, useContext, useMemo, useState } from "react";
import EnergyPriceDisplay from "../../compare/energy/PriceDisplay";
import InsurancePriceDisplay from "../../compare/insurance/PriceDisplay";
import iconTel from "../../../assets/img/icons/icon-tel-green.svg";
import iconDocs from "../../../assets/img/icons/icon-documents-blue.svg";
import ReactTooltip from "react-tooltip";
import IconList from "../../compare/energy/IconList";
import iconCtaBtnArrow from "../../../assets/img/icons/icon-arrow-circle-right-white.svg";
import iconNetwork from "../../../assets/img/icons/icon-network-blue.svg";
import iconTrash from "../../../assets/img/icons/icon-remove.svg";
import iconLocation from "../../../assets/img/icons/icon-location-blue.svg";
import iconDestination from "../../../assets/img/icons/icon-destination-blue.svg";
import iconMovingTruck from "../../../assets/img/icons/icon-moving-truck.svg";
import { InternetOfferType } from "src/services/compare/internet/internet";
import InternetFeaturesList from "src/services/compare/internet/FeaturesList";
import { Cart } from "../../compare/cart/cart";
import SubmitButton from "../../forms/SubmitButton";
import InsuranceFeaturesList from "src/services/compare/insurance/InsuranceFeaturesList";
import { PricePer } from "../../compare/common";
import PersonalDetailsDialog from "../../compare/insurance/PersonalDetailsDialog";
import { getAcheelUrl } from "../../compare/insurance/api";
import { HousingContext } from "../../compare/insurance/housingProvider";
import { promiseThenOpenUrl } from "../../data-structures/promise";
import iconDocumentsBlue from "../../../assets/img/icons/icon-documents-blue.svg";
import { toFixed } from "../../numbers/formatting";
import { EnergyType } from "../../compare/energy/energy";
import { useConsumption } from "src/services/compare/energy/consumptionProvider";

const ComparatorListItem = <
  P extends HTMLAttributes<HTMLDivElement> & {
    offer?: any;
    brand?: any;
    cart?: any;
    dataCompleteCondition?: boolean;
    // dataCompleteCondition : condition to be met to display call to actions when all needed data has been entered by the user
    viewMore?: () => void;
    onEstimate?: () => void;
    onChoose?: () => void;
    onChooseOffer?: () => void;
    cartIcon?: string;
    onAddToCart?: (ev: any) => Promise<void>;
    iconCalling?: string;
    onDelete?: (cartId: Cart<any>["id"]) => void;
    calendlyContext: string;
    pricePer?: PricePer;
    togglePricePer?: () => void;
  }
>({
  offer,
  brand,
  cart,
  dataCompleteCondition,
  className,
  viewMore,
  onEstimate,
  onChoose,
  onChooseOffer,
  cartIcon,
  onAddToCart,
  iconCalling = iconTel,
  onDelete,
  calendlyContext,
  pricePer = PricePer.Month,
  togglePricePer = () => {
    /* do nothing */
  },
  ...props
}: P): JSX.Element => {
  const housingContext = useContext(HousingContext);
  const [
    showPersonalDetailsRequired,
    setShowPersonalDetailsRequired,
  ] = useState<string | null>(null);

  const { priceFormat } = useConsumption();

  const consumptionEstimate = useMemo(() => {
    if (!cart || !offer) return null;
    if (priceFormat === PricePer.Month)
      return offer.type === EnergyType.Electricity
        ? toFixed(cart.data.electricityConsumption, 2)
        : toFixed(cart.data.gasConsumption, 2);
    return offer.type === EnergyType.Electricity
      ? cart.data.electricityConsumption * 12
      : cart.data.gasConsumption * 12;
  }, [cart, offer, priceFormat]);
  const energyConsumption = useMemo(() => {
    if (!consumptionEstimate || !offer) return null;
    return toFixed((consumptionEstimate * offer.price) / 100, 2);
  }, [offer, consumptionEstimate]);

  const [deleting, setDeleting] = useState(false);

  return (
    <>
      <div
        className={cx(["card", className, deleting && "deleting"])}
        {...props}
      >
        <div className={"card-body offer-list-item"}>
          <div className={"col-logo"}>
            <div>
              {brand ? (
                <img alt={`Logo ${brand.name}`} src={brand.logo} />
              ) : (
                cart &&
                (cart.data.previousAddress || cart.data.newAddress) && (
                  <img alt={"Camion de déménagement"} src={iconMovingTruck} />
                )
              )}
            </div>
          </div>

          {cart &&
            (cart.data.previousAddress || cart.data.newAddress) &&
            !brand && (
              <div className={"col-infos"}>
                <h2 className={"offer-name"}>Déménagement</h2>

                <div className={"section-s"}>
                  <div className={"body-s"}>
                    <img
                      alt={"icône adresse"}
                      className={"icon-s"}
                      src={iconLocation}
                    />{" "}
                    {cart.data.previousAddress === null
                      ? "Adresse de départ non renseignée"
                      : cart.data.previousAddress && (
                          <span>{cart.data.previousAddress.plainAddress}</span>
                        )}
                  </div>
                  <div className={"body-s"}>
                    <img
                      alt={"icône adresse"}
                      className={"icon-s"}
                      src={iconDestination}
                    />{" "}
                    {cart.data.newAddress === null
                      ? "adresse d'arrivée non renseignée"
                      : cart.data.newAddress && (
                          <span>{cart.data.newAddress.plainAddress}</span>
                        )}
                  </div>
                </div>
              </div>
            )}

          {(offer || brand) && (
            <div className={"col-infos"}>
              <h2 className={"offer-name"}>
                {offer ? offer.name : brand.name}
              </h2>
              {offer?.bandwidth && (
                <>
                  <p className={"body-s"}>
                    <img
                      alt={"icône internet"}
                      className={"icon-s"}
                      src={iconNetwork}
                    />{" "}
                    {offer.type === InternetOfferType.Fiber
                      ? "Fibre Optique"
                      : offer.type.toString().toUpperCase()}
                  </p>
                  <div className={"block-price"}>
                    <p className={"computed-price"}>
                      {offer.price.toFixed(2)}&nbsp;€{" "}
                      <span className={"price-unit"}>/ mois</span>
                    </p>
                    {!!offer?.commitmentDuration && offer?.commitmentEndPrice && (
                      <div>
                        <p className={"price-component"}>
                          Pendant {offer.commitmentDuration} mois puis{" "}
                          {offer.commitmentEndPrice}&nbsp;€ / mois
                        </p>
                      </div>
                    )}
                    {offer?.commitmentDuration === 0 ||
                      (!offer?.commitmentDuration && (
                        <div>
                          <p className={"price-component"}>Sans engagement</p>
                        </div>
                      ))}
                  </div>
                </>
              )}
              {offer && (offer.subscription || offer.electricityOffer) && (
                <EnergyPriceDisplay
                  electricityConsumptionOverride={
                    cart?.data.electricityConsumption
                  }
                  gasConsumptionOverride={cart?.data.gasConsumption}
                  offer={offer}
                />
              )}
              {/* TEMP : Energy Features list*/}
              {!cart && offer && !offer.bandwidth && <IconList offer={offer} />}
              {!!cart && offer && !offer.bandwidth && (
                <div className={"section-xs"}>
                  <table className={"price-components-table"}>
                    <tbody>
                      <tr>
                        <td>Abonnement</td>
                        <td>
                          {priceFormat === PricePer.Month
                            ? toFixed(offer.subscription, 2)
                            : toFixed(offer.subscription * 12, 2)}
                          &nbsp;€ /{" "}
                          {priceFormat === PricePer.Month ? "mois" : "an"}
                        </td>
                      </tr>
                      <tr>
                        <td>Consommation</td>
                        <td>
                          {energyConsumption}&nbsp;€ /{" "}
                          {priceFormat === PricePer.Month ? "mois" : "an"}
                        </td>
                      </tr>
                      <tr className={"light"}>
                        <td>Estimation de consommation</td>
                        <td>
                          {consumptionEstimate}&nbsp;kWh /{" "}
                          {priceFormat === PricePer.Month ? "mois" : "an"}
                        </td>
                      </tr>
                      <tr className={"light"}>
                        <td>Prix du kWh TTC</td>
                        <td>{toFixed(offer.price / 100, 4)}&nbsp;€ / kWh</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}

              {/* TEMP : Internet features list => TODO refacto */}
              {offer?.bandwidth && !cart && (
                <>
                  <InternetFeaturesList offer={offer} />
                </>
              )}

              {brand && brand.apiId !== undefined && (
                <InsuranceFeaturesList brand={brand} />
              )}

              {!offer && brand && brand.simulatedPrice && (
                <>
                  {dataCompleteCondition && (
                    <InsurancePriceDisplay
                      brand={
                        brand // temporary condition for insurance data
                      }
                      priceFormat={pricePer}
                      togglePriceFormat={togglePricePer}
                    />
                  )}
                </>
              )}
              {cart && (cart.data.previousAddress || cart.data.newAddress) && (
                <div className={"section-s"}>
                  <div className={"body-s"}>
                    <img
                      alt={"icône adresse"}
                      className={"icon-s"}
                      src={iconLocation}
                    />{" "}
                    {cart.data.previousAddress === null
                      ? "Adresse de départ non renseignée"
                      : cart.data.previousAddress && (
                          <span>{cart.data.previousAddress.plainAddress}</span>
                        )}
                  </div>
                  <div className={"body-s"}>
                    <img
                      alt={"icône adresse"}
                      className={"icon-s"}
                      src={iconDestination}
                    />{" "}
                    {cart.data.newAddress === null
                      ? "adresse d'arrivée non renseignée"
                      : cart.data.newAddress && (
                          <span>{cart.data.newAddress.plainAddress}</span>
                        )}
                  </div>
                </div>
              )}
            </div>
          )}

          {!cart && (
            <div className={"col-actions"}>
              {offer?.withdrawn ? (
                <div className={"info info-withdrawn"}>
                  <h6 className={"info-title"}>Offre retirée du marché</h6>
                  En raison des conditions du marché, le fournisseur n'accepte
                  plus de nouveau client pour cette offre.
                </div>
              ) : (
                <>
                  {
                    dataCompleteCondition && (
                      <>
                        {!offer && brand && (
                          <button
                            className={"btn-branded"}
                            onClick={(e) => {
                              onChooseOffer && onChooseOffer();
                            }}
                          >
                            <img
                              alt={"icône documents"}
                              className={"icon icon-left"}
                              src={iconCtaBtnArrow}
                            />
                            Choisir
                          </button>
                        )}

                        {onChoose && (
                          <SubmitButton
                            className={"btn-branded"}
                            onClick={onChoose}
                          >
                            <img
                              alt={"icône panier"}
                              className={"icon icon-left"}
                              src={iconCtaBtnArrow}
                            />
                            Choisir
                          </SubmitButton>
                        )}
                        {/* {!offer?.bandwidth &&
                          onAddToCart &&
                          (offer || (brand?.apiId && brand?.price)) && (
                            <>
                              <SubmitButton
                                className={"btn-branded-outlined"}
                                onClick={(ev) => onAddToCart(ev)}
                              >
                                <img
                                  alt={"icône panier"}
                                  className={"icon icon-left"}
                                  src={cartIcon || iconCart}
                                />
                                Ajouter au panier
                              </SubmitButton>
                            </>
                          )} */}

                        {/* 
                  TODO : ADD TO CART FOR INSURANCE
                  
                      onClick={(ev) => {
                        ev.stopPropagation();
                        addToCart({
                          type: CartType.Insurance,
                          data: {
                            brandId: brand.id,
                            address: currentLocation!,
                            price: brand.price,
                            home: (queryParams.home as number) || null,
                            situation: (queryParams.situation as number) || null,
                          },
                      }); */}
                      </>
                    )
                    // : (
                    //   <button className={"btn-cta-estimate"} onClick={onEstimate}>
                    //     {offer?.bandwidth
                    //       ? "Tester mon éligibilité"
                    //       : "Estimer ma mensualité"}
                    //     <img
                    //       alt={"estimer"}
                    //       className={"icon icon-right"}
                    //       src={iconCtaBtnArrow}
                    //     />
                    //   </button>
                    // )
                  }

                  {offer?.bandwidth && ( // Internet
                    <>
                      <button className={"btn-branded"} onClick={onChooseOffer}>
                        <img
                          alt={"choisir"}
                          className={"icon icon-left"}
                          src={iconCtaBtnArrow}
                        />
                        Choisir
                      </button>

                      {/* {onAddToCart && offer && (
                        <>
                          <SubmitButton
                            className={"btn-branded-outlined"}
                            onClick={(ev) => onAddToCart(ev)}
                          >
                            <img
                              alt={"icône panier"}
                              className={"icon icon-left"}
                              src={cartIcon || iconCart}
                            />
                            Ajouter au panier
                          </SubmitButton>
                        </>
                      )} */}
                    </>
                  )}
                </>
              )}

              <button className={"btn-link"} onClick={viewMore}>
                <img
                  alt={"icône documents"}
                  className={"icon icon-left"}
                  src={iconDocs}
                />
                En savoir plus
              </button>
              {/* {offer?.url && (
                <a
                  className={"btn-link"}
                  href={offer.url}
                  rel={"noreferrer"}
                  target={"_blank"}
                >
                  <img
                    alt={"icône lien"}
                    className={"icon icon-left"}
                    src={iconLink}
                  />
                  Site Web
                </a>
              )} */}
            </div>
          )}

          {cart && (
            <div className={"col-actions"}>
              {viewMore && (
                <button className={"btn-link"} onClick={viewMore}>
                  <img
                    alt={"icône documents"}
                    className={"icon icon-left"}
                    src={iconDocs}
                  />
                  En savoir plus
                </button>
              )}
              {offer?.pricingDoc && (
                <a
                  className={"btn-link btn-s"}
                  href={offer.pricingDoc}
                  rel={"noreferrer"}
                  target={"_blank"}
                  download
                >
                  <img
                    alt={"info"}
                    className={"icon icon-left"}
                    src={iconDocumentsBlue}
                  />
                  Grille tarifaire de l'offre
                </a>
              )}
              {offer?.electricityOffer?.pricingDoc && (
                <a
                  className={"btn-link btn-s"}
                  href={offer.electricityOffer.pricingDoc}
                  rel={"noreferrer"}
                  target={"_blank"}
                  download
                >
                  <img
                    alt={"info"}
                    className={"icon icon-left"}
                    src={iconDocumentsBlue}
                  />
                  Grille tarifaire de l'offre d'électricité
                </a>
              )}
              {offer?.gasOffer?.pricingDoc && (
                <a
                  className={"btn-link btn-s"}
                  href={offer.gasOffer.pricingDoc}
                  rel={"noreferrer"}
                  target={"_blank"}
                  download
                >
                  <img
                    alt={"info"}
                    className={"icon icon-left"}
                    src={iconDocumentsBlue}
                  />
                  Grille tarifaire de l'offre de gaz
                </a>
              )}
              {onDelete && (
                <SubmitButton
                  className={"cart-delete-btn"}
                  onClick={() => {
                    setDeleting(true);
                    onDelete(cart.id);
                  }}
                  type={"button"}
                >
                  <img
                    alt={"icône suppression"}
                    className={"icon"}
                    src={iconTrash}
                  />
                </SubmitButton>
              )}
            </div>
          )}
        </div>
        <ReactTooltip class={"tooltip"} html={true} />
      </div>
      {/* {cart && (
        <div className={"card-add-info"}>
          {cart.data.address &&
            `${cart.data.address.cleanedAddress}, ${cart.data.address.city}`}
          {cart.data.housingType &&
            ` - ${
              cart.data.housingType === HousingType.Flat ? "appt" : "maison"
            }`}
          {cart.data.roomNumber && ` - ${cart.data.roomNumber}p`}
          {(cart.data.area || cart.data.size) &&
            ` - ${cart.data.area || cart.data.size}m²`}
          {cart.data.residentCount && ` - ${cart.data.residentCount} pers.`}
          {cart.data.electricityConsumption &&
            cart.data.electricityConsumption !== 0 && (
              <span>
                {" "}
                - Électricité : {cart.data.electricityConsumption} kWh/an
              </span>
            )}
          {cart.data.gasConsumption && cart.data.gasConsumption !== 0 && (
            <span> - Gaz : {cart.data.gasConsumption} kWh/an</span>
          )}
        </div>
      )} */}

      {showPersonalDetailsRequired && (
        <PersonalDetailsDialog
          onClose={() => setShowPersonalDetailsRequired(null)}
          onSubmit={(infos) => {
            if (showPersonalDetailsRequired === "acheel") {
              return promiseThenOpenUrl(() =>
                getAcheelUrl(housingContext!.acheelEstimate!.code, infos).then(
                  ({ data }) => data.url,
                ),
              ).then(() => setShowPersonalDetailsRequired(null));
            } else {
              return Promise.resolve();
            }
          }}
        />
      )}
    </>
  );
};

export default ComparatorListItem;
