import { HTMLAttributes } from "react";

import CardBody from "../../ui/block/CardBody";
import CardHead from "../../ui/block/CardHead";

import { OfferWithBrand, InternetOfferType } from "./internet";
import iconFiber from "../../../assets/img/icons/icon-wifi-blue.svg";

import iconDocumentsBlue from "../../../assets/img/icons/icon-documents-blue.svg";
import FeaturesList from "./FeaturesList";

import iconCtaBtnArrow from "../../../assets/img/icons/icon-arrow-circle-right-white.svg";

const InternetHighlightedCard = <
  P extends HTMLAttributes<HTMLDivElement> & {
    label: string;
    offer: OfferWithBrand;
    dataCompleteCondition?: boolean;
    viewMore?: () => void;
    onEstimate?: () => void;
    onChooseOffer: () => void;
  }
>({
  label,
  offer,
  dataCompleteCondition,
  viewMore,
  onEstimate,
  onChooseOffer,
  ...props
}: P): JSX.Element => {
  return (
    <div className={"card-block"}>
      <CardHead title={label} />
      <div className={"offer-promo"}>
        {(offer.promotions.length || 0) > 0 && (
          <span>{offer?.promotions[0]}</span>
        )}
      </div>
      <div className={"card lcol"}>
        <CardBody>
          <div className={"offer-header"}>
            <div className={"brand-logo"}>
              <img alt={"logo marque"} src={offer.brand.logo} />
            </div>
          </div>
          <div className={"offer-name"}>{offer.name}</div>
          <div className={"internet-techno"}>
            <img alt={"fibre"} className={"icon"} src={iconFiber} />
            {offer.type === InternetOfferType.Fiber ? (
              <span>Fibre</span>
            ) : (
              <span>ADSL</span>
            )}
          </div>
          <div className={"block-price no-margin-bottom"}>
            <p className={"computed-price"}>
              {offer.price.toFixed(2)}€{" "}
              <span className={"price-unit"}>/ mois</span>
            </p>

            {!!offer.commitmentDuration && (
              <div className={"price-component"}>
                Pendant {offer.commitmentDuration} mois
                {offer.commitmentEndPrice && (
                  <span> puis {offer.commitmentEndPrice}€ / mois</span>
                )}
              </div>
            )}

            {!offer.commitmentDuration && (
              <div className={"price-component"}>
                <span>Sans engagement</span>
              </div>
            )}
          </div>
          <div className={"offer-body"}>
            <FeaturesList offer={offer} />
          </div>
        </CardBody>
        <div className={"card-footer"}>
          <button className={"btn-branded"} onClick={onChooseOffer}>
            <img
              alt={"choisir"}
              className={"icon icon-left"}
              src={iconCtaBtnArrow}
            />
            Choisir
          </button>

          {/* <SubmitButton
            className={"btn-branded-outlined"}
            onClick={(ev) => {
              ev.stopPropagation();
              return addToCart({
                type: CartType.Internet,
                data: {
                  offerId: offer.id,
                  address: currentLocation!,
                  home: (queryParams.home as number) || null,
                  situation: (queryParams.situation as number) || null,
                  bestAvailableTechnology,
                },
              });
            }}
            type={"button"}
          >
            <img alt={""} className={"icon icon-left"} src={iconCart} />
            Ajouter au panier
          </SubmitButton> */}
          <button className={"btn-link"} onClick={viewMore}>
            <img
              alt={""}
              className={"icon icon-left"}
              src={iconDocumentsBlue}
            />
            En savoir plus
          </button>
        </div>
      </div>
    </div>
  );
};

export default InternetHighlightedCard;
