import { HTMLAttributes } from "react";
import { Brand } from "./water";
import { cx } from "@emotion/css";
import { useAuth } from "src/services/auth/apiProvider";

const WaterCard = <
  P extends HTMLAttributes<HTMLDivElement> & {
    brand: Brand;
  }
>({
  brand,
  ...props
}: P): JSX.Element => {
  const { generateCalendlyLink } = useAuth();

  return (
    <div className={cx("water-supplier", props.className)}>
      <img
        alt={`Logo ${brand.name}`}
        className={"brand-logo"}
        src={brand.logo}
      />

      <h3 className={"brand-name"}>{brand.name}</h3>
      <div className={"body-s center"}>
        <p>{brand.address}</p>
        <p>
          <strong>{brand.phone}</strong>
        </p>
        {brand.url && (
          <p>
            <a
              className={"link"}
              href={brand.url}
              rel={"noreferrer"}
              target={"_blank"}
            >
              Visiter le site Internet du distributeur
            </a>
          </p>
        )}
      </div>

      <div className={"buttons-row"}>
        <a
          className={"btn-branded-outlined"}
          href={generateCalendlyLink("", "water")}
          rel={"noreferrer"}
          target={"_blank"}
        >
          Être rappelé
        </a>

        {/* <button
          className={"btn-branded"}
          onClick={(ev) => {
            ev.stopPropagation();
            addToCart({
              type: CartType.Water,
              data: {
                brandId: brand.id,
                address: currentLocation!,
                home: (queryParams.home as number) || null,
                situation: (queryParams.situation as number) || null,
              },
            });
          }}
        >
          Ajouter au panier
        </button> */}
      </div>
    </div>
  );
};

export default WaterCard;
