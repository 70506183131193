import ImgStep1 from "../../../../assets/img/step1.png";
import ImgStep2 from "../../../../assets/img/step2.png";
import ImgStep3 from "../../../../assets/img/step3.png";

const HowItWorksMoving = (): JSX.Element => {
  return (
    <div className={"how-it-works"}>
      <h2 className={"title"}>Comment ça marche ?</h2>
      <div className={"steps"}>
        <div className={"step"}>
          <img alt={"1"} src={ImgStep1} />
          <p>
            Je complète <strong>mes informations</strong>
          </p>
        </div>
        <div className={"step"}>
          <img alt={"2"} src={ImgStep2} />
          <p>
            Je reçois 4 devis par mail et je choisis{" "}
            <strong>mon offre préférée</strong>
          </p>
        </div>
        <div className={"step"}>
          <img alt={"3"} src={ImgStep3} />
          <p>
            Mon déménageur arrive <strong>à la date souhaitée</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowItWorksMoving;
