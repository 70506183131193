import { number, object, string } from "yup";

export interface Location {
  plainAddress: string;
  cleanedAddress: string;
  city: string;
  postCode: string;
  lat: number;
  lng: number;
}

export function locationSchema() {
  return object({
    plainAddress: string(),
    cleanedAddress: string(),
    city: string(),
    postCode: string(),
    lat: number(),
    lng: number(),
  });
}

export function mapPlaceResultToLocation(
  place?: google.maps.places.PlaceResult,
): Location | null {
  const location = {
    city:
      place?.address_components?.find((ac) => ac.types.includes("locality"))
        ?.long_name || null,
    postCode:
      place?.address_components?.find((ac) => ac.types.includes("postal_code"))
        ?.long_name || null,
    cleanedAddress:
      place?.address_components
        ?.reduce(
          (cleanedAddress, ac) =>
            ["street_number", "route"].some((t) => ac.types.includes(t))
              ? `${cleanedAddress} ${ac.long_name}`
              : cleanedAddress,
          "",
        )
        .trimStart() || null,
    plainAddress: place?.formatted_address || "",
    lat: place?.geometry?.location?.lat() || null,
    lng: place?.geometry?.location?.lng() || null,
  };
  return location.city !== null &&
    location.cleanedAddress !== null &&
    location.postCode !== null &&
    location.lat !== null &&
    location.lng !== null
    ? (location as Location)
    : null;
}

export async function geocodeAddress(
  address?: string,
): Promise<Location | null> {
  if (!address) {
    return null;
  }

  const geocoder = new google.maps.Geocoder();
  return await geocoder.geocode({ address }).then(({ results }) => {
    if (results.length === 0) {
      return null;
    } else {
      return mapPlaceResultToLocation(results[0]);
    }
  });
}
