import { AxiosPromise } from "axios";
import { publicAPI } from "../publicApi";

export function getOffers(): AxiosPromise<{
  offers: unknown[];
  duals: unknown[];
}> {
  return publicAPI.get("/energy/offers");
}

export function getBrands(): AxiosPromise<unknown[]> {
  return publicAPI.get("/energy/brands");
}
