import { useEffect, useMemo, useState } from "react";
import {
  InternetOfferType,
  InternetTechnology,
  INTERNET_OFFER_OPTION_NO_COMMITMENT,
  OfferWithBrand,
} from "./internet";
import { multiSorts, orderByField } from "../../data-structures/array";
import { withLoader } from "../../routing/useLoader";
import { useInternet } from "./internetProvider";
import InternetDetailsDialog from "./InternetDetailsDialog";
import InternetHighlightedCard from "./InternetHighlightCard";
import Button from "../../ui/reactive/Button";
import { Highlight, sortByRelevance } from "../common";
import iconNetwork from "../../../assets/img/icons/icon-network-blue.svg";
import iconOptions from "../../../assets/img/icons/icon-options-blue.svg";
import iconReload from "../../../assets/img/icons/icon-reload-light-blue.svg";
import iconTel from "../../../assets/img/icons/icon-tel-light-blue.svg";
import { cx } from "@emotion/css";
import { usePlace } from "../../google-maps/placeProvider";
import Dialog from "../../ui/block/Dialog";
import LoadMoreList from "../../ui/reactive/LoadMoreList";
import ComparatorListItem from "src/services/ui/common-components/ComparatorListItem";
// import { useCart } from "../cart/cartProvider";
import OrderByPhone from "src/services/common-components/OrderByPhone";
import ElegibilityCard from "./ElegibilityCard";
import iconCtaBtnArrow from "../../../assets/img/icons/icon-arrow-circle-right-white.svg";
import iconCart from "../../../assets/img/icons/icon-cart-light-blue.svg";
import iconFilter from "../../../assets/img/icons/icon-filters-blue.svg";
import InternetCaluculatorDialog from "./InternetCalculatorDialog";
import { GtmEvent, useGtm } from "../../gtm/gtmProvider";
import Form from "src/services/forms/Form";
import { object, string } from "yup";
import { GoogleMapsSearchbar } from "../HomeInput";
import SubmitButton from "src/services/forms/SubmitButton";
import WhyMovecool from "src/services/common-components/WhyMovecool";
import InternetActionsDialog from "./InternetActionsDialog";

enum Sort {
  Relevance,
  PriceAsc,
  PriceDesc,
  Speed,
}

const InsuranceComparator = (): JSX.Element => {
  // Store
  const {
    offersWithBrands: offers,
    availableTechnologies,
    bestElegibilityByOp,
  } = useInternet();
  const { currentLocation, setCurrentLocation } = usePlace();
  const { push } = useGtm();

  // State
  const [chosenOffer, setChosenOffer] = useState<OfferWithBrand | null>(null);
  const [selectedOffer, setSelectedOffer] = useState<OfferWithBrand | null>(
    null,
  );
  const [sort, setSort] = useState(Sort.PriceAsc);
  const [showInternetCalculator, setShowInternetCalculator] = useState(false);
  const [
    connectionType,
    setConnectionType,
  ] = useState<InternetOfferType | null>(null);
  const [filterWithTv, setFilterWithTv] = useState(false);
  const [filterWithPhone, setFilterWithPhone] = useState(false);
  const [filterWithoutCommitment, setFilterWithoutCommitment] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const bestTechByOp = useMemo(() => new Map(bestElegibilityByOp), [
    bestElegibilityByOp,
  ]);

  useEffect(
    () =>
      availableTechnologies.has(InternetTechnology.Fiber)
        ? setConnectionType(InternetOfferType.Fiber)
        : availableTechnologies.has(InternetTechnology.Adsl)
        ? setConnectionType(InternetOfferType.Adsl)
        : setConnectionType(InternetOfferType.Fiber),
    [availableTechnologies],
  );

  // Filters
  const filteredOffers = useMemo(
    () =>
      [...offers.values()].filter((offer) => {
        if (
          bestTechByOp.get(offer.brand.elegibilityCode)?.tech_code ===
            InternetTechnology.Adsl &&
          offer.type === InternetOfferType.Fiber
        )
          return false;
        if (
          filterWithoutCommitment &&
          !offer.options.includes(INTERNET_OFFER_OPTION_NO_COMMITMENT)
        )
          return false;
        if (filterWithPhone && !offer.phone) return false;
        if (filterWithTv && offer.tvChannelsAmount === 0) return false;
        if (connectionType && offer.type !== connectionType) return false;
        return true;
      }),
    [
      bestTechByOp,
      connectionType,
      filterWithPhone,
      filterWithTv,
      filterWithoutCommitment,
      offers,
    ],
  );

  // Sort
  const sortedOffers = useMemo(
    () =>
      [...filteredOffers].sort(
        sort === Sort.Relevance
          ? multiSorts(sortByRelevance, orderByField("price", false))
          : sort === Sort.PriceAsc
          ? orderByField("price", false)
          : sort === Sort.PriceDesc
          ? orderByField("price", true)
          : orderByField("bandwidth", true),
      ),
    [filteredOffers, sort],
  );

  // Memos
  const offerHighlights = useMemo(
    () =>
      filteredOffers.reduce(
        (highlights, offer) =>
          offer.highlights.length > 0 &&
          (bestTechByOp.has(offer.brand.elegibilityCode) ||
            offer.type === InternetOfferType.Fiber)
            ? highlights.concat(
                offer.highlights.map((h) => ({
                  label: h,
                  element: { offer },
                })),
              )
            : highlights,
        [] as Highlight[],
      ),
    [bestTechByOp, filteredOffers],
  );

  // const { addToCart } = useCart();

  return (
    <div id={"comparator"}>
      <div className={"section-l"}>
        <ElegibilityCard />
        {availableTechnologies.has(InternetTechnology.Fiber) && (
          <div className={"section"}>
            <div className={"info info-success"}>
              <h3>Cette adresse est éligible à la fibre optique</h3>
              {sortedOffers.length} offres disponibles
            </div>
          </div>
        )}

        {bestElegibilityByOp.length === 0 && (
          <div className={"section container-wrap --wrap--s"}>
            <h2 className={"title-l center"}>
              Vérifiez votre éligibilité à la fibre optique
            </h2>
            <div className={"card margin-top"}>
              <div className={"card-body --card-body--l"}>
                <Form
                  initialValues={{
                    address: currentLocation,
                  }}
                  onSubmit={(values) => {
                    setCurrentLocation(values.address!);
                    setShowInternetCalculator(false);
                    push(GtmEvent.FORM, {
                      event_name: "submit_monthly_estimation",
                      item_category: "Internet",
                    });
                    return Promise.resolve();
                  }}
                  schema={object({
                    address: object({
                      plainAddress: string(),
                      cleanedAddress: string(),
                      city: string(),
                      postCode: string(),
                    })
                      .label("adresse")
                      .nullable()
                      .required({ key: "address" }),
                  })}
                >
                  <div className={"inputs-row"}>
                    <GoogleMapsSearchbar
                      className={"form-block"}
                      name={"address"}
                    />
                    <SubmitButton>Vérifier</SubmitButton>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        )}
      </div>

      {!!offerHighlights.length && (
        <>
          <h2 className={"title-l center margin-top"}>
            Notre sélection des meilleures offres du mois
          </h2>
          <div className={"offers-highlights"}>
            {offerHighlights.map((highlight) => (
              <InternetHighlightedCard
                key={highlight.label}
                dataCompleteCondition={currentLocation !== null}
                label={highlight.label}
                offer={highlight.element.offer as OfferWithBrand}
                onChooseOffer={() =>
                  setChosenOffer(highlight.element.offer as OfferWithBrand)
                }
                onClick={() =>
                  setSelectedOffer(highlight.element.offer as OfferWithBrand)
                }
                onEstimate={() => {
                  setShowInternetCalculator(true);
                  push(GtmEvent.GENERIC_EVENT, {
                    event_name: "click_monthly_estimation",
                    item_category: "Internet",
                    item_id: (highlight.element.offer as OfferWithBrand).id,
                    item_name: (highlight.element.offer as OfferWithBrand).name,
                    item_price: (highlight.element.offer as OfferWithBrand)
                      .price,
                    item_brand: (highlight.element.offer as OfferWithBrand)
                      .brand.name,
                  });
                }}
                viewMore={() =>
                  setSelectedOffer(highlight.element.offer as OfferWithBrand)
                }
              />
            ))}
          </div>
        </>
      )}

      <div className={"section-l container-wrap --wrap--m"}>
        <WhyMovecool />
      </div>

      <div className={"section-l"}>
        <h2 className={"title-l center"}>L'ensemble des marques disponibles</h2>

        <div className={"comparator-list margin-top"}>
          <div className={"grid"}>
            <div className={"comparator-sidebar"}>
              <div className={"card-block filter"}>
                <div className={"card-head"}>
                  <h2 className={"card-title"}>Filtrer les résultats</h2>
                </div>
                <div className={"card"}>
                  <div className={"card-body"}>
                    <div className={"filter-group"}>
                      <h3 className={"filter-title"}>
                        <img
                          alt={"icône réseau"}
                          className={"icon"}
                          src={iconNetwork}
                        />
                        Technologie
                      </h3>
                      <Button
                        className={cx(
                          connectionType === InternetOfferType.Fiber &&
                            "active",
                        )}
                        onClick={() => {
                          setConnectionType((prev) =>
                            prev === InternetOfferType.Fiber
                              ? null
                              : InternetOfferType.Fiber,
                          );
                          push(GtmEvent.GENERIC_EVENT, {
                            event_name: "filter",
                            item_category: "Internet",
                            item_variant: InternetOfferType.Fiber,
                            option_filter: "Technologie Fibre",
                          });
                        }}
                      >
                        Fibre
                      </Button>
                      <Button
                        className={cx(
                          connectionType === InternetOfferType.Adsl && "active",
                        )}
                        onClick={() => {
                          setConnectionType((prev) =>
                            prev === InternetOfferType.Adsl
                              ? null
                              : InternetOfferType.Adsl,
                          );
                          push(GtmEvent.GENERIC_EVENT, {
                            event_name: "filter",
                            item_category: "Internet",
                            item_variant: InternetOfferType.Adsl,
                            option_filter: "Technologie ADSL",
                          });
                        }}
                      >
                        ADSL
                      </Button>
                      <Button
                        className={cx(
                          connectionType === InternetOfferType.G4 && "active",
                        )}
                        onClick={() => {
                          setConnectionType((prev) =>
                            prev === InternetOfferType.G4
                              ? null
                              : InternetOfferType.G4,
                          );
                          push(GtmEvent.GENERIC_EVENT, {
                            event_name: "filter",
                            item_category: "Internet",
                            item_variant: InternetOfferType.G4,
                            option_filter: "Technologie 4G",
                          });
                        }}
                      >
                        4G
                      </Button>
                    </div>
                    <div className={"filter-group"}>
                      <h3 className={"filter-title"}>
                        <img
                          alt={"icône options"}
                          className={"icon"}
                          src={iconOptions}
                        />
                        Options
                      </h3>
                      <Button
                        className={cx(filterWithTv && "active")}
                        onClick={() => {
                          setFilterWithTv((prev) => !prev);
                          push(GtmEvent.GENERIC_EVENT, {
                            event_name: "filter",
                            item_category: "Internet",
                            item_variant: "TV",
                            option_filter: "Options TV",
                          });
                        }}
                      >
                        TV
                      </Button>
                      <Button
                        className={cx(filterWithPhone && "active")}
                        onClick={() => {
                          setFilterWithPhone((prev) => !prev);
                          push(GtmEvent.GENERIC_EVENT, {
                            event_name: "filter",
                            item_category: "Internet",
                            item_variant: "Téléphonie",
                            option_filter: "Options Téléphonie",
                          });
                        }}
                      >
                        Téléphonie
                      </Button>
                      <Button
                        className={cx(filterWithoutCommitment && "active")}
                        onClick={() => {
                          setFilterWithoutCommitment((prev) => !prev);
                          push(GtmEvent.GENERIC_EVENT, {
                            event_name: "filter",
                            item_category: "Internet",
                            item_variant: "Sans Engagement",
                            option_filter: "Options Sans Engagement",
                          });
                        }}
                      >
                        Sans Engagement
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              {showFilter && (
                <Dialog
                  className={"side-panel"}
                  closeLabel={"Retour  x"}
                  onClose={() => setShowFilter((prev) => !prev)}
                >
                  <div className={"card-block"}>
                    <div className={"card-head"}>
                      <h2 className={"card-title"}>Filtrer les résultats</h2>
                    </div>
                    <div className={"card"}>
                      <div className={"card-body"}>
                        <div className={"filter-group"}>
                          <h3 className={"filter-title"}>
                            <img
                              alt={"icône réseau"}
                              className={"icon"}
                              src={iconNetwork}
                            />
                            Technologie
                          </h3>
                          <Button
                            className={cx(
                              connectionType === InternetOfferType.Fiber &&
                                "active",
                            )}
                            onClick={() => {
                              setConnectionType((prev) =>
                                prev === InternetOfferType.Fiber
                                  ? null
                                  : InternetOfferType.Fiber,
                              );
                              push(GtmEvent.GENERIC_EVENT, {
                                event_name: "filter",
                                item_category: "Internet",
                                option_filter: "Technologie Fibre",
                              });
                            }}
                          >
                            Fibre
                          </Button>
                          <Button
                            className={cx(
                              connectionType === InternetOfferType.Adsl &&
                                "active",
                            )}
                            onClick={() => {
                              setConnectionType((prev) =>
                                prev === InternetOfferType.Adsl
                                  ? null
                                  : InternetOfferType.Adsl,
                              );
                              push(GtmEvent.GENERIC_EVENT, {
                                event_name: "filter",
                                item_category: "Internet",
                                option_filter: "Technologie ADSL",
                              });
                            }}
                          >
                            ADSL
                          </Button>
                          <Button
                            className={cx(
                              connectionType === InternetOfferType.G4 &&
                                "active",
                            )}
                            onClick={() => {
                              setConnectionType((prev) =>
                                prev === InternetOfferType.G4
                                  ? null
                                  : InternetOfferType.G4,
                              );
                              push(GtmEvent.GENERIC_EVENT, {
                                event_name: "filter",
                                item_category: "Internet",
                                option_filter: "Technologie 4G",
                              });
                            }}
                          >
                            4G
                          </Button>
                        </div>
                        <div className={"filter-group"}>
                          <h3 className={"filter-title"}>
                            <img
                              alt={"icône options"}
                              className={"icon"}
                              src={iconOptions}
                            />
                            Options
                          </h3>
                          <Button
                            className={cx(filterWithTv && "active")}
                            onClick={() => {
                              setFilterWithTv((prev) => !prev);
                              push(GtmEvent.GENERIC_EVENT, {
                                event_name: "filter",
                                item_category: "Internet",
                                option_filter: "Options TV",
                              });
                            }}
                          >
                            TV
                          </Button>
                          <Button
                            className={cx(filterWithPhone && "active")}
                            onClick={() => {
                              setFilterWithPhone((prev) => !prev);
                              push(GtmEvent.GENERIC_EVENT, {
                                event_name: "filter",
                                item_category: "Internet",
                                option_filter: "Options Téléphonie",
                              });
                            }}
                          >
                            Téléphonie
                          </Button>
                          <Button
                            className={cx(filterWithoutCommitment && "active")}
                            onClick={() => {
                              setFilterWithoutCommitment((prev) => !prev);
                              push(GtmEvent.GENERIC_EVENT, {
                                event_name: "filter",
                                item_category: "Internet",
                                option_filter: "Options Sans Engagement",
                              });
                            }}
                          >
                            Sans Engagement
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button
                    className={"btn-block margin-top"}
                    onClick={() => setShowFilter((prev) => !prev)}
                  >
                    Valider
                  </Button>
                </Dialog>
              )}

              <div className={"section"}>
                <div className={"card-block"}>
                  <div className={"card-head"}>
                    <h2 className={"card-title"}>Test d'éligibilité</h2>
                  </div>
                  <div className={"card"}>
                    <div className={"card-body"}>
                      {currentLocation?.plainAddress ? (
                        <>
                          <div className={"filter-group"}>
                            <h3 className={"filter-title"}>
                              <img
                                alt={"icône adresse"}
                                className={"icon"}
                                src={iconOptions}
                              />
                              Votre adresse
                            </h3>
                            <p>{currentLocation?.plainAddress}</p>
                          </div>
                          <div className={"filter-group"}>
                            <h3 className={"filter-title"}>
                              <img
                                alt={"icône technologie"}
                                className={"icon"}
                                src={iconNetwork}
                              />
                              Technologie disponible
                            </h3>
                            <p>
                              {availableTechnologies.has(
                                InternetTechnology.Fiber,
                              )
                                ? "Fibre Optique"
                                : availableTechnologies.has(
                                    InternetTechnology.Adsl,
                                  )
                                ? "ADSL"
                                : "NA"}
                            </p>
                          </div>
                          <div className={"margin-top-l center"}>
                            <button
                              className={"link-3-s margin-auto"}
                              onClick={() => setShowInternetCalculator(true)}
                            >
                              <img
                                alt={"icône modifier"}
                                className={"icon icon-left"}
                                src={iconReload}
                              />
                              Modifier mes informations
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <p className={"center"}>
                            Renseignez vos{" "}
                            <strong>informations manquantes</strong>
                          </p>
                          <p className={"center"}>
                            pour obtenir une <strong>estimation de prix</strong>{" "}
                            et nous permettre de vous conseiller au mieux
                          </p>
                          <div className={"section center"}>
                            <button
                              className={"btn-cta-estimate btn-block"}
                              onClick={() => setShowInternetCalculator(true)}
                            >
                              Tester mon éligibilité
                              <img
                                alt={"estimer"}
                                className={"icon icon-right"}
                                src={iconCtaBtnArrow}
                              />
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={"section"}>
                <OrderByPhone />
              </div>
            </div>

            <div className={"comparator-offers"}>
              <div className={"list-head"}>
                <button
                  className={"btn-filter margin-right"}
                  onClick={() => {
                    setShowFilter((prev) => !prev);
                  }}
                >
                  Filtres
                  <img
                    alt={"estimer"}
                    className={"icon icon-right"}
                    src={iconFilter}
                  />
                </button>
                <select
                  className={"select-sp"}
                  onChange={(ev) => setSort(parseInt(ev.target.value))}
                  value={sort}
                >
                  <option value={Sort.Relevance}>Classer / trier</option>
                  <option value={Sort.PriceAsc}>Du - cher au + cher</option>
                  <option value={Sort.PriceDesc}>Du + cher au - cher</option>
                  <option value={Sort.Speed}>Débit</option>
                </select>
              </div>
              <LoadMoreList
                items={sortedOffers}
                loadMoreLabel={"Afficher plus d'offres"}
                paginatedBy={10}
                render={(item) => (
                  <ComparatorListItem
                    key={item.id}
                    brand={item.brand}
                    calendlyContext={"internet"}
                    cartIcon={iconCart}
                    dataCompleteCondition={currentLocation !== null}
                    iconCalling={iconTel}
                    offer={item}
                    onChooseOffer={() => setChosenOffer(item)}
                    onClose={() => setSelectedOffer(null)}
                    // onAddToCart={(ev) => {
                    //   ev.stopPropagation();
                    //   return addToCart({
                    //     type: CartType.Internet,
                    //     data: {
                    //       offerId: item.id,
                    //       address: currentLocation!,
                    //       home: null,
                    //       situation: null,
                    //       bestAvailableTechnology,
                    //     },
                    //   });
                    // }}
                    onEstimate={() => {
                      setShowInternetCalculator(true);
                      push(GtmEvent.GENERIC_EVENT, {
                        event_name: "click_monthly_estimation",
                        item_category: "Internet",
                        item_id: item.id,
                        item_name: item.name,
                        item_price: item.price,
                        item_brand: item.brand.name,
                      });
                    }}
                    viewMore={() => setSelectedOffer(item)}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>

      {selectedOffer && (
        <InternetDetailsDialog
          offer={selectedOffer}
          onChooseOffer={() => setChosenOffer(selectedOffer)}
          onClose={() => setSelectedOffer(null)}
        />
      )}

      {showInternetCalculator && (
        <InternetCaluculatorDialog
          onClose={() => setShowInternetCalculator(false)}
          onSubmit={(values) => {
            setCurrentLocation(values.address!);
            setShowInternetCalculator(false);
            push(GtmEvent.FORM, {
              event_name: "submit_monthly_estimation",
              item_category: "Internet",
            });
            return Promise.resolve();
          }}
        />
      )}

      {chosenOffer && (
        <InternetActionsDialog
          offer={chosenOffer}
          onClose={() => setChosenOffer(null)}
        />
      )}
    </div>
  );
};

const useLoad = () => {
  const { loadAll } = useInternet();

  return loadAll;
};

export default withLoader(InsuranceComparator, useLoad);
