import loggerBuilder from "../logger";
import { typedPropertyToValueBuilder } from "../notion/notion";

const logger = loggerBuilder("content");

export enum FAQService {
  Energy = "Energie",
  Insurance = "Assurance",
  Internet = "Box internet",
  Water = "Eau",
  Moving = "Déménageurs",
  Movecool = "Movecool",
}

export interface Faq {
  id: string;
  services: string[];
  question: string;
  answer: JSX.Element;
}
export function mapRawFaqsToFaqs(rawFaqs: unknown[]): Faq[] {
  return logger.withErrorCatcher(
    () =>
      rawFaqs.map((faq: any) => {
        logger.debug(`Mapping faq: ${faq.id}`);
        const propertyToValue = typedPropertyToValueBuilder(faq.properties);

        return {
          id: faq.id,
          services: propertyToValue("Secteur", "multi_select"),
          question: propertyToValue("Question", "title"),
          answer: propertyToValue("Réponse", "rich_text").element,
        };
      }),
    "Error while parsing brands",
  );
}
