import Card from "../../ui/block/Card";
import CardBody from "../../ui/block/CardBody";
import CardHead from "../../ui/block/CardHead";
import Dialog from "../../ui/block/Dialog";
import Button from "../../ui/reactive/Button";
import tutorImg from "../../../assets/img/tutor.png";
import { GtmEvent, useGtm } from "../../gtm/gtmProvider";

const SubscriptionDetailsDialog = ({
  onClose,
}: {
  onClose(): void;
}): JSX.Element => {
  const { push } = useGtm();

  return (
    <Dialog onClose={onClose}>
      <Card className={"card-popup-info"}>
        <CardHead title={"L'information Movecool"} />
        <CardBody>
          <div className={"card-img"}>
            <img alt={"professeur"} src={tutorImg} />
          </div>
          <div className={"section-s"}>
            <h3>
              Le prix de votre abonnement dépend de la puissance de votre
              compteur.
            </h3>
            <p>
              Celles-ci vont de 3 kVa à 36 kVa. Plus la puissance est élevée
              plus l’abonnement est cher.
            </p>
          </div>
          <div className={"section-s"}>
            <h3>Qu’est-ce que la puissance d’un compteur électrique ?</h3>
            <p>
              La puissance du compteur correspond au volume maximal
              d’électricité qu’un logement peut utiliser de manière simultanée.
              70% des Français ont une puissance de compteur de 6 kVA.
            </p>
          </div>
          <div className={"section-s"}>
            <h3>Pourquoi est-ce important ?</h3>
            <p>
              Une puissance inférieure à vos besoins en électricité peut
              entraîner une coupure de l’installation électrique. Par exemple la
              simple mise en marche d’un grille-pain ou d’un four électrique
              pourrait faire sauter toute l’installation électrique.
            </p>
          </div>
        </CardBody>
        <div className={"card-footer"}>
          <p>
            Besoin d’aide pour paramétrer la puissance de votre compteur ?<br />
            <a
              className={"link"}
              href={"tel:0745893259"}
              onClick={() => {
                push(GtmEvent.GENERIC_EVENT, {
                  event_name: "call",
                });
              }}
            >
              Contactez- nous !
            </a>
          </p>
          <Button onClick={onClose}>&larr; Fermer</Button>
        </div>
      </Card>
    </Dialog>
  );
};

export default SubscriptionDetailsDialog;
