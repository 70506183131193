import React, { useMemo } from "react";
import Link from "../../routing/components/Link";
import { LOST_PASSWORD_LINK, REGISTER_LINK } from "../../../routes/public";
import { object, string } from "yup";
import { UserToLogin } from "../user";
import { SUBMIT_BUTTON_ID } from "../../../fixtures/auth";
import ValidationsErrors from "../../forms/ValidationsErrors";
import SubmitButton from "../../forms/SubmitButton";
import Form from "../../forms/Form";
import AutoField from "../../forms/AutoField";
import { useTranslation } from "react-i18next";
import FormFooter from "../../forms/FormFooter";
import Button from "../../ui/reactive/Button";

interface Props {
  onSubmit: (values: UserToLogin) => Promise<unknown>;
  onCancel?: () => void;
  onRegisterClick?: () => void;
  onLostPasswordClick?: () => void;
  FormFooterElement?: JSX.Element;
}

const LoginForm = ({
  onSubmit,
  onCancel,
  onRegisterClick,
  onLostPasswordClick,
  FormFooterElement,
}: Props): JSX.Element => {
  const { t } = useTranslation(["auth"]);
  const LoginSchema = useMemo(
    () =>
      object()
        .shape({
          email: string().label(t("auth:email")).required().email(),
          password: string().label(t("auth:password")).password(),
        })
        .defined(),
    [t],
  );

  return (
    <Form
      initialValues={{ email: "", password: "" }}
      onSubmit={(values) => {
        return onSubmit(values);
      }}
      schema={LoginSchema}
    >
      <AutoField name={"email"} />

      <AutoField name={"password"} />

      <div className={"lost-password-cta"}>
        {onLostPasswordClick ? (
          <Button className={"link"} onClick={onLostPasswordClick}>
            Mot de passe oublié
          </Button>
        ) : (
          <Link to={LOST_PASSWORD_LINK}>Mot de passe oublié</Link>
        )}
      </div>

      <div>
        <ValidationsErrors />
      </div>

      {FormFooterElement ? (
        FormFooterElement
      ) : (
        <FormFooter>
          {onCancel && (
            <button onClick={onCancel} type={"button"}>
              Annuler
            </button>
          )}
          <SubmitButton data-testid={SUBMIT_BUTTON_ID} block>
            Connexion
          </SubmitButton>
          {!onCancel && (
            <div className={"section-s"}>
              {onRegisterClick ? (
                <button
                  className={"btn-link btn-block"}
                  onClick={onRegisterClick}
                >
                  Pas encore de compte ? Je m'inscris !
                </button>
              ) : (
                <Link className={"btn-link btn-block"} to={REGISTER_LINK}>
                  Pas encore de compte ? Je m'inscris !
                </Link>
              )}
            </div>
          )}
        </FormFooter>
      )}
    </Form>
  );
};

export default LoginForm;
