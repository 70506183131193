import { useCallback, useState } from "react";
import { useToastsWithIntl } from "../../toast-notifications";
import Card from "../../ui/block/Card";
import CardBody from "../../ui/block/CardBody";
import CardHead from "../../ui/block/CardHead";
import Dialog from "../../ui/block/Dialog";
import { useAuth } from "../apiProvider";
import { User, UserToLogin, UserToRegisterForm } from "../user";
import LoginForm from "./LoginForm";
import LostPasswordForm from "./LostPasswordForm";
import RegisterForm from "./RegisterForm";
import { GtmEvent, useGtm } from "src/services/gtm/gtmProvider";
import { pickKeys } from "src/services/data-structures/object";

export enum AuthDialogState {
  Login,
  Register,
  LostPassword,
}

const AuthDialog = ({
  onClose,
  onAuthSuccess,
  defaultState = AuthDialogState.Login,
  defaultUser = null,
}: {
  onClose(): void;
  onAuthSuccess(userId: User["id"]): void;
  defaultState?: AuthDialogState;
  defaultUser?: UserToRegisterForm | null;
}): JSX.Element => {
  const { login, register } = useAuth();
  const { toastError } = useToastsWithIntl(["auth"]);
  const gtm = useGtm();

  const [state, setState] = useState<AuthDialogState>(defaultState);
  const onLogin = useCallback(
    (values: UserToLogin) => {
      return login(values).then(
        (user) => {
          onAuthSuccess(user.id);
        },
        () => {
          toastError("auth:login.TOAST_ERROR");
        },
      );
    },
    [login, onAuthSuccess, toastError],
  );

  const onRegister = useCallback(
    (values: UserToRegisterForm, setSubmitting) => {
      setSubmitting(true);

      return register(values)
        .then(
          () => {
            gtm.push(GtmEvent.SIGN_UP, {
              event_name: "sign_up",
              user_data: pickKeys(values, ["email"]),
            });
            return onLogin(values);
          },
          (err) => {
            if (err?.response?.status === 409)
              toastError("auth:register.TOAST_ERROR_ALREADY_EXISTS");
            else toastError("auth:register.TOAST_ERROR");
          },
        )
        .finally(() => setSubmitting(false));
    },
    [gtm, onLogin, register, toastError],
  );

  return (
    <Dialog className={"side-panel"} closeLabel={"Retour  x"} onClose={onClose}>
      {state === AuthDialogState.Login && (
        <div className={"card-block"}>
          <CardHead>
            <h1>Connexion</h1>
            <p>Connectez-vous pour accéder à votre espace utilisateur</p>
          </CardHead>
          <Card className={"auth-card"}>
            <CardBody>
              <LoginForm
                onLostPasswordClick={() =>
                  setState(AuthDialogState.LostPassword)
                }
                onRegisterClick={() => setState(AuthDialogState.Register)}
                onSubmit={onLogin}
              />
            </CardBody>
          </Card>
        </div>
      )}

      {state === AuthDialogState.Register && (
        <div className={"card-block"}>
          <CardHead>
            <h1>Inscription</h1>
            <p>
              Créez votre compte Movecool en 3 clics et faites des économies !
            </p>
          </CardHead>
          <Card className={"auth-card"}>
            <CardBody>
              <RegisterForm
                onLoginBack={() => setState(AuthDialogState.Login)}
                onSubmit={onRegister}
              />
            </CardBody>
          </Card>
        </div>
      )}

      {state === AuthDialogState.LostPassword && (
        <div className={"card-block"}>
          <CardHead>
            <h1>Mot de passe oublié</h1>
            <p>
              Veuillez renseigner votre adresse e-mail pour recevoir un lien de
              réinitialisation de votre mot de passe
            </p>
          </CardHead>
          <Card className={"auth-card"}>
            <CardBody>
              <LostPasswordForm
                onCancel={() => setState(AuthDialogState.Login)}
                onSubmit={onClose}
              />
            </CardBody>
          </Card>
        </div>
      )}
    </Dialog>
  );
};

export default AuthDialog;
