import {
  ComponentType,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Cart,
  CartMutation,
  CartType,
  CartWithOffer,
  Checkout,
  getElementIdFromCart,
  mapRawCartsToCarts,
  NewCart,
  prepareCheckoutToSubmit,
} from "./cart";
import {
  getCart as apiGetCart,
  addToCart as apiAddtoCart,
  deleteFromCart as apiDeleteFromCart,
  submitCart as apiSubmitCart,
  pushCartMutations,
  saveHousingDataRequest,
  saveContractDataRequest,
} from "./api";
import { useAuth } from "../../auth/apiProvider";
import { LoggedUser, User } from "../../auth/user";
import { useToastsWithIntl } from "../../toast-notifications";
import { replaceInArray, spliceReturn } from "../../data-structures/array";
import { randomUUID } from "../../data-structures/crypto";
import { copyMapAndReplace } from "../../data-structures/map";
import { loggerBuilder } from "../../logger";
import { useEnergy } from "../energy/energyProvider";
import { useInsurance } from "../insurance/insuranceProvider";
import { useInternet } from "../internet/internetProvider";
import { useWater } from "../water/waterProvider";
import { useMoving } from "../moving/movingProvider";
import { GtmCartItem, GtmEvent, useGtm } from "../../gtm/gtmProvider";

const logger = loggerBuilder("cartProvider");

const ENEDIS_FIXED_AMOUNT = 13.94;
const GRDF_FIXED_AMOUNT = 19.88;
const INTERNET_FIXED_AMOUNT = 49;
const INSURANCE_FIXED_AMOUNT = 0;

export interface CartAPI {
  elements: Map<string, Cart<any>>;
  elementsPerCartType: Map<CartType, Cart<any>[]>;
  cartMutationsBuffer: CartMutation<any>[];

  electricityOffer: CartWithOffer<CartType.Electricity> | null | undefined;
  gasOffer: CartWithOffer<CartType.Gas> | null | undefined;
  internetOffer: CartWithOffer<CartType.Internet> | null | undefined;
  insuranceOffer: CartWithOffer<CartType.Insurance> | null | undefined;

  totalAmount: number | null;
  nextMonthAmount: number | null;

  checkedOutElements: Map<CartType, Cart<any>[]> | null;

  getCart(userId?: User["id"]): Promise<Cart<any>[]>;
  addToCart<T extends CartType>(
    cart: NewCart<T>,
    userId?: User["id"],
  ): Promise<void>;
  removeFromCart(cartId: Cart<never>["id"]): Promise<void>;
  submitCart(checkout: Checkout): Promise<void>;

  pendingToAdd: NewCart<any> | null;
  setPendingToAdd(cart: NewCart<any> | null): void;

  checkout: Checkout;
  setCheckout(checkout: Checkout): void;

  setOverrideCart(override: boolean): void;

  gtmCartItems: GtmCartItem[];
  gtmUserAddress: string | null;

  saveHousingData(checkout: any, housing: any): Promise<void>;
  saveContractData(checkout: any, housing: any, contract: any): Promise<void>;
}

export const CartContext = createContext<CartAPI | null>(null);

export const ProvideCart = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const { user, setAfterLoginHooks, setAfterLogoutHooks } = useAuth();
  const { toastSuccess, toastError } = useToastsWithIntl(["compare"]);
  const { offers: energyOffers, brands: energyBrands } = useEnergy();
  const { brands: insuranceBrands } = useInsurance();
  const { offers: internetOffers, brands: internetBrands } = useInternet();
  const { brands: waterBrands } = useWater();
  const { brands: movingBrands } = useMoving();
  const gtm = useGtm();

  const [elements, setElements] = useState<CartAPI["elements"]>(
    new Map(
      [].concat(JSON.parse(localStorage.getItem("movecool-cart") || "[]")),
    ),
  );
  const [cartMutationsBuffer, setCartMutationsBuffer] = useState<
    CartAPI["cartMutationsBuffer"]
  >(JSON.parse(localStorage.getItem("movecool-mutation-buffer") || "[]"));
  const [pendingToAdd, setPendingToAdd] = useState<CartAPI["pendingToAdd"]>(
    null,
  );
  const [checkout, setCheckout] = useState<Checkout>({
    home: null,
    userData: null,
    dates: [],
    contract: null,
    elements: [],
  });
  const [checkedOutElements, setCheckedOutElements] = useState<
    CartAPI["checkedOutElements"]
  >(null);
  const [overrideCart, setOverrideCart] = useState(false);

  const elementsWithMutations = useMemo(() => {
    let new_elements = [...elements.entries()];

    for (const mutation of cartMutationsBuffer) {
      if (mutation.cartToRemove) {
        new_elements = spliceReturn(
          new_elements,
          new_elements.findIndex(([id]) => id === mutation.cartToRemove),
        );
      } else if (mutation.cartToAdd) {
        new_elements.push([
          mutation.tempCartId!,
          {
            ...mutation.cartToAdd,
            id: mutation.tempCartId!,
            createdAt: new Date(),
          },
        ]);
      }
    }

    return new Map(new_elements);
  }, [cartMutationsBuffer, elements]);

  const elementsPerCartType: CartAPI["elementsPerCartType"] = useMemo(
    () =>
      [...elementsWithMutations.values()].reduce(
        (res: Map<CartType, Cart<any>[]>, element) => {
          if (element.type === CartType.Dual) {
            logger.error("ERROR: DUAL", element);
            return res;
          } else {
            return copyMapAndReplace(res, element.type, [
              ...(res.get(element.type) || []),
              element,
            ]);
          }
        },
        new Map(),
      ),
    [elementsWithMutations],
  );

  const electricityOffer = useMemo(() => {
    const offers = elementsPerCartType.get(CartType.Electricity) || [];
    if (offers.length < 1) return null;
    if (offers.length > 1) return undefined;

    const cart = offers[0] as Cart<CartType.Electricity>;
    if (!energyOffers.has(cart.data.offerId!)) return null;
    const offer = energyOffers.get(cart.data.offerId!)!;
    return {
      ...cart,
      price:
        (offer.price * cart.data.electricityConsumption) / 100 +
        offer.subscription,
      offer: {
        ...offer,
        brand: energyBrands.get(offer.brandId!)!,
      },
    } as CartWithOffer<CartType.Electricity>;
  }, [elementsPerCartType, energyBrands, energyOffers]);

  const gasOffer = useMemo(() => {
    const offers = elementsPerCartType.get(CartType.Gas) || [];
    if (offers.length < 1) return null;
    if (offers.length > 1) return undefined;

    const cart = offers[0] as Cart<CartType.Gas>;
    if (!energyOffers.has(cart.data.offerId!)) return null;
    const offer = energyOffers.get(cart.data.offerId!)!;
    return {
      ...cart,
      price:
        (offer.price * cart.data.gasConsumption) / 100 + offer.subscription,
      offer: {
        ...offer,
        brand: energyBrands.get(offer.brandId!)!,
      },
    } as CartWithOffer<CartType.Gas>;
  }, [elementsPerCartType, energyBrands, energyOffers]);

  const insuranceOffer = useMemo(() => {
    const offers = elementsPerCartType.get(CartType.Insurance) || [];
    if (offers.length < 1) return null;
    if (offers.length > 1) return undefined;

    const cart = offers[0] as Cart<CartType.Insurance>;
    if (!insuranceBrands.has(cart.data.brandId!)) return null;
    const brand = insuranceBrands.get(cart.data.brandId!)!;
    return {
      ...cart,
      offer: { brand },
      price:
        (cart.data.price || cart.data.simulatedPrice || brand.simulatedPrice) /
        12,
    } as CartWithOffer<CartType.Insurance>;
  }, [elementsPerCartType, insuranceBrands]);

  const internetOffer = useMemo(() => {
    const offers = elementsPerCartType.get(CartType.Internet) || [];
    if (offers.length < 1) return null;
    if (offers.length > 1) return undefined;

    const cart = offers[0] as Cart<CartType.Internet>;
    if (!internetOffers.has(cart.data.offerId!)) return null;
    const offer = internetOffers.get(cart!.data.offerId!)!;
    return {
      ...cart,
      price: offer.price,
      offer: {
        ...offer,
        brand: internetBrands.get(offer.brandId!)!,
      },
    } as CartWithOffer<CartType.Internet>;
  }, [elementsPerCartType, internetBrands, internetOffers]);

  const movingOffer = useMemo(() => {
    const offers = elementsPerCartType.get(CartType.Moving) || [];
    if (offers.length < 1) return null;
    if (offers.length > 1) return undefined;

    const cart = offers[0] as Cart<CartType.Moving>;
    if (!movingBrands.has(cart.data.offerId!)) return null;
    const brand = movingBrands.get(cart.data.offerId!)!;
    return {
      ...cart,
      offer: { brand },
    } as CartWithOffer<CartType.Moving>;
  }, [elementsPerCartType, movingBrands]);

  const waterOffer = useMemo(() => {
    const offers = elementsPerCartType.get(CartType.Water) || [];
    if (offers.length < 1) return null;
    if (offers.length > 1) return undefined;

    const cart = offers[0] as Cart<CartType.Water>;
    if (!waterBrands.has(cart.data.offerId!)) return null;
    const brand = waterBrands.get(cart.data.offerId!)!;
    return {
      ...cart,
      offer: { brand },
    } as CartWithOffer<CartType.Water>;
  }, [elementsPerCartType, waterBrands]);

  const totalAmount = useMemo(() => {
    let amount = null;
    if (
      electricityOffer === undefined ||
      gasOffer === undefined ||
      internetOffer === undefined ||
      insuranceOffer === undefined
    )
      return null;

    if (electricityOffer !== null)
      amount = (amount || 0) + (electricityOffer.price || 0);
    if (gasOffer !== null) amount = (amount || 0) + (gasOffer.price || 0);
    if (internetOffer !== null)
      amount = (amount || 0) + (internetOffer.price || 0);
    if (insuranceOffer !== null)
      amount = (amount || 0) + (insuranceOffer.price || 0);

    return amount;
  }, [electricityOffer, gasOffer, insuranceOffer, internetOffer]);
  const nextMonthAmount = useMemo(() => {
    let amount = null;
    if (
      electricityOffer === undefined ||
      gasOffer === undefined ||
      internetOffer === undefined ||
      insuranceOffer === undefined
    )
      return null;

    if (electricityOffer !== null) amount = (amount || 0) + ENEDIS_FIXED_AMOUNT;
    if (gasOffer !== null) amount = (amount || 0) + GRDF_FIXED_AMOUNT;
    if (internetOffer !== null) amount = (amount || 0) + INTERNET_FIXED_AMOUNT;
    if (insuranceOffer !== null)
      amount = (amount || 0) + INSURANCE_FIXED_AMOUNT;

    return amount;
  }, [electricityOffer, gasOffer, insuranceOffer, internetOffer]);

  const gtmCartItems: CartAPI["gtmCartItems"] = useMemo(
    () =>
      [...elements.values()]
        .map(gtm.mapCartToItem)
        .filter((e) => e !== null) as CartAPI["gtmCartItems"],
    [elements, gtm.mapCartToItem],
  );
  const gtmUserAddress: CartAPI["gtmUserAddress"] = useMemo(
    () =>
      ([...elements.values()].find(
        (e) =>
          e.type === CartType.Internet ||
          e.type === CartType.Electricity ||
          e.type === CartType.Insurance ||
          e.type === CartType.Gas,
      ) as
        | Cart<
            | CartType.Internet
            | CartType.Electricity
            | CartType.Insurance
            | CartType.Gas
          >
        | undefined)?.data.address?.plainAddress || null,
    [elements],
  );

  const getCart: CartAPI["getCart"] = useCallback(
    (userId) =>
      userId || user?.id
        ? apiGetCart(userId || user!.id).then(({ data }) => {
            const elements = mapRawCartsToCarts(data);
            setElements(elements);
            return [...elements.values()] as Cart<any>[];
          })
        : Promise.resolve([...elementsWithMutations.values()]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  );
  const addToCart: CartAPI["addToCart"] = useCallback(
    (cart, userId) => {
      const carts = [];
      if (cart.type === CartType.Dual) {
        const dualCart = cart as NewCart<CartType.Dual>;
        carts.push({
          type: CartType.Electricity,
          data: dualCart.data.electricity,
        });
        carts.push({
          type: CartType.Gas,
          data: dualCart.data.gas,
        });
      } else {
        carts.push(cart);
      }

      // REVIEW: Move to each one of the buttons to have more context information
      gtm.push(GtmEvent.ECOMMERCE, {
        event_name: "add_to_cart",
        value: totalAmount,
        currency: "EUR",
        items: carts.map((cart, idx) =>
          gtm.mapCartToItem(cart as NewCart<any>, idx),
        ),
      });

      if (userId || user?.id) {
        return Promise.all(
          carts.map((c) => apiAddtoCart(userId || user!.id, c)),
        ).then(
          (resps) => {
            const { data } = resps.length > 1 ? resps[1] : resps[0];
            setElements(mapRawCartsToCarts(data));
            //toastSuccess("compare:add-to-cart.SUCCESS");
          },
          (err) => {
            if (err?.response?.status !== 409)
              toastError("compare:add-to-cart.ERROR");
          },
        );
      } else {
        const addToTempCart = (cart: NewCart<any>) => {
          const mutationId = getElementIdFromCart(cart) || "";
          const isNotAlreadyInCart = [...elementsWithMutations.values()].every(
            (e) => {
              const eMutationId = getElementIdFromCart(e) || "";
              return mutationId !== eMutationId;
            },
          );

          if (isNotAlreadyInCart)
            setCartMutationsBuffer((prev) => {
              const deletedInBufferIdx = prev.findIndex(
                (c) => c.mutationId === mutationId,
              );

              return deletedInBufferIdx === -1
                ? [
                    ...prev,
                    {
                      cartToAdd: cart,
                      tempCartId: randomUUID(),
                      mutationId: getElementIdFromCart(cart) || "",
                    },
                  ]
                : spliceReturn(prev, deletedInBufferIdx);
            });
        };

        for (const c of carts) {
          addToTempCart(c);
        }
        //toastSuccess("compare:add-to-cart.SUCCESS");
        return Promise.resolve();
      }
    },
    [elementsWithMutations, gtm, toastError, totalAmount, user],
  );
  const removeFromCart: CartAPI["removeFromCart"] = useCallback(
    (cartId) => {
      if (user?.id) {
        return apiDeleteFromCart(user.id, cartId).then(
          ({ data }) => {
            setElements(mapRawCartsToCarts(data));
            toastSuccess("compare:remove-from-cart.SUCCESS");
          },
          () => {
            toastError("compare:remove-from-cart.ERROR");
          },
        );
      } else {
        const elem = elementsWithMutations.get(cartId);
        const mutationId = (elem && getElementIdFromCart(elem)) || "";
        setCartMutationsBuffer((prev) =>
          prev.some((cm) => cm.mutationId === mutationId)
            ? spliceReturn(prev, (cm) => cm.mutationId === mutationId)
            : [
                ...prev,
                {
                  cartToRemove: cartId,
                  mutationId,
                },
              ],
        );
        return Promise.resolve();
      }
    },
    [elementsWithMutations, toastError, toastSuccess, user],
  );

  const submitCart: CartAPI["submitCart"] = useCallback(
    (checkout) => {
      setCheckedOutElements(elementsPerCartType);
      return apiSubmitCart(
        user!.id,
        [...elements.keys()],
        totalAmount || 0,
        prepareCheckoutToSubmit(checkout),
        [
          electricityOffer,
          gasOffer,
          internetOffer,
          insuranceOffer,
          movingOffer,
          waterOffer,
        ].filter((e) => e !== null && e !== undefined) as Checkout["elements"],
      ).then(({ data }) => {
        setElements(mapRawCartsToCarts(data));
      });
    },
    [
      electricityOffer,
      elements,
      elementsPerCartType,
      gasOffer,
      insuranceOffer,
      internetOffer,
      movingOffer,
      totalAmount,
      user,
      waterOffer,
    ],
  );

  const saveHousingData: CartAPI["saveHousingData"] = useCallback(
    (checkout, housing) => {
      return saveHousingDataRequest(user!.id, checkout, housing).then(() => {
        // Do nothing
      });
    },
    [user],
  );
  const saveContractData: CartAPI["saveContractData"] = useCallback(
    (checkout, housing, contract) => {
      return saveContractDataRequest(
        user!.id,
        checkout,
        housing,
        contract,
      ).then(() => {
        // Do nothing
      });
    },
    [user],
  );

  // NOTE: Update the LocalStorage
  useEffect(() => {
    localStorage.setItem(
      "movecool-cart",
      JSON.stringify([...elements.entries()]),
    );
  }, [elements]);
  useEffect(() => {
    localStorage.setItem(
      "movecool-mutation-buffer",
      JSON.stringify(cartMutationsBuffer),
    );
  }, [cartMutationsBuffer]);

  // NOTE: Setup and update hook
  useEffect(() => {
    setAfterLoginHooks((prev) => {
      const hook = (user: LoggedUser) =>
        cartMutationsBuffer.length > 0
          ? pushCartMutations(user.id, cartMutationsBuffer, overrideCart).then(
              ({ data }) => {
                setCartMutationsBuffer([]);
                setElements(mapRawCartsToCarts(data));
              },
              () => {
                // NOTE: Silently ignore errors
              },
            )
          : Promise.resolve();

      if (prev.some(([h]) => h === "cart-buffer-hook")) {
        return replaceInArray(prev, ([h]) => h === "cart-buffer-hook", [
          "cart-buffer-hook",
          hook,
        ]);
      } else return [...prev, ["cart-buffer-hook", hook]];
    });
  }, [cartMutationsBuffer, setAfterLoginHooks, overrideCart]);
  useEffect(() => {
    setAfterLogoutHooks((prev) => {
      const hook = () => {
        setElements(new Map());
        return Promise.resolve();
      };

      if (prev.some(([h]) => h === "clean-buffer-hook")) {
        return replaceInArray(prev, ([h]) => h === "clean-buffer-hook", [
          "clean-buffer-hook",
          hook,
        ]);
      } else return [...prev, ["clean-buffer-hook", hook]];
    });
  }, [setAfterLogoutHooks]);

  return (
    <CartContext.Provider
      value={{
        elements: elementsWithMutations,
        elementsPerCartType,
        pendingToAdd,
        cartMutationsBuffer,
        electricityOffer,
        gasOffer,
        insuranceOffer,
        internetOffer,
        totalAmount,
        nextMonthAmount,
        checkout,
        checkedOutElements,
        gtmCartItems,
        gtmUserAddress,
        getCart,
        addToCart,
        removeFromCart,
        submitCart,
        setPendingToAdd,
        setCheckout,
        setOverrideCart,
        saveHousingData,
        saveContractData,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export function withProvideCart<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvideCart(props: P) {
    return (
      <ProvideCart>
        <WrappedComponent {...props} />
      </ProvideCart>
    );
  }

  WithProvideCart.displayName = `withProvideCart(${displayName})`;

  return WithProvideCart;
}

export function useCart(): CartAPI {
  return useContext(CartContext) as CartAPI;
}
