import { Brand, FeatureCode } from "./insurance";
import ReactTooltip from "react-tooltip";
import { toFixed } from "../../numbers/formatting";
import { PricePer } from "../common";

const PriceDisplay = ({
  brand,
  priceFormat,
  togglePriceFormat,
}: {
  brand: Brand;
  priceFormat: PricePer;
  togglePriceFormat: () => void;
}): JSX.Element => {
  const franchiseDataTip =
    "Exemple: Si votre franchise est de 150 € et que vous demandez un remboursement pour un sinistre d’une valeur 500 €. Alors, vous recevrez la différence entre les deux, c'est-à-dire 500 € - 150 = 350 €. Vous recevrez 350 €.<br/>Si vous augmentez le montant de la franchise, cela diminue votre mensualité, mais recevrez moins d’argent en cas de d’indemnisation. Si vous baissez le montant de la franchise, cela augmente votre mensualité, mais recevrez plus d’argent en cas de d’indemnisation.";
  const coverageAmountDataTip =
    "Le capital mobilier, c'est la somme déclarée des valeurs de vos biens, donc le montant maximal qui sera remboursé en cas de sinistre pour tous vos biens mobilier : <ul><li>Les meubles : tables, chaises, armoires ;</li><li>La décoration : tapis, rideaux, lustres ;</li><li>Les appareils électroniques : téléviseurs, ordinateurs ;</li><li>L’électroménager : lave-vaisselle, plaque de cuisson, four ;</li></ul><br>Quand vous déclarez votre capital mobilier à votre assurance, vous lui indiquez le niveau de risque à assurer. Donc, vous estimez la somme qui serait nécessaire pour remplacer toutes vos affaires à l'identique si tous vos biens étaient détruits par un sinistre.";
  return (
    <div className={"block-price"}>
      {brand.price && (
        <>
          <p className={"computed-price"}>
            {toFixed(
              priceFormat === PricePer.Month ? brand.price / 12 : brand.price,
              2,
            )}
            &nbsp;€ /{" "}
            <button
              className={"unit-switch-btn"}
              onClick={togglePriceFormat}
              type={"button"}
            >
              <span>{priceFormat === PricePer.Month ? "mois" : "an"}</span>
            </button>
          </p>
          <p className={"price-component"}>
            <span className={"with-tooltip"}>
              <span className={"tooltip-trigger"} data-tip={franchiseDataTip}>
                ?
              </span>
              Franchise:&nbsp;
            </span>
            {brand?.calculatedFeatures?.get(FeatureCode.DeductibleAmount)
              ?.value ||
              (!brand?.price && brand?.deductibleAmount) ||
              "N/A"}
            &nbsp;€
          </p>
          <p className={"price-component"}>
            <span className={"with-tooltip"}>
              <span
                className={"tooltip-trigger"}
                data-tip={coverageAmountDataTip}
              >
                ?
              </span>
              <span>Capital mobilier:&nbsp;</span>
            </span>
            <span>
              {brand?.calculatedFeatures?.get(FeatureCode.TotalCoverageAmount)
                ?.value ||
                (!brand?.price && brand?.totalCoverageAmount) ||
                "N/A"}
              &nbsp;€
            </span>
          </p>
        </>
      )}

      <ReactTooltip class={"tooltip"} html={true} />
    </div>
  );
};

export default PriceDisplay;
