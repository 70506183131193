import { useContent } from "../content/contentProvider";
import Collapsible from "../ui/block/Collapsible";
import iconFAQ from "../../assets/img/icons/icon-questions-blue.svg";
import { FAQService } from "../content/content";
import { withLoader } from "../routing/useLoader";
import { GtmEvent, useGtm } from "../gtm/gtmProvider";

const Faq = ({ service }: { service: FAQService }): JSX.Element => {
  const { faqs } = useContent();
  const { push } = useGtm();

  const filteredFaqs = faqs.filter((faq) => faq.services.includes(service));

  return filteredFaqs.length > 0 ? (
    <div className={"faq"}>
      <div className={"faq-title"}>
        <img alt={""} className={"icon"} src={iconFAQ} />
        Foire Aux Questions
      </div>
      <div className={"faq-body"}>
        {filteredFaqs.map((faq) => (
          <Collapsible
            key={faq.id}
            className={"item"}
            onClick={() =>
              push(GtmEvent.GENERIC_EVENT, {
                event_name: "question_faq",
                question: faq.question,
              })
            }
            title={faq.question}
          >
            <div className={"item-body"}>
              <p>{faq.answer}</p>
            </div>
          </Collapsible>
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
};

const useLoad = () => {
  const { getFaqs } = useContent();
  return getFaqs;
};

export default withLoader(Faq, useLoad);
