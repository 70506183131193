import {
  ComponentType,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import { Navigate } from "react-router-dom";
import { geocodeAddress, Location } from "./place";

export interface PlaceAPI {
  currentLocation: Location | null;

  setCurrentLocation(location: Location | null): void;
  loadLocationFromAddress(address: string): Promise<void>;
}

export const PlaceContext = createContext<PlaceAPI | null>(null);

export const ProvidePlace = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [currentLocation, setCurrentLocation] = useState<
    PlaceAPI["currentLocation"]
  >(null);

  const loadLocationFromAddress: PlaceAPI["loadLocationFromAddress"] = useCallback(
    (address: string) => {
      return geocodeAddress(address).then(setCurrentLocation);
    },
    [],
  );

  return (
    <PlaceContext.Provider
      value={{
        currentLocation,
        setCurrentLocation,
        loadLocationFromAddress,
      }}
    >
      {children}
    </PlaceContext.Provider>
  );
};

export function withProvidePlace<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvidePlace(props: P) {
    return (
      <ProvidePlace>
        <WrappedComponent {...props} />
      </ProvidePlace>
    );
  }

  WithProvidePlace.displayName = `withProvidePlace(${displayName})`;

  return WithProvidePlace;
}

export function withoutLocationRedirect<P, C extends React.ComponentType<P>>(
  WrappedComponent: C,
  to: string,
): C {
  return ((props: P) => {
    const { currentLocation } = usePlace();

    if (currentLocation === null) return <Navigate to={to} />;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <WrappedComponent {...(props as P)} />;
  }) as C;
}

export function usePlace(): PlaceAPI {
  return useContext(PlaceContext) as PlaceAPI;
}
