import {
  ComponentType,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import { getFaqs as apiGetFaqs } from "./api";
import { Faq, mapRawFaqsToFaqs } from "./content";

export interface ContentAPI {
  faqs: Faq[];

  getFaqs(): Promise<void>;
  loadAll(): Promise<void>;
}

export const ContentContext = createContext<ContentAPI | null>(null);

export const ProvideContent = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [faqs, setFaqs] = useState<ContentAPI["faqs"]>([]);

  const getFaqs = useCallback(
    () => apiGetFaqs().then(({ data }) => setFaqs(mapRawFaqsToFaqs(data))),
    [],
  );
  const loadAll = useCallback(async () => {
    await Promise.all([getFaqs()]);
  }, [getFaqs]);

  return (
    <ContentContext.Provider
      value={{
        faqs,
        getFaqs,
        loadAll,
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};

export function withProvideContent<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvideContent(props: P) {
    return (
      <ProvideContent>
        <WrappedComponent {...props} />
      </ProvideContent>
    );
  }

  WithProvideContent.displayName = `withProvideContent(${displayName})`;

  return WithProvideContent;
}

export function useContent(): ContentAPI {
  return useContext(ContentContext) as ContentAPI;
}
