import ComparatorListItem from "../../ui/common-components/ComparatorListItem";
import { PricePer } from "../common";
import { useMoving } from "../moving/movingProvider";
import { Cart, CartType } from "./cart";
import { useCart } from "./cartProvider";

const MovingSection = ({
  elements,
  priceFormat,
  togglePriceFormat,
  disableRemove = false,
}: {
  elements: Cart<CartType.Moving>[];
  priceFormat?: PricePer;
  togglePriceFormat?: () => void;
  disableRemove?: boolean;
}): JSX.Element => {
  const { brands } = useMoving();
  const { removeFromCart } = useCart();

  return (
    <div className={"section"}>
      <h2 className={"cart-section-title"}>Mon déménagement</h2>

      {elements.length > 1 && (
        <div className={"info info-warning-soft full-w m-bottom-xs"}>
          Veuillez ne conserver qu’une seule de ces offres pour passer à l’étape
          suivante
        </div>
      )}

      <div className={"offers-list"}>
        {elements.map((element) => (
          <ComparatorListItem
            key={element.id}
            brand={
              element.data.submittedBrandId
                ? brands.get(element.data.submittedBrandId)
                : null
            }
            calendlyContext={"moving"}
            cart={element}
            onDelete={
              disableRemove ? undefined : (cartId) => removeFromCart(cartId)
            }
            pricePer={priceFormat}
            togglePricePer={togglePriceFormat}
          />
        ))}
      </div>
    </div>
  );
};

export default MovingSection;
