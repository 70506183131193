import { loggerBuilder } from "../logger";

const LOWER_A_CHARCODE = "a".charCodeAt(0);
const ZERO_CHARCODE = "0".charCodeAt(0);

const logger = loggerBuilder("crypto");

export function getRandomChars(count: number) {
  const lowerAlphas = [...Array(26)].map((_, idx) => LOWER_A_CHARCODE + idx);
  const nums = [...Array(10)].map((_, idx) => ZERO_CHARCODE + idx);

  const allChars = String.fromCharCode(...[...lowerAlphas, ...nums]);
  return [...Array(count)]
    .map(() => allChars[Math.floor(Math.random() * allChars.length)])
    .join("");
}

export function randomUUID() {
  const c = crypto as any;
  if (c.randomUUID) return c.randomUUID();
  else {
    logger.warn(
      "Using shim for crypto.randomUUID, it's pseudo-random and should not be used for secure or mathematical operations.",
    );
    return `${getRandomChars(8)}-${getRandomChars(4)}-${getRandomChars(
      4,
    )}-${getRandomChars(4)}-${getRandomChars(12)}`;
  }
}
