import ProfilePic1 from "../../../assets/img/teams/energy/lena.png";
import ProfilePic2 from "../../../assets/img/teams/energy/robin.png";
import ProfilePic3 from "../../../assets/img/teams/energy/theo.png";
import ProfilePic4 from "../../../assets/img/teams/energy/noah.png";
import ProfilePic5 from "../../../assets/img/teams/energy/tabara.png";
import ProfilePic6 from "../../../assets/img/teams/energy/arthur.png";
import ProfilePic7 from "../../../assets/img/teams/energy/alisson.png";
import ProfilePic8 from "../../../assets/img/teams/energy/anais.png";
import { useAuth } from "src/services/auth/apiProvider";

const EnergyTeam = () => {
  const { generateCalendlyLink } = useAuth();
  return (
    <div className={"team"}>
      <h2 className={"title"}>
        Une équipe disponible 6&nbsp;jours&nbsp;sur&nbsp;7
      </h2>
      <div className={"ctas"}>
        <a className={"btn-outlined"} href={"tel:+33980805110"}>
          09 80 80 51 10
        </a>
        <a
          className={"btn-1"}
          href={generateCalendlyLink("", "energy")}
          rel={"noreferrer"}
          target={"_blank"}
        >
          Être rappelé
        </a>
      </div>
      <div className={"teammates"}>
        <div className={"teammate"}>
          <img alt={"Sandra"} src={ProfilePic1} />
          <p className={"name"}>Léna</p>
          <p className={"position"}>Expert Électricité</p>
        </div>
        <div className={"teammate"}>
          <img alt={"Gabriel"} src={ProfilePic2} />
          <p className={"name"}>Robin</p>
          <p className={"position"}>Expert Électricité</p>
        </div>
        <div className={"teammate"}>
          <img alt={"Lohan"} src={ProfilePic3} />
          <p className={"name"}>Théo</p>
          <p className={"position"}>Expert Électricité</p>
        </div>
        <div className={"teammate"}>
          <img alt={"Léa"} src={ProfilePic4} />
          <p className={"name"}>Noah</p>
          <p className={"position"}>Expert Électricité</p>
        </div>
        <div className={"teammate"}>
          <img alt={"Sandra"} src={ProfilePic5} />
          <p className={"name"}>Tabara</p>
          <p className={"position"}>Expert Énergie Verte</p>
        </div>
        <div className={"teammate"}>
          <img alt={"Gabriel"} src={ProfilePic6} />
          <p className={"name"}>Arthur</p>
          <p className={"position"}>Expert Énergie Verte</p>
        </div>
        <div className={"teammate"}>
          <img alt={"Lohan"} src={ProfilePic7} />
          <p className={"name"}>Alisson</p>
          <p className={"position"}>Resp. Activation</p>
        </div>
        <div className={"teammate"}>
          <img alt={"Léa"} src={ProfilePic8} />
          <p className={"name"}>Anaïs</p>
          <p className={"position"}>Team Leader Énergie</p>
        </div>
      </div>
    </div>
  );
};

export default EnergyTeam;
