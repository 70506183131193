import { DetailedHTMLProps, HTMLAttributes } from "react";
import { cx } from "@emotion/css";

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const Card = ({ className, ...cardProps }: Props): JSX.Element => {
  return <div {...cardProps} className={cx("card", className)} />;
};

export default Card;
