import axios from "axios";

interface Props {
  reload(): void;
  error: unknown;
}

const LoaderErrors = ({ reload, error }: Props): JSX.Element => {
  if (axios.isAxiosError(error)) {
    return (
      <div className={"info info-error center"}>
        <h6 className={"title-xs"}>
          Une erreur est survenue <small>({error.message})</small>
        </h6>
        <p>Ceci est probablement temporaire, vous pouvez essayer de</p>
        <div>
          <button className={"btn-1 btn-s"} onClick={reload}>
            Recharger les données
          </button>
        </div>

        <p>
          <small>
            Si le problème persiste, n'hésitez pas à nous contacter au 09 80 51
            51 10 afin que nous puissions vous dépanner au plus vite !
          </small>
        </p>
      </div>
    );
  }
  return (
    <div className={"info info-error"}>
      <p>Une erreur est survenue</p>
      <p>
        Ceci est probablement temporaire, vous pouvez essayer de
        <button className={"btn-1"} onClick={reload}>
          Recharger les données
        </button>
      </p>
      <p>
        <small>
          Si le problème persiste, n'hésitez pas à nous contacter au 09 80 51 51
          10 afin que nous puissions vous dépanner au plus vite !
        </small>
      </p>
    </div>
  );
};

export default LoaderErrors;
