import { DetailedHTMLProps, HTMLAttributes } from "react";
import { cx } from "@emotion/css";

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  size?: "xs" | "s";
}

const Section = ({ className, size, ...sectionProps }: Props): JSX.Element => {
  return (
    <div
      {...sectionProps}
      className={cx(className, size ? `section-${size}` : "section")}
    />
  );
};

export default Section;
