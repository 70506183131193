import { useCallback } from "react";
import { toFixed } from "../../numbers/formatting";
import { PricePer } from "../common";
import ConsumptionFormatButton from "./ConsumptionFormatButton";
import { useConsumption } from "./consumptionProvider";
import { EnergyType } from "./energy";

const PriceDisplay = ({
  offer,
  priceFormatOverride,
  electricityConsumptionOverride,
  gasConsumptionOverride,
}: {
  offer?: any;
  priceFormatOverride?: PricePer;
  electricityConsumptionOverride?: number;
  gasConsumptionOverride?: number;
}): JSX.Element => {
  const {
    electricityConsumption: providedElectricityConsumption,
    gasConsumption: providedGasConsumption,
    priceFormat,
  } = useConsumption();

  const electricityConsumption =
    electricityConsumptionOverride || providedElectricityConsumption;
  const gasConsumption = gasConsumptionOverride || providedGasConsumption;
  const finalPriceFormat = priceFormatOverride || priceFormat;

  const OfferPrice = useCallback(
    ({ offer }: { offer: any }) => {
      if (offer.type === EnergyType.Electricity) {
        if (electricityConsumption !== null) {
          const price = (offer.price * electricityConsumption) / 100;

          return (
            <div>
              <p className={"computed-price"}>
                {toFixed(
                  finalPriceFormat === PricePer.Month
                    ? price + offer.subscription
                    : (price + offer.subscription) * 12,
                  2,
                )}
                &nbsp;€ <ConsumptionFormatButton />
              </p>
            </div>
          );
        } else {
          return (
            <div>
              <div className={"section-xs"}>
                <p className={"price-component"}>
                  Prix du kWh TTC :{" "}
                  <strong> {toFixed(offer.price / 100, 4)} € / kWh</strong>
                </p>

                <p className={"price-component"}>
                  Abonnement TTC :{" "}
                  <strong>
                    {finalPriceFormat === PricePer.Month
                      ? `${offer.subscription}\xa0€ / mois`
                      : `${toFixed(offer.subscription * 12, 2)}\xa0€ / an`}
                  </strong>
                </p>
              </div>
            </div>
          );
        }
      }

      if (offer.type === EnergyType.Gas) {
        if (gasConsumption !== null) {
          const price = (offer.price * gasConsumption) / 100;

          return (
            <div>
              <p className={"computed-price"}>
                {toFixed(
                  finalPriceFormat === PricePer.Month
                    ? price + offer.subscription
                    : (price + offer.subscription) * 12,
                  2,
                )}
                € <ConsumptionFormatButton />
              </p>
            </div>
          );
        } else {
          return (
            <div>
              <div className={"section-xs"}>
                <p className={"price-component"}>
                  Prix du kWh TTC :{" "}
                  <strong> {toFixed(offer.price / 100, 4)} € / kWh</strong>
                </p>

                <p className={"price-component"}>
                  Abonnement TTC :{" "}
                  <strong>
                    {finalPriceFormat === PricePer.Month
                      ? `${offer.subscription} € / mois`
                      : `${toFixed(offer.subscription * 12, 2)} € / an`}
                  </strong>
                </p>
              </div>
            </div>
          );
        }
      }

      if (
        offer.electricityOffer && // TEMP check for dual offers
        electricityConsumption !== null &&
        gasConsumption !== null
      ) {
        <p className={"computed-price"}>
          {toFixed(
            finalPriceFormat === PricePer.Month
              ? (offer.gasOffer.price * gasConsumption +
                  offer.electricityOffer.price * electricityConsumption) /
                  100 +
                  offer.gasOffer.subscription +
                  offer.electricityOffer.subscription
              : ((offer.gasOffer.price * gasConsumption +
                  offer.electricityOffer.price * electricityConsumption) /
                  100 +
                  offer.gasOffer.subscription +
                  offer.electricityOffer.subscription) *
                  12,
            2,
          )}
          € <ConsumptionFormatButton />
        </p>;
      }

      return null;
    },
    [electricityConsumption, gasConsumption, finalPriceFormat],
  );

  return (
    <div className={"block-price"}>
      {electricityConsumption !== null && gasConsumption !== null && (
        <p className={"price-label"}>Prix estimé</p>
      )}

      {offer?.electricityOffer ? ( // TEMP check for dual offers
        electricityConsumption !== null &&
        gasConsumption !== null && (
          <div>
            <p className={"computed-price"}>
              {toFixed(
                finalPriceFormat === PricePer.Month
                  ? (offer.gasOffer.price * gasConsumption +
                      offer.electricityOffer.price * electricityConsumption) /
                      100 +
                      offer.gasOffer.subscription +
                      offer.electricityOffer.subscription
                  : ((offer.gasOffer.price * gasConsumption +
                      offer.electricityOffer.price * electricityConsumption) /
                      100 +
                      offer.gasOffer.subscription +
                      offer.electricityOffer.subscription) *
                      12,
                2,
              )}
              € <ConsumptionFormatButton />
            </p>
          </div>
        )
      ) : offer ? (
        <OfferPrice offer={offer} />
      ) : null}
    </div>
  );
};

export default PriceDisplay;
