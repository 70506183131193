import { boolean, object, string } from "yup";
import {
  CART_PERSONAL_INFOS_LINK,
  CART_SUBMITTED_LINK,
} from "../../../routes/cart";
import { useCart } from "../../../services/compare/cart/cartProvider";
import AutoField from "../../../services/forms/AutoField";
import Form from "../../../services/forms/Form";
import SubmitButton from "../../../services/forms/SubmitButton";
import ToggleField from "../../../services/forms/ToggleField";
import ValidationsErrors from "../../../services/forms/ValidationsErrors";
import Link from "../../../services/routing/components/Link";
import useNavigate from "../../../services/routing/useNavigate";
import { useKeepQueryParams } from "../../../services/routing/useQueryParams";
import OrderByPhone from "src/services/common-components/OrderByPhone";
import CartReinsuranceBlock from "src/services/ui/common-components/CartReinsuranceBlock";
import { useEffect, useCallback, useMemo } from "react";
import { GtmEvent, useGtm } from "../../../services/gtm/gtmProvider";
import { useAuth } from "../../../services/auth/apiProvider";
import {
  ClientType,
  HabitationUsage,
  HousingType,
} from "../../../services/compare/insurance/insurance";
import WhyMovecool from "src/services/common-components/WhyMovecool";

const CartContract = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {
    checkout,
    setCheckout,
    submitCart,
    totalAmount,
    gtmCartItems,
  } = useCart();
  const keepQueryParams = useKeepQueryParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { push } = useGtm();

  const regexIban = useMemo(
    () => new RegExp("[A-Z]{2}[0-9]{2}[A-Z0-9 ]{10,28}"),
    [],
  );

  const regexBic = useMemo(
    () => new RegExp("[A-Z]{6,6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3,3}){0,1}"),
    [],
  );

  const modulo = useCallback((divident, divisor) => {
    const partLength = 10;
    while (divident.length > partLength) {
      const part = divident.substring(0, partLength);
      divident = (part % divisor) + divident.substring(partLength);
    }
    return divident % divisor;
  }, []);

  const checkIban = useCallback(
    (iban: string | undefined | null) => {
      if (!iban || iban === null) return true;

      iban = iban.replace(/ /g, "");
      if (!regexIban.test(iban)) return false;

      const countryCode = iban.slice(0, 2);
      const key = iban.slice(2, 4);
      const trail = iban.slice(4);
      let convertedIban = trail + countryCode + "00";

      convertedIban = convertedIban.replaceAll(/[A-Z]/g, (m) =>
        String(m.charCodeAt(0) - 64 + 9),
      );

      const mod = modulo(convertedIban, 97);
      const checksum = 98 - mod;

      return checksum === Number(key);
    },
    [modulo, regexIban],
  );

  const checkBic = useCallback(
    (bic: string | undefined | null) => {
      return !bic || regexBic.test(bic);
    },
    [regexBic],
  );

  const { generateCalendlyLink } = useAuth();

  // const [isProcessing, setIsProcessing] = useState(false);

  return (
    <div className={"grid container-wrap"}>
      <div className={"checkout-top-nav"}>
        <Link
          className={"back-link"}
          to={keepQueryParams(CART_PERSONAL_INFOS_LINK)}
        >
          &lt; Retour à l'étape précédente
        </Link>
      </div>

      <div className={"col-m-2-3"}>
        <div className={"section"}>
          <h2 className={"cart-section-title"}>Validation</h2>
          <div className={"card"}>
            <div className={"card-body"}>
              <Form
                initialValues={{
                  iban: checkout.contract?.iban || null,
                  bic: checkout.contract?.bic || null,
                  bankingInstitution:
                    checkout.contract?.bankingInstitution || null,
                  bankingHolderIsUser: !!checkout.contract?.bankingHolderIsUser,
                }}
                onSubmit={(values) => {
                  setCheckout({
                    ...checkout,
                    contract: values as any,
                  });
                  push(GtmEvent.CHECKOUT, {
                    event_name: "add_payment_info",
                    ecommerce: {
                      value: totalAmount,
                      currency: "EUR",
                      items: gtmCartItems,
                      user_data: {
                        email: user?.email,
                        phone: checkout.userData?.phoneNumber,
                        user_firstname: checkout.userData?.firstname,
                        user_lastname: checkout.userData?.lastname,
                      },
                      home_data: {
                        property_zip: checkout.home?.address.postCode,
                        address: checkout.home?.address.plainAddress,
                        rooms: checkout.home?.rooms,
                        surface: checkout.home?.surface,
                        item_category2:
                          checkout.home?.type === HousingType.Flat
                            ? "Appartement"
                            : "Maison",
                        item_category3:
                          (checkout.home?.clientType as ClientType | null) ===
                          ClientType.Owner
                            ? "Propriétaire"
                            : "Locataire",
                        item_category4:
                          checkout.home?.usage === HabitationUsage.Main
                            ? "Principale"
                            : checkout.home?.usage ===
                              HabitationUsage.NonOccupantOwner
                            ? "Non occupant"
                            : "Secondaire",
                      },
                      dates: checkout.dates,
                    },
                  });
                  return submitCart({
                    ...checkout,
                    contract: values as any,
                  }).then(() => {
                    navigate(keepQueryParams(CART_SUBMITTED_LINK));
                  });
                }}
                schema={object({
                  withdrawalRightsRenunciation: boolean().label(
                    "Je renonce expressément à mon droit de rétractation de 14 jours pour que mon contrat puisse prendre effet au plus vite. Le contrat est non-engageant et sa résiliation est gratuite à tout moment.",
                  ),
                  iban: string()
                    .label("IBAN (facultatif)")
                    .test("", (value) => checkIban(value))
                    .nullable(),
                  bic: string()
                    .label("BIC (facultatif)")
                    .nullable()
                    .test("", (value) => checkBic(value)),
                  // bankingInstitution: string()
                  //   .label("Nom de l'établissement bancaire")
                  //   .required()
                  //   .test("", (value) => value.length >= 3),
                  // bankingHolderIsUser: boolean()
                  //   .label(
                  //     "Le titulaire du compte bancaire est le titulaire de l'abonnement",
                  //   )
                  //   .required(),
                  acceptance: boolean()
                    .label(
                      "Autoriser la marque à prélever, sur le compte bancaire désigné ci-dessus, les sommes dues au titre de votre contrat. À ce titre, vous reconnaissez votre obligation de paiement. *",
                    )
                    .required(() => ({ key: "direct-debit-authorization" }))
                    .oneOf([true], () => ({
                      key: "direct-debit-authorization",
                    })),
                })}
              >
                <div className={"section-s"}>
                  <div className={"filter-group"}>
                    <ToggleField name={"withdrawalRightsRenunciation"} />
                  </div>
                </div>
                <div className={"section-s"}>
                  <AutoField
                    name={"iban"}
                    placeholder={"27 chiffres et lettres sur votre RIB"}
                  />
                  <AutoField
                    name={"bic"}
                    placeholder={"8 lettres sur votre RIB"}
                  />
                  {/* <AutoField name={"bankingInstitution"} /> */}
                </div>

                <div className={"section-s"}>
                  {/* <div className={"filter-group"}>
                    <ToggleField name={"bankingHolderIsUser"} />
                  </div> */}

                  <div className={"filter-group"}>
                    <ToggleField name={"acceptance"} />
                  </div>
                </div>

                <ValidationsErrors />

                <div className={"form-footer"}>
                  <div className={"buttons-row right --v-center"}>
                    {/* <button
                      className={"btn-outlined cart-btn"}
                      disabled={isProcessing}
                      onClick={() => {
                        setIsProcessing(true);
                        submitCart({
                          ...checkout,
                        }).then(() => {
                          navigate(keepQueryParams(CART_SUBMITTED_LINK));
                        });
                      }}
                      type={"button"}
                    >
                      {isProcessing
                        ? "Envoi en cours..."
                        : "Ignorer cette étape"}
                    </button> */}
                    <a
                      className={"link"}
                      href={generateCalendlyLink("", "header")}
                      rel={"noreferrer"}
                      target={"_blank"}
                    >
                      J'ai encore des questions
                    </a>
                    <SubmitButton>Confirmer</SubmitButton>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <div className={"section"}>
          <WhyMovecool />
        </div>
      </div>

      <div className={"col-m-1-3"}>
        <div className={"section"}>
          <h2 className={"cart-section-title"}>&nbsp;</h2>
          <CartReinsuranceBlock />
        </div>
        <OrderByPhone />
      </div>

      {/* <div className={"section"}>
        <h2 className={"page-section-title"}>
          Que se passe-t-il une fois ma commande confirmée ?
        </h2>
        <NextSteps />
      </div> */}

      <div className={"checkout-nav"}>
        <Link
          className={"back-link"}
          to={keepQueryParams(CART_PERSONAL_INFOS_LINK)}
        >
          &lt; Retour à l'étape précédente
        </Link>
      </div>

      {/* <div className={"checkout-disclaimer"}>
        En passant commande vous acceptez nos{" "}
        <a
          href={
            "https://movecool.notion.site/Mentions-L-gales-Conditions-G-n-rales-d-utilisation-7de1bc001712449fb83908486d769537"
          }
          rel={"noreferrer"}
          target={"_blank"}
        >
          conditions générales d'utilisation et de vente,
        </a>{" "}
        notre{" "}
        <a
          href={
            "https://movecool.notion.site/Politique-de-confidentialit-gestion-des-cookies-48624257b4754a498d8727d8efd135d5"
          }
          rel={"noreferrer"}
          target={"_blank"}
        >
          politique de protection des données
        </a>{" "}
        ainsi que les conditions tarifaires des offres auxquelles vous
        souscrivez.
      </div> */}
    </div>
  );
};

export default CartContract;
