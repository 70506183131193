import { useState } from "react";
import ComparatorListItem from "../../ui/common-components/ComparatorListItem";
import { PricePer } from "../common";
import { Brand } from "../insurance/insurance";
// import InsuranceDetailsDialog from "../insurance/InsuranceDetailsDialog";
import { useInsurance } from "../insurance/insuranceProvider";
import { Cart, CartType } from "./cart";
import { useCart } from "./cartProvider";
import iconInsurance from "../../../assets/img/icons/icon-insurance-plain.svg";

const InsuranceSection = ({
  elements,
  priceFormat,
  togglePriceFormat,
  disableRemove = false,
}: {
  elements: Cart<CartType.Insurance>[];
  priceFormat?: PricePer;
  togglePriceFormat?: () => void;
  disableRemove?: boolean;
}): JSX.Element => {
  const { brands } = useInsurance();
  const { removeFromCart } = useCart();
  const [, setSelectedBrand] = useState<Brand | null>(null);

  return (
    <div className={"section"}>
      <h2 className={"cart-section-title"}>
        <img alt={"assurance"} className={"icon"} src={iconInsurance} />
        Mon assurance habitation
      </h2>

      {elements.length > 1 && (
        <div className={"info info-warning-soft full-w m-bottom-xs"}>
          Veuillez ne conserver qu’une seule de ces offres pour passer à l’étape
          suivante
        </div>
      )}

      <div className={"offers-list"}>
        {elements.map((element) => {
          const brand = brands.get(element.data.brandId) || null;

          return (
            <ComparatorListItem
              key={element.id}
              brand={{
                ...brand,
                price: element.data.price,
                simulatedPrice: element.data.simulatedPrice,
                calculatedFeatures: new Map(element.data.calculatedFeatures),
              }}
              calendlyContext={"insurance"}
              cart={element}
              dataCompleteCondition={true}
              onDelete={
                disableRemove ? undefined : (cartId) => removeFromCart(cartId)
              }
              pricePer={priceFormat}
              togglePricePer={togglePriceFormat}
              viewMore={() => setSelectedBrand(brand)}
            />
          );
        })}
      </div>
      {/* {selectedBrand && (
        <InsuranceDetailsDialog
          brand={selectedBrand}
          cart={true}
          onClose={() => setSelectedBrand(null)}
        />
      )} */}
    </div>
  );
};

export default InsuranceSection;
