import {
  ComponentType,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import { getBrands as apiGetBrands } from "./api";
import { Brand, mapRawBrandsToBrands } from "./water";

export interface WaterAPI {
  brands: Map<string, Brand>;

  getBrands(): Promise<void>;
  loadAll(): Promise<void>;
}

export const WaterContext = createContext<WaterAPI | null>(null);

export const ProvideWater = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [brands, setBrands] = useState<WaterAPI["brands"]>(new Map());

  const getBrands = useCallback(
    () =>
      apiGetBrands().then(({ data }) => setBrands(mapRawBrandsToBrands(data))),
    [],
  );
  const loadAll = useCallback(async () => {
    await Promise.all([getBrands()]);
  }, [getBrands]);

  return (
    <WaterContext.Provider
      value={{
        brands,
        getBrands,
        loadAll,
      }}
    >
      {children}
    </WaterContext.Provider>
  );
};

export function withProvideWater<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvideWater(props: P) {
    return (
      <ProvideWater>
        <WrappedComponent {...props} />
      </ProvideWater>
    );
  }

  WithProvideWater.displayName = `withProvideWater(${displayName})`;

  return WithProvideWater;
}

export function useWater(): WaterAPI {
  return useContext(WaterContext) as WaterAPI;
}
