import {
  ComponentType,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { usePlace } from "../../google-maps/placeProvider";
import {
  getBrands as apiGetBrands,
  getOffers as apiGetOffers,
  getEligibility as apiGetElegibility,
} from "./api";
import {
  Brand,
  Elegibility,
  InternetOperator,
  InternetTechnology,
  mapRawBrandsToBrands,
  mapRawElegibitiesToElegibilities,
  mapRawOffersToOffers,
  Offer,
  OfferWithBrand,
} from "./internet";

export interface InternetAPI {
  offers: Map<string, Offer>;
  brands: Map<string, Brand>;
  offersWithBrands: Map<string, OfferWithBrand>;
  elegibilities: Elegibility[];
  availableTechnologies: Set<InternetTechnology>;
  bestElegibilityByOp: [InternetOperator, Elegibility][];
  bestAvailableTechnology: InternetTechnology | null;

  getOffers(): Promise<void>;
  getBrands(): Promise<void>;
  loadAll(): Promise<void>;

  getElegibility(): Promise<void>;
}

export const InternetContext = createContext<InternetAPI | null>(null);

export const ProvideInternet = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const { currentLocation } = usePlace();

  const [offers, setOffers] = useState<InternetAPI["offers"]>(new Map());
  const [brands, setBrands] = useState<InternetAPI["brands"]>(new Map());
  const [elegibilities, setElegibilities] = useState<
    InternetAPI["elegibilities"]
  >([]);

  const getOffers = useCallback(
    () =>
      apiGetOffers().then(({ data }) => setOffers(mapRawOffersToOffers(data))),
    [],
  );
  const getBrands = useCallback(
    () =>
      apiGetBrands().then(({ data }) => setBrands(mapRawBrandsToBrands(data))),
    [],
  );
  const getElegibility = useCallback(() => {
    return currentLocation
      ? apiGetElegibility(currentLocation).then(({ data }) =>
          setElegibilities(mapRawElegibitiesToElegibilities(data)),
        )
      : Promise.resolve();
  }, [currentLocation]);
  const loadAll = useCallback(async () => {
    await Promise.all([getOffers(), getBrands()]);
  }, [getOffers, getBrands]);

  const availableTechnologies = useMemo(
    () => new Set(elegibilities.map((el) => el.tech_code)),
    [elegibilities],
  );

  const bestElegibilityByOp = useMemo(() => {
    const elegibilitiesByOp = elegibilities
      .filter((elegibility) =>
        Object.values(InternetOperator).includes(elegibility.op_code),
      )
      .reduce((acc, el) => {
        const previousElegibility: Elegibility | undefined = acc[el.op_code];
        return {
          ...acc,
          [el.op_code]: previousElegibility
            ? previousElegibility.tech_code === InternetTechnology.Fiber
              ? previousElegibility
              : el
            : el,
        };
      }, {} as Record<InternetOperator, Elegibility>);

    return Object.entries(
      elegibilitiesByOp,
    ) as InternetAPI["bestElegibilityByOp"];
  }, [elegibilities]);

  const bestAvailableTechnology = useMemo(
    () =>
      [...availableTechnologies.values()].reduce(
        (best, tech) => (best !== null && best < tech ? best : tech),
        null as null | InternetTechnology,
      ),
    [availableTechnologies],
  );

  const offersWithBrands: InternetAPI["offersWithBrands"] = useMemo(() => {
    const newOffers = [...offers.values()];

    return new Map(
      newOffers
        .filter((offer) => brands.has(offer.brandId))
        .map((offer) => [
          offer.id,
          { ...offer, brand: brands.get(offer.brandId) as Brand },
        ]),
    );
  }, [brands, offers]);

  return (
    <InternetContext.Provider
      value={{
        offers,
        brands,
        offersWithBrands,
        elegibilities,
        availableTechnologies,
        bestElegibilityByOp,
        bestAvailableTechnology,
        getOffers,
        getBrands,
        loadAll,
        getElegibility,
      }}
    >
      {children}
    </InternetContext.Provider>
  );
};

export function withProvideInternet<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvideInternet(props: P) {
    return (
      <ProvideInternet>
        <WrappedComponent {...props} />
      </ProvideInternet>
    );
  }

  WithProvideInternet.displayName = `withProvideInternet(${displayName})`;

  return WithProvideInternet;
}

export function useInternet(): InternetAPI {
  return useContext(InternetContext) as InternetAPI;
}
