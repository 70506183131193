import React, { useEffect, useState } from "react";
import withRedirectToPrivate from "../../services/auth/hocs/withRedirectToPrivate";
import { useToastsWithIntl } from "../../services/toast-notifications";
import useQueryParams from "../../services/routing/useQueryParams";
import LoginForm from "../../services/auth/components/LoginForm";
import { useAuth } from "../../services/auth/apiProvider";
import { useNavigate } from "react-router-dom";
import CardHead from "../../services/ui/block/CardHead";
import CardBody from "../../services/ui/block/CardBody";
import Card from "../../services/ui/block/Card";

const Login = (): JSX.Element => {
  const { login, validateUserRegistration } = useAuth();
  const { toastError } = useToastsWithIntl(["auth"]);
  const [accountValidated, setAccountValidated] = useState(false);
  const [{ guid, redirectTo }] = useQueryParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof guid === "string") {
      validateUserRegistration(guid).then(
        () => setAccountValidated(true),
        () => toastError("auth:validate-user-registration"),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guid]);

  return (
    <Card className={"auth-card theme-light --max-600"}>
      <CardHead title={"Connexion"} />

      <CardBody>
        {guid && accountValidated && (
          <p>
            Votre compte a bien été validé, vous pouvez désormais vous
            connecter.
          </p>
        )}

        <LoginForm
          onSubmit={(loginInfos) => {
            return login(loginInfos).then(
              () => {
                if (redirectTo && typeof redirectTo === "string") {
                  navigate(redirectTo);
                }
              },
              () => {
                toastError("auth:login.TOAST_ERROR");
              },
            );
          }}
        />
      </CardBody>
    </Card>
  );
};

export default withRedirectToPrivate(Login);
