import { AxiosPromise } from "axios";
import { User } from "../../auth/user";
import { publicAPI } from "../publicApi";
import {
  AcheelQuote,
  AppeninQuote,
  FlatLevel,
  HabitationUsage,
  LeocareQuote,
  LovysQuote,
  LukoQuote,
} from "./insurance";

export function getBrands(): AxiosPromise<unknown[]> {
  return publicAPI.get("/insurance/brands");
}

export interface Quote {
  floor: FlatLevel;
  habitationUsage: HabitationUsage;
  isHouse: boolean;
  isOwner: boolean;
  roomsCount: number;
  surface: number;
  address: string;
  postCode: string;
  city: string;
  totalGoodCoverage: number | null;
  deductible: number | null;
  valueableCoverage: number | null;
  theftCoverage: boolean;
  brokenGlassCoverage: boolean;
  assistanceCoverage: boolean;
  lockReplacementCoverage: boolean;
  schoolCoverage: boolean;
  asNewCoverage: boolean;
}

export type PartialQuote = Partial<
  Omit<Quote, "address" | "city" | "postCode">
> &
  Pick<Quote, "address" | "city" | "postCode">;

export function getQuotes(
  quote: Quote,
): AxiosPromise<{
  lovys: LovysQuote[] | null;
  luko: LukoQuote[] | null;
  appenin: AppeninQuote | null;
  acheel: AcheelQuote[] | null;
  leocare: LeocareQuote[] | null;
}> {
  return publicAPI.post("/insurance/quote", quote);
}

export function getAcheelUrl(
  quoteId: AcheelQuote["code"],
  personalInfo: Pick<User, "email" | "firstname" | "lastname" | "phoneNumber">,
): AxiosPromise<{ url: string }> {
  return publicAPI.post(`/insurance/acheel/${quoteId}`, personalInfo);
}
