import React, { useCallback } from "react";
import { Route, useLocation } from "react-router-dom";
import {
  CART_CONTRACT,
  CART_CONTRACT_LINK,
  CART_LINK,
  CART_PERSONAL_INFOS,
  CART_PERSONAL_INFOS_LINK,
  CART_SUBMITTED,
} from "../../routes/cart";
// import { CartType } from "../../services/compare/cart/cart";
import { useCart } from "../../services/compare/cart/cartProvider";
import { useEnergy } from "../../services/compare/energy/energyProvider";
import { useInsurance } from "../../services/compare/insurance/insuranceProvider";
import { useInternet } from "../../services/compare/internet/internetProvider";
import { useWater } from "../../services/compare/water/waterProvider";
import NavLink from "../../services/routing/components/NavLink";
import Routes from "../../services/routing/Routes";
import { withLoader } from "../../services/routing/useLoader";
import { useKeepQueryParams } from "../../services/routing/useQueryParams";
import CartContract from "./cart/CartContract";
import CartPersonalInfos from "./cart/CartPersonalInfos";
import CartSubmitted from "./cart/CartSubmitted";
import ViewCart from "./cart/ViewCart";

const Cart = (): JSX.Element => {
  const { totalAmount, checkout } = useCart();
  const keepQueryParams = useKeepQueryParams();
  const location = useLocation();

  return (
    <div>
      {location.pathname !== "/cart/submitted/" && (
        <div className={"step-tabs"}>
          <NavLink className={"tab-item"} to={keepQueryParams(CART_LINK)}>
            Votre offre
          </NavLink>
          <fieldset disabled={totalAmount === null}>
            <NavLink
              className={"tab-item"}
              to={keepQueryParams(CART_PERSONAL_INFOS_LINK)}
            >
              Vos informations
            </NavLink>
          </fieldset>
          <fieldset
            disabled={
              totalAmount === null || (checkout.dates || []).length === 0
            }
          >
            <NavLink
              className={"tab-item"}
              to={keepQueryParams(CART_CONTRACT_LINK)}
            >
              Validation
            </NavLink>
          </fieldset>
        </div>
      )}

      <Routes>
        <Route element={<ViewCart />} index />
        <Route element={<CartPersonalInfos />} path={CART_PERSONAL_INFOS} />
        <Route element={<CartContract />} path={CART_CONTRACT} />
        <Route element={<CartSubmitted />} path={CART_SUBMITTED} />
      </Routes>
    </div>
  );
};

const useLoadCart = () => {
  const { getCart } = useCart();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(() => getCart(), []);
};

const useLoadElements = () => {
  const { loadAll: loadEnergy } = useEnergy();
  const { loadAll: loadInsurance } = useInsurance();
  const { loadAll: loadInternet } = useInternet();
  const { loadAll: loadWater } = useWater();

  return useCallback(() => {
    const promises_to_run = [];

    promises_to_run.push(loadEnergy());
    promises_to_run.push(loadInsurance());
    promises_to_run.push(loadInternet());
    promises_to_run.push(loadWater());

    return Promise.all(promises_to_run).then(() => Promise.resolve());
  }, [loadEnergy, loadInsurance, loadInternet, loadWater]);
};

export default withLoader(withLoader(Cart, useLoadElements), useLoadCart);
