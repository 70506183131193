import axios from "axios";
import { baseURL } from "../auth/config";
import logger from "../auth/logger";

export const publicAPI = axios.create({
  baseURL,
  url: "/",
  headers: {},
  timeout: 60000,
});

publicAPI.interceptors.response.use(
  (response) => {
    logger.debug(response.config.url, response.data);
    return response;
  },
  (error) => {
    logger.debug(error.config.url, error.data);
    return Promise.reject(error);
  },
);
