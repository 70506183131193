import ProfilePic1 from "../../../assets/img/teams/insurance/sandra.png";
import ProfilePic2 from "../../../assets/img/teams/insurance/gabriel.png";
import ProfilePic3 from "../../../assets/img/teams/insurance/lohan.png";
import ProfilePic4 from "../../../assets/img/teams/insurance/lea.png";
import { useAuth } from "src/services/auth/apiProvider";

const InsuranceTeam = () => {
  const { generateCalendlyLink } = useAuth();
  return (
    <div className={"team"}>
      <h2 className={"title"}>
        Une équipe disponible 6&nbsp;jours&nbsp;sur&nbsp;7
      </h2>
      <div className={"ctas"}>
        <a className={"btn-outlined"} href={"tel:+33980805110"}>
          09 80 80 51 10
        </a>
        <a
          className={"btn-1"}
          href={generateCalendlyLink("", "insurance")}
          rel={"noreferrer"}
          target={"_blank"}
        >
          Être rappelé
        </a>
      </div>
      <div className={"teammates"}>
        <div className={"teammate"}>
          <img alt={"Sandra"} src={ProfilePic1} />
          <p className={"name"}>Sandra</p>
          <p className={"position"}>Expert Assurance</p>
        </div>
        <div className={"teammate"}>
          <img alt={"Gabriel"} src={ProfilePic2} />
          <p className={"name"}>Gabriel</p>
          <p className={"position"}>Expert Assurance</p>
        </div>
        <div className={"teammate"}>
          <img alt={"Lohan"} src={ProfilePic3} />
          <p className={"name"}>Lohan</p>
          <p className={"position"}>Expert Assurance</p>
        </div>
        <div className={"teammate"}>
          <img alt={"Léa"} src={ProfilePic4} />
          <p className={"name"}>Léa</p>
          <p className={"position"}>Team Leader Assurance</p>
        </div>
      </div>
    </div>
  );
};

export default InsuranceTeam;
