import { PUBLIC_LINK } from "../../../routes/public";
import NavLink from "../../routing/components/NavLink";
import Header from "../../ui/block/Header";
import Nav from "../../ui/block/Nav";
import logoMovecool from "../../../assets/img/logo-movecool.png";
// import iconLogout from "../../../assets/img/icons/icon-logout.svg";
// import iconCart from "../../../assets/img/icons/icon-cart-blue.svg";
import iconTelBlue from "../../../assets/img/icons/icon-tel-blue.svg";
import iconAppointment from "../../../assets/img/icons/icon-appointment-white.svg";
// import iconLogin from "../../../assets/img/icons/icon-login-blue.svg";
import { useAuth } from "../apiProvider";
import { useMatch } from "react-router-dom";
import {
  COMPARE_ENERGY_LINK,
  COMPARE_INSURANCE_LINK,
  COMPARE_INTERNET_LINK,
  COMPARE_MOVING_LINK,
} from "../../../routes/compare";
// import { useCart } from "../../compare/cart/cartProvider";
// import { useMemo } from "react";
import { CART_LINK } from "../../../routes/cart";
import { GtmEvent, useGtm } from "../../gtm/gtmProvider";
import { useState } from "react";
import Dialog from "src/services/ui/block/Dialog";
import { cx } from "@emotion/css";
import IconMenu from "../../../assets/img/icons/icon-menu.svg";

const CommonHeader = (): JSX.Element => {
  const { generateCalendlyLink } = useAuth();
  const isCart = useMatch(CART_LINK);
  // const { elements } = useCart();
  const { push } = useGtm();
  const [showMenu, setShowMenu] = useState(false);

  // const elementsCount = useMemo(() => [...elements.keys()].length, [elements]);

  const matchEnergy = useMatch(COMPARE_ENERGY_LINK + "*");
  const matchInsurance = useMatch(COMPARE_INSURANCE_LINK + "*");
  const matchInternet = useMatch(COMPARE_INTERNET_LINK + "*");
  const matchMoving = useMatch(COMPARE_MOVING_LINK + "*");

  return (
    <>
      <Header>
        <div className={"container"}>
          <NavLink to={isCart ? COMPARE_ENERGY_LINK : PUBLIC_LINK}>
            <img
              alt={"logo Movecool"}
              className={"logo-header"}
              src={logoMovecool}
            />
          </NavLink>
          <Nav>
            <div className={"nav-item hidden-s"}>
              <a
                className={"link-2"}
                href={"tel:+33980805110"}
                onClick={() => {
                  push(GtmEvent.GENERIC_EVENT, {
                    event_name: "call",
                  });
                }}
              >
                <img
                  alt={"icône téléphone"}
                  className={"icon icon-left"}
                  src={iconTelBlue}
                />
                <span>09 80 80 51 10</span>
              </a>
            </div>

            {/* <div className={"nav-item"}>
          <NavLink
            className={"btn-outlined btn-rounded cart-btn"}
            to={CART_LINK}
          >
            <img
              alt={"icône panier"}
              className={"icon icon-left"}
              src={iconCart}
            />
            <span>Votre panier</span>
            {elementsCount > 0 && (
              <span className={"cart-chip"}>{elementsCount}</span>
            )}
          </NavLink>
        </div> */}
            <div className={"nav-item hidden-s"}>
              <a
                className={"btn-sp"}
                href={generateCalendlyLink("", "header")}
                onClick={() => {
                  push(GtmEvent.GENERIC_EVENT, {
                    event_name: "callback_header",
                  });
                }}
                rel={"noreferrer"}
                target={"_blank"}
              >
                <img
                  alt={"icône rdv"}
                  className={"icon icon-left"}
                  src={iconAppointment}
                />
                <span>Me faire rappeler</span>
              </a>
            </div>
            <button className={"btn-icon"} onClick={() => setShowMenu(true)}>
              <img alt={"Menu"} className={"icon"} src={IconMenu} />
            </button>
            {/* {user ? (
          <div className={"nav-item"}>
            <button className={"link"} onClick={logout}>
              <img alt={"logout icon"} className={"icon"} src={iconLogout} />
            </button>
          </div>
        ) : (
          <div className={"nav-item"}>
            <button
              className={"btn-icon"}
              onClick={() => setShowAuthDialog(true)}
            >
              <img alt={"login icon"} className={"icon"} src={iconLogin} />
            </button>
          </div>
        )} */}
          </Nav>
        </div>
      </Header>

      {showMenu && (
        <Dialog
          className={"side-panel --side-panel--s"}
          closeLabel={"Fermer  x"}
          onClose={() => setShowMenu(false)}
        >
          <div className={"main-menu"}>
            <div className={"section"}>
              <a
                className={cx(
                  "nav-item energy-nav-item",
                  matchEnergy && "active",
                )}
                href={COMPARE_ENERGY_LINK}
              >
                <span className={"nav-icon"}></span>
                Énergie
              </a>
              <a
                className={cx(
                  "nav-item internet-nav-item",
                  matchInternet && "active",
                )}
                href={COMPARE_INTERNET_LINK}
              >
                <span className={"nav-icon"}></span> Box Internet
              </a>
              <a
                className={cx(
                  "nav-item insurance-nav-item",
                  matchInsurance && "active",
                )}
                href={COMPARE_INSURANCE_LINK}
              >
                <span className={"nav-icon"}></span> Assurance
              </a>
              <a
                className={cx(
                  "nav-item mooving-nav-item",
                  matchMoving && "active",
                )}
                href={COMPARE_MOVING_LINK}
              >
                <span className={"nav-icon"}></span> Déménageurs
              </a>
            </div>
            <hr />
            <div className={"section"}>
              <h2 className={"title-xs center"}>NOUS CONTACTER</h2>
              <br />
              <div className={"section-s center"}>
                <a
                  className={"link-2"}
                  href={"tel:+33980805110"}
                  onClick={() => {
                    push(GtmEvent.GENERIC_EVENT, {
                      event_name: "call",
                    });
                  }}
                >
                  <img
                    alt={"icône téléphone"}
                    className={"icon icon-left"}
                    src={iconTelBlue}
                  />
                  <span>09 80 80 51 10</span>
                </a>
              </div>
              <div className={"section-s"}>
                <a
                  className={"btn-sp btn-block"}
                  href={generateCalendlyLink("", "header")}
                  onClick={() => {
                    push(GtmEvent.GENERIC_EVENT, {
                      event_name: "callback_header",
                    });
                  }}
                  rel={"noreferrer"}
                  target={"_blank"}
                >
                  <img
                    alt={"icône rdv"}
                    className={"icon icon-left"}
                    src={iconAppointment}
                  />
                  <span>Me faire rappeler</span>
                </a>
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default CommonHeader;
