import React, { ComponentType, ReactNode, useContext } from "react";
import { cx } from "@emotion/css";
import NavLink from "../routing/components/NavLink";
import { UNSAFE_RouteContext } from "react-router-dom";
import { createPortal } from "react-dom";
import iconChevronRightGrey from "../../assets/img/icons/icon-chevron-right-grey.svg";
import {
  ProvideBreadcrumbs,
  useBreadcrumbs,
  useBreadcrumbsAPI,
} from "./Breadcrumbs";
import { ProvideHeadActions, useHeadActionsAPI } from "./HeadActions";
import { ProvideTabs, Tabs, useTabsAPI } from "./Tabs";

export const ViewName = ({
  name,
  actions,
}: {
  name: string;
  actions?: ReactNode;
}): JSX.Element | null => {
  const { matches } = useContext(UNSAFE_RouteContext);
  useBreadcrumbs(name, matches[matches.length - 1].pathnameBase);

  const { domElement } = useHeadActionsAPI();
  if (actions && domElement.current) {
    return createPortal(actions, domElement.current);
  }

  return null;
};

const Head = ({
  className,
  children,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>): JSX.Element => {
  const { thread } = useBreadcrumbsAPI();
  const { tabs } = useTabsAPI();
  const { domElement } = useHeadActionsAPI();

  return (
    <div {...props} className={cx(className, "head", "container")}>
      <div className={"row row-v-center"}>
        <div className={"breadcrumb col"}>
          {thread
            .map(({ name, link }) =>
              link ? (
                <NavLink to={link} end>
                  {name}
                </NavLink>
              ) : (
                <span>{name}</span>
              ),
            )
            .reduce(
              (acc, element) =>
                acc === null ? (
                  element
                ) : (
                  <>
                    {acc}{" "}
                    <img
                      alt={"breadcrumb-separator"}
                      className={"breadcrumb-chevron"}
                      src={iconChevronRightGrey}
                    />{" "}
                    {element}
                  </>
                ),
              null,
            )}
        </div>
        <div
          ref={(element) => (domElement.current = element)}
          className={"col-fit view-head-actions"}
        />
      </div>

      {tabs.length > 0 && <Tabs tabs={tabs[tabs.length - 1]} />}
      {children}
    </div>
  );
};

export default Head;

export const Content = ({
  className,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>): JSX.Element => {
  return <div {...props} className={cx(className, "content")} />;
};

export const ActionBar = ({
  className,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>): JSX.Element => {
  return <div {...props} className={cx(className, "action-bar")} />;
};

export function withProvideHead<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvideHead(props: P) {
    return (
      <ProvideHeadActions>
        <ProvideTabs>
          <ProvideBreadcrumbs>
            <WrappedComponent {...props} />
          </ProvideBreadcrumbs>
        </ProvideTabs>
      </ProvideHeadActions>
    );
  }

  WithProvideHead.displayName = `withProvideHead(${displayName})`;

  return WithProvideHead;
}
