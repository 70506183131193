import React, { ComponentType, useEffect } from "react";
import "./services/validations/yup-init";
import "./services/i18n";
import { ProvideToast } from "./services/toast-notifications";
import { AuthContext, useProvideAuth } from "./services/auth/apiProvider";
import { ProvideConfirmation } from "./services/ui/ConfirmationDialog";
import { PUBLIC } from "./routes/public";
import Public from "./views/Public";
import Private from "./views/Private";
import { PRIVATE } from "./routes/private";
import { Route } from "react-router-dom";
import Routes from "./services/routing/Routes";
import { RequiresAuth } from "./services/auth/hocs/withRedirectToPrivate";
import { LoadScript } from "@react-google-maps/api";
import { withProvidePlace } from "./services/google-maps/placeProvider";
import { withProvideCart } from "./services/compare/cart/cartProvider";
import "vanilla-cookieconsent";
import { withProvideLoading } from "./services/common-components/LoadingDisplay";
import { withProvideEnergy } from "./services/compare/energy/energyProvider";
import { withProvideHousing } from "./services/compare/insurance/housingProvider";
import { withProvideInsurance } from "./services/compare/insurance/insuranceProvider";
import { withProvideInternet } from "./services/compare/internet/internetProvider";
import { withProvideWater } from "./services/compare/water/waterProvider";
import { withProvideMoving } from "./services/compare/moving/movingProvider";
import { withProvideConsumption } from "./services/compare/energy/consumptionProvider";
import { useGtm, withProvideGtm } from "./services/gtm/gtmProvider";

const MainRouter = withProvideGtm(
  "GTM-K4DTVMQ",
  withProvideCart(
    (): JSX.Element => {
      const { init, setCookieRefused } = useGtm();

      useEffect(() => {
        const consent = initCookieConsent();
        consent.run({
          autorun: true,
          force_consent: true,
          current_lang: "fr",
          cookie_same_site: "strict",
          autoclear_cookies: true,
          revision: 0,
          onAccept(cookies) {
            if (cookies.categories.includes("analytics")) {
              init();
              setCookieRefused(false);
            }
          },
          languages: {
            fr: {
              consent_modal: {
                title: "Cookies 🍪",
                description:
                  "Nous utilisons des cookies pour vous garantir la meilleure expérience sur notre site. En continuant votre navigation, vous acceptez l'utilisation de ces derniers.",
                // NOTE: Set a valid revision_message parameter (optional) inside consent_modal, and add the following placeholder {{revision_message}} inside description:
                revision_message:
                  "<br><br> Notre politique de cookies a été mise à jour. Veuillez accepter nos cookies pour continuer à profiter de la meilleure expérience sur notre site.",
                primary_btn: {
                  text: "J'accepte tous les cookies",
                  role: "accept_all",
                },
                secondary_btn: {
                  text: "Paramétrer",
                  role: "settings",
                },
              },
              // NOTE: Required, configuration example: https://github.com/orestbida/cookieconsent#full-example-configurations
              settings_modal: {
                title: "Paramétrer les cookies 🍪",
                save_settings_btn: "Valider votre sélection",
                accept_all_btn: "Tout accepter",
                reject_all_btn: "Tout refuser",
                close_btn_label: "Fermer",
                cookie_table_headers: [
                  { col1: "Nom" },
                  { col2: "Domaine" },
                  { col3: "Expiration" },
                  { col4: "Description" },
                ],
                blocks: [
                  {
                    title: "Politique de confidentialité & gestion des cookies",
                    description:
                      '<a href="https://movecool.notion.site/Politique-de-confidentialit-gestion-des-cookies-48624257b4754a498d8727d8efd135d5" class="cc-link" target="_blank" rel="noreferrer">Politique de protection des données</a>.',
                  },
                  {
                    title: "Cookies Essentiels",
                    description:
                      "Les cookies essentiels sont nécessaires au bon fonctionnement de notre site web et sont indispensables pour vous permettre d'utiliser ses fonctionnalités de base. Ces cookies ne collectent pas d'informations vous concernant qui pourraient être utilisées à des fins de marketing ou de suivi. Ils sont généralement définis en réponse à des actions que vous avez effectuées sur le site, telles que la connexion à un compte ou la sélection d'une langue de préférence. Vous pouvez configurer votre navigateur pour bloquer ou vous alerter sur ces cookies, mais certaines parties du site risquent de ne pas fonctionner correctement sans eux.",
                    toggle: {
                      value: "necessary",
                      enabled: true,
                      readonly: true,
                    },
                    cookie_table: [
                      {
                        col1: "access_token",
                        col2: window.location.host,
                        col3: "1 semaine",
                        col4:
                          "Ce cookie est utilisé pour stocker les informations d'authentification d'un utilisateur connecté. Ce cookie est créé lorsque vous vous connectez à notre site web et permet à notre système de vous identifier en tant qu'utilisateur authentifié. Il est utilisé pour vous permettre d'accéder à des fonctionnalités réservées aux utilisateurs connectés, telles que l'accès à votre compte, la modification de vos préférences ou la gestion de vos commandes. Les données stockées dans ce cookie sont cryptées et ne sont pas accessibles à des tiers.",
                      },
                    ],
                  },
                  {
                    title: "Cookies Statistiques",
                    description:
                      "Les cookies de statistiques nous permettent de collecter des informations sur la façon dont les visiteurs utilisent notre site web, afin d'améliorer son fonctionnement et de mieux répondre à vos besoins. Ces cookies nous aident à comprendre comment vous interagissez avec notre site, quelles pages vous trouvez les plus intéressantes et les plus utiles, et à identifier les éventuels problèmes techniques. Les données collectées sont anonymes et ne permettent pas de vous identifier personnellement.",
                    toggle: {
                      value: "analytics",
                      enabled: false,
                      readonly: false,
                    },
                    cookie_table: [
                      {
                        col1: "^_ga",
                        col2: "google.com",
                        col3: "2 ans",
                        col4:
                          "Cookie utilisé par Google Analytics pour distinguer les utilisateurs uniques en attribuant un identifiant généré de manière aléatoire. Ce cookie est utilisé pour suivre l'utilisation du site par les visiteurs.",
                        is_regex: true,
                      },
                      {
                        col1: "_gid",
                        col2: "google.com",
                        col3: "1 jour",
                        col4:
                          "Cookie utilisé par Google Analytics, stocke et met à jour une valeur unique pour chaque page visitée. Il est utilisé pour calculer les données de visiteurs, de session et de campagne.",
                      },
                    ],
                  },
                ],
              },
            },
          },
        });
      }, [init, setCookieRefused]);

      return (
        <Routes>
          <Route element={<Public />} index />
          <Route element={<Public />} path={`${PUBLIC}/*`} />

          <Route
            element={
              <RequiresAuth>
                <Private />
              </RequiresAuth>
            }
            path={`${PRIVATE}/*`}
          />
        </Routes>
      );
    },
  ),
  false,
);

const GOOGLE_MAPS_LIBRAIRIES = ["places" as const];

const App = (): JSX.Element => {
  const auth = useProvideAuth();

  useEffect(() => {
    auth.checkUserValidity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""}
      libraries={GOOGLE_MAPS_LIBRAIRIES}
    >
      <AuthContext.Provider value={auth}>
        <ProvideToast>
          <ProvideConfirmation>
            <MainRouter />
          </ProvideConfirmation>
        </ProvideToast>
      </AuthContext.Provider>
    </LoadScript>
  );
};

const withEnergyProviders = <T extends ComponentType>(Component: T) =>
  withProvideEnergy(withProvideConsumption(Component));
const withInsuranceProviders = <T extends ComponentType>(Component: T) =>
  withProvideHousing(withProvideInsurance(Component));
const withInternetProviders = <T extends ComponentType>(Component: T) =>
  withProvideInternet(Component);
const withWaterProviders = <T extends ComponentType>(Component: T) =>
  withProvideWater(Component);
const withMovingProviders = <T extends ComponentType>(Component: T) =>
  withProvideMoving(Component);

export default withProvideLoading(
  withProvidePlace(
    withInsuranceProviders(
      withInternetProviders(
        withEnergyProviders(withMovingProviders(withWaterProviders(App))),
      ),
    ),
  ),
);
