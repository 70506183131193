import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  LoggedUser,
  User,
  UserToRegister,
  UserToLogin,
  UserToUpdateForm,
  UserPasswordForm,
  UserToRegisterForm,
} from "./user";
import baseAPI, {
  deleteLocalUser,
  getLocalUser,
  getUserById,
  isSetLocalUser,
  login as apiLogin,
  logout as apiLogout,
  lostPassword as apiLostPassword,
  register as apiRegister,
  resetPassword as apiResetPassword,
  setLocalUser,
  updateUserById,
  updateUserPasswordById,
  validateUserRegistration as apiValidateUserRegistration,
  createPipedriveLead,
} from "./api";
import { AxiosPromise } from "axios";
import { useMatch, useNavigate } from "react-router-dom";
import { COMPARE_ENERGY_LINK } from "../../routes/compare";
import { useQueryParam } from "../routing/useQueryParam";
import logger from "./logger";
import { PRIVATE } from "../../routes/private";
import { AuthDialogState } from "./components/AuthDialog";
import { UserInfosBase } from "./components/PersonalInfosForm";
import { isDate, parseISO } from "date-fns";

const defaultUser = isSetLocalUser() ? getLocalUser() : null;

export interface AuthAPI {
  user: LoggedUser | null;

  login(user: UserToLogin): Promise<LoggedUser>;

  logout(): Promise<void>;

  checkUserValidity(): Promise<void>;

  register(user: UserToRegister): Promise<void>;

  validateUserRegistration(guid: string): AxiosPromise<void>;

  updateUser(newUser: UserToUpdateForm): Promise<void>;
  updateUserPassword(form: UserPasswordForm): AxiosPromise<void>;

  lostPassword(email: User["email"]): AxiosPromise<void>;

  resetPassword(
    guid: string,
    password: UserToLogin["password"],
  ): AxiosPromise<void>;

  showAuthDialog: [AuthDialogState, UserToRegisterForm | null] | boolean;
  setShowAuthDialog(
    show: [AuthDialogState, UserToRegisterForm | null] | boolean,
  ): void;

  generateCalendlyLink(
    content: string,
    context:
      | "energy"
      | "internet"
      | "insurance"
      | "water"
      | "moving"
      | "cart"
      | string,
  ): string;

  setAfterLoginHooks(
    fn: (
      prev: [string, (user: LoggedUser) => Promise<void>][],
    ) => [string, (user: LoggedUser) => Promise<void>][],
  ): void;

  setAfterLogoutHooks(
    fn: (
      prev: [string, () => Promise<void>][],
    ) => [string, () => Promise<void>][],
  ): void;

  userInfos: UserInfosBase | null;
  setUserInfos(val: UserInfosBase | null): void;
  createPipedrivePersonAndLead(val: UserInfosBase): AxiosPromise<void>;
}

export interface AuthAPIConnected extends AuthAPI {
  user: LoggedUser;
}

/**
 * Default value should never be used
 */
export const AuthContext = createContext<AuthAPI | null>(null);

export function useProvideAuth(): AuthAPI {
  const isPrivate = !!useMatch(PRIVATE);

  const [user, setUser] = useState<LoggedUser | null>(defaultUser);
  const [afterLoginHooks, setAfterLoginHooks] = useState<
    [string, (user: LoggedUser) => Promise<void>][]
  >([
    [
      "set-authorization-header-hook",
      (user) => {
        baseAPI.defaults.headers["Authorization"] = `Bearer ${user.xsrfToken}`;
        return Promise.resolve();
      },
    ],
  ]);
  const [afterLogoutHooks, setAfterLogoutHooks] = useState<
    [string, () => Promise<void>][]
  >([]);
  const navigate = useNavigate();
  const [authRedirectTo] = useQueryParam("authRedirectTo", "string");

  const [showAuthDialog, setShowAuthDialog] = useState<
    AuthAPI["showAuthDialog"]
  >(false);
  const [userInfos, setUserInfos] = useState<AuthAPI["userInfos"]>(null);

  const runAfterLoginHooks = useCallback(
    (user: LoggedUser) =>
      Promise.all(
        afterLoginHooks.map(([name, f]) =>
          f(user).finally(() => {
            logger.debug("%s after login hook runned", name);
          }),
        ),
      ),
    [afterLoginHooks],
  );
  const runAfterLogoutHooks = useCallback(
    () =>
      Promise.all(
        afterLogoutHooks.map(([name, f]) =>
          f().finally(() => {
            logger.debug("%s after logout hook runned", name);
          }),
        ),
      ),
    [afterLogoutHooks],
  );

  useMemo(() => {
    if (user !== null) {
      setLocalUser(user);
      baseAPI.defaults.headers["Authorization"] = `Bearer ${user.xsrfToken}`;
    } else {
      deleteLocalUser();
      delete baseAPI.defaults.headers["Authorization"];
      runAfterLogoutHooks();
    }
  }, [runAfterLogoutHooks, user]);

  useEffect(() => {
    const interceptor = baseAPI.interceptors.response.use(
      (res) => res,
      (error) => {
        if (error?.response?.status === 401) {
          setUser(null);
        }
        return Promise.reject(error);
      },
    );

    return () => {
      baseAPI.interceptors.response.eject(interceptor);
    };
  }, []);

  useEffect(() => {
    // TODO: Replace this with a better solution (from some kind of router state)
    if (!!authRedirectTo && authRedirectTo.length > 0 && !user) {
      setShowAuthDialog(true);
    }
  }, [authRedirectTo, showAuthDialog, user]);

  const login: AuthAPI["login"] = useCallback(
    (u: UserToLogin) => {
      return apiLogin(u).then((res) => {
        const user = {
          ...res.data.user,
          birthDate:
            typeof res.data.user.birthDate === "string"
              ? parseISO((res.data.user.birthDate as unknown) as string)
              : null,
        };
        runAfterLoginHooks(user);
        setUser(user);
        setShowAuthDialog(false);
        return user;
      });
    },
    [runAfterLoginHooks],
  );

  const logout: AuthAPI["logout"] = useCallback(() => {
    if (user !== null) {
      if (isPrivate) navigate(COMPARE_ENERGY_LINK);
      setUser(null);
    }
    return apiLogout().then(() => {
      return Promise.resolve();
    });
  }, [user, isPrivate, navigate]);

  const checkUserValidity: AuthAPI["checkUserValidity"] = useCallback(() => {
    if (isSetLocalUser()) {
      const u = getLocalUser() as LoggedUser;
      return getUserById(u.id).then(
        (res) => {
          const newUser = {
            ...u,
            ...res.data,
            birthDate:
              typeof res.data.birthDate === "string"
                ? parseISO((res.data.birthDate as unknown) as string)
                : null,
          };
          setUser(newUser);
          setLocalUser(newUser);
        },
        (err) => {
          if (err?.response?.status === 401) {
            setUser(null);
            //deleteLocalUser();
          }
        },
      );
    }
    return Promise.resolve();
  }, []);

  const register: AuthAPI["register"] = useCallback((user) => {
    return apiRegister(user).then(() => {
      // Do nothing
    });
  }, []);

  const validateUserRegistration: AuthAPI["validateUserRegistration"] = useCallback(
    (guid) => {
      return apiValidateUserRegistration(guid);
    },
    [],
  );

  const updateUser: AuthAPI["updateUser"] = useCallback((newUser) => {
    return updateUserById(newUser).then(() => {
      setUser((prevUser) =>
        prevUser
          ? {
              ...prevUser,
              ...newUser,
              birthDate:
                typeof newUser.birthDate === "string"
                  ? parseISO((newUser.birthDate as unknown) as string)
                  : isDate(prevUser.birthDate)
                  ? prevUser.birthDate
                  : null,
            }
          : null,
      );
    });
  }, []);

  const updateUserPassword: AuthAPI["updateUserPassword"] = useCallback(
    (form) =>
      updateUserPasswordById((user as NonNullable<typeof user>).id, form),
    [user],
  );

  const lostPassword: AuthAPI["lostPassword"] = useCallback((email) => {
    return apiLostPassword(email);
  }, []);

  const resetPassword: AuthAPI["resetPassword"] = useCallback(
    (guid, password) => {
      return apiResetPassword(guid, password);
    },
    [],
  );

  const generateCalendlyLink: AuthAPI["generateCalendlyLink"] = useCallback(
    (content, context) => {
      let link = "";

      switch (context) {
        case "header":
          link +=
            "https://calendly.com/decouvrir/comparateur-movecool-tapbar/?";
          break;
        case "energy":
          link +=
            "https://calendly.com/decouvrir/comparateur-movecool-energie/?";
          break;
        case "internet":
          link +=
            "https://calendly.com/decouvrir/comparateur-movecool-box-internet/?";
          break;
        case "insurance":
          link +=
            "https://calendly.com/decouvrir/comparateur-movecool-assurance/?";
          break;
        case "water":
          link +=
            "https://calendly.com/decouvrir/comparateur-movecool-service-des-eaux/?";
          break;
        case "moving":
          link +=
            "https://calendly.com/decouvrir/comparateur-movecool-demenageur/?";
          break;
        case "cart":
          link +=
            "https://calendly.com/decouvrir/comparateur-movecool-commande-valide/?";
          break;
        default:
          link +=
            "https://calendly.com/decouvrir/comparateur-movecool-tapbar/?";
      }

      if (user) {
        link += `name=${encodeURIComponent(
          `${user.firstname} ${user.lastname}`,
        )}&`;
        link += `first_name=${encodeURIComponent(user.firstname)}&`;
        link += `last_name=${encodeURIComponent(user.lastname)}&`;
        link += `email=${encodeURIComponent(user.email)}&`;
        if (user.phoneNumber)
          link += `location=${encodeURIComponent(
            user.phoneNumber.replace(/^0/, "+33").replace("+", ""),
          )}&`;
      }

      link += `a1=${encodeURIComponent(content)}`;

      return link;
    },
    [user],
  );

  const createPipedrivePersonAndLead: AuthAPI["createPipedrivePersonAndLead"] = useCallback(
    (user) => {
      return createPipedriveLead(user);
    },
    [],
  );

  return {
    user,
    showAuthDialog,
    userInfos,
    setUserInfos,
    createPipedrivePersonAndLead,
    login,
    logout,
    checkUserValidity,
    register,
    validateUserRegistration,
    updateUser,
    updateUserPassword,
    lostPassword,
    resetPassword,
    setShowAuthDialog,
    generateCalendlyLink,
    setAfterLoginHooks,
    setAfterLogoutHooks,
  };
}

export function useAuth(): AuthAPI {
  return useContext(AuthContext) as AuthAPI;
}
