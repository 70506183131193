import React, { useMemo } from "react";
import { useToastsWithIntl } from "../../services/toast-notifications";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line no-restricted-imports
import { Form, Formik } from "formik";
import { object, string } from "yup";
import Link from "../../services/routing/components/Link";
import { LOGIN_LINK } from "../../routes/public";
import { useAuth } from "../../services/auth/apiProvider";
import Card from "../../services/ui/block/Card";
import CardHead from "../../services/ui/block/CardHead";
import CardBody from "../../services/ui/block/CardBody";
import Button from "../../services/ui/reactive/Button";
import FormFooter from "../../services/forms/FormFooter";
import Input from "../../services/ui/reactive/Input";

const LostPassword = (): JSX.Element => {
  const { lostPassword } = useAuth();
  const { toastError, toastSuccess } = useToastsWithIntl(["auth"]);
  const { t } = useTranslation(["auth"]);

  const LostPasswordSchema = useMemo(
    () =>
      object().shape({
        email: string()
          .label(t("auth:email"))
          .required("L'adresse e-mail est requise")
          .email("L'adresse e-mail est requise"),
      }),
    [t],
  );

  return (
    <Card className={"auth-card theme-light"}>
      <CardHead title={"Mot de passe oublié"} />

      <CardBody>
        <Formik
          initialValues={{ email: "" }}
          onSubmit={(values, { setSubmitting }) => {
            lostPassword(values.email).then(
              () => {
                setSubmitting(false);
                toastSuccess("auth:lost-password.SUCCESS");
              },
              () => {
                setSubmitting(false);
                toastError("auth:lost-password.ERROR");
              },
            );
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={LostPasswordSchema}
        >
          {({ handleChange, errors, isSubmitting }) => (
            <Form>
              <div className={"form-block"}>
                <label className={"input-label"}>{t("auth:email")} *</label>
                <Input
                  name={"email"}
                  onChange={handleChange}
                  placeholder={"e.g. john.doe@mail.com"}
                  type={"email"}
                />
              </div>

              <div>
                <ul>
                  {Object.values(errors).map((value, index) => (
                    <li key={index}>{value}</li>
                  ))}
                </ul>
              </div>

              <FormFooter>
                <Button disabled={isSubmitting} block>
                  Envoyer
                </Button>
                <div className={"go-back-to-login-cta"}>
                  <Link to={LOGIN_LINK}>Retour à la connexion</Link>
                </div>
              </FormFooter>
            </Form>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};

export default LostPassword;
