import { HTMLAttributes, useCallback, useState } from "react";
import Dialog from "../../ui/block/Dialog";
import Button from "../../ui/reactive/Button";
import { Brand } from "./insurance";
import PriceDisplay from "./PriceDisplay";
import iconCtaBtnArrow from "../../../assets/img/icons/icon-arrow-circle-right-white.svg";
import { PricePer } from "../common";
import { cx } from "@emotion/css";

const InsuranceDetailsDialog = <
  P extends HTMLAttributes<HTMLDivElement> & {
    brand: Brand;
    cart?: boolean;
    onChoose(): void;
    onClose(): void;
  }
>({
  brand,
  cart,
  onChoose,
  onClose,
  ...props
}: P): JSX.Element => {
  const [priceFormat, setPriceFormat] = useState(PricePer.Month);
  const togglePriceFormat = useCallback(
    () =>
      setPriceFormat((p) =>
        p === PricePer.Month ? PricePer.Year : PricePer.Month,
      ),
    [setPriceFormat],
  );

  return (
    <Dialog
      className={cx("offer-detail-popup", !brand.price && "--s")}
      closeLabel={"Revenir aux offres"}
      onClose={onClose}
    >
      <div className={"grid"} {...props}>
        <div className={cx(brand.price ? "col-m-1-2" : "")}>
          <div className={"card-body"}>
            <div className={"brand-and-offer-head"}>
              <img
                alt={`Logo ${brand.name}`}
                className={"brand-logo"}
                src={brand.logo}
                style={{ width: "6em" }}
              />
              <div>
                <h2 className={"brand-name"}>{brand.name}</h2>
              </div>
            </div>
            <div className={"section"}>
              <h4 className={"section-title"}>Présentation de l'assureur</h4>
              <p>{brand.description}</p>
            </div>
          </div>
        </div>

        {brand.price && (
          <div className={"col-m-1-2 col-offer-detail"}>
            <div className={"card-body"}>
              <PriceDisplay
                brand={brand}
                priceFormat={priceFormat}
                togglePriceFormat={togglePriceFormat}
              />

              {!cart && (
                <div className={"section-xl"}>
                  <div className={"offer-cta comp-insurance"}>
                    <Button
                      className={"btn-branded"}
                      onClick={() => {
                        onClose();
                        onChoose();
                      }}
                      type={"button"}
                    >
                      Choisir
                      <img
                        alt={"estimer"}
                        className={"icon icon-right"}
                        src={iconCtaBtnArrow}
                      />
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default InsuranceDetailsDialog;
