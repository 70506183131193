import { HTMLAttributes, useState } from "react";
import { usePlace } from "../../google-maps/placeProvider";
import Dialog from "../../ui/block/Dialog";
import { CartType } from "../cart/cart";
import { useCart } from "../cart/cartProvider";
import {
  ConsumptionCalculatorStep,
  useConsumption,
} from "./consumptionProvider";
import { Brand, DualWithOffers, Offer, EnergyType } from "./energy";
import DetailPriceDisplay from "./DetailPriceDisplay";
import iconCtaBtnArrow from "../../../assets/img/icons/icon-arrow-circle-right-white.svg";
import { useDate } from "../../date/DateContext";
import SubscriptionDetailsDialog from "./SubscriptionDetailsDialog";
import HowToReadAnEnergyInvoiceDialog from "./HowToReadAnEnergyInvoiceDialog";
import iconDocumentsBlue from "../../../assets/img/icons/icon-documents-blue.svg";
import { useAuth } from "src/services/auth/apiProvider";
import { GtmEvent, useGtm } from "src/services/gtm/gtmProvider";

type ExtractOffer<P> = P extends { offer: infer O } ? O : undefined;

const EnergyDetailsModal = <
  P extends HTMLAttributes<HTMLDivElement> & {
    brand: Brand;
    cart?: boolean;
    onClose(): void;
    onEstimate?: () => void;
  } & (ExtractOffer<P> extends undefined
      ? {
          dual: DualWithOffers;
          offer?: never;
        }
      : {
          offer: Offer;
          dual?: never;
        })
>({
  offer,
  dual,
  brand,
  cart,
  onClose,
  onEstimate,
  ...props
}: P): JSX.Element => {
  const { formatDate } = useDate();
  const { addToCart } = useCart();
  const { currentLocation } = usePlace();
  const {
    electricityConsumption,
    gasConsumption,
    area,
    cookingSource,
    heatingSource,
    hotWaterSource,
    lastConsumptionCalculatorStep,
    residentCount,
  } = useConsumption();

  const { push } = useGtm();

  const OfferDisplay = ({ offer }: { offer: any }) => {
    const [showSubscriptionDetails, setShowSubscriptionDetails] = useState(
      false,
    );
    const [showReadInvoiceGuide, setShowReadInvoiceGuide] = useState(false);

    return (
      <>
        <div className={"section-xl"}>
          <h4 className={"section-title"}>Présentation de l'offre</h4>
          <p>{offer.description}</p>
        </div>
        <DetailPriceDisplay offer={offer} />
        <p className={"body-xs right"}>
          {(offer?.updated_at || dual?.updated_at) && (
            <>
              Tarifs mis à jour le{" "}
              {formatDate((offer?.updated_at || dual?.updated_at)!, "PPP")}
            </>
          )}
        </p>
        <div className={"section"}>
          {offer.pricingDoc && (
            <a
              className={"btn-link btn-s"}
              href={offer.pricingDoc}
              rel={"noreferrer"}
              target={"_blank"}
              download
            >
              <img
                alt={"info"}
                className={"icon icon-left"}
                src={iconDocumentsBlue}
              />
              Grille tarifaire de l'offre
            </a>
          )}
          {offer.electricityOffer?.pricingDoc && (
            <a
              className={"btn-link btn-s"}
              href={offer.electricityOffer.pricingDoc}
              rel={"noreferrer"}
              target={"_blank"}
              download
            >
              <img
                alt={"info"}
                className={"icon icon-left"}
                src={iconDocumentsBlue}
              />
              Grille tarifaire de l'offre d'électricité
            </a>
          )}
          {offer.gasOffer?.pricingDoc && (
            <a
              className={"btn-link btn-s"}
              href={offer.gasOffer.pricingDoc}
              rel={"noreferrer"}
              target={"_blank"}
              download
            >
              <img
                alt={"info"}
                className={"icon icon-left"}
                src={iconDocumentsBlue}
              />
              Grille tarifaire de l'offre de gaz
            </a>
          )}
          <button
            className={"btn-link btn-s"}
            onClick={() => setShowSubscriptionDetails(true)}
          >
            <img
              alt={"info"}
              className={"icon icon-left"}
              src={iconDocumentsBlue}
            />
            Comprendre la notion d'abonnement
          </button>
          <button
            className={"btn-link btn-s"}
            onClick={() => setShowReadInvoiceGuide(true)}
          >
            <img
              alt={"info"}
              className={"icon icon-left"}
              src={iconDocumentsBlue}
            />
            Comprendre ma facture d'énergie
          </button>
        </div>

        {showSubscriptionDetails && (
          <SubscriptionDetailsDialog
            onClose={() => setShowSubscriptionDetails(false)}
          />
        )}
        {showReadInvoiceGuide && (
          <HowToReadAnEnergyInvoiceDialog
            onClose={() => setShowReadInvoiceGuide(false)}
          />
        )}
      </>
    );
  };

  const dataCompleteCondition =
    (offer?.type === EnergyType.Electricity && !!electricityConsumption) ||
    (offer?.type === EnergyType.Gas && !!gasConsumption) ||
    (dual && electricityConsumption && gasConsumption);

  const { generateCalendlyLink } = useAuth();

  return (
    <Dialog
      className={"offer-detail-popup"}
      closeLabel={"Revenir aux offres"}
      onClose={onClose}
    >
      <div className={"grid"} {...props}>
        <div className={"col-m-1-2"}>
          <div className={"card-body"}>
            <div className={"brand-and-offer-head"}>
              <img
                alt={`Logo ${brand.name}`}
                className={"brand-logo"}
                src={brand.logo}
              />
              <div>
                <h2 className={"brand-name"}>{brand.name}</h2>
                {offer && <h3 className={"offer-name"}>{offer.name}</h3>}
              </div>
            </div>
            <div className={"section"}>
              <h4 className={"section-title"}>Présentation du fournisseur</h4>
              <p>{brand.description}</p>
            </div>

            {offer && (
              <div>
                <div className={"section-xs"}>
                  <h4 className={"section-title"}>Les plus de cette offre</h4>
                  <p className={"indent formated-rich-text"}>
                    {offer.good_points}
                  </p>
                </div>
                <div className={"section-xs"}>
                  <h4 className={"section-title"}>Les moins de cette offre</h4>
                  <p className={"indent formated-rich-text"}>
                    {offer.bad_points}
                  </p>
                </div>
              </div>
            )}

            {dataCompleteCondition && !cart && (
              <div className={"offer-cta comp-energy"}>
                <a
                  className={"btn-branded-outlined"}
                  href={generateCalendlyLink("", "energy")}
                  onClick={() => {
                    push(GtmEvent.ECOMMERCE, {
                      event_name: "click_callback",
                    });
                  }}
                  rel={"noreferrer"}
                  target={"_blank"}
                >
                  Être rappelé
                </a>
              </div>
            )}
          </div>{" "}
        </div>

        <div className={"col-m-1-2 col-offer-detail"}>
          <div className={"card-body"}>
            {offer ? (
              <div>
                <OfferDisplay offer={offer} />
              </div>
            ) : dual ? (
              <div>
                <h2>{dual.name}</h2>
                <OfferDisplay offer={dual} />
              </div>
            ) : null}

            {offer?.withdrawn ? (
              <div className={"info info-withdrawn"}>
                Cette offre n'est plus disponible à la vente, bien qu'encore
                distribuée
              </div>
            ) : (
              <>
                {!cart && (
                  <div className={"section-xl"}>
                    <div className={"offer-cta comp-energy"}>
                      {dataCompleteCondition ? (
                        <button
                          className={"btn-branded"}
                          onClick={(ev) => {
                            ev.stopPropagation();
                            const data = {
                              ...(lastConsumptionCalculatorStep ===
                              ConsumptionCalculatorStep.Calculated
                                ? {
                                    area,
                                    cookingSource,
                                    heatingSource,
                                    hotWaterSource,
                                    residentCount,
                                  }
                                : {
                                    area: null,
                                    cookingSource: null,
                                    heatingSource: null,
                                    hotWaterSource: null,
                                    residentCount: null,
                                  }),
                              offerId: (offer?.id || dual?.id)!,
                              address: currentLocation!,
                              electricityConsumption:
                                electricityConsumption || 0,
                              gasConsumption: gasConsumption || 0,
                              home: null,
                              situation: null,
                            };
                            return addToCart(
                              dual
                                ? {
                                    type: CartType.Dual,
                                    data: {
                                      electricity: {
                                        ...data,
                                        offerId: dual.electricity,
                                      },
                                      gas: {
                                        ...data,
                                        offerId: dual.gas,
                                      },
                                    },
                                  }
                                : {
                                    type:
                                      offer?.type === EnergyType.Electricity
                                        ? CartType.Electricity
                                        : CartType.Gas,
                                    data,
                                  },
                            );
                          }}
                          type={"button"}
                        >
                          Passer à l'étape suivante
                          <img
                            alt={"estimer"}
                            className={"icon icon-right"}
                            src={iconCtaBtnArrow}
                          />
                        </button>
                      ) : (
                        <button
                          className={"btn-cta-estimate"}
                          onClick={onEstimate}
                        >
                          Estimer ma mensualité
                          <img
                            alt={"estimer"}
                            className={"icon icon-right"}
                            src={iconCtaBtnArrow}
                          />
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default EnergyDetailsModal;
