import ImgGoogleNotation from "../../../assets/img/google-notation.png";
import ImgClient1 from "../../../assets/img/reviews/florine.png";
import ImgClient2 from "../../../assets/img/reviews/pierre.png";
import ImgClient3 from "../../../assets/img/reviews/noemie.png";

const MovecoolReinsuranceBlock = (): JSX.Element => {
  return (
    <>
      <div className={"movecool-reinsurance-block"}>
        <h2 className={"title"}>+12 000 utilisateurs satisfaits</h2>
        <h3 className={"tagline"}>
          Faites comme eux : comparez, choisissez, économisez en quelques clics
          avec Movecool.
        </h3>
        <div className={"google-notation"}>
          <img alt={"noté 4,9/5 sur Google"} src={ImgGoogleNotation} />
          <span>Selon nos utilisateurs sur Google</span>
        </div>
        <div className={"reviews"}>
          <div className={"review-card"}>
            <div className={"review"}>
              J’étais dubitative avant de tester le service mais j’étais ravie
              lorsque j’ai réalisé le temps et l’argent gagné. Merci à Marie.
            </div>
            <div className={"client"}>
              <img alt={"Florine"} src={ImgClient1} />
              <div className={"client-infos"}>
                <div className={"name"}>Florine</div>
                <div className={"age"}>31 ans</div>
              </div>
            </div>
            <div className={"meta"}>a économisé avec Movecool en août 2022</div>
          </div>
          <div className={"review-card"}>
            <div className={"review"}>
              Première expérience avec Movecool réussie ! J’en redemande pour
              mon prochain déménagement !
            </div>
            <div className={"client"}>
              <img alt={"Florine"} src={ImgClient2} />
              <div className={"client-infos"}>
                <div className={"name"}>Pierre</div>
                <div className={"age"}>24 ans</div>
              </div>
            </div>
            <div className={"meta"}>a emménagée à Nanterre en juin 2023</div>
          </div>
          <div className={"review-card"}>
            <div className={"review"}>
              Prise en charge rapide, des conseils pertinents, et un vrai stress
              pour faire des économies Thanks a lot !
            </div>
            <div className={"client"}>
              <img alt={"Florine"} src={ImgClient3} />
              <div className={"client-infos"}>
                <div className={"name"}>Noémie</div>
                <div className={"age"}>42 ans</div>
              </div>
            </div>
            <div className={"meta"}>a économisé avec Movecool en Mai 2023</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MovecoolReinsuranceBlock;
