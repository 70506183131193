import { Offer, OfferFeature } from "./energy";
import iconGreenEnergy from "../../../assets/img/icons/icon-leaf-green.svg";
import iconGuard from "../../../assets/img/icons/icon-guard-green.svg";
import iconHotlineTel from "../../../assets/img/icons/icon-phone-green.svg";
import iconHeart from "../../../assets/img/icons/icon-heart-green.svg";
import iconHotlineWeb from "../../../assets/img/icons/icon-laptop-green.svg";
import iconTrophee from "../../../assets/img/icons/icon-trophy-green.svg";
import icon1 from "../../../assets/img/icons/icon-1-green.svg";
import icon2 from "../../../assets/img/icons/icon-2-green.svg";
import icon3 from "../../../assets/img/icons/icon-3-green.svg";
import icon4 from "../../../assets/img/icons/icon-4-green.svg";
import icon5 from "../../../assets/img/icons/icon-5-green.svg";
import icon6 from "../../../assets/img/icons/icon-6-green.svg";

function IconList({ offer }: { offer: any }): JSX.Element {
  return (
    <div className={"icons-list"}>
      {offer.features?.includes(OfferFeature.GreenEnergy) && (
        <img
          alt={String(OfferFeature.GreenEnergy)}
          className={"icon"}
          data-tip={OfferFeature.GreenEnergy}
          src={iconGreenEnergy}
        />
      )}
      {offer.features?.includes(OfferFeature.AwardedClientService) && (
        <img
          alt={String(OfferFeature.AwardedClientService)}
          className={"icon"}
          data-tip={OfferFeature.AwardedClientService}
          src={iconTrophee}
        />
      )}
      {offer.features?.includes(OfferFeature.PhoneClientService) && (
        <img
          alt={String(OfferFeature.PhoneClientService)}
          className={"icon"}
          data-tip={OfferFeature.PhoneClientService}
          src={iconHotlineTel}
        />
      )}
      {offer.features?.includes(OfferFeature.OnlineClientSpace) && (
        <img
          alt={String(OfferFeature.OnlineClientSpace)}
          className={"icon"}
          data-tip={OfferFeature.OnlineClientSpace}
          src={iconHotlineWeb}
        />
      )}
      {offer.fixed_price && (
        <img
          alt={`Tarif fixe pendant ${(offer as Offer).fixed_price} ans`}
          className={"icon"}
          data-tip={`Tarif fixe pendant ${(offer as Offer).fixed_price} ans`}
          src={iconGuard}
        />
      )}
      {offer.features?.includes(
        OfferFeature.FirstFrenchAlternativeProvider,
      ) && (
        <img
          alt={String(OfferFeature.FirstFrenchAlternativeProvider)}
          className={"icon"}
          data-tip={OfferFeature.FirstFrenchAlternativeProvider}
          src={icon1}
        />
      )}
      {offer.features?.includes(
        OfferFeature.SecondFrenchAlternativeProvicer,
      ) && (
        <img
          alt={String(OfferFeature.SecondFrenchAlternativeProvicer)}
          className={"icon"}
          data-tip={OfferFeature.SecondFrenchAlternativeProvicer}
          src={icon2}
        />
      )}
      {offer.features?.includes(
        OfferFeature.ThirdFrenchAlternativeProvider,
      ) && (
        <img
          alt={String(OfferFeature.ThirdFrenchAlternativeProvider)}
          className={"icon"}
          data-tip={OfferFeature.ThirdFrenchAlternativeProvider}
          src={icon3}
        />
      )}
      {offer.features?.includes(
        OfferFeature.FourthFrenchAlternativeProvider,
      ) && (
        <img
          alt={String(OfferFeature.FourthFrenchAlternativeProvider)}
          className={"icon"}
          data-tip={OfferFeature.FourthFrenchAlternativeProvider}
          src={icon4}
        />
      )}
      {offer.features?.includes(
        OfferFeature.FifthFrenchAlternativeProvider,
      ) && (
        <img
          alt={String(OfferFeature.FifthFrenchAlternativeProvider)}
          className={"icon"}
          data-tip={OfferFeature.FifthFrenchAlternativeProvider}
          src={icon5}
        />
      )}
      {offer.features?.includes(OfferFeature.SixthBiggestEuropeanProvider) && (
        <img
          alt={String(OfferFeature.SixthBiggestEuropeanProvider)}
          className={"icon"}
          data-tip={OfferFeature.SixthBiggestEuropeanProvider}
          src={icon6}
        />
      )}
      {offer.features?.includes(OfferFeature.Favorite) && (
        <img
          alt={String(OfferFeature.Favorite)}
          className={"icon"}
          data-tip={OfferFeature.Favorite}
          src={iconHeart}
        />
      )}
    </div>
  );
}

export default IconList;
