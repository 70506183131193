import {
  ComponentType,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLoading } from "../../common-components/LoadingDisplay";
import { orderByField } from "../../data-structures/array";
import { usePlace } from "../../google-maps/placeProvider";
import { useToastsWithIntl } from "../../toast-notifications";
import { ConsumptionContext } from "../energy/consumptionProvider";
import { getQuotes, PartialQuote } from "./api";
import {
  AcheelQuote,
  AppeninQuote,
  ClientType,
  FlatLevel,
  HabitationUsage,
  HousingType,
  LeocareQuote,
  LovysQuote,
  LukoQuote,
} from "./insurance";

export interface HousingAPI {
  // Housing profile
  housingType: HousingType | null;
  flatLevel: FlatLevel | null;
  clientType: ClientType | null;
  housingCategory: HabitationUsage | null;
  roomNumber: number | null;
  size: number | null;

  // Refinements
  totalGoodCoverage: number | null;
  deductible: number | null;
  valueableCoverage: number | null;
  theftCoverage: boolean;
  brokenGlassCoverage: boolean;
  assistanceCoverage: boolean;
  lockReplacementCoverage: boolean;
  schoolCoverage: boolean;
  asNewCoverage: boolean;

  // Estimates
  lukoEstimate: LukoQuote | null | undefined;
  lovysEstimate: LovysQuote | null | undefined;
  appeninEstimate: AppeninQuote | null | undefined;
  acheelEstimate: AcheelQuote | null | undefined;
  leocareEstimate: LeocareQuote | null | undefined;

  canRefineQuotes: boolean;

  quotesAlreadyLoaded: boolean;

  setHousing(
    housing: Pick<
      HousingAPI,
      | "housingType"
      | "flatLevel"
      | "clientType"
      | "housingCategory"
      | "roomNumber"
      | "size"
    >,
  ): void;
  setRefinements(
    refinements: Pick<
      HousingAPI,
      | "totalGoodCoverage"
      | "deductible"
      | "valueableCoverage"
      | "theftCoverage"
      | "brokenGlassCoverage"
      | "assistanceCoverage"
      | "lockReplacementCoverage"
      | "schoolCoverage"
      | "asNewCoverage"
    >,
  ): void;
  loadQuotes(quote: PartialQuote): Promise<void>;
}

export const HousingContext = createContext<HousingAPI | null>(null);

export const ProvideHousing = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const { setLoading } = useLoading();
  const consumption = useContext(ConsumptionContext);
  const { currentLocation } = usePlace();

  const [housingType, setHousingType] = useState<HousingAPI["housingType"]>(
    null,
  );
  const [flatLevel, setFlatLevel] = useState<HousingAPI["flatLevel"]>(null);
  const [clientType, setClientType] = useState<HousingAPI["clientType"]>(null);
  const [housingCategory, setHousingCategory] = useState<
    HousingAPI["housingCategory"]
  >(null);
  const [roomNumber, setRoomNumber] = useState<HousingAPI["roomNumber"]>(null);
  const [size, setSize] = useState<HousingAPI["size"]>(
    consumption?.area || null,
  );
  const [totalGoodCoverage, setTotalGoodCoverage] = useState<
    HousingAPI["totalGoodCoverage"]
  >(8000);
  const [deductible, setDeductible] = useState<HousingAPI["deductible"]>(300);
  const [valueableCoverage, setValueableCoverage] = useState<
    HousingAPI["valueableCoverage"]
  >(0);
  const [theftCoverage, setTheftCoverage] = useState<
    HousingAPI["theftCoverage"]
  >(true);
  const [brokenGlassCoverage, setBrokenGlassCoverage] = useState<
    HousingAPI["brokenGlassCoverage"]
  >(false);
  const [assistanceCoverage, setAssistanceCoverage] = useState<
    HousingAPI["assistanceCoverage"]
  >(false);
  const [lockReplacementCoverage, setLockReplacementCoverage] = useState<
    HousingAPI["lockReplacementCoverage"]
  >(false);
  const [schoolCoverage, setSchoolCoverage] = useState<
    HousingAPI["schoolCoverage"]
  >(false);
  const [asNewCoverage, setAsNewCoverage] = useState<
    HousingAPI["asNewCoverage"]
  >(false);

  const setHousing: HousingAPI["setHousing"] = (values) => {
    setHousingType(values.housingType);
    setFlatLevel(values.flatLevel);
    setClientType(values.clientType);
    setHousingCategory(values.housingCategory);
    setRoomNumber(values.roomNumber);
    setSize(values.size);
  };

  const setRefinements: HousingAPI["setRefinements"] = (values) => {
    setTotalGoodCoverage(values.totalGoodCoverage);
    setDeductible(values.deductible);
    setValueableCoverage(values.valueableCoverage);
    setTheftCoverage(values.theftCoverage);
    setBrokenGlassCoverage(values.brokenGlassCoverage);
    setAssistanceCoverage(values.assistanceCoverage);
    setLockReplacementCoverage(values.lockReplacementCoverage);
    setSchoolCoverage(values.schoolCoverage);
    setAsNewCoverage(values.asNewCoverage);
  };

  const [luko, setLuko] = useState<HousingAPI["lukoEstimate"]>(null);
  const [lovys, setLovys] = useState<HousingAPI["lovysEstimate"]>(null);
  const [appenin, setAppenin] = useState<HousingAPI["appeninEstimate"]>(null);
  const [acheel, setAcheel] = useState<HousingAPI["acheelEstimate"]>(null);
  const [leocare, setLeocare] = useState<HousingAPI["leocareEstimate"]>(null);
  const [quotesAlreadyLoaded, setQuotesAlreadyLoaded] = useState(false);

  const canRefineQuotes = useMemo(() => {
    return (
      flatLevel !== null &&
      housingCategory !== null &&
      housingType !== null &&
      clientType !== null &&
      roomNumber !== null &&
      size !== null
    );
  }, [clientType, flatLevel, housingCategory, housingType, roomNumber, size]);

  const loadQuotes: HousingAPI["loadQuotes"] = useCallback(
    (quote: PartialQuote) => {
      setLoading(true);
      return getQuotes({
        floor: flatLevel!,
        habitationUsage: housingCategory!,
        isHouse: housingType === HousingType.House,
        isOwner: clientType === ClientType.Owner,
        roomsCount: roomNumber!,
        surface: size!,
        totalGoodCoverage: totalGoodCoverage!,
        deductible: deductible!,
        valueableCoverage: valueableCoverage!,
        theftCoverage: theftCoverage!,
        brokenGlassCoverage: brokenGlassCoverage!,
        assistanceCoverage: assistanceCoverage!,
        lockReplacementCoverage: lockReplacementCoverage!,
        schoolCoverage: schoolCoverage!,
        asNewCoverage: asNewCoverage!,
        ...quote,
      })
        .then(({ data }) => {
          if (data.luko) {
            if (data.luko.length > 0)
              setLuko([...data.luko].sort(orderByField("price"))[0]);
            else setLuko(undefined); // no quote
          }
          if (data.lovys) {
            if (data.lovys.length > 0)
              setLovys([...data.lovys].sort(orderByField("price"))[0]);
            else setLovys(undefined); // no quote
          }
          if (data.appenin?.price) setAppenin(data.appenin);
          else if (!!data.appenin) setAppenin(undefined); // no quote
          if (data.acheel) {
            if (data.acheel.length > 0)
              setAcheel([...data.acheel].sort(orderByField("price"))[0]);
            else setAcheel(undefined); // no quote
          }
          if (data.leocare) {
            if (data.leocare.length > 0)
              setLeocare(
                [...data.leocare].sort(orderByField("price"))[0] || null,
              );
            else setLeocare(undefined); // no quote
          }
          setQuotesAlreadyLoaded(true);
        })
        .finally(() => setLoading(false));
    },
    [
      asNewCoverage,
      assistanceCoverage,
      brokenGlassCoverage,
      clientType,
      deductible,
      flatLevel,
      housingCategory,
      housingType,
      lockReplacementCoverage,
      roomNumber,
      schoolCoverage,
      size,
      theftCoverage,
      totalGoodCoverage,
      valueableCoverage,
      setLoading,
    ],
  );

  const { toastError } = useToastsWithIntl(["compare"]);
  const [prevLocation, setPrevLocation] = useState(currentLocation);
  useEffect(() => {
    if (
      currentLocation &&
      currentLocation !== prevLocation &&
      quotesAlreadyLoaded
    ) {
      loadQuotes({
        totalGoodCoverage,
        deductible,
        valueableCoverage,
        theftCoverage,
        brokenGlassCoverage,
        assistanceCoverage,
        lockReplacementCoverage,
        schoolCoverage,
        asNewCoverage,
        address: currentLocation.cleanedAddress,
        postCode: currentLocation.postCode,
        city: currentLocation.city,
      }).catch(() => {
        toastError("compare:getting-quote-for-housing.ERROR");
      });
    }
    setPrevLocation(currentLocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentLocation,
    quotesAlreadyLoaded,
    prevLocation,
    loadQuotes,
    toastError,
  ]);

  return (
    <HousingContext.Provider
      value={{
        housingType,
        flatLevel,
        clientType,
        housingCategory,
        roomNumber,
        size,

        lukoEstimate: luko,
        lovysEstimate: lovys,
        appeninEstimate: appenin,
        acheelEstimate: acheel,
        leocareEstimate: leocare,

        totalGoodCoverage,
        deductible,
        valueableCoverage,
        theftCoverage,
        brokenGlassCoverage,
        assistanceCoverage,
        lockReplacementCoverage,
        schoolCoverage,
        asNewCoverage,

        canRefineQuotes,
        quotesAlreadyLoaded,

        setHousing,
        setRefinements,
        loadQuotes,
      }}
    >
      {children}
    </HousingContext.Provider>
  );
};

export function withProvideHousing<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvideHousing(props: P) {
    return (
      <ProvideHousing>
        <WrappedComponent {...props} />
      </ProvideHousing>
    );
  }

  WithProvideHousing.displayName = `withProvideHousing(${displayName})`;

  return WithProvideHousing;
}

export function useHousing(): HousingAPI {
  return useContext(HousingContext) as HousingAPI;
}

export const coverageTooltips = {
  legalMin:
    "Minimum Légal<br/><br/>La garantie minimum légale est une protection obligatoire que chaque contrat d'assurance doit inclure. Cette garantie de base, vous couvre pour les risques les plus courants, tels que les incendies, les dégâts des eaux et les explosions.<br/><br/>En général, la garantie minimum légale est suffisante pour protéger votre patrimoine immobilier et mobilier, mais elle ne couvre pas tous les risques potentiels. Si vous voulez être protégé contre d'autres risques, tels que le vol, les catastrophes naturelles ou les dégâts causés par un tiers, vous pouvez souscrire des garanties complémentaires.",
  theftCoverage:
    "Vol et Vandalisme<br/><br/>Vous êtes indemnisé si les biens situés dans le logement assuré sont volés à la suite d'une effraction ou d'une agression ; il en va de même pour les dégradations immobilières subies à la suite d'une tentative de vol ou d'un acte de vandalisme.",
  brokenGlass:
    'Bris de glace<br/><br/>La garantie "bris de glace" peut couvrir différents types de vitres, tels que les fenêtres, les portes-fenêtres, les baies vitrées, les vitrines, les vérandas, etc. Elle peut également prendre en charge les frais de remplacement des miroirs cassés ou des plaques de verre utilisées dans les meubles.',
  assistance:
    "Assistance<br/><br>Si votre logement devient inhabitable à la suite d'un sinistre couvert par votre assurance habitation, votre assureur peut prendre en charge votre relogement temporaire ainsi que celui de votre famille. Cette prise en charge peut inclure les frais d'hôtel, de location d'un autre logement ou de tout autre type d'hébergement temporaire.",
  lockSmith:
    "Dépannage Serrurerie<br/><br/>Cette garantie peut prendre en charge vos frais de dépannage en cas de perte, vol, casse ou blocage de clés ou de serrures.",
  scolar:
    "Assurance scolaire<br/><br/>Cette garantie couvre les enfants du foyer lors de leurs activités scolaires (étude, cantine, etc) et extra-scolaires (sport, etc)",
  replacementCost:
    "Remplacement à neuf<br/><br/>Avec cette option, en cas de sinistre, lorsqu’un bien est endommagé par un sinistre, l’assurance vous indemnise d’un montant égal à la valeur actuelle du bien, sans faire valoir de coefficient de vétusté.",
};
