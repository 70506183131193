import loggerBuilder from "../../logger";
import { typedPropertyToValueBuilder } from "../../notion/notion";

const logger = loggerBuilder("compare/moving");

export interface Brand {
  id: string;
  name: string;
  description: JSX.Element;
  logo: string;
  url: string | null;
  relevance: number;
  address: string | null;
  postalCode: string | null;
  city: string | null;
  phone: string | null;
}
export function mapRawBrandsToBrands(rawBrands: unknown[]): Map<string, Brand> {
  return logger.withErrorCatcher(
    () =>
      new Map(
        rawBrands.map((brand: any) => {
          const propertyToValue = typedPropertyToValueBuilder(brand.properties);

          return [
            brand.id,
            {
              id: brand.id,
              name: propertyToValue("Nom", "title"),
              description: propertyToValue("Description", "rich_text").element,
              logo: propertyToValue("Logo", "files")[0],
              url: propertyToValue("Url", "url"),
              relevance: propertyToValue("Pertinence", "number")!,
              address: propertyToValue("Adresse", "rich_text").plain_text,
              city: propertyToValue("Ville", "rich_text").plain_text,
              postalCode: propertyToValue("Code postal", "rich_text")
                .plain_text,
              phone: propertyToValue("Téléphone", "rich_text").plain_text,
            },
          ];
        }),
      ),
    "Error while parsing brands",
  );
}
