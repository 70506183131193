import { useWater } from "../water/waterProvider";
import { Cart, CartType } from "./cart";
import ComparatorListItem from "src/services/ui/common-components/ComparatorListItem";
import { useCart } from "./cartProvider";
import { PricePer } from "../common";

const WaterSection = ({
  elements,
  priceFormat,
  togglePriceFormat,
  disableRemove = false,
}: {
  elements: Cart<CartType.Water>[];
  priceFormat?: PricePer;
  togglePriceFormat?: () => void;
  disableRemove?: boolean;
}): JSX.Element => {
  const { brands } = useWater();
  const { removeFromCart } = useCart();

  return (
    <div>
      <h2 className={"cart-section-title"}>Mon contrat d'eau</h2>

      {elements.length > 1 && (
        <div className={"info info-warning-soft full-w m-bottom-xs"}>
          Veuillez ne conserver qu’une seule de ces offres pour passer à l’étape
          suivante
        </div>
      )}

      <div className={"offers-list"}>
        {elements.map((element) => {
          if (brands.has(element.data.brandId)) {
            const brand = brands.get(element.data.brandId)!;

            return (
              <ComparatorListItem
                key={element.id}
                brand={brand}
                calendlyContext={"water"}
                cart={element}
                onDelete={
                  disableRemove ? undefined : (cartId) => removeFromCart(cartId)
                }
                pricePer={priceFormat}
                togglePricePer={togglePriceFormat}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};

export default WaterSection;
