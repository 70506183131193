import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { object, string } from "yup";
import { LOGIN_LINK } from "../../../routes/public";
import EmailField from "../../forms/EmailField";
import Form from "../../forms/Form";
import FormFooter from "../../forms/FormFooter";
import SubmitButton from "../../forms/SubmitButton";
import ValidationsErrors from "../../forms/ValidationsErrors";
import { useToastsWithIntl } from "../../toast-notifications";
import { useAuth } from "../apiProvider";

const LostPasswordForm = ({
  onCancel,
  onSubmit,
}: {
  onCancel?: () => void;
  onSubmit?: () => void;
}): JSX.Element => {
  const { lostPassword } = useAuth();
  const { toastError, toastSuccess } = useToastsWithIntl(["auth"]);
  const { t } = useTranslation(["auth"]);

  const LostPasswordSchema = useMemo(
    () =>
      object().shape({
        email: string()
          .label(t("auth:email"))
          .required("L'adresse e-mail est requise")
          .email("L'adresse e-mail est requise"),
      }),
    [t],
  );

  return (
    <Form
      initialValues={{ email: "" }}
      onSubmit={(values, { setSubmitting }) => {
        return lostPassword(values.email).then(
          () => {
            setSubmitting(false);
            toastSuccess("auth:lost-password.SUCCESS");
            onSubmit && onSubmit();
          },
          () => {
            setSubmitting(false);
            toastError("auth:lost-password.ERROR");
          },
        );
      }}
      schema={LostPasswordSchema}
    >
      <div className={"form-block"}>
        <label className={"input-label"}>{t("auth:email")} *</label>
        <EmailField name={"email"} placeholder={"e.g. john.doe@mail.com"} />
      </div>

      <ValidationsErrors />

      <FormFooter>
        <SubmitButton block>Envoyer</SubmitButton>
        <div className={"go-back-to-login-cta"}>
          {onCancel ? (
            <button className={"btn-link btn-block"} onClick={onCancel}>
              Retour à la connexion
            </button>
          ) : (
            <Link to={LOGIN_LINK}>Retour à la connexion</Link>
          )}
        </div>
      </FormFooter>
    </Form>
  );
};

export default LostPasswordForm;
