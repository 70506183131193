import { isDate } from "date-fns";

type PropertyType =
  | "rich_text"
  | "title"
  | "checkbox"
  | "relation"
  | "rollup_relation"
  | "number"
  | "url"
  | "phone_number"
  | "select"
  | "multi_select"
  | "files"
  | "date";

type MapPropertyTypeToValue<T> = T extends "title"
  ? string
  : T extends "rich_text"
  ? { plain_text: string; element: JSX.Element }
  : T extends "url"
  ? string | null
  : T extends "phone_number"
  ? string | null
  : T extends "number"
  ? number | null
  : T extends "checkbox"
  ? boolean
  : T extends "relation"
  ? string[]
  : T extends "rollup_relation"
  ? string[]
  : T extends "select"
  ? string | null
  : T extends "multi_select"
  ? string[]
  : T extends "files"
  ? string[]
  : T extends "date"
  ? Date | null
  : unknown;

// NOTE: This method help us to debug, if the property name is not found.
/**
 * Use this methods to get data from a Notion property.
 * Only if you known the type of the property.
 */
export function typedPropertyToValueBuilder(
  properties: any,
): <T extends PropertyType, R = MapPropertyTypeToValue<T>>(
  property: string,
  type: T,
) => R {
  return (property, type) => {
    try {
      return typedPropertyToValue(properties[property], type);
    } catch (error: unknown) {
      throw new Error(`${(error as Error).message}, property: ${property}`);
    }
  };
}

/**
 * Use this methods to get data from a Notion property.
 * Only if you known the type of the property.
 */
export function typedPropertyToValue<
  T extends PropertyType,
  R = MapPropertyTypeToValue<T>
>(property: any, _property_type: T): R {
  try {
    return propertyToValue(property) as R;
  } catch (error: unknown) {
    throw new Error(`${(error as Error).message}, type: ${_property_type}`);
  }
}

/**
 * Use this methods to get data from a Notion property.
 * Only if you dont known the type of the property.
 */
export function propertyToValue(
  property: Record<string, any> & { type: PropertyType },
): any {
  switch (property.type) {
    case "rich_text":
      const plain_text = property.rich_text
        .map((item: any) => item.plain_text)
        .join("");

      const bold = (text: JSX.Element) => <strong>{text}</strong>;
      const italic = (text: JSX.Element) => <em>{text}</em>;
      const underline = (text: JSX.Element) => <u>{text}</u>;
      const strikethrough = (text: JSX.Element) => <del>{text}</del>;
      const code = (text: JSX.Element) => <code>{text}</code>;
      const color = (text: JSX.Element, color: string) => (
        <span className={color}>{text}</span>
      );
      const components = property.rich_text.map((item: any) => {
        const transformations = Object.entries(item.annotations).map(
          ([key, value]) => {
            if (key === "bold" && value === true) return bold;
            if (key === "italic" && value === true) return italic;
            if (key === "underline" && value === true) return underline;
            if (key === "strikethrough" && value === true) return strikethrough;
            if (key === "code" && value === true) return code;
            if (key === "color" && value !== "default")
              return (text: JSX.Element) => color(text, value as string);
            return (text: JSX.Element) => text;
          },
        );

        const replace_linebreaks: JSX.Element = item.plain_text
          .split("\n")
          .map((text: string, idx: number, array: unknown[]) => (
            <span key={`linebreak-${idx}`}>
              {text}
              {idx !== array.length - 1 && <br />}
            </span>
          ));

        return transformations.reduce(
          (final, transformation) => transformation(final),
          replace_linebreaks,
        );
      });
      const element = (
        <span>
          {components.map((element: JSX.Element, idx: number) => (
            <span key={`compo-${idx}`}>{element}</span>
          ))}
        </span>
      );

      return { plain_text, element };
    case "title":
      return property.title.map((item: any) => item.plain_text).join("");
    case "number":
      return property.number;
    case "url":
      return property.url;
    case "phone_number":
      return property.phone_number;
    case "checkbox":
      return property.checkbox;
    case "relation":
      return property.relation.map((item: any) => item.id);
    case "rollup_relation":
      // BUG: rollup_relation does not return the id of the relation, Notion bug
      return property.rollup.array.flatMap((item: any) => item.relation.id);
    case "select":
      return property.select ? property.select.name : null;
    case "multi_select":
      return property.multi_select.map((item: any) => item.name);
    case "files":
      return property.files.map((item: any) => item.file.url);
    case "date":
      if (!property.date) return null;
      const date = new Date(property.date.start);
      return isDate(date) ? date : null;
    default:
      throw new Error(`Unknown notion property type: ${property.type}`);
  }
}
