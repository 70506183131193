import { PUBLIC } from "./public";

export const CART = "/cart" as const;
const COMPOSABLE_CART_LINK = `${PUBLIC}${CART}` as const;
export const CART_LINK = `${COMPOSABLE_CART_LINK}/` as const;

export const CART_PERSONAL_INFOS = "/personal" as const;
export const CART_PERSONAL_INFOS_LINK = `${COMPOSABLE_CART_LINK}${CART_PERSONAL_INFOS}/` as const;

export const CART_CONTRACT = "/contract" as const;
export const CART_CONTRACT_LINK = `${COMPOSABLE_CART_LINK}${CART_CONTRACT}/` as const;

export const CART_SUBMITTED = "/submitted" as const;
export const CART_SUBMITTED_LINK = `${COMPOSABLE_CART_LINK}${CART_SUBMITTED}/` as const;
