import { DetailedHTMLProps, HTMLAttributes } from "react";
import { cx } from "@emotion/css";

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const CardBody = ({ className, ...cardBodyProps }: Props): JSX.Element => {
  return <div {...cardBodyProps} className={cx("card-body", className)} />;
};

export default CardBody;
