import { useRef } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import useOnScreen from "../useOnScreen";
import iconArrowDown from "../../../assets/img/icons/icon-arrow-down-blue.svg";

const LoadMoreList = <Item,>({
  items,
  render,
  paginatedBy,
  loadMoreLabel = "Afficher plus",
  anchorOffset = "0px",
}: {
  items: Item[];
  render: (item: Item) => JSX.Element | null;
  paginatedBy: number;
  loadMoreLabel?: string;
  anchorOffset?: string;
}): JSX.Element => {
  const [loadedAmount, setLoadedAmount] = useState(paginatedBy);
  const anchor = useRef<HTMLDivElement>(null);

  // Memos
  const loadedItems = useMemo(() => items.slice(0, loadedAmount), [
    items,
    loadedAmount,
  ]);
  const hasNextPage = useMemo(() => loadedAmount < items.length, [
    loadedAmount,
    items.length,
  ]);
  const isAnchorVisible = useOnScreen(anchor, `${anchorOffset}`);

  // Effects
  // NOTE: When the items change, we reset the page to 1.
  useEffect(() => {
    setLoadedAmount(paginatedBy);
  }, [items, paginatedBy]);

  // Methods
  const gotoNextPage = useCallback(
    () => setLoadedAmount((prev) => prev + paginatedBy),
    [paginatedBy],
  );

  return (
    <div className={"offers-list"}>
      <div>
        <div
          ref={anchor}
          style={{ opacity: 0, top: `-${anchorOffset}`, position: "relative" }}
        />
        {loadedItems.map((item) => render(item))}
      </div>

      <div className={"pagination"}>
        {hasNextPage && (
          <button
            className={"floating-btn"}
            onClick={() => gotoNextPage()}
            type={"button"}
          >
            <img alt={"arrow down"} className={"icon"} src={iconArrowDown} />
            {loadMoreLabel}
          </button>
        )}
      </div>

      {!isAnchorVisible && (
        <button
          className={"btn-back-to-top"}
          onClick={() => anchor.current?.scrollIntoView({ behavior: "smooth" })}
          type={"button"}
        >
          Haut de la liste
        </button>
      )}
    </div>
  );
};

export default LoadMoreList;
