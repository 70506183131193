import ImgStep1 from "../../assets/img/step1.png";
import ImgStep2 from "../../assets/img/step2.png";
import ImgStep3 from "../../assets/img/step3.png";

const HowItWorksMain = (): JSX.Element => {
  return (
    <div className={"how-it-works"}>
      <h2 className={"title"}>Comment fonctionne le comparateur Movecool ?</h2>
      <div className={"steps"}>
        <div className={"step"}>
          <img alt={"1"} src={ImgStep1} />
          <p>
            Je complète <strong>mes informations</strong>
          </p>
        </div>
        <div className={"step"}>
          <img alt={"2"} src={ImgStep2} />
          <p>
            Je choisis <strong>l'offre qui me correspond le mieux</strong>
          </p>
        </div>
        <div className={"step"}>
          <img alt={"3"} src={ImgStep3} />
          <p>
            Je souscris <strong>en ligne ou par téléphone</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowItWorksMain;
