import { Offer, formatBandwidthDisplay } from "./internet";
import iconNetwork from "../../../assets/img/icons/icon-network-light-blue.svg";
import iconTV from "../../../assets/img/icons/icon-tv-light-blue.svg";
import iconPhone from "../../../assets/img/icons/icon-phone-light-blue.svg";
import iconGift from "../../../assets/img/icons/icon-gift-light-blue.svg";
import { cx } from "@emotion/css";

const FeaturesList = ({
  offer,
  className,
}: {
  offer: Offer;
  className?: string;
}): JSX.Element => {
  return (
    <div className={cx("features-list", className)}>
      <div className={"feature"}>
        <img alt={"icône débit"} className={"icon"} src={iconNetwork} /> Débit
        descendant jusqu'à {formatBandwidthDisplay(offer.bandwidth)}
      </div>

      {offer.tvChannelsAmount > 0 && (
        <div className={"feature"}>
          <img alt={"icône tv"} className={"icon"} src={iconTV} />{" "}
          {offer.tvChannelsAmount} chaînes de télévision
        </div>
      )}

      {offer.phone && (
        <div className={"feature"}>
          <img alt={"icône téléphone"} className={"icon"} src={iconPhone} />{" "}
          {offer.phone}
        </div>
      )}

      {offer.options.map((option) => (
        <div key={option} className={"feature"}>
          <img alt={"icône cadeau"} className={"icon"} src={iconGift} />{" "}
          {option}
        </div>
      ))}
    </div>
  );
};

export default FeaturesList;
