import React, { ReactElement } from "react";
import {
  LinkProps as RRLinkProps,
  Link as RRLink,
  useLocation,
} from "react-router-dom";
import { ExtractRouteParams } from "../types";
import generatePath from "../generatePath";

interface BaseProps extends React.PropsWithoutRef<RRLinkProps> {
  queryParams?: Record<string, unknown>;
}

interface OwnProps<T extends string> extends BaseProps {
  to: T;
  params?: never;
}
interface OwnPropsWithParams<T extends string> extends BaseProps {
  to: T;
  params: ExtractRouteParams<T>;
}

export type Props<T extends string> = (T extends
  | `${string}:${string}/${string}`
  | `${string}:${string}`
  ? OwnPropsWithParams<T>
  : OwnProps<T>) &
  React.RefAttributes<HTMLAnchorElement>;

const Link = <T extends string>({
  to,
  params,
  queryParams,
  ...restProps
}: Props<T>): ReactElement => {
  const location = useLocation();
  const state = location.state as Record<string | number | symbol, unknown>;

  return (
    <RRLink
      {...restProps}
      state={{
        ...state,
        history: [...((state?.history as []) || []), location.pathname],
      }}
      to={generatePath(to, params, queryParams)}
    />
  );
};

export default Link;
