import { PricePer } from "../common";
import { useConsumption } from "./consumptionProvider";

export const ConsumptionFormatButton = ({
  overrideFormat,
  onClick,
}: {
  overrideFormat?: PricePer;
  onClick?: (pricePer: PricePer) => void;
}): JSX.Element => {
  const { priceFormat, setPriceFormat } = useConsumption();
  const finalPriceFormat =
    overrideFormat !== undefined ? overrideFormat : priceFormat;

  return (
    <button
      className={"unit-switch-btn"}
      onClick={(ev) => {
        ev.stopPropagation();
        if (onClick) {
          onClick(finalPriceFormat);
        } else {
          setPriceFormat((prev) =>
            prev === PricePer.Month ? PricePer.Year : PricePer.Month,
          );
        }
      }}
      type={"button"}
    >
      / <span>{finalPriceFormat === PricePer.Month ? "mois" : "an"}</span>
    </button>
  );
};

export default ConsumptionFormatButton;
