import { useNavigate as rrdUseNavigate } from "react-router-dom";
import generatePath from "./generatePath";

const useNavigate = (): ((
  path?: string,
  params?: Record<string, unknown>,
  queryParams?: Record<string, number | string | boolean | null>,
) => void) => {
  const navigate = rrdUseNavigate();
  return (path = "/", params = {}, queryParams) =>
    navigate(generatePath(path, params, queryParams));
};

export default useNavigate;
