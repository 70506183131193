import { typedPropertyToValueBuilder } from "../../notion/notion";
import loggerBuilder from "../../logger";

const logger = loggerBuilder("compare/energy");

export enum EnergyType {
  Electricity = "Électricité",
  Gas = "Gaz",
  Dual = "Dual",
}

export enum OfferFeature {
  GreenEnergy = "Énergie Verte",
  AwardedClientService = "Service client récompensé",
  PhoneClientService = "Service client téléphonique",
  OnlineClientSpace = "Espace client en ligne",
  FirstFrenchAlternativeProvider = "1er fournisseur alternatif de France",
  SecondFrenchAlternativeProvicer = "2nd fournisseur alternatif de France",
  ThirdFrenchAlternativeProvider = "3ème fournisseur alternatif de France",
  FourthFrenchAlternativeProvider = "4ème fournisseur alternatif de France",
  FifthFrenchAlternativeProvider = "5ème fournisseur alternatif de France",
  SixthBiggestEuropeanProvider = "6ème plus gros fournisseur européen",
  Favorite = "Coup de coeur",
  Default = 0,
}
function mapStringToOfferFeature(str: string): OfferFeature {
  if (Object.values(OfferFeature).includes(str)) {
    return str as OfferFeature;
  } else {
    logger.warn(`Unknown OfferFeature: ${str}`);
    return OfferFeature.Default;
  }
}

export interface Offer {
  id: string;
  name: string;
  description: JSX.Element;
  price: number;
  subscription: number;
  type: EnergyType;
  brandId: Brand["id"];
  url: string | null;
  fixed_price: number | null;
  relevance: number;
  highlights: string[];
  promotions: string[];
  good_points: JSX.Element;
  bad_points: JSX.Element;
  updated_at: Date | null;
  features: OfferFeature[];
  pricingDoc: string | null;
  withdrawn: boolean | null;
}
export function mapRawOffersToOffers(offers: unknown[]): Map<string, Offer> {
  return logger.withErrorCatcher(
    () =>
      new Map(
        offers.map((offer: any) => {
          logger.debug(`Mapping offer: ${offer.id}`);
          const propertyToValue = typedPropertyToValueBuilder(offer.properties);

          const highlights = propertyToValue("3 Best Offer", "select");
          const promotions = propertyToValue("Promotion", "select");
          const fixed_price = propertyToValue("Tarif fixe (ans)", "number")!;

          return [
            offer.id,
            {
              id: offer.id,
              name: propertyToValue("Nom", "title"),
              description: propertyToValue("Description", "rich_text").element,
              // NOTE: Originally, the price was stored in cts/kWh, but we now store it in €/kWh
              price: propertyToValue("Prix (€/kWh)", "number")! * 100,
              subscription: propertyToValue("Abonnement (€/mois)", "number")!,
              type: propertyToValue("Type", "select") as EnergyType,
              brandId: propertyToValue("Marque", "relation")[0],
              url: propertyToValue("Url", "url"),
              fixed_price:
                fixed_price !== null && fixed_price !== 0 ? fixed_price : null,
              relevance: propertyToValue("Pertinence", "number")!,
              highlights: highlights ? [highlights] : [],
              promotions: promotions ? [promotions] : [],
              good_points: propertyToValue("Les plus", "rich_text").element,
              bad_points: propertyToValue("Les moins", "rich_text").element,
              updated_at: propertyToValue("Date de MAJ", "date"),
              features: propertyToValue("Features", "multi_select").map(
                mapStringToOfferFeature,
              ),
              pricingDoc: propertyToValue("Grille tarifaire", "files")[0],
              withdrawn: propertyToValue("Offre retirée du marché", "checkbox"),
            },
          ];
        }),
      ),
    "Error while parsing offers",
  );
}

export interface Brand {
  id: string;
  name: string;
  description: JSX.Element;
  logo: string;
  url: string | null;
  postalCodes: string[];
  offersIds: string[];
}
export function mapRawBrandsToBrands(rawBrands: unknown[]): Map<string, Brand> {
  return logger.withErrorCatcher(
    () =>
      new Map(
        rawBrands.map(({ brand, eld }: any) => {
          logger.debug(`Mapping brand: ${brand.id}`);
          const propertyToValue = typedPropertyToValueBuilder(brand.properties);
          const eldPropertyToValue =
            eld !== null ? typedPropertyToValueBuilder(eld.properties) : null;

          return [
            brand.id,
            {
              id: brand.id,
              name: propertyToValue("Nom", "title"),
              description: propertyToValue("Description", "rich_text").element,
              logo: propertyToValue("Logo", "files")[0],
              url: propertyToValue("Url", "url"),
              postalCodes:
                eldPropertyToValue !== null
                  ? eldPropertyToValue("postal_codes", "multi_select")
                  : [],
              offersIds: propertyToValue("Offres", "relation"),
            },
          ];
        }),
      ),
    "Error while parsing brands",
  );
}

export interface Dual {
  id: string;
  name: string;
  description: JSX.Element;
  electricity: Offer["id"];
  gas: Offer["id"];
  relevance: number;
  highlights: string[];
  promotions: string[];
  updated_at: Date | null;
  features: OfferFeature[];
}
export function mapRawDualsToDuals(rawDuals: unknown[]): Map<string, Dual> {
  return logger.withErrorCatcher(
    () =>
      new Map(
        rawDuals.map((dual: any) => {
          logger.debug(`Mapping dual: ${dual.id}`);
          const propertyToValue = typedPropertyToValueBuilder(dual.properties);

          const highlights = propertyToValue("3 Best Offer", "select");
          const promotions = propertyToValue("Promotion", "select");

          return [
            dual.id,
            {
              id: dual.id,
              name: propertyToValue("Nom", "title"),
              description: propertyToValue("Description", "rich_text").element,
              electricity: propertyToValue("Offre Élec", "relation")[0],
              gas: propertyToValue("Offre Gaz", "relation")[0],
              relevance: propertyToValue("Pertinence", "number")!,
              highlights: highlights ? [highlights] : [],
              promotions: promotions ? [promotions] : [],
              updated_at: propertyToValue("Date de MAJ", "date"),
              features: propertyToValue("Features", "multi_select").map(
                mapStringToOfferFeature,
              ),
            },
          ];
        }),
      ),
    "Error while parsing duals",
  );
}
export interface DualWithOffers extends Dual {
  electricityOffer: Offer;
  gasOffer: Offer;
}
